import { SubscriptionPriceContainer } from '@app/domain/user/components/subscription/SubscriptionPriceContainer';
import { ECurrency, EPlanCycle } from '@app/swagger-types';
import { UIStyledSection } from '../../components/UIStyledSection';

export const UISectionSubscriptionPrice: React.FC = () => {
  return (
    <UIStyledSection title="SubscriptionPriceContainer">
      <div className="flex flex-wrap gap-4">
        {CASES.map((e, i) => {
          return (
            <div key={i} className="flex gap-4 p-2 hover:ring-1">
              <SubscriptionPriceContainer planPrice={e.planPrice} compareToPlanPrice={e.compareToPlanPrice} />
            </div>
          );
        })}
      </div>
    </UIStyledSection>
  );
};

const CASES: React.ComponentProps<typeof SubscriptionPriceContainer>[] = [
  {
    planPrice: {
      price: 25,
      planCycle: EPlanCycle.MONTHLY,
      planCurrency: ECurrency.EUR,
    },
  },
  {
    planPrice: {
      price: 250,
      planCycle: EPlanCycle.YEARLY,
      planCurrency: ECurrency.EUR,
    },
  },
  {
    planPrice: {
      price: 250,
      planCycle: EPlanCycle.YEARLY,
      planCurrency: ECurrency.EUR,
    },
    compareToPlanPrice: {
      price: 25,
      planCycle: EPlanCycle.MONTHLY,
      planCurrency: ECurrency.EUR,
    },
  },
  {
    planPrice: {
      price: 0,
      planCycle: EPlanCycle.QUARTERLY,
      planCurrency: ECurrency.EUR,
    },
  },
  {
    planPrice: {
      price: 0,
      planCycle: EPlanCycle.YEARLY,
      planCurrency: ECurrency.EUR,
    },
    compareToPlanPrice: {
      price: 0,
      planCycle: EPlanCycle.QUARTERLY,
      planCurrency: ECurrency.EUR,
    },
  },
];

import { FORMAT } from '@app/constants/formats';
import { clsxm } from '@app/styles/clsxm';
import { EPlanStatus, EPlanType } from '@app/swagger-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const SubscriptionStatusBadge: React.FC<{
  endOfSubscriptionDate?: string;
  planType?: EPlanType;
  planStatus?: EPlanStatus;
  onClick?: () => void;
  shortVariant?: boolean;
  className?: string;
}> = ({ endOfSubscriptionDate, planType, planStatus, onClick, shortVariant, className }) => {
  const { t } = useTranslation();
  const expireDate = endOfSubscriptionDate ? new Date(endOfSubscriptionDate) : undefined;
  const friendlyExpireDate = expireDate ? format(expireDate, FORMAT.DATE_YEAR) : undefined;
  const isTrial = planType === EPlanType.TRIAL;
  const isExpired = expireDate ? expireDate.getTime() < Date.now() : false;
  const isActive = !isExpired && planStatus === EPlanStatus.ACTIVE;
  const isCanceled = !isExpired && planStatus === EPlanStatus.CANCELED;
  const textPrefix = (() => {
    if (isExpired) {
      return t('subscription.expired');
    }
    if (shortVariant) {
      if (isActive) {
        return t('subscription.active');
      }
      if (isCanceled) {
        return t('subscription.canceled');
      }
    }
    if (isTrial) {
      return t('subscription.expiresOn');
    }
    if (isActive) {
      return t('subscription.renews');
    }
    if (isCanceled) {
      return t('subscription.cancels');
    }
    return '';
  })();

  return (
    <div className="inline-block">
      <div
        className={clsxm(
          'inline-flex items-center rounded px-2 py-[2px] text-xs',
          onClick && 'cursor-pointer',
          isTrial && 'bg-orange-200 text-orange-800',
          isExpired && 'bg-red-200 text-red-800',
          (shortVariant || !isTrial) && isActive && 'bg-accept-light text-accept-main',
          !isTrial && isCanceled && 'bg-gray-200 text-gray-800',
          className
        )}
        onClick={() => {
          onClick?.();
        }}
        aria-hidden
      >
        {textPrefix} {!shortVariant && friendlyExpireDate}
      </div>
    </div>
  );
};

import { Routes } from '@app/constants/routes';

import { ERole } from '@app/swagger-types';
import { MyRouteObject } from '@app/models/router';
import { TypedNavigate } from '@app/router';
import { AdminModule } from './AdminModule';
import { UserManagementRoutes } from './modules/user-management/UserManagementRoutes';
import { AdminDashboardPage } from './pages/qr/AdminDashboardPage';
import { TestPage } from './pages/TestPage';
import { AdminQRListPage } from './pages/qr/AdminQRListPage';
import { AdminQREditPage } from './pages/qr/AdminQREditPage';
import { AdminEmailListPage } from './pages/email/AdminEmailListPage';
import { AdminBriefPage } from './pages/brief/AdminBriefPage';

export const AdminRoutes: MyRouteObject = {
  path: Routes.admin.index,
  element: <AdminModule />,
  role: [ERole.ROLE_ADMIN],
  children: [
    { path: Routes.admin.manage_accounts, element: <AdminDashboardPage /> },

    { path: Routes.admin.qr.index, element: <AdminQRListPage /> },
    { path: Routes.admin.qr.edit, element: <AdminQREditPage /> },

    { path: Routes.admin.email.index, element: <AdminEmailListPage /> },
    { path: Routes.admin.brief.index, element: <AdminBriefPage /> },

    { path: Routes.admin.test_page, element: <TestPage /> },
    UserManagementRoutes,
    { index: true, path: '*', element: <TypedNavigate to={Routes.admin.user_management.index} /> },
  ],
};

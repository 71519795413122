import { DutchIcon, EnglishIcon, FrenchIcon, GermanIcon, ItalianIcon, SpanishIcon } from '@app/assets';
import { ELanguage } from '@app/swagger-types';
import { renderFriendlyLanguage } from '../utils/render-friendly-text.utils';

export const LANGUAGE_CODES = {
  [ELanguage.ENGLISH]: 'en-US',
  [ELanguage.GERMAN]: 'de-DE',
  [ELanguage.DUTCH]: 'nl-NL',
  [ELanguage.SPANISH]: 'es-ES',
  [ELanguage.FRENCH]: 'fr-FR',
  [ELanguage.ITALIAN]: 'it-IT',
} as const;

export const LANGUAGE_ITEMS = [
  {
    title: renderFriendlyLanguage(ELanguage.ENGLISH),
    value: LANGUAGE_CODES[ELanguage.ENGLISH],
    icon: <EnglishIcon />,
  },
  {
    title: renderFriendlyLanguage(ELanguage.GERMAN),
    value: LANGUAGE_CODES[ELanguage.GERMAN],
    icon: <GermanIcon />,
  },
  {
    title: renderFriendlyLanguage(ELanguage.DUTCH),
    value: LANGUAGE_CODES[ELanguage.DUTCH],
    icon: <DutchIcon />,
  },
  {
    title: renderFriendlyLanguage(ELanguage.SPANISH),
    value: LANGUAGE_CODES[ELanguage.SPANISH],
    icon: <SpanishIcon />,
  },
  {
    title: renderFriendlyLanguage(ELanguage.FRENCH),
    value: LANGUAGE_CODES[ELanguage.FRENCH],
    icon: <FrenchIcon />,
  },
  {
    title: renderFriendlyLanguage(ELanguage.ITALIAN),
    value: LANGUAGE_CODES[ELanguage.ITALIAN],
    icon: <ItalianIcon />,
  },
];

export const getLanguageForApi = (language?: string) => {
  switch (language) {
    case 'en-US':
      return ELanguage.ENGLISH;
    case 'de-DE':
      return ELanguage.GERMAN;
    case 'nl-NL':
      return ELanguage.DUTCH;
    case 'es-ES':
      return ELanguage.SPANISH;
    case 'fr-FR':
      return ELanguage.FRENCH;
    case 'it-IT':
      return ELanguage.ITALIAN;
    default:
      return ELanguage.ENGLISH;
  }
};

import { TranslationKey } from '@app/locales/defaultTranslation';
import { TEMPLATE_STEPPER_STEPS_TYPE } from '@app/domain/template/components/TemplateStepper';

export const getStepTranslationKey = (step: TEMPLATE_STEPPER_STEPS_TYPE): TranslationKey | null => {
  if (step === 'Logo') {
    return 'qr.stepCustomize.logo.tabTitle';
  }
  if (step === 'Frames') {
    return 'qr.stepCustomize.frame.tabTitle';
  }
  if (step === 'Pattern') {
    return 'qr.stepCustomize.pattern.tabTitle';
  }
  if (step === 'Templates') {
    return 'qr.stepCustomize.template.tabTitle';
  }
  return null;
};

import { PaginatedResponse } from '@app/api/api.type';
import { GET_LIBRARY_BY_ID_QUERY, GET_LIBRARY_QUERY } from '@app/constants/query-api-configs';
import { LibraryInDto, LibraryOutDto } from '@app/swagger-types';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { APILibraryQueryParams } from '../library.types';
import { LibraryApi } from './library.api';

export const useGetLibrary = (
  params: APILibraryQueryParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<LibraryOutDto>,
    (typeof GET_LIBRARY_QUERY.name | APILibraryQueryParams)[]
  >
) => {
  return useQuery(
    [GET_LIBRARY_QUERY.name, params],
    async () => {
      return await LibraryApi.getAll(params);
    },
    {
      staleTime: GET_LIBRARY_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useCreateLibrary = ({
  onSuccess,
  ...options
}: UseMutationOptions<PaginatedResponse<LibraryOutDto>, Error, LibraryInDto> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (Dto) => {
      return await LibraryApi.create(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_LIBRARY_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useDeleteLibrary = ({ onSuccess, ...options }: UseMutationOptions<unknown, Error, string> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (Dto) => {
      return await LibraryApi.delete(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_LIBRARY_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useGetLibraryById = (
  id?: string,
  options?: UseQueryOptions<
    unknown,
    Error,
    LibraryOutDto | undefined,
    (typeof GET_LIBRARY_BY_ID_QUERY.name | string | undefined)[]
  >
) => {
  return useQuery(
    [GET_LIBRARY_BY_ID_QUERY.name, id],
    async () => {
      if (!id) {
        return;
      }
      return await LibraryApi.publicGetById(id);
    },
    {
      staleTime: GET_LIBRARY_BY_ID_QUERY.config.staleTime,
      ...options,
    }
  );
};

import { useQueryParamsPrefix } from '@app/hooks/useQueryParamsPrefix.hook';
import { StringParam } from '@app/utils/use-query-params.utils';
import { withDefault } from 'use-query-params';

export const useTemplatesQueryParams = () => {
  return useQueryParamsPrefix({
    paramConfigMap: {
      term: withDefault(StringParam, ''),
    },
  });
};

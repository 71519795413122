import { inferIdentity } from './type.utils';

type BaseConfig = {
  name: string;
  searchParam?: string;
  config?: { staleTime: number };
} & (
  | {
      variant: 'base';
    }
  | {
      variant: 'table';
      tablePrefix: string;
      defaultSort: string;
    }
);

export const identityQueryConfig = inferIdentity<BaseConfig>();

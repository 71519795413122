import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { PARAM_GOOGLE_CLICK_ID } from './google.constant';

export const useGoogleClickId = () => {
  /**
   * "gclid" is query param added by Google Ads from ad links
   * how to test: search to buy shoes in google and click on sponsored ad link, inspect URL
   */
  const [gclid] = useQueryParam('gclid', withDefault(StringParam, null));
  const [queryClickId] = useQueryParam(PARAM_GOOGLE_CLICK_ID, withDefault(StringParam, null));
  let googleClickId = gclid || queryClickId;
  if (googleClickId) {
    localStorage.setItem(PARAM_GOOGLE_CLICK_ID, googleClickId);
  } else {
    googleClickId = localStorage.getItem(PARAM_GOOGLE_CLICK_ID);
  }
  return { googleClickId: googleClickId || undefined };
};

import { Box, Collapse } from '@mui/material';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox, Typography } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { ECodeType } from '@app/swagger-types';

export enum ECodeTypeALL {
  ALL = 'ALL',
  NON_ALL = 'NON_ALL',
}

export const QRCodeMobileTypes: React.FC<{ typeValues: string[]; handleTypeValues: (v: string[]) => void }> = ({
  typeValues,
  handleTypeValues,
}) => {
  const [open, setOpen] = useState(false);

  const getCheckboxValue = (v: ECodeType | ECodeTypeALL) => {
    return !!typeValues.find((t) => t === v);
  };

  const isAllSelected = Object.keys(ECodeType).length > 0 && typeValues.length === Object.keys(ECodeType).length;

  return (
    <div>
      <Box
        onClick={() => {
          setOpen((prev) => !prev);
        }}
        className="flex items-center justify-between gap-4 hover:cursor-pointer"
      >
        <Typography className="font-semibold">Types</Typography>
        <span>
          <KeyboardArrowDownIcon className={clsxm('text-8 transition-all', !open && '-rotate-90')} />
        </span>
      </Box>
      <Collapse in={open} className="pl-2">
        <div className="mt-2">
          <Checkbox
            label="All"
            checked={isAllSelected}
            onChange={() => {
              if (isAllSelected) {
                handleTypeValues([]);
              } else {
                handleTypeValues(Object.keys(ECodeType));
              }
            }}
          />
          <Checkbox
            label="Website"
            checked={getCheckboxValue(ECodeType.WEBSITE)}
            onChange={() => {
              if (getCheckboxValue(ECodeType.WEBSITE)) {
                handleTypeValues(typeValues.filter((t) => t !== ECodeType.WEBSITE));
              } else {
                handleTypeValues([...typeValues, ECodeType.WEBSITE]);
              }
            }}
          />
          <Checkbox
            label="Multi links"
            checked={getCheckboxValue(ECodeType.MULTILINK)}
            onChange={() => {
              if (getCheckboxValue(ECodeType.MULTILINK)) {
                handleTypeValues(typeValues.filter((t) => t !== ECodeType.MULTILINK));
              } else {
                handleTypeValues([...typeValues, ECodeType.MULTILINK]);
              }
            }}
          />
          <Checkbox
            label="Pdf"
            checked={getCheckboxValue(ECodeType.PDF)}
            onChange={() => {
              if (getCheckboxValue(ECodeType.PDF)) {
                handleTypeValues(typeValues.filter((t) => t !== ECodeType.PDF));
              } else {
                handleTypeValues([...typeValues, ECodeType.PDF]);
              }
            }}
          />
          <Checkbox
            label="vCard Plus"
            checked={getCheckboxValue(ECodeType.V_CARD)}
            onChange={() => {
              if (getCheckboxValue(ECodeType.V_CARD)) {
                handleTypeValues(typeValues.filter((t) => t !== ECodeType.V_CARD));
              } else {
                handleTypeValues([...typeValues, ECodeType.V_CARD]);
              }
            }}
          />
        </div>
      </Collapse>
    </div>
  );
};

import styles from './QRCodeModule.module.scss';
import { Outlet } from 'react-router-dom';

import { clsxm } from '@app/styles/clsxm';
import { Routes } from '@app/constants/routes';

export const QRCodeModule = () => {
  // TODO refactor routing to avoid conflicts with root qr id scanning
  if (
    location.href.startsWith(Routes.admin.index) ||
    location.href.startsWith(Routes.auth.index) ||
    location.href.startsWith(`${Routes.customer.index}/`)
  ) {
    window.location.href = '/';
    return null;
  }

  return (
    <div className={clsxm('relative flex w-full flex-1 bg-accept-light-bg', styles.root)}>
      <div className={styles['app-content']}>
        <main className={clsxm('w-full')}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

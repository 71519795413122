// TODO optimize assets. Render background separately from image on it + add animations.
import rawImageSrcMultilink from './images/multilink-background.jpg';
import rawImageSrcPdf from './images/pdf-background.jpg';
import rawImageSrcVCard from './images/vcard-background.jpg';
import rawImageSrcFrames from './images/frames-background.jpg';
import rawImageSrcPatterns from './images/patterns-background.jpg';
import rawImageSrcLogos from './images/logos-background.jpg';
import rawImageSrcScans from './images/scans-background.jpg';
import rawAppLogoUrl from '@app/assets/images/app-logo.svg';

import { IS_LOCALHOST, typedEnv } from '@app/environment/typed-env';

const prefix = IS_LOCALHOST ? '' : typedEnv.REACT_APP_HOST_URL;
export const getRawImageUrl = {
  rawImageSrcMultilink: `${prefix}${rawImageSrcMultilink}`,
  rawImageSrcPdf: `${prefix}${rawImageSrcPdf}`,
  rawImageSrcVCard: `${prefix}${rawImageSrcVCard}`,
  rawImageSrcFrames: `${prefix}${rawImageSrcFrames}`,
  rawImageSrcPatterns: `${prefix}${rawImageSrcPatterns}`,
  rawImageSrcLogos: `${prefix}${rawImageSrcLogos}`,
  rawImageSrcScans: `${prefix}${rawImageSrcScans}`,
  rawAppLogoUrl: `${prefix}${rawAppLogoUrl}`,
};

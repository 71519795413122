import React from 'react';

import { Routes } from '@app/constants/routes';

import { SidebarRouteLink } from '@app/models/router';

import { DrawerProps as MuiDrawerProps } from '@mui/material';
import { AnalyticsIcon } from '@app/assets';
import { CreateQRCodeIcon, DashboardIcon, QrCodeIcon, TemplatesIcon } from '@app/assets';
import { CustomerNavSidebar } from '../nav-sidebar/customer/CustomerNavSidebar';

const navItems: SidebarRouteLink[][] = [
  [
    {
      path: Routes.customer.create_qr_code,
      icon: <CreateQRCodeIcon />,
      name: 'Create QR Code',
      translationKey: 'createQRCode.pageTitle',
    },
    {
      path: Routes.customer.dashboard,
      icon: <DashboardIcon />,
      name: 'Dashboard',
      translationKey: 'dashboard.pageTitle',
    },
    {
      path: Routes.customer.my_qr_codes.index,
      icon: <QrCodeIcon />,
      name: 'My QR Codes',
      translationKey: 'myQRCodes.pageTitle',
    },
    {
      path: Routes.customer.analytics.index,
      icon: <AnalyticsIcon />,
      name: 'Analytics',
      translationKey: 'qrAnalytics.pageTitle',
    },
    {
      path: Routes.customer.templates.index,
      icon: <TemplatesIcon />,
      name: 'Templates',
      translationKey: 'templates.pageTitle',
    },
  ],
];

export const CustomerSidebarMenu: React.FC<MuiDrawerProps> = ({ ...rest }) => {
  // FETCH COUNTS FROM REALTIME / API
  const badgesData: Record<string, number> = {
    testBadge: 25,
  };

  return <CustomerNavSidebar navItems={navItems} {...rest} badgesData={badgesData} />;
};

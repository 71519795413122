import { typedEnv } from '@app/environment/typed-env';
import { briefApiService, validateAndAssert } from '@app/services/api-service';
import { BriefGenerateOutDtoSchema } from './brief.schema';
import { BriefGenerateInDto } from './brief.type';

export abstract class BriefApi {
  static getLinkForFile(filename: string) {
    return `${typedEnv.REACT_APP_API_BRIEF_SERVICE_BASE_URL}/public/brief/download/${encodeURIComponent(filename)}`;
  }

  static async generateBrief(data: BriefGenerateInDto) {
    return validateAndAssert(briefApiService.put('/private/brief/generate', data), BriefGenerateOutDtoSchema);
  }
}

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageForApi } from './i18.utils';

export const useCurrentLanguage = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const memoLang = useMemo(() => {
    return getLanguageForApi(language);
  }, [language]);
  return memoLang;
};

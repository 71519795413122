import { Autocomplete, Button, Form, Input, Typography } from '@app/components';
import { BillingOutDtoFormSchema, BillingOutDtoSchema } from '@app/domain/billing/api/billing.schema';
import { useHandler } from '@app/hooks/useHandler.hook';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@app/hoc';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';
import { usePatchMyBillingInfo } from '@app/domain/billing/api/billing.hooks.api';
import iso from 'iso-3166-1';
import { useState } from 'react';
import { Country } from 'iso-3166-1/dist/iso-3166';
import { ALL_COUNTRIES } from '@app/domain/billing/billing.constants';

export const AccountBillingInfoDialog: React.FC<
  NiceModalHocProps & {
    defaultValues?: BillingOutDtoSchema;
  }
> = NiceModal.create(({ defaultValues }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const isMobile = useIsMobile();
  const [search, setSearch] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    defaultValues?.country ? iso.whereAlpha2(defaultValues.country) || null : null
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<BillingOutDtoFormSchema>({
    resolver: yupResolver(BillingOutDtoFormSchema),
    defaultValues,
  });

  const closeDialog = (_?: any, reason?: 'backdropClick' | 'escapeKeyDown') => {
    // prevent accidental dialog close
    if (reason && isDirty) {
      return;
    }
    modal.remove();
  };

  const { mutate, isLoading } = usePatchMyBillingInfo({
    onSuccess: () => {
      closeDialog();
    },
  });

  const onSubmit: SubmitHandler<BillingOutDtoFormSchema> = useHandler((data) => {
    mutate(data);
  });

  return (
    <Dialog
      open={modal.visible}
      onClose={closeDialog}
      hideHeader
      paperClassName="rounded-2xl text-accept-dark-blue p-6"
      maxWidth="lg"
      fullScreen={isMobile}
    >
      <div>
        <Form control={control} onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <Typography variant="l" className="font-bold">
            {t('account.sectionBilling.editBillingInfo')}
          </Typography>
          <div className="grid gap-2 sm:grid-cols-2 sm:gap-4">
            <div className="flex flex-col gap-2">
              <Input
                withBG
                label={t('account.sectionBilling.firstName')}
                placeholder={t('account.sectionBilling.placeholderFirstName')}
                {...register('firstName')}
                errorMessage={errors.firstName?.message || ''}
              />
              <Input
                withBG
                label={t('account.sectionBilling.lastName')}
                placeholder={t('account.sectionBilling.placeholderLastName')}
                {...register('lastName')}
                errorMessage={errors.lastName?.message || ''}
              />
              <Input
                withBG
                label={t('account.sectionBilling.billingEmail')}
                {...register('billingEmail')}
                errorMessage={errors.billingEmail?.message || ''}
              />
              <Input
                withBG
                label={t('account.sectionBilling.companyName')}
                placeholder={t('account.sectionBilling.placeholderCompanyName')}
                {...register('companyName')}
                errorMessage={errors.companyName?.message || ''}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2 sm:flex-row">
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Autocomplete<Country>
                      value={selectedCountry}
                      isOptionEqualToValue={(option, value) => {
                        return value === option;
                      }}
                      fetchOnOpen
                      browserAutocompleteOff
                      asyncSearch
                      InputProps={{
                        withBG: true,
                        label: t('account.sectionBilling.country'),
                        placeholder: t('account.sectionBilling.placeholderCountry'),
                      }}
                      inputValue={search}
                      onInputChange={(e) => setSearch(e)}
                      filterOptions={(e) => e}
                      getOptionLabel={(option) => `${option.country} (${option.alpha2})`}
                      getOptions={async (search) => {
                        if (!search) {
                          return ALL_COUNTRIES;
                        }
                        const res = ALL_COUNTRIES.filter((c) => {
                          const country = c.country.toLowerCase();
                          const s = search?.toLowerCase();
                          return country.startsWith(s) || c.alpha2.toLowerCase().startsWith(s);
                        });
                        return res;
                      }}
                      onChange={(_, data) => {
                        console.log('change', data);
                        setSelectedCountry(data);
                        onChange(data?.alpha2 || null);
                      }}
                      className="flex-1"
                    />
                  )}
                />
                <Input
                  withBG
                  label={t('account.sectionBilling.state')}
                  placeholder={t('account.sectionBilling.placeholderState')}
                  {...register('state')}
                  errorMessage={errors.state?.message || ''}
                  className="flex-1"
                />
              </div>
              <div className="flex flex-col gap-2 sm:flex-row">
                <Input
                  withBG
                  label={t('account.sectionBilling.city')}
                  placeholder={t('account.sectionBilling.placeholderCity')}
                  {...register('city')}
                  errorMessage={errors.city?.message || ''}
                  className="flex-1"
                />
                <Input
                  withBG
                  label={t('account.sectionBilling.zipCode')}
                  placeholder={'00000'}
                  {...register('zipCode')}
                  errorMessage={errors.zipCode?.message || ''}
                  className="flex-1"
                />
              </div>
              <Input
                withBG
                multiline
                minRows={4}
                maxRows={6}
                label={t('account.sectionBilling.streetAddress')}
                placeholder={t('account.sectionBilling.placeholderStreetAddress')}
                {...register('streetAddress')}
                errorMessage={errors.streetAddress?.message || ''}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 justify-start gap-4 pt-6 sm:grid-cols-4">
            <Button onClick={closeDialog} disabled={isLoading} className="flex-1" variant="outlined">
              {t('dialog.cancel')}
            </Button>
            <Button loading={isLoading} className="flex-1" type="submit">
              {t('dialog.save')}
            </Button>
          </div>
        </Form>
      </div>
    </Dialog>
  );
});

import { SubscriptionStatusBadge } from '@app/domain/subscription/SubscriptionStatusBadge';
import { EPlanStatus, EPlanType } from '@app/swagger-types';
import { UIStyledSection } from '../../components/UIStyledSection';

export const UISectionSubscriptionStatus: React.FC = () => {
  return (
    <UIStyledSection title="SubscriptionStatusBadge">
      <div className="flex flex-col gap-4">
        {CASES.map((e, i) => {
          return (
            <div key={i} className="flex gap-4">
              <div className="w-40">
                {e.planType} {e.planStatus}
              </div>
              <SubscriptionStatusBadge
                planType={e.planType}
                planStatus={e.planStatus}
                endOfSubscriptionDate={e.endOfSubscriptionDate}
                shortVariant={e.shortVariant}
              />
            </div>
          );
        })}
      </div>
    </UIStyledSection>
  );
};

const ONE_DAY = 1e3 * 60 * 60 * 24;
const getShiftedDate = (shiftInDays = 0) => new Date(new Date().getTime() + ONE_DAY * shiftInDays).toJSON();

const CASES: React.ComponentProps<typeof SubscriptionStatusBadge>[] = [
  {
    planType: EPlanType.TRIAL,
    planStatus: EPlanStatus.ACTIVE,
    shortVariant: true,
  },
  {
    planType: EPlanType.BASIC,
    planStatus: EPlanStatus.ACTIVE,
    shortVariant: true,
  },
  {
    planType: EPlanType.BASIC,
    planStatus: EPlanStatus.CANCELED,
    endOfSubscriptionDate: getShiftedDate(10),
    shortVariant: true,
  },
  {
    planType: EPlanType.BASIC,
    planStatus: EPlanStatus.CANCELED,
    endOfSubscriptionDate: getShiftedDate(-1),
    shortVariant: true,
  },
  {
    planType: EPlanType.TRIAL,
    planStatus: EPlanStatus.ACTIVE,
    endOfSubscriptionDate: getShiftedDate(10),
  },
  {
    planType: EPlanType.TRIAL,
    planStatus: EPlanStatus.ACTIVE,
    endOfSubscriptionDate: getShiftedDate(-1),
  },
  {
    planType: EPlanType.BASIC,
    planStatus: EPlanStatus.ACTIVE,
    endOfSubscriptionDate: getShiftedDate(30),
  },
  {
    planType: EPlanType.BASIC,
    planStatus: EPlanStatus.CANCELED,
    endOfSubscriptionDate: getShiftedDate(30),
  },
  {
    planType: EPlanType.BASIC,
    planStatus: EPlanStatus.ACTIVE,
    endOfSubscriptionDate: getShiftedDate(-1),
  },
  {
    planType: EPlanType.BASIC,
    planStatus: EPlanStatus.CANCELED,
    endOfSubscriptionDate: getShiftedDate(-1),
  },
];

import { AuthResDtoSchema } from '@app/auth/api/auth.dto';
import { IS_PROD_ENV } from '@app/environment/typed-env';
import { DEV_KIT_AUTH_OVERRIDE_LOCALSTORAGE_KEY } from './devkit.constants';

export const devkitMaybeOverrideAuthUser = (user: AuthResDtoSchema) => {
  if (IS_PROD_ENV) {
    return;
  }
  try {
    const overrideRawObj = localStorage.getItem(DEV_KIT_AUTH_OVERRIDE_LOCALSTORAGE_KEY);
    if (overrideRawObj) {
      const obj = JSON.parse(overrideRawObj);
      Object.assign(user, obj);
    }
  } catch {}
};

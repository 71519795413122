import { EmptyPlaceholderIcon } from '@app/assets/icons/vcard';
import { Avatar, Typography } from '@app/components';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { useGetLibraryById } from '@app/domain/library/api/library.hooks.api';
import { VCardFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { clsxm } from '@app/styles/clsxm';
import { VCardOutDto } from '@app/swagger-types';
import { Box } from '@mui/material';
import { useState } from 'react';
import { VCardCompany } from './VCardCompany';
import { VCardContacts } from './VCardContacts';
import { QRCodeSocialLinks } from '../../../qr-code/components/QRCodeSocialLinks';
import { renderFriendlyLinkType } from '../../scan.utils';
import { DownloadButton } from './DownloadButton';
import { FileApi } from '@app/api/file/file.api';
import { QRCodePreviewWrap } from '@app/domain/qr-code/components/QRCodePreviewWrap';

export const VCardPreview: React.FC<{
  vcard?: VCardOutDto | null;
  vcardFromForm?: VCardFormSchema | null;
  isRenderFromForm?: boolean;
}> = ({ vcard, vcardFromForm, isRenderFromForm }) => {
  const [tab, setTab] = useState(0);

  const { data: library } = useGetLibraryById(
    vcardFromForm?.libraryId !== NO_LOGO_ID ? vcardFromForm?.libraryId : undefined,
    {
      enabled: Boolean(isRenderFromForm),
    }
  );

  const fileId = isRenderFromForm ? library?.file?.id : vcard?.library?.file?.id;
  const currentVCard = isRenderFromForm ? vcardFromForm : vcard;

  const handleTab = (val: number) => {
    setTab(() => val);
  };

  const makeVCard = async () => {
    if (!currentVCard) {
      return;
    }
    const base64Photo = fileId
      ? await FileApi.getBase64File({
          id: fileId,
          isPublic: true,
        })
      : null;

    const noteWithLinks = currentVCard.links
      ?.map((l) => `${renderFriendlyLinkType(l.linkType)}: ${l?.url}`)
      ?.join('; ');
    const socialLinks = currentVCard.links
      ?.map((l) => `X-SOCIALPROFILE;TYPE=${renderFriendlyLinkType(l.linkType)}:${l?.url}`)
      ?.join('\n');

    const [firstName = '', lastName = '', middleName = '', honorificPrefix = ''] = (currentVCard.fullName || '').split(
      /\s+/
    );
    const vCardSource = [
      `BEGIN:VCARD`,
      `VERSION:4.0`,
      `FN:${currentVCard.fullName || ''}`,
      `N:${lastName};${firstName};${middleName};${honorificPrefix};`,
      `ORG:${currentVCard.companyName || ''}`,
      `TITLE:${currentVCard.jobPosition || ''}`,
      `EMAIL:${currentVCard.email || ''}`,
      `TEL:${currentVCard.phoneNumber || ''}`,
    ];
    if (currentVCard.alternativePhoneNumber) {
      vCardSource.push(`TEL:${currentVCard.alternativePhoneNumber || ''}`);
    }
    vCardSource.push(
      ...[
        `URL:${currentVCard.website || ''}`,
        `ADR;TYPE=work:;;${currentVCard.street || ''};${currentVCard.city || ''};${currentVCard.state || ''};${
          currentVCard.postalCode || ''
        };${currentVCard.country || ''}`,
        `NOTE:${noteWithLinks}`,
      ]
    );
    if (socialLinks) {
      vCardSource.push(`${socialLinks}`);
    }
    if (base64Photo) {
      vCardSource.push(`PHOTO;ENCODING=BASE64;TYPE=JPEG:${base64Photo}`);
    }
    vCardSource.push(`END:VCARD`);
    const vCardContent = vCardSource.join('\n');

    downloadFile(vCardContent, 'vcard.vcf', 'text/vcard');
  };

  const fontSize = currentVCard?.buttonSize || 16;

  return (
    <QRCodePreviewWrap
      isRenderFromForm={isRenderFromForm}
      style={{
        backgroundColor: currentVCard?.designSecondaryColor,
      }}
    >
      <div
        className="w-full px-3"
        style={{
          backgroundColor: currentVCard?.designPrimaryColor,
        }}
      >
        <div className="flex items-center justify-center">
          <div
            style={{
              backgroundColor: currentVCard?.designSecondaryColor,
            }}
            className={clsxm('mt-[50px] mb-6 h-[70px] w-[70px] overflow-hidden rounded-[10px]')}
          >
            <Avatar
              isPublic
              imageId={fileId}
              className="rounded-none"
              skeletonVariant="rectangular"
              height="100%"
              width="100%"
            >
              <EmptyPlaceholderIcon />
            </Avatar>
          </div>
        </div>
        <Typography
          variant="l"
          className="block text-center font-bold text-white"
          style={{ wordBreak: 'break-word', color: currentVCard?.designNameColor }}
        >
          {currentVCard?.fullName ? `${currentVCard?.fullName}` : 'Your name'}
        </Typography>
        <div className="mt-6 flex w-full justify-between">
          <Box
            className={clsxm('rounded-t-lg px-2 py-1 !text-xxs font-medium text-white', 'hover:cursor-pointer')}
            onClick={() => handleTab(0)}
            style={{
              color: tab === 0 ? currentVCard?.designPrimaryColor : currentVCard?.designSecondaryColor,
              backgroundColor: tab === 0 ? currentVCard?.designSecondaryColor : currentVCard?.designPrimaryColor,
            }}
          >
            Contacts
          </Box>
          <Box
            className={clsxm('rounded-t-lg px-2 py-1 !text-xxs font-medium text-white', 'hover:cursor-pointer')}
            style={{
              color: tab === 1 ? currentVCard?.designPrimaryColor : currentVCard?.designSecondaryColor,
              backgroundColor: tab === 1 ? currentVCard?.designSecondaryColor : currentVCard?.designPrimaryColor,
            }}
            onClick={() => handleTab(1)}
          >
            Company
          </Box>
          <Box
            onClick={() => handleTab(2)}
            className={clsxm('rounded-t-lg px-2 py-1 !text-xxs font-medium text-white', 'hover:cursor-pointer')}
            style={{
              color: tab === 2 ? currentVCard?.designPrimaryColor : currentVCard?.designSecondaryColor,
              backgroundColor: tab === 2 ? currentVCard?.designSecondaryColor : currentVCard?.designPrimaryColor,
            }}
          >
            Socials
          </Box>
        </div>
      </div>
      <div className="flex w-full flex-1 flex-col py-6 px-3">
        {tab === 0 && <VCardContacts vcard={currentVCard} />}
        {tab === 1 && <VCardCompany vcard={currentVCard} />}
        {tab === 2 && <QRCodeSocialLinks links={currentVCard?.links} />}

        <DownloadButton
          text={currentVCard?.buttonText || 'Add contact'}
          hoverColor={currentVCard?.buttonHoverColor || ''}
          bgColor={currentVCard?.buttonBgColor || ''}
          style={{
            fontSize,
            lineHeight: `${fontSize * 1.3}px`,
            color: currentVCard?.buttonTextColor,
            backgroundColor: currentVCard?.buttonBgColor,
            borderColor: currentVCard?.buttonBorderColor,
          }}
          fullWidth
          className="mt-auto mb-4"
          onClick={makeVCard}
          color="primary"
        />
      </div>
    </QRCodePreviewWrap>
  );
};

const downloadFile = (content: string, filename: string, contentType: string) => {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
};

import { Typography } from '@app/components';
import { Routes } from '@app/constants/routes';
import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { mutable } from '@app/utils/type.utils';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const tabRoutes = mutable([
  Routes.customer.account.settings,
  Routes.customer.account.subscription,
  Routes.customer.account.billing,
] as const);

export const AccountModule: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);
  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to, preserveQueryParams: true });
  };

  return (
    <div className="flex flex-col gap-3 py-6">
      <div className="px-6">
        <Typography variant="xl" className="mb-3 font-bold text-gray-800">
          {t('account.pageTitle')}
        </Typography>
        <div>
          <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ className: 'h-[3px] rounded-t-[3px]' }}>
            <Tab label={t('account.tabSettings')} value={tabRoutes[0]} />
            <Tab label={t('account.tabSubscription')} value={tabRoutes[1]} />
            <Tab label={t('account.tabBilling')} value={tabRoutes[2]} />
          </Tabs>
        </div>
      </div>
      <div className="lg:px-6">{<Outlet />}</div>
    </div>
  );
};

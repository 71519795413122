import { FileDto, GetFileParams, UploadFileParams } from '@app/models/file';

import { apiService, publicApiService } from '@app/services/api-service';
import { ResponseType } from 'axios';

export abstract class FileApi {
  static async getFile({
    id,
    isAttachment,
    isPublic,
    cropSize,
    responseType = 'blob',
  }: GetFileParams & { responseType?: ResponseType }) {
    const api = isPublic ? publicApiService : apiService;
    return api.get<Blob>(`/${isPublic ? 'public' : 'private'}/files/${id}`, {
      params: {
        isAttachment,
        cropSize,
      },
      responseType,
    });
  }

  static async getBase64File({ id, isAttachment, cropSize }: GetFileParams) {
    return apiService
      .get<string>(`/public/files/${id}/base64`, {
        params: {
          isAttachment,
          cropSize,
        },
      })
      .then((res) => res.data);
  }

  static async uploadFile({ formData, config, isPublic }: UploadFileParams) {
    return await apiService.post<FileDto>('/private/files', formData, {
      ...config,
      params: { ...config?.params, isPublic },
    });
  }

  static async deleteFile(fileId: string) {
    return await apiService.delete(`/private/files/${fileId}`);
  }
}

import { saveAs } from 'file-saver';

import { OPENABLE_EXTENSION_TO_MIME, DOWNLOADABLE_EXTENSION_TO_MIME } from '@app/constants/file-uploads';

import { FileApi } from '@app/api/file/file.api';

export const openFileById = async (fileId: string, type?: string, fileBlob?: Blob) => {
  let data = fileBlob;

  if (!fileBlob) {
    const res = await FileApi.getFile({ id: fileId });
    data = res.data;
  }

  const blob = new Blob([data as Blob], { type });
  const blobUrl = URL.createObjectURL(blob);
  const newWindow = window.open(blobUrl);
  if (newWindow) {
    if (newWindow.document.readyState === 'complete') {
      URL.revokeObjectURL(blobUrl);
    } else {
      newWindow.onload = () => {
        URL.revokeObjectURL(blobUrl);
      };
    }
  }
};

export const saveFileById = async (fileId: string, type?: string, title?: string, fileBlob?: Blob) => {
  let data = fileBlob;

  if (!fileBlob) {
    const res = await FileApi.getFile({ id: fileId });
    data = res.data;
  }

  const blob = new Blob([data as Blob], { type });
  saveAs(blob, title);
};

export const combineFileExtension = (filename: string, source: string, upper?: boolean) => {
  const splittedStr = filename.split('.');
  const extension = splittedStr[splittedStr.length - 1];
  return `${source}.${upper ? extension.toUpperCase() : extension}`;
};

export const getFileMimeType = (filename: string) => {
  const splittedStr = filename.toLocaleLowerCase().split('.');
  const extension = splittedStr[splittedStr.length - 1];

  const mappedOpenableMimeType = (OPENABLE_EXTENSION_TO_MIME as Record<string, string>)[extension];

  if (!mappedOpenableMimeType) {
    return {
      openable: false,
      mimeType: (DOWNLOADABLE_EXTENSION_TO_MIME as Record<string, string>)[extension],
    };
  }

  return {
    openable: true,
    mimeType: mappedOpenableMimeType,
  };
};

export const removeExtensionFromName = (filename: string) => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex !== -1) {
    return filename.substr(0, dotIndex);
  }
  return filename;
};

export async function getFilesAsync(dataTransfer: DataTransfer) {
  const files: File[] = [];
  const promises = Array.from(dataTransfer.items).map(async (item) => {
    if (item.kind === 'file') {
      if (typeof item.webkitGetAsEntry === 'function') {
        const entry = item.webkitGetAsEntry();

        if (entry) {
          const entryContent = await readEntryContentAsync(entry);
          files.push(...entryContent);
        }
      }

      const file = item.getAsFile();
      if (file) {
        files.push(file);
      }
    }
  });

  await Promise.all(promises);
  return files;
}

export function readEntryContentAsync(entry: FileSystemEntry) {
  return new Promise<File[]>((resolve) => {
    let reading = 0;
    const contents: File[] = [];

    readEntry(entry);

    function readEntry(entry: FileSystemEntry) {
      if (isFile(entry)) {
        reading++;
        entry.file((file) => {
          reading--;
          contents.push(file);

          if (reading === 0) {
            resolve(contents);
          }
        });
      } else if (isDirectory(entry)) {
        readReaderContent(entry.createReader());
      }
    }

    function readReaderContent(reader: FileSystemDirectoryReader) {
      reading++;

      reader.readEntries(function (entries) {
        reading--;
        for (const entry of entries) {
          readEntry(entry);
        }

        if (reading === 0) {
          resolve(contents);
        }
      });
    }
  });
}

export function isDirectory(entry: FileSystemEntry): entry is FileSystemDirectoryEntry {
  return entry.isDirectory;
}

export function isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
  return entry.isFile;
}

export interface FileSystemFileEntry extends FileSystemEntry {
  file(successCallback: (file: File) => void, errorCallback?: (error: FileError) => void): void;
}

export interface FileSystemDirectoryEntry extends FileSystemEntry {
  createReader(): FileSystemDirectoryReader;
  getDirectory(): FileSystemDirectoryEntry;
  getFile(): FileSystemFileEntry;
}

export interface DataTransferItem {
  webkitGetAsEntry?(): FileSystemEntry;
}

export interface FileSystemDirectoryReader {
  readEntries(successCallback: (entries: FileSystemEntry[]) => void, errorCallback?: (error: FileError) => void): void;
}

export interface Metadata {
  modificationTime: Date;
  size: number;
}

export interface FileError extends Error {}

export const uploadFile = async ({
  file,
  onUploadProgress,
  isPublic,
}: {
  file: File;
  onUploadProgress?: (e: ProgressEvent) => void;
  isPublic: boolean;
}) => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await FileApi.uploadFile({
    isPublic,
    formData,
    config: {
      onUploadProgress,
    },
  });

  return data;
};

export const uploadImage = async <
  Img extends {
    file?: File | null;
  }
>(
  img: Img,
  onUploadProgress?: (e: ProgressEvent) => void
) => {
  const formData = new FormData();
  if (img.file) {
    formData.append('file', img.file);
  }
  const { data } = await FileApi.uploadFile({
    isPublic: true,
    formData,
    config: {
      onUploadProgress,
    },
  });

  return {
    ...img,
    imageId: data.id,
  };
};

export const friendlyByteSize = (bytes: number) => {
  if (bytes > MB) {
    return `${(bytes / MB).toFixed(1)} MB`;
  }
  return `${(bytes / KB).toFixed(1)} KB`;
};

const KB = 1024;
const MB = KB * KB;

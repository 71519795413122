import { DashboardOutDto } from '@app/swagger-types';
import { checkTypeCompliance } from '@app/utils/type.utils';
import { InferType, number, object } from 'yup';

export const DashboardOutDtoSchema = object({
  totalQRCodes: number().default(0),
  totalScans: number().default(0),
  totalUsers: number().default(0),
  monthlyScans: number().default(0),
  monthlyUsers: number().default(0),
});
export type DashboardOutDtoSchema = InferType<typeof DashboardOutDtoSchema>;
checkTypeCompliance<DashboardOutDto, DashboardOutDtoSchema>();

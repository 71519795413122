import { AdminQRTable } from './components/AdminQRTable';
import { GET_ADMIN_QRS_QUERY } from '@app/constants/query-api-configs';
import { SearchInput } from '@app/components/fields/search-input/SearchInput';
import { useQueryParam, withDefault, StringParam, DateParam } from 'use-query-params';
import { Page } from '@app/hoc';
import { OptionItem, Select } from '@app/components';
import { SelectChangeEvent } from '@mui/material';
import { oneOfEnum } from '@app/utils/yup.utils';
import { ECodeType } from '@app/swagger-types';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { calcSpecialQRSearch } from '../admin-qr.utils';

const ALL_TIME_DATE = new Date(2021, 0);
const DEFAULT_AFTER_DATE = new Date();
DEFAULT_AFTER_DATE.setDate(DEFAULT_AFTER_DATE.getDate() - 7);
export const AdminQRListPage: React.FC = () => {
  const [search, setSearch] = useQueryParam(GET_ADMIN_QRS_QUERY.searchParam, withDefault(StringParam, ''));
  const [type, setType] = useQueryParam(GET_ADMIN_QRS_QUERY.typeParam, withDefault(StringParam, ''));
  const [afterDate, setAfterDate] = useQueryParam<Date>(
    GET_ADMIN_QRS_QUERY.afterDateParam,
    withDefault(DateParam, DEFAULT_AFTER_DATE)
  );

  useEffect(() => {
    let mounted = true;
    // timeout needed to avoid bugs with route navigation
    // (bug: param set before navigation and navigation gets blocked)
    setTimeout(() => {
      if (!mounted) {
        return;
      }
      const { isSpecial } = calcSpecialQRSearch(search);
      setAfterDate((cur) =>
        isSpecial
          ? ALL_TIME_DATE
          : cur.getFullYear() === DEFAULT_AFTER_DATE.getFullYear()
          ? DEFAULT_AFTER_DATE
          : ALL_TIME_DATE
      );
    });
    return () => {
      mounted = false;
    };
  }, [search, setAfterDate]);

  return (
    <Page>
      <div className="flex items-end justify-end gap-2 p-5">
        <Select
          size="small"
          inputProps={{
            label: 'Date filter',
            className: /*tw*/ 'min-w-[200px]',
          }}
          displayEmpty
          value={afterDate ? (afterDate.getFullYear() === DEFAULT_AFTER_DATE.getFullYear() ? 'date' : '') : ''}
          onChange={(e: SelectChangeEvent<string>) => {
            const val = e.target.value;
            if (val) {
              setAfterDate(DEFAULT_AFTER_DATE);
            } else {
              setAfterDate(ALL_TIME_DATE);
            }
          }}
        >
          <OptionItem value={''}>All time</OptionItem>
          <OptionItem value={'date'}>{`after ${format(DEFAULT_AFTER_DATE, 'PP')}`}</OptionItem>
        </Select>
        <Select
          size="small"
          inputProps={{
            label: 'Code type',
          }}
          displayEmpty
          value={type || ''}
          onChange={(e: SelectChangeEvent<string>) => {
            const val = e.target.value;
            // TODO auto validate select output, create reusable solution
            if (oneOfEnum(ECodeType).isValidSync(val)) {
              setType(val);
            } else {
              setType(undefined);
            }
          }}
        >
          <OptionItem value={''}>All</OptionItem>
          <OptionItem value={ECodeType.WEBSITE}>{ECodeType.WEBSITE}</OptionItem>
          <OptionItem value={ECodeType.MULTILINK}>{ECodeType.MULTILINK}</OptionItem>
          <OptionItem value={ECodeType.PDF}>{ECodeType.PDF}</OptionItem>
          <OptionItem value={ECodeType.V_CARD}>{ECodeType.V_CARD}</OptionItem>
        </Select>
        <SearchInput value={search} onChange={setSearch} placeholder="ID or owner email" />
      </div>
      <div className="p-5">
        <AdminQRTable />
      </div>
    </Page>
  );
};

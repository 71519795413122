import { DownloadIcon, PdfFilledIcon } from '@app/assets';
import { Button } from '@app/components';
import { typedEnv } from '@app/environment/typed-env';
import { GetFileParams } from '@app/models/file';
import { PDFOutDto } from '@app/swagger-types';
import { CircularProgress } from '@mui/material';

const IS_ATTACHMENT_PARAM: keyof GetFileParams = 'isAttachment';
const getFileUrl = (fileId: string, isAttachment: boolean) =>
  `${typedEnv.REACT_APP_API_BASE_URL}/public/files/${fileId}?${IS_ATTACHMENT_PARAM}=${isAttachment}`;

export const QRPdfViewer: React.FC<{
  pdf?: PDFOutDto;
}> = ({ pdf }) => {
  const renderIframe = () => {
    if (!pdf) {
      return null;
    }
    const url = getFileUrl(pdf.file.id, false);
    /**
     * workaround to show PDF on mobile
     * see more at https://stackoverflow.com/a/75514788
     */
    return (
      <iframe
        title="PDF preview"
        src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(url)}`}
        width="100%"
        height="100%"
      />
    );
  };

  if (!pdf) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-wrap items-center gap-4 p-2">
          <a href={getFileUrl(pdf.file.id, true)} download={pdf.file.fileName || 'file.pdf'}>
            <Button variant="outlined">
              <DownloadIcon />
            </Button>
          </a>
          <div className="flex gap-2">
            <PdfFilledIcon />
            {pdf.file.fileName}
          </div>
        </div>
        {renderIframe()}
      </div>
    </div>
  );
};

import { useMemo } from 'react';

import { useQuery } from 'react-query';
import { GET_ADMIN_EMAIL_REPORTS_QUERY } from '@app/constants/query-api-configs';

import { useBasicQueryControls } from '@app/hooks/useBasicQueryControls.hook';

import { Typography } from '@mui/material';

import { Button, Table } from '@app/components';
import { useTableColumns } from './useTableColumns';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { EEmailTopic, ELanguage } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { EmailApi } from '@app/domain/email/api/EmailApi';

export const AdminEmailTable: React.VFC = ({}) => {
  const {
    values: { page, sort = GET_ADMIN_EMAIL_REPORTS_QUERY.defaultSort, size },
    handlers: { handlePageChange, handleSortChange, handleSizeChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      size: 10,
    },
    prefix: GET_ADMIN_EMAIL_REPORTS_QUERY.tablePrefix,
  });
  const [recipient] = useQueryParam(GET_ADMIN_EMAIL_REPORTS_QUERY.recipientParam, withDefault(StringParam, ''));
  const [topic] = useQueryParam(GET_ADMIN_EMAIL_REPORTS_QUERY.topicParam, withDefault(StringParam, ''));
  const [language] = useQueryParam(GET_ADMIN_EMAIL_REPORTS_QUERY.languageParam, withDefault(StringParam, ''));

  const { data, isFetching, refetch } = useQuery(
    [GET_ADMIN_EMAIL_REPORTS_QUERY.name, { page, sort, size, recipient, topic, language }],
    () => {
      // TODO auto validate params
      const validTopic = oneOfEnum(EEmailTopic).isValidSync(topic) ? topic : undefined;
      // TODO auto validate params
      const validLanguage = oneOfEnum(ELanguage).isValidSync(language) ? language : undefined;
      return EmailApi.adminGetEmailReports({
        sort,
        page: Math.ceil((page || 1) - 1),
        size,
        recipientEmail: recipient,
        topic: validTopic,
        language: validLanguage,
      });
    },
    { keepPreviousData: true, ...GET_ADMIN_EMAIL_REPORTS_QUERY.config }
  );

  const columns = useTableColumns();

  const leftContent = useMemo(() => {
    return (
      <div>
        <Typography variant="xl" className="font-bold">
          Email Reports Table ({data?.total})
        </Typography>
      </div>
    );
  }, [data?.total]);

  const rightContent = useMemo(() => {
    return (
      <div>
        <Button loading={isFetching} onClick={() => refetch()}>
          Refetch
        </Button>
      </div>
    );
  }, [refetch, isFetching]);

  return (
    <Table
      cols={columns}
      tableData={data?.result ?? []}
      leftHeaderContent={leftContent}
      rightHeaderContent={rightContent}
      notFoundMessage="No users on file."
      currentPage={page}
      loading={isFetching}
      pageSize={size}
      onSizeChange={handleSizeChange}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
    />
  );
};

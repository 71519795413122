import styles from './Input.module.scss';
import { get } from 'lodash';
import React from 'react';
import { useToggle } from 'react-use';

import { clsxm } from '@app/styles/clsxm';

import { FormControlUnstyled } from '@mui/base';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  InputBase,
  InputBaseProps,
  InputLabelProps,
  InputLabel,
  FormHelperText,
  FormHelperTextProps,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { withQA } from '@app/debug/withQA';

export interface InputProps extends Omit<InputBaseProps, 'ref'> {
  errorMessage?: string | boolean | undefined;
  label?: string | React.ReactNode;
  labelRequired?: boolean;
  labelProps?: InputLabelProps;
  labelVariant?: 'regular' | 'futura';
  helperTextProps?: FormHelperTextProps;
  variant?: 'outlined' | 'filled';
  inputSize?: 'large' | 'medium' | 'small';
  inAutocomplete?: boolean;
  withBG?: boolean;
}

export const Input = withQA(
  {
    name: 'Input',
    debugProps: ({ asString, asEnum }) => ({
      errorMessage: asString(''),
      inputSize: asEnum(['small', 'large', 'medium']),
      type: asEnum(['text', 'password']),
    }),
  },
  React.forwardRef<HTMLInputElement, InputProps>(
    (
      {
        errorMessage,
        label,
        required,
        labelProps,
        labelVariant = 'regular',
        helperTextProps,
        className,
        classes,
        variant = 'outlined',
        inputSize = 'medium',
        withBG = false,
        ...props
      },
      ref
    ) => {
      const [hiddenPassword, toggleVisibility] = useToggle(true);

      return (
        <FormControlUnstyled className={clsxm(styles.root, className)} error={!!errorMessage || !!props.error}>
          {label && (
            <InputLabel
              htmlFor={props.id}
              disableAnimation
              {...labelProps}
              className={clsxm(
                styles.label,
                get(styles, `${labelVariant}`),
                labelProps?.className,
                props?.disabled && 'text-gray-400'
              )}
            >
              {required && <span>*</span>}
              {label}
            </InputLabel>
          )}

          <InputBase
            error={!!errorMessage || !!props.error}
            className={clsxm(
              styles.input,
              get(styles, inputSize),
              get(styles, variant),
              'schema-light dark:schema-dark',
              withBG && 'bg-accept-light-bg'
            )}
            classes={{
              ...classes,
              focused: styles.focused,
              error: styles.error,
            }}
            inputRef={ref}
            {...props}
            type={props.type === 'password' ? (hiddenPassword ? 'password' : 'text') : props.type}
            endAdornment={
              props.type === 'password' ? (
                <InputAdornment position="end" className={styles['end-adornment']}>
                  <IconButton disableRipple onClick={toggleVisibility}>
                    {hiddenPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : (
                props.endAdornment
              )
            }
          />
          {errorMessage && (
            <FormHelperText className={styles['error-text']} {...helperTextProps}>
              {errorMessage}
            </FormHelperText>
          )}
        </FormControlUnstyled>
      );
    }
  )
);

import { string, object, InferType } from 'yup';

// ===== FILE ======

export const FileDto = object({
  id: string().defined(),
  fileName: string().defined(),
  createdDate: string().defined(),
});
export type FileDto = InferType<typeof FileDto>;

import { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';

export const AppRouter: React.FC = ({ children }) => {
  return (
    <BrowserRouter>
      <RouterBody>{children}</RouterBody>
    </BrowserRouter>
  );
};

const RouterBody: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return <>{children}</>;
};

import { Input, Typography } from '@app/components';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DesignFormSchema } from '../../api/template.form.schema.api';

export const TemplateTitleStep: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<DesignFormSchema>();

  return (
    <div className="space-y-4">
      <Typography variant="m" className="font-bold">
        {t('template.title')}
      </Typography>
      <Controller
        name="template.title"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            label={t('template.enterName')}
            withBG
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
    </div>
  );
};

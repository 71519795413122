import { UserBaseOutSchema } from '@app/auth/api/auth.schema';
import { DesignOutDtoSchema } from '@app/domain/template/api/template.schema.api';
import { ECodeType } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { boolean, InferType, number, object, string } from 'yup';

export const WebSiteOutDtoSchema = object({
  id: string().required(),
  url: string().required(),
  createdDate: string().required(),
});
export type WebSiteOutDtoSchema = InferType<typeof WebSiteOutDtoSchema>;

export const DesignPublicOutDtoSchema = DesignOutDtoSchema.omit(['createdBy']);

export const QRCodeOutDtoSchema = object({
  id: string().required(),
  name: string().required(),
  // TODO fix after backend fixes. Should be not public schema here:
  // design: DesignOutDtoSchema.nullable(),
  design: DesignPublicOutDtoSchema.nullable(),
  createdBy: UserBaseOutSchema.nullable(),
  shouldAskForGPSCoordinates: boolean().required().default(false),
  codeType: oneOfEnum(ECodeType),
  amountOfScans: number().nullable(),
  createdDate: string().required(),
  expireDate: string().nullable(),
  website: WebSiteOutDtoSchema.nullable(),
});
export type QRCodeOutDtoSchema = InferType<typeof QRCodeOutDtoSchema>;

// import { checkTypeCompliance } from '@app/utils/type.utils';
// checkTypeCompliance<QRCodeOutDto, QRCodeOutDtoSchema>();

export const QRCodePublicOutDtoSchema = QRCodeOutDtoSchema.omit(['createdBy', 'design']).concat(
  object({
    design: DesignPublicOutDtoSchema.nullable(),
  })
);
export type QRCodePublicOutDtoSchema = InferType<typeof QRCodePublicOutDtoSchema>;

import { Button, Container } from '@app/components';
import { QRLineChartBare } from '@app/modules/customer/pages/analytics/components/QRLineChartBare';
import { ChartAnalyticOutDto } from '@app/swagger-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UIStyledSection } from '../../components/UIStyledSection';

export const UISectionLineCharts: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [, setRerender] = useState(0);

  return (
    <UIStyledSection title="Line charts">
      <div className="flex gap-4">
        <Button
          onClick={() => {
            regenMockData();
            setRerender((i) => i + 1);
          }}
        >
          randomize data
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setIsLoading((v) => !v);
          }}
        >
          toggle loader
        </Button>
      </div>
      <Container
        className="flex min-h-[340px] flex-col gap-4 lg:rounded-xl"
        isLoading={isLoading}
        title={t('qrAnalytics.amountOfScans')}
      >
        {!isLoading && <QRLineChartBare data={MOCK_CHART_DATA} />}
      </Container>
    </UIStyledSection>
  );
};

const MOCK_CHART_DATA: ChartAnalyticOutDto = { results: [], total: 0 };
const regenMockData = () => {
  MOCK_CHART_DATA.results.length = 0;
  MOCK_CHART_DATA.total = MOCK_CHART_DATA.results.length;
  const length = 7;
  const date = new Date();
  date.setDate(date.getDate() - length);
  for (let i = 0; i < length; i++) {
    MOCK_CHART_DATA.results.push({
      date: date.toJSON(),
      total: Math.floor(Math.random() * 150),
    });
    date.setDate(date.getDate() + 1);
  }
  MOCK_CHART_DATA.total = MOCK_CHART_DATA.results.length;
};
regenMockData();

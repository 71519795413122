import QRCodeStyling, { Options } from 'qr-code-styling';

let counter = 0;

/**
 * universally improved fix for unique SVG definitions (clip paths)
 * based on https://github.com/kozakdenys/qr-code-styling/issues/72#issuecomment-1315360180
 */
export class QRCodePatched extends QRCodeStyling {
  readonly count: number;

  constructor(options?: Partial<Options> | undefined) {
    super(options);
    this.count = counter++;
  }

  update(options?: Partial<Options> | undefined): void {
    super.update(options);

    if (this._svgDrawingPromise) {
      this._svgDrawingPromise.then(() => {
        const svg = this._svg;
        if (!(svg instanceof SVGElement)) {
          return;
        }

        const allClips = svg.querySelectorAll<SVGClipPathElement>('clipPath');
        allClips.forEach((clip) => {
          const id = clip.getAttribute('id');
          const rect = svg.querySelector<SVGRectElement>(`rect[clip-path="url('#${id}')"]`);
          if (rect) {
            // specify id uniquely on the page
            clip.setAttribute('id', `${id}__${this.count}`);
            rect.setAttribute('clip-path', `url('#${id}__${this.count}')`);
          }
        });
      });
    }
  }
}

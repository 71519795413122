import { FilterIcon } from '@app/assets';
import { Button, IconButton, OptionItem, Select, Typography } from '@app/components';
import { SearchInput } from '@app/components/fields/search-input/SearchInput';
import { clsxm } from '@app/styles/clsxm';
import { Divider, SelectChangeEvent, SwipeableDrawer } from '@mui/material';
import { useCallback, useState } from 'react';
import { useQRCodeQueryParams } from './qr-code.hooks';
import CloseIcon from '@mui/icons-material/Close';
import { QRCodeMobileSort } from './components/search/QRCodeMobileSort';
import { ECodeTypeALL, QRCodeMobileTypes } from './components/search/QRCodeMobileTypes';
import { renderFriendlyQRCodeSort, renderFriendlyQRCodeTypes } from '@app/utils/render-friendly-text.utils';
import { isString } from 'lodash';
import { ECodeType } from '@app/swagger-types';
import { EQRCodeSort } from './qr-code.types';
import { useTranslation } from 'react-i18next';

export const QRCodeSearch: React.FC = () => {
  const { t } = useTranslation();
  const [isOpenMobileFilters, setIsOpenMobileFilters] = useState(false);
  const [params, setParams] = useQRCodeQueryParams();
  const [defaultParams, setDefaultParams] = useState(params);

  const { term, sort, type } = params;

  const isAllSelected = Object.keys(ECodeType).length > 0 && type.length === Object.keys(ECodeType).length;

  const toggleFiltersMenu = useCallback(
    (open: boolean) => () => {
      setIsOpenMobileFilters(open);
    },
    [setIsOpenMobileFilters]
  );

  const handleChangeTyoe = (event: SelectChangeEvent<string[] | ECodeTypeALL[]>) => {
    const value = event.target.value;
    if (isString(value)) {
      setParams({ type: [] });
      return;
    }

    setParams({ type: value });
  };

  const handleSelectAll = (selected: boolean) => {
    if (selected) {
      setParams({ type: [] });
    } else {
      setParams({ type: Object.keys(ECodeType) });
    }
  };

  const clearFilters = useCallback(() => {
    const defParams = {
      sort: EQRCodeSort.MOST_RECENT,
      type: [],
    };
    setParams(defParams);
    setDefaultParams({ ...defaultParams, ...defParams });
  }, [setParams, defaultParams]);

  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2 lg:gap-4">
          <SearchInput
            className="flex-1"
            placeholder={t('search.searchByTitle')}
            value={term}
            onChange={(text) => {
              setParams({ term: text });
            }}
          />
          <div className="lg:hidden">
            <IconButton onClick={toggleFiltersMenu(true)} variant="outlined">
              <FilterIcon className="text-black" />
            </IconButton>
          </div>
          <div className="hidden items-center gap-4 lg:flex">
            <div className="w-[250px]">
              <Select
                multiple
                value={type}
                displayEmpty
                renderValue={(values) => {
                  const currentValues = values.map((v) => renderFriendlyQRCodeTypes(v as ECodeType)).filter((t) => !!t);

                  if (!currentValues.length || isAllSelected) {
                    return (
                      <span className="flex h-full items-center text-xs">
                        <span className="mr-1 text-gray-500">{t('search.type')}:</span>
                        {!isAllSelected && <span className="text-gray-500">{t('search.notSelected')}</span>}
                        {isAllSelected && <span className="w-[150px] truncate font-semibold">{t('search.all')}</span>}
                      </span>
                    );
                  }

                  return (
                    <span className="flex h-full items-center text-xs">
                      <span className="mr-1 text-gray-500">{t('search.type')}:</span>
                      <span className="w-[150px] truncate font-semibold">{currentValues.join(', ')}</span>
                    </span>
                  );
                }}
                onChange={handleChangeTyoe}
                size="small"
              >
                <div>
                  <OptionItem
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleSelectAll(isAllSelected);
                    }}
                    selected={isAllSelected}
                    value={ECodeTypeALL.ALL}
                  >
                    {renderFriendlyQRCodeTypes(ECodeTypeALL.ALL)}
                  </OptionItem>
                </div>
                {(Object.keys(ECodeType) as Array<keyof typeof ECodeType>).map((key) => {
                  const val = renderFriendlyQRCodeTypes(ECodeType[key]);
                  if (!val) {
                    return null;
                  }
                  return (
                    <OptionItem value={ECodeType[key]} key={key}>
                      {val}
                    </OptionItem>
                  );
                })}
              </Select>
            </div>
            <div className="min-w-[250px]">
              <Select
                value={sort}
                onChange={(e) => {
                  setParams({ sort: e.target.value });
                }}
                size="small"
                renderValue={(value) => {
                  return (
                    <span className="truncate text-xs text-slate-800">
                      <span className="mr-1 truncate text-gray-500">{t('search.sortBy')}:</span>
                      <span className="font-semibold">{renderFriendlyQRCodeSort(value as EQRCodeSort, t)}</span>
                    </span>
                  );
                }}
              >
                {(Object.keys(EQRCodeSort) as Array<keyof typeof EQRCodeSort>).map((key) => {
                  return (
                    <OptionItem value={EQRCodeSort[key]} key={key}>
                      {renderFriendlyQRCodeSort(EQRCodeSort[key], t)}
                    </OptionItem>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <SwipeableDrawer
        open={isOpenMobileFilters}
        onOpen={toggleFiltersMenu(true)}
        onClose={toggleFiltersMenu(false)}
        anchor="bottom"
        classes={{ root: clsxm('lg:hidden') }}
        PaperProps={{
          className: clsxm('p-6 h-[70%] rounded-t-[20px]'),
        }}
        elevation={0}
      >
        <div className="flex h-full flex-1 flex-col">
          <div className="mb-4 flex items-center justify-between">
            <Typography variant="l" className="font-bold">
              {t('search.filters')}
            </Typography>
            <IconButton
              onClick={() => {
                toggleFiltersMenu(false)();
              }}
              variant="transparent"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex flex-col gap-3 overflow-scroll">
            <QRCodeMobileSort
              sortValue={sort}
              handleSortValue={(sort) => {
                setParams({ sort });
              }}
            />
            <Divider flexItem />
            <QRCodeMobileTypes
              typeValues={type}
              handleTypeValues={(type) => {
                setParams({ type });
              }}
            />
            <Divider flexItem />
          </div>
          <div className="mt-auto grid grid-cols-2 gap-4 pt-4">
            <Button
              onClick={() => {
                toggleFiltersMenu(false)();
                clearFilters();
              }}
              variant="outlined"
            >
              {t('search.clearAll')}
            </Button>
            <Button
              onClick={() => {
                toggleFiltersMenu(false)();
                setDefaultParams(params);
              }}
            >
              {t('search.done')}
            </Button>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

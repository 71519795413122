import { useMemo } from 'react';

import { TableColumn } from '@app/components';

import { Typography } from '@mui/material';
import { EmailReportOutDto } from '@app/swagger-types';
import { NestedKeyOf } from '@app/utils/type.utils';
import { format } from 'date-fns';
import { FORMAT } from '@app/constants/formats';

const getEntityField = (v: NestedKeyOf<EmailReportOutDto>) => v;

export const useTableColumns = () => {
  const tableColumns = useMemo<TableColumn<EmailReportOutDto>[]>(() => {
    return [
      {
        headerName: 'Created date',
        field: getEntityField('createdDate'),
        align: 'center',
        render: ({ createdDate }) => {
          return <Typography variant="xs">{format(new Date(createdDate), FORMAT.FULL_TIME)}</Typography>;
        },
      },
      {
        headerName: 'Subject',
        field: getEntityField('id'),
        align: 'left',
        firstClickSort: 'asc',
        render: ({ subject, topic }) => (
          <div className="flex flex-col items-start gap-2">
            <div>{subject}</div>
            <div className="rounded bg-gray-200 px-1 text-xxs text-gray-600">{topic}</div>
          </div>
        ),
      },
      {
        headerName: 'Recipient',
        field: getEntityField('recipientEmail'),
        align: 'left',
        firstClickSort: 'asc',
        render: ({ recipientEmail }) => (
          <Typography variant="xs" noWrap>
            {recipientEmail}
          </Typography>
        ),
      },
      {
        headerName: 'Language',
        field: getEntityField('language'),
        align: 'left',
        firstClickSort: 'asc',
        render: ({ language }) => (
          <Typography variant="xs" noWrap>
            {language}
          </Typography>
        ),
      },
      {
        headerName: 'Context',
        field: getEntityField('context'),
        align: 'left',
        disableSort: true,
        render: ({ context }) => <Typography variant="xs">{context}</Typography>,
      },
    ];
  }, []);

  return tableColumns;
};

import localforage from 'localforage';

import { AuthStatus } from '@app/auth/api/auth.type';

import { StoreSlice } from '../storeTypes';
import { AuthSlice } from './types';
import { AuthApi } from '@app/auth/api/auth.api';
import { AUTH_ADMIN_REF_TOKEN_KEY, AUTH_ADMIN_TOKEN_KEY, AUTH_REF_TOKEN_KEY, AUTH_TOKEN_KEY } from '@app/auth/constant';
import { IS_AUTH_METHOD_COOKIE, IS_AUTH_METHOD_TOKEN } from '@app/environment/typed-env';
import { takeLatestAsync } from '@app/utils/async.utils';
import { devkitMaybeOverrideAuthUser } from '@app/modules/dev-kit/pages/devkit.utils';
import { ERole } from '@app/swagger-types';

export const createAuthSlice: StoreSlice<AuthSlice> = (set, get) => ({
  currentUser: null,
  authStatus: AuthStatus.UNKNOWN,
  hasAdminTokens: false,

  signOut: async (skipCookieSignOut) => {
    set({ currentUser: null, authStatus: AuthStatus.UNAUTHORIZED, hasAdminTokens: false });
    if (IS_AUTH_METHOD_TOKEN) {
      await localforage.clear();
    }
    if (IS_AUTH_METHOD_COOKIE) {
      if (skipCookieSignOut) {
        return;
      }
      await AuthApi.signOut();
    }
  },

  setCurrentUser: async (user) => {
    const { signOut } = get();

    if (user) {
      devkitMaybeOverrideAuthUser(user);

      if (IS_AUTH_METHOD_COOKIE) {
        set({ currentUser: user, authStatus: AuthStatus.SIGNED_IN });
        return;
      }
      if (IS_AUTH_METHOD_TOKEN) {
        const { accessToken, refreshToken, ...safeUser } = user;
        if (!accessToken || !refreshToken) {
          await signOut();
          return;
        }
        await localforage.setItem(AUTH_TOKEN_KEY, accessToken);
        await localforage.setItem(AUTH_REF_TOKEN_KEY, refreshToken);
        set({ currentUser: safeUser, authStatus: AuthStatus.SIGNED_IN });
        return;
      }
      console.error('unsupported auth method');
    } else {
      await signOut();
    }
  },

  patchCurrentUser: async (user) => {
    const { currentUser } = get();
    if (!currentUser) {
      return;
    }
    set({
      currentUser: {
        ...currentUser,
        ...user,
      },
    });
  },

  getMe: async () => {
    const { signOut, checkAdminTokens } = get();
    await checkAdminTokens();

    // TODO avoid checking token here. It is job for interceptor.
    if (IS_AUTH_METHOD_TOKEN) {
      const token = await localforage.getItem(AUTH_TOKEN_KEY);

      if (!token) {
        await signOut();
        return;
      }
    }

    const { result, staleError, error } = await takeLatestAsync(AuthApi.me(), 'authGetMe');
    if (staleError) {
      return;
    }
    if (error) {
      await signOut(true);
      return;
    }
    if (!result) {
      return;
    }
    const me = result.data;
    devkitMaybeOverrideAuthUser(me);
    set({ currentUser: me, authStatus: AuthStatus.SIGNED_IN });
    return me;
  },

  preserveAdminTokens: async () => {
    const accessTokenAdmin = await localforage.getItem(AUTH_TOKEN_KEY);
    const refreshTokenAdmin = await localforage.getItem(AUTH_REF_TOKEN_KEY);

    if (accessTokenAdmin && refreshTokenAdmin) {
      await localforage.setItem(AUTH_ADMIN_TOKEN_KEY, accessTokenAdmin);
      await localforage.setItem(AUTH_ADMIN_REF_TOKEN_KEY, refreshTokenAdmin);
      set({ hasAdminTokens: true });
    }
  },

  checkAdminTokens: async () => {
    const accessTokenAdmin = await localforage.getItem(AUTH_ADMIN_TOKEN_KEY);
    const refreshTokenAdmin = await localforage.getItem(AUTH_ADMIN_REF_TOKEN_KEY);

    if (typeof accessTokenAdmin !== 'string' || typeof refreshTokenAdmin !== 'string') {
      return {};
    }
    set({ hasAdminTokens: true });
    return {
      accessTokenAdmin,
      refreshTokenAdmin,
    };
  },

  restoreAdminTokens: async (redirectUrl) => {
    const { checkAdminTokens } = get();
    const { accessTokenAdmin, refreshTokenAdmin } = await checkAdminTokens();
    if (typeof accessTokenAdmin !== 'string' || typeof refreshTokenAdmin !== 'string') {
      return;
    }
    await localforage.setItem(AUTH_TOKEN_KEY, accessTokenAdmin);
    await localforage.setItem(AUTH_REF_TOKEN_KEY, refreshTokenAdmin);
    await localforage.removeItem(AUTH_ADMIN_TOKEN_KEY);
    await localforage.removeItem(AUTH_ADMIN_REF_TOKEN_KEY);
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      window.location.reload();
    }
  },

  signInAsCustomer: async (accessToken) => {
    const { currentUser, preserveAdminTokens } = get();

    const role = currentUser?.role;
    if (role !== ERole.ROLE_ADMIN) {
      return;
    }

    if (!IS_AUTH_METHOD_TOKEN) {
      return;
    }
    await preserveAdminTokens();
    await localforage.removeItem(AUTH_REF_TOKEN_KEY);
    await localforage.setItem(AUTH_TOKEN_KEY, accessToken);
    window.location.reload();
  },
});

import React, { useEffect, useState } from 'react';

let counter = 0;

export const SvgScopeWrap: React.FC<{ children: React.ReactElement<SVGElement> }> = ({ children }) => {
  const [ref, setRef] = useState<SVGElement | null>(null);

  useEffect(() => {
    if (!(ref instanceof SVGElement)) {
      return;
    }
    counter++;
    const allClips = ref.querySelectorAll<SVGClipPathElement>('clipPath');
    const allLinearGradients = ref.querySelectorAll<SVGLinearGradientElement>('linearGradient');
    const allRadialGradients = ref.querySelectorAll<SVGLinearGradientElement>('radialGradient');
    [...allClips, ...allLinearGradients, ...allRadialGradients].forEach((el) => {
      const id = el.getAttribute('id');
      const clipTarget = [
        ...ref.querySelectorAll(`[clip-path="url(#${id})"]`),
        ...ref.querySelectorAll(`[clip-path="url('#${id}')"]`),
      ];
      const fillTarget = [
        ...ref.querySelectorAll(`[fill="url(#${id})"]`),
        ...ref.querySelectorAll(`[fill="url('#${id}')"]`),
      ];
      if (clipTarget.length || fillTarget.length) {
        // specify id uniquely on the page
        el.setAttribute('id', `${id}__${counter}`);
      }
      if (clipTarget.length) {
        clipTarget.forEach((t) => t.setAttribute('clip-path', `url(#${id}__${counter})`));
      }
      if (fillTarget.length) {
        fillTarget.forEach((t) => t.setAttribute('fill', `url(#${id}__${counter})`));
      }
    });
  }, [ref]);

  return React.cloneElement(children, { ref: setRef });
};

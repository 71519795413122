import { useChangePassword } from '@app/auth/api/auth.api.hook';
import { Button, Form, Input, Typography } from '@app/components';
import { useHandler } from '@app/hooks/useHandler.hook';
import { clsxm } from '@app/styles/clsxm';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ChangePasswordFormSchema } from '../api/user.form.schema.api';

export const ChangePasswordModal: React.FC<NiceModalHocProps> = NiceModal.create(() => {
  const { t } = useTranslation();
  const modal = useModal();

  const { mutate: changePassword, isLoading } = useChangePassword();

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordFormSchema>({
    resolver: yupResolver(ChangePasswordFormSchema),
  });

  const closeDialog = () => {
    modal.remove();
  };

  const onSubmit: SubmitHandler<ChangePasswordFormSchema> = useHandler((data) => {
    changePassword(data, {
      onSuccess: () => {
        toast.success(t('toaster.changesSaved'));
        closeDialog();
      },
    });
  });

  const { confirmNewPassword, newPassword } = watch();

  const disabledSumbitButton =
    !newPassword || !confirmNewPassword || !!errors?.newPassword?.message || !!errors?.confirmNewPassword?.message;

  return (
    <Modal open={modal.visible} onClose={closeDialog} onClick={(e) => e.stopPropagation()}>
      <div
        className={clsxm(
          'absolute top-[50%] left-[50%] w-[350px] max-w-[calc(100%-24px)] -translate-x-[50%] -translate-y-[50%] overflow-hidden rounded-3xl bg-white px-8 py-6'
        )}
      >
        <Form control={control} onSubmit={handleSubmit(onSubmit)} className="space-y-3">
          <Typography variant="l" className="font-bold">
            {t('account.changePassword.title')}
          </Typography>
          <div className="!mb-4">
            <Typography variant="xs" className="text-gray-500">
              {t('account.changePassword.recommendations')}
            </Typography>
          </div>
          <Input
            {...register('newPassword')}
            withBG
            type="password"
            label={t('account.changePassword.newPassword')}
            placeholder={t('account.changePassword.createNewPassword')}
            errorMessage={errors.newPassword?.message || ''}
          />
          <Input
            {...register('confirmNewPassword')}
            withBG
            type="password"
            label={t('account.changePassword.confirmNewPassword')}
            placeholder={t('account.changePassword.confirmNewPassword')}
            errorMessage={errors.confirmNewPassword?.message || ''}
          />
          <div className="!mt-6 flex justify-between gap-4">
            <Button onClick={closeDialog} disabled={isLoading} className="flex-1" variant="outlined">
              {t('dialog.cancel')}
            </Button>
            <Button disabled={disabledSumbitButton} loading={isLoading} className="flex-1" type="submit">
              {t('dialog.confirm')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
});

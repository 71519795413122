import { Container } from '@app/components';
import { TemplateFrameStep } from '@app/domain/template/components/form/TemplateFrameStep';
import { TemplateLogoStep } from '@app/domain/template/components/form/TemplateLogoStep';
import { TemplatePatternStep } from '@app/domain/template/components/form/TemplatePatternStep';
import { TemplateStepper, TEMPLATE_STEPPER_STEPS_TYPE } from '@app/domain/template/components/TemplateStepper';
import { useMemo, useState } from 'react';
import { QRCodeCustomizeTemplatesStep } from './QRCodeCustomizeTemplatesStep';

export const QRCodeCustomizeContainer: React.FC = () => {
  const [activeStep, setActiveStep] = useState<TEMPLATE_STEPPER_STEPS_TYPE>('Logo');

  const renderActiveStep = useMemo(() => {
    switch (activeStep) {
      case 'Logo':
        return <TemplateLogoStep />;
      case 'Frames':
        return <TemplateFrameStep />;
      case 'Pattern':
        return <TemplatePatternStep />;
      case 'Templates':
        return <QRCodeCustomizeTemplatesStep />;
      default:
        return null;
    }
  }, [activeStep]);

  return (
    <>
      <TemplateStepper
        className="bg-white p-5 xl:rounded-xl"
        activeStep={activeStep}
        onChange={(step) => setActiveStep(step)}
        withTemplatesStep
      />
      <Container className="flex-1 rounded-none xl:rounded-xl" rounded>
        {renderActiveStep}
      </Container>
    </>
  );
};

import { Routes } from '@app/constants/routes';
import { MyRouteObject } from '@app/models/router';
import { QRWidgetContainer } from './QRWidgetContainer';

const WidgetPage: React.FC = () => {
  return (
    <div className="py-24">
      <QRWidgetContainer />
    </div>
  );
};

export const WidgetRoutes: MyRouteObject = {
  path: Routes.widget,
  element: <WidgetPage />,
};

import { ELinkType, VCardOutDto } from '@app/swagger-types';
import { VCardFormSchema } from '../qr-code/api/qr-code.form.schema.api';

export const getDeviceCoords = async () => {
  const pos = await new Promise((resolve: PositionCallback, reject: PositionErrorCallback) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  return {
    lng: pos.coords.longitude,
    lat: pos.coords.latitude,
    accuracy: pos.coords.accuracy,
  };
};

export const getFriendlyAdreess = (vcard: VCardOutDto | VCardFormSchema) => {
  const address = [
    vcard?.street || '',
    vcard?.city || '',
    vcard?.state || '',
    vcard?.postalCode || '',
    vcard?.country || '',
  ]
    .filter((v) => !!v)
    .join(', ');
  return address;
};

export const renderFriendlyLinkType = (linkType: ELinkType, title?: string) => {
  const variants = {
    [ELinkType.FACEBOOK]: 'Facebook',
    [ELinkType.INSTAGRAM]: 'Instagram',
    [ELinkType.LINKEDIN]: 'LinkedIn',
    [ELinkType.TELEGRAM]: 'Telegram',
    [ELinkType.TIKTOK]: 'TikTok',
    [ELinkType.TWITTER]: 'Twitter',
    [ELinkType.WHATSAPP]: 'WhatsApp',
    [ELinkType.YOUTUBE]: 'Youtube',
    [ELinkType.SKYPE]: 'Skype',
    [ELinkType.CUSTOM]: `${title || linkType}`,
  };
  return variants[linkType];
};

import { AccountEmailPreferences } from '@app/domain/user/components/AccountEmailPreferences';
import { AccountInfo } from '@app/domain/user/components/AccountInfo';
import { AccountQRCodePreferences } from '@app/domain/user/components/AccountQRCodePreferences';
import { AccountSecurity } from '@app/domain/user/components/AccountSecurity';
import { AccountUsage } from '@app/domain/user/components/AccountUsage';

export const AccountSettingsPage: React.FC = () => {
  return (
    <div className="flex flex-col gap-3">
      <AccountInfo />
      <AccountUsage />
      <AccountEmailPreferences />
      <AccountQRCodePreferences />
      <AccountSecurity />
    </div>
  );
};

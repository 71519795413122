import { useEffect, useState } from 'react';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { ForgotPasswordForm } from '@app/modules/auth/components/ForgotPasswordForm';
import { useForgotPassword } from '@app/auth/api/auth.api.hook';
import { Button, Typography } from '@app/components';
import { useTranslation } from 'react-i18next';

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string | undefined>();
  const [isSentEmail, setIsSentEmail] = useState(false);
  const [timer, setTimer] = useState(0);

  const {
    mutate: handleForgotPassword,
    isLoading: forgotPasswordLoading,
    error: forgotPasswordError,
  } = useForgotPassword({
    onSuccess: (_, { email }) => {
      setIsSentEmail(true);
      setEmail(email);
      setTimer(59);
    },
  });

  useEffect(() => {
    let intervalId: number;
    if (timer > 0) {
      intervalId = window.setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  return (
    <div className="h-full">
      {!isSentEmail ? (
        <ForgotPasswordForm
          onForgotPassword={handleForgotPassword}
          loading={forgotPasswordLoading}
          error={forgotPasswordError}
        />
      ) : (
        <div className="space-y-4">
          <div className="mb-4 flex flex-col gap-2">
            <Typography variant="xxxl" className="text-center font-bold">
              {t('auth.forgot.sent.linkHasBeenSent')}
            </Typography>
            <Typography variant="s" className="text-center">
              {t('auth.forgot.sent.description')}
            </Typography>
          </div>

          {/* TODO: maybe need to add Open Gmail button? */}
          {/* Open Gmail */}

          <div className="flex flex-col items-center justify-center gap-2">
            <Button
              variant="text"
              onClick={() => {
                if (email) {
                  handleForgotPassword(
                    { email },
                    {
                      onSuccess: () => {
                        setTimer(59);
                      },
                    }
                  );
                }
              }}
              disabled={!!timer}
            >
              {t('auth.forgot.sent.resendEmailLink')}
            </Button>
            {timer ? <span className="italic text-gray-400">{timer ? `${timer} sec` : ''}</span> : null}
          </div>

          <Typography align="center">
            {t('auth.forgot.backTo')}{' '}
            <TypedLink to={Routes.auth.sign_in} className="font-bold text-accept-main hover:text-accept-hover">
              {t('auth.signIn')}
            </TypedLink>
          </Typography>
        </div>
      )}
    </div>
  );
};

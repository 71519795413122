import AmericaExpressImg from '@app/assets/images/america-express.svg';
import DinnerClubImg from '@app/assets/images/dinner-club.svg';
import DiscoverImg from '@app/assets/images/discover.svg';
import JBCImg from '@app/assets/images/jbc.svg';
import MasterCardWhiteImg from '@app/assets/images/mastercard-white.svg';
import MasterCardImg from '@app/assets/images/mastercard.svg';
import UnionPayImg from '@app/assets/images/union-pay.svg';
import VisaWhiteImg from '@app/assets/images/visa-white.svg';
import VisaImg from '@app/assets/images/visa.svg';

export const cardBrandToImgUrl = (brand: string | null) => {
  switch (brand) {
    case 'visa':
      return {
        black: VisaImg,
        white: VisaWhiteImg,
      };

    case 'mastercard':
      return {
        black: MasterCardImg,
        white: MasterCardWhiteImg,
      };
    case 'amex':
      return {
        black: AmericaExpressImg,
        white: AmericaExpressImg,
      };

    case 'discover':
      return {
        black: DiscoverImg,
        white: DiscoverImg,
      };

    case 'diners':
      return {
        black: DinnerClubImg,
        white: DinnerClubImg,
      };

    case 'jcb':
      return {
        black: JBCImg,
        white: JBCImg,
      };

    case 'unionpay':
      return {
        black: UnionPayImg,
        white: UnionPayImg,
      };

    default:
      return null;
  }
};

export const formatCardBrand = (brand?: string | null) => {
  switch (brand) {
    case 'visa':
      return 'Visa';
    case 'mastercard':
      return 'Mastercard';
    case 'amex':
      return 'American Express';

    case 'discover':
      return 'Discover';

    case 'diners':
      return 'Dinners Club';

    case 'jcb':
      return 'JCB';
    case 'unionpay':
      return 'UnionPay';
    default:
      return 'Unknown';
  }
};

export const ALL_CARD_BRANDS = ['visa', 'mastercard', 'amex', 'discover', 'diners', 'jcb', 'unionpay'] as const;

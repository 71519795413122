import { Outlet } from 'react-router-dom';

import { Routes } from '@app/constants/routes';

import { mutable } from '@app/utils/type.utils';

import { Tabs, Tab, SelectChangeEvent } from '@mui/material';

import { useMatchActiveRoute, useTypedNavigate } from '@app/router';
import { Page } from '@app/hoc';
import { SearchInput } from '@app/components/fields/search-input/SearchInput';
import { withDefault, StringParam, useQueryParam } from 'use-query-params';
import { GET_USERS_QUERY } from '@app/constants/query-api-configs';
import { OptionItem, Select } from '@app/components';
import { EPlanType, ESubscriptionAdminStatus } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';

const tabRoutes = mutable([Routes.admin.user_management.users, Routes.admin.user_management.admins] as const);

export const UserManagementModule = () => {
  const navigate = useTypedNavigate();
  const value = useMatchActiveRoute(tabRoutes);
  const handleChange = (event: React.ChangeEvent<unknown>, to: typeof tabRoutes[number]) => {
    navigate({ to, preserveQueryParams: true });
  };
  const [search, setSearch] = useQueryParam(GET_USERS_QUERY.searchParam, withDefault(StringParam, ''));
  const [planType, setPlanType] = useQueryParam(GET_USERS_QUERY.planTypeParam, withDefault(StringParam, ''));
  const [planStatus, setPlanStatus] = useQueryParam(GET_USERS_QUERY.planStatusParam, withDefault(StringParam, ''));

  return (
    <Page>
      <div className="flex items-end justify-between space-x-4 p-5">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="customers" value={tabRoutes[0]} />
          {/* <Tab label="admins" value={tabRoutes[1]} /> */}
        </Tabs>
        <div className="flex items-end gap-2">
          <Select
            size="small"
            inputProps={{
              label: 'Plan type',
            }}
            displayEmpty
            value={planType || ''}
            onChange={(e: SelectChangeEvent<string>) => {
              const val = e.target.value;
              // TODO auto validate select output, create reusable solution
              if (oneOfEnum(EPlanType).isValidSync(val)) {
                setPlanType(val);
              } else {
                setPlanType(undefined);
              }
            }}
          >
            <OptionItem value={''}>All</OptionItem>
            <OptionItem value={EPlanType.TRIAL}>{EPlanType.TRIAL}</OptionItem>
            <OptionItem value={EPlanType.BASIC}>{EPlanType.BASIC}</OptionItem>
            <OptionItem value={EPlanType.STARTER}>{EPlanType.STARTER}</OptionItem>
            <OptionItem value={EPlanType.PREMIUM}>{EPlanType.PREMIUM}</OptionItem>
          </Select>
          <Select
            size="small"
            inputProps={{
              label: 'Plan status',
            }}
            displayEmpty
            value={planStatus || ''}
            onChange={(e: SelectChangeEvent<string>) => {
              const val = e.target.value;
              // TODO auto validate select output, create reusable solution
              if (oneOfEnum(ESubscriptionAdminStatus).isValidSync(val)) {
                setPlanStatus(val);
              } else {
                setPlanStatus(undefined);
              }
            }}
          >
            <OptionItem value={''}>All</OptionItem>
            <OptionItem value={ESubscriptionAdminStatus.ACTIVE}>{ESubscriptionAdminStatus.ACTIVE}</OptionItem>
            <OptionItem value={ESubscriptionAdminStatus.CANCELED_NOT_EXPIRED}>
              {ESubscriptionAdminStatus.CANCELED_NOT_EXPIRED}
            </OptionItem>
            <OptionItem value={ESubscriptionAdminStatus.EXPIRED}>{ESubscriptionAdminStatus.EXPIRED}</OptionItem>
          </Select>
          <SearchInput value={search} onChange={setSearch} />
        </div>
      </div>
      <div className="p-5">
        <Outlet />
      </div>
    </Page>
  );
};

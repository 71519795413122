import { ChartAnalyticOutDto, ChartResultsOutDto } from '@app/swagger-types';
import { format } from 'date-fns';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export const QRLineChartBare: React.FC<{ data: ChartAnalyticOutDto }> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={250}>
      <LineChart width={400} height={400} data={data.results} margin={{ top: 16 }}>
        <CartesianGrid stroke="#E5E7EB" vertical={false} />
        <XAxis
          dataKey={X_AXIS_KEY}
          tickFormatter={(date) => format(new Date(date), 'MMM dd')}
          tickLine={false}
          axisLine={false}
          padding={{ left: 32, right: 32 }}
        />
        <YAxis tickLine={false} axisLine={false} />
        <Line
          type="linear"
          dataKey={DATA_KEY}
          stroke="#9AD396"
          strokeWidth={2}
          dot={false}
          activeDot={{
            r: 10,
            strokeWidth: 2,
            fill: '#2C9424',
            filter: 'drop-shadow(0px 2px 4px rgba(50, 50, 71, 0.5))',
          }}
        />
        <Tooltip
          cursor={false}
          labelFormatter={(date) => format(new Date(date), 'MMM dd')}
          wrapperClassName="rounded"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const DATA_KEY: keyof ChartResultsOutDto = 'total';
const X_AXIS_KEY: keyof ChartResultsOutDto = 'date';

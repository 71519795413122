import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { Routes } from '@app/constants/routes';
import { QRCodeApi } from '@app/domain/qr-code/api/qr-code.api';
import { QRDownloadHandlerFn } from '@app/domain/qr-code/components/qr-code-internal.types';
import { QRCodeRenderer } from '@app/domain/qr-code/components/QRCodeRenderer';
import { extractRenderPropsFromQR } from '@app/domain/qr-code/qr-code.utils';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useTypedParams } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const RenderQRPage: React.FC = () => {
  const { id } = useTypedParams(Routes.render.qr);
  const [downloadHandler, setDownloadHandler] = useState<QRDownloadHandlerFn | undefined>(undefined);

  const onDownloadHandler = useHandler((downloadFn?: QRDownloadHandlerFn) => {
    // setter has to be a function to avoid downloadFn call
    setDownloadHandler(() => downloadFn);
  });

  const {
    data: qr,
    isLoading,
    error,
  } = useQuery({
    queryKey: [id],
    queryFn: async () => {
      if (!id) {
        return;
      }
      return QRCodeApi.getPublicById(id);
    },
  });

  if (isLoading || !qr || error) {
    return <ContentLoader loading error={error} />;
  }

  return (
    <div className="flex flex-col items-center gap-8 pb-24">
      <div className={downloadHandler && 'qr-ready'}>{downloadHandler ? 'render ready' : 'rendering...'}</div>
      <div className={clsxm('h-full max-h-[1400px] w-full max-w-[1024px]')} id="qr-container">
        <QRCodeRenderer qrId={id} {...extractRenderPropsFromQR(qr)} onDownloadHandler={onDownloadHandler} />
      </div>
    </div>
  );
};

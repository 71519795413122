export enum EAnalyticsPeriodAfterDate {
  LAST_7_DAYS = 'last7days',
  LAST_30_DAYS = 'last30days',
  LAST_6_MONTHS = 'last6months',
  LAST_12_MONTHS = 'last12months',
}

export enum EAnalyticsInterval {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

import { MockQRCodeIcon } from '@app/assets';
import { Typography } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { useMemo } from 'react';
import styles from './FrameContainer.module.scss';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { EFrameType } from '@app/swagger-types';
import { useTranslation } from 'react-i18next';

// TODO make all frames scalable
export const FrameContainer: React.FC<{
  scale?: number;
  type?: EFrameType;
  onClick?: (value: EFrameType) => void;
  isOption?: boolean;
  selected?: boolean;
  text?: string | null;
  fontSize?: number;
  color?: string;
  backgroundColor?: string;
  qrBackgroundColor?: string;
  className?: string;
  forWidgetMode?: boolean;
}> = ({
  scale = 1,
  type = EFrameType.NONE,
  onClick,
  text = 'SCAN ME',
  selected = false,
  isOption = false,
  fontSize = 12,
  color,
  backgroundColor,
  qrBackgroundColor,
  className,
  children = <MockQRCodeIcon className={clsxm(styles.qrCode, 'pointer-events-none h-full')} />,
  forWidgetMode,
}) => {
  const { t } = useTranslation();

  if (scale < 1) {
    scale = 1;
  }

  // TODO: optimize render frames
  const frames = useMemo(() => {
    const frameStyle: { style: React.CSSProperties } = {
      style: { backgroundColor: qrBackgroundColor, borderRadius: 4 * scale },
    };
    return {
      [EFrameType.NONE]: isOption ? (
        <div className={clsxm(styles.container, 'flex flex-col items-center justify-center gap-2')}>
          <DoNotDisturbAltIcon className="text-4xl text-gray-300" />
          {!forWidgetMode && (
            <Typography variant="xs" className="text-center text-[#63758F]">
              {t('qr.stepCustomize.frame.noFrame')}
            </Typography>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <div className={clsxm(styles.qrCode, 'rounded')}>{children}</div>
        </div>
      ),
      [EFrameType.BORDER_LABEL_BOTTOM]: (
        <div
          className={clsxm(styles.container, 'flex flex-col bg-black')}
          style={{
            borderRadius: frameStyle.style.borderRadius,
            ...(!isOption ? { backgroundColor } : undefined),
          }}
        >
          <div
            className={clsxm('w-full bg-white', isOption && 'h-full bg-black p-1', forWidgetMode && 'pb-0')}
            {...{
              style: {
                backgroundColor,
                borderTopLeftRadius: frameStyle.style.borderRadius,
                borderTopRightRadius: frameStyle.style.borderRadius,
                ...(!isOption
                  ? {
                      padding: 8 * scale,
                    }
                  : undefined),
              },
            }}
          >
            <div className={clsxm(styles.qrCode, isOption && 'h-full bg-white')}>{children}</div>
          </div>
          {text && (
            <div
              className={clsxm(forWidgetMode && 'p-0')}
              style={{
                ...(!forWidgetMode ? { paddingBottom: 8 * scale } : undefined),
              }}
            >
              <div className={clsxm(styles.text)} style={!isOption ? { color } : undefined}>
                <span>{text}</span>
              </div>
            </div>
          )}
        </div>
      ),
      [EFrameType.BORDER_LABEL_TOP]: (
        <div
          className={clsxm(styles.container, 'border-top flex flex-col bg-black')}
          style={{
            borderRadius: frameStyle.style.borderRadius,
            ...(!isOption ? { backgroundColor, borderTopColor: backgroundColor } : undefined),
          }}
        >
          {text && (
            <div className="pt-2">
              <div className={clsxm(styles.text)} style={!isOption ? { color } : undefined}>
                <span>{text}</span>
              </div>
            </div>
          )}
          <div
            className={clsxm(
              'w-full rounded-b bg-white',
              isOption && 'h-full bg-black p-1',
              !isOption && 'p-[6px] sm:p-2'
            )}
            {...{ style: { backgroundColor } }}
          >
            <div className={clsxm(styles.qrCode, isOption && 'h-full bg-white')}>{children}</div>
          </div>
        </div>
      ),
      [EFrameType.ARROW_LABEL_TOP]: (
        <div className={styles.container}>
          {text && (
            <div
              className={clsxm(styles.text, 'relative bg-black py-1')}
              style={
                !isOption
                  ? {
                      color,
                      backgroundColor,
                      borderTopColor: backgroundColor,
                      borderRadius: frameStyle.style.borderRadius,
                      marginBottom: 8 * scale,
                    }
                  : { borderTopColor: '#000', borderRadius: frameStyle.style.borderRadius, marginBottom: 8 * scale }
              }
            >
              <div
                className={styles.bottomArrow}
                style={{
                  borderLeftWidth: 6 * scale,
                  borderTopWidth: 10 * scale,
                  borderRightWidth: 6 * scale,
                  bottom: -13 * scale,
                }}
              ></div>
              <span>{text}</span>
            </div>
          )}
          <div className="w-full p-[6px] sm:p-2" {...frameStyle}>
            <div className={clsxm(styles.qrCode)}>{children}</div>
          </div>
        </div>
      ),
      [EFrameType.ARROW_LABEL_BOTTOM]: (
        <div className={styles.container}>
          <div className={clsxm('w-full p-[6px]', !forWidgetMode && 'sm:p-2', forWidgetMode && 'p-1')} {...frameStyle}>
            <div className={clsxm(styles.qrCode)}>{children}</div>
          </div>
          {text && (
            <div
              className={clsxm(styles.text, 'relative bg-black py-1', forWidgetMode && 'p-0')}
              style={
                !isOption
                  ? {
                      color,
                      backgroundColor,
                      borderBottomColor: backgroundColor,
                      borderRadius: frameStyle.style.borderRadius,
                      marginTop: 8 * scale,
                    }
                  : { borderBottomColor: '#000', borderRadius: frameStyle.style.borderRadius, marginTop: 8 * scale }
              }
            >
              <div
                className={styles.topArrow}
                style={{
                  borderLeftWidth: 6 * scale,
                  borderBottomWidth: 10 * scale,
                  borderRightWidth: 6 * scale,
                  top: -2 * scale,
                }}
              ></div>
              <span>{text}</span>
            </div>
          )}
        </div>
      ),
      [EFrameType.BORDER_SPACE_LABEL_BOTTOM]: (
        <div className={styles.container}>
          <div
            className="w-full bg-black"
            style={{
              padding: 4 * scale,
              borderRadius: frameStyle.style.borderRadius,
              ...(!isOption ? { backgroundColor: backgroundColor } : undefined),
            }}
          >
            <div className={clsxm(styles.qrCode, 'rounded bg-white')}>{children}</div>
          </div>
          {text && (
            <div
              className={clsxm(styles.text, 'bg-black')}
              style={{
                marginTop: 4 * scale,
                paddingTop: 4 * scale,
                paddingBottom: 4 * scale,
                borderRadius: frameStyle.style.borderRadius,
                ...(!isOption ? { backgroundColor, color } : undefined),
              }}
            >
              <span>{text}</span>
            </div>
          )}
        </div>
      ),
      [EFrameType.BORDER_SPACE_LABEL_TOP]: (
        <div className={styles.container}>
          {text && (
            <div className={clsxm(styles.text, 'mt-0 mb-2 py-1 text-black')} style={!isOption ? { color } : undefined}>
              <span>{text}</span>
            </div>
          )}
          <div
            className="w-full rounded bg-black p-1"
            style={!isOption ? { backgroundColor: backgroundColor, borderTopColor: backgroundColor } : undefined}
          >
            <div className={clsxm(styles.qrCode, 'rounded bg-white')}>{children}</div>
          </div>
        </div>
      ),
      [EFrameType.BORDER_INTERRUPTION]: (
        <div className={styles.container}>
          <div
            className={clsxm(styles.cornerBorder, 'w-full rounded p-[6px] sm:p-2')}
            style={
              !isOption
                ? ({
                    '--custom-boder-color': backgroundColor,
                    ...frameStyle.style,
                  } as React.CSSProperties)
                : ({
                    '--custom-boder-color': '#000',
                    ...frameStyle.style,
                  } as React.CSSProperties)
            }
          >
            <div className={clsxm(styles.qrCode, 'rounded')}>{children}</div>
          </div>
          {text && (
            <div className={clsxm(styles.text, 'mt-2 mb-0 py-1 text-black')} style={!isOption ? { color } : undefined}>
              <span>{text}</span>
            </div>
          )}
        </div>
      ),
      [EFrameType.NO_BORDER]: (
        <div className={styles.container}>
          <div className="w-full rounded bg-white p-[6px] sm:p-2" {...frameStyle}>
            <div className={clsxm(styles.qrCode, 'rounded')}>{children}</div>
          </div>
          {text && (
            <div className={clsxm(styles.text, 'mt-2 mb-0 py-1 text-black')} style={!isOption ? { color } : undefined}>
              <span>{text}</span>
            </div>
          )}
        </div>
      ),
      [EFrameType.BORDER]: (
        <div className={styles.container}>
          <div
            className="w-full rounded border-2 border-black bg-white"
            style={!isOption ? { borderColor: backgroundColor } : undefined}
          >
            <div className={clsxm(styles.qrCode)}>{children}</div>
          </div>
          {text && (
            <div className={clsxm(styles.text, 'mt-2 mb-0 py-1 text-black')} style={!isOption ? { color } : undefined}>
              <span>{text}</span>
            </div>
          )}
        </div>
      ),
    };
  }, [backgroundColor, children, color, isOption, text, qrBackgroundColor, t, forWidgetMode, scale]);

  return (
    <div
      className={clsxm(
        styles.root,
        onClick && 'hover:cursor-pointer',
        isOption && 'rounded-xl bg-gray-100 p-[10px]',
        isOption && 'border-[1.5px] border-transparent',
        isOption && selected && 'border-accept-main',
        forWidgetMode && 'rounded-lg p-2',
        className
      )}
      style={{ fontSize: fontSize * scale }}
      onClick={() => {
        onClick && onClick(type);
      }}
      aria-hidden
    >
      {frames[type]}
    </div>
  );
};

import { Trans } from 'react-i18next';
import { Typography } from '@mui/material';

export const AgreeWithTermsText: React.FC = () => {
  return (
    <Typography variant="s">
      <Trans i18nKey="auth.signUpStep.iAgreeWithTerms">
        I agree with the{' '}
        <a
          className="text-s font-semibold text-accept-dark-blue"
          href="https://trueqrcode.com/wp-content/uploads/2022/11/Terms-and-Conditions-Nov-2022.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Terms
        </a>{' '}
        and{' '}
        <a
          className="text-s font-semibold text-accept-dark-blue"
          href="https://trueqrcode.com/wp-content/uploads/2022/11/Privacy-Policy-Nov-2022.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </Trans>
    </Typography>
  );
};

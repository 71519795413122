import { ECodeType } from '@app/swagger-types';

export interface IEventDownloadQRCode {
  codeType: ECodeType;
  websiteURL: string;
}

export enum EGTAGRegistrationPopUp {
  MULTILINK = 'multilink',
  PDF = 'pdf',
  VCARD_PLUS = 'vcard plus',
  SCAN_TRACKING = 'scan tracking',
  FRAME = 'frame',
  PATTERN = 'pattern',
  COLOR = 'color',
  LOGO = 'logo',
  DOWNLOAD = 'download',
}

export enum EGTAGRegistrationOrigin {
  /**
   * account created from claim page
   */
  CLAIM = 'claim',
  /**
   * account created from popup from Wordpress Widget
   */
  GENERATOR = 'generator',
  /**
   * account created directly from sign up page
   */
  DIRECT = 'direct',
}

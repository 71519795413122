import { Button } from '@app/components';
import { useTypedNavigate } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Routes } from '@app/constants/routes';
import { MyQRCodesDashboard } from './components/MyQRCodesDashboard';
import { MyTemplatesDashboard } from './components/MyTemplatesDashboard';
import { useTranslation } from 'react-i18next';
import { QRAnalyticsOverall } from '../analytics/components/QRAnalyticsOverall';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { PlanLimitTooltip } from '@app/domain/plan/components/PlanLimitTooltip';

export const CustomerDashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const { canCreateQRCode } = usePlanLimits();

  return (
    <div className="relative flex h-full flex-col gap-4 pb-32 lg:p-6">
      <div className="flex items-center justify-between gap-4">
        <Typography variant="xl" className="px-6 pt-6 font-bold lg:p-0 lg:text-xxl">
          {t('dashboard.pageTitle')}
        </Typography>
        <div
          className={clsxm(
            'fixed bottom-0 left-0 right-0 z-10 flex h-[100px] items-end justify-center bg-white pb-10',
            'lg:relative lg:h-auto lg:bg-inherit lg:pb-0'
          )}
        >
          <PlanLimitTooltip condition={!canCreateQRCode} divWrap>
            <Button
              onClick={() => {
                navigate({ to: Routes.customer.create_qr_code });
              }}
              startIcon={<AddIcon />}
              disabled={!canCreateQRCode}
            >
              {t('createQRCode.pageTitle')}
            </Button>
          </PlanLimitTooltip>
        </div>
      </div>
      <QRAnalyticsOverall fromDashboardPage />
      <MyQRCodesDashboard />
      <MyTemplatesDashboard />
    </div>
  );
};

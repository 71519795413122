import { Container, Input, Typography } from '@app/components';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { Controller, useFormContext } from 'react-hook-form';

export const QRCodeCompanyDetails: React.FC = () => {
  const { control, trigger } = useFormContext<QRCodeFormSchema>();

  return (
    <Container className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:rounded-xl">
      <Typography className="font-bold sm:text-l md:col-span-2">Company details</Typography>
      <Controller
        name="vcard.companyName"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.companyName');
              }
              onChange(e);
            }}
            label="Company name"
            withBG
            placeholder="e.g. My company"
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
      <Controller
        name="vcard.jobPosition"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.jobPosition');
              }
              onChange(e);
            }}
            label="Job position"
            withBG
            placeholder="e.g. Film editor"
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
    </Container>
  );
};

import { UsersTable } from '@app/domain/user/users-table/UsersTable';
import { ERole } from '@app/swagger-types';

// TODO migrate to SuperAdmins when BE API ready
export const Admins = () => {
  if ('TODO') {
    return null;
  }
  return <UsersTable role={ERole.ROLE_CUSTOMER} />;
};

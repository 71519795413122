import { UIStyledSection } from '../components/UIStyledSection';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButtonProps } from '@app/components/buttons/icon-button/IconButton';
import { IconButton } from '@app/components';
import { FormControlLabel, Switch } from '@mui/material';

export const UITabIconButtons: React.FC = () => {
  const render = (buttons: IconButtonPropsRender[]) =>
    buttons.map(({ children, ...b }, i) => (
      <IconButton key={i} {...b}>
        {children}
      </IconButton>
    ));

  return (
    <>
      <UIStyledSection title="UISectionIconButtons">
        <div className="flex gap-4">
          <div className="flex flex-col gap-4 pb-6">
            <div className="font-bold">Contained</div>
            <div className="flex w-fit flex-col gap-4">{render(CONTAINER_ICON_BUTTONS)}</div>
          </div>
          <div className="flex flex-col gap-4 pb-6">
            <div className="font-bold">Outlined</div>
            <div className="flex w-fit flex-col gap-4">{render(OUTLINED_ICON_BUTTONS)}</div>
          </div>
          <div className="flex flex-col gap-4 pb-6">
            <div className="font-bold">Transparent</div>
            <div className="flex w-fit flex-col gap-4">{render(TRANSPARENT_ICON_BUTTONS)}</div>
          </div>
          <div className="flex flex-col gap-4 pb-6">
            <div className="font-bold">Circle</div>
            <div className="flex w-fit flex-col gap-4">{render(CIRCLE_ICON_BUTTONS)}</div>
          </div>
        </div>
      </UIStyledSection>

      <UIStyledSection title="UISectionSwitchs">
        <div className="flex gap-4 p-5">
          <FormControlLabel control={<Switch className="my-1" />} label="Switch" />
          <FormControlLabel control={<Switch className="my-1" />} label="Disabled" disabled />
        </div>
      </UIStyledSection>
    </>
  );
};

type IconButtonPropsRender = IconButtonProps;

const CONTAINER_ICON_BUTTONS: IconButtonPropsRender[] = [
  { size: 'large', variant: 'contained', children: <ChevronLeftIcon /> },
  { size: 'medium', variant: 'contained', children: <ChevronLeftIcon /> },
  { size: 'small', variant: 'contained', children: <ChevronLeftIcon /> },
  { variant: 'contained', disabled: true, children: <ChevronLeftIcon /> },
];

const OUTLINED_ICON_BUTTONS: IconButtonPropsRender[] = [
  { size: 'large', variant: 'outlined', children: <ChevronRightIcon /> },
  { size: 'medium', variant: 'outlined', children: <ChevronRightIcon /> },
  { size: 'small', variant: 'outlined', children: <ChevronRightIcon /> },
  { variant: 'outlined', disabled: true, children: <ChevronRightIcon /> },
];

const TRANSPARENT_ICON_BUTTONS: IconButtonPropsRender[] = [
  { size: 'large', variant: 'transparent', children: <ChevronRightIcon /> },
  { size: 'medium', variant: 'transparent', children: <ChevronRightIcon /> },
  { size: 'small', variant: 'transparent', children: <ChevronRightIcon /> },
  { variant: 'transparent', disabled: true, children: <ChevronRightIcon /> },
];

const CIRCLE_ICON_BUTTONS: IconButtonPropsRender[] = [
  { size: 'large', variant: 'contained', circle: true, children: <ChevronRightIcon /> },
  { size: 'medium', variant: 'outlined', circle: true, children: <ChevronRightIcon /> },
  { size: 'small', variant: 'transparent', circle: true, children: <ChevronRightIcon /> },
  { variant: 'contained', disabled: true, circle: true, children: <ChevronRightIcon /> },
];

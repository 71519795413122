import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Typography } from '@mui/material';

import { Routes } from '@app/constants/routes';
import { TypedLink } from '@app/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Input } from '@app/components';
import { ForgotPasswordReqDto } from '@app/auth/api/auth.dto';
import { ForgotPasswordValidationSchema } from '@app/auth/api/auth.form';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { useTranslation } from 'react-i18next';

interface Props {
  onForgotPassword: (data: ForgotPasswordReqDto) => void;
  loading: boolean;
  error?: Error | null;
}

export const ForgotPasswordForm: React.FC<Props> = ({ onForgotPassword, loading, error }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    register,
    clearErrors,
  } = useForm<ForgotPasswordValidationSchema>({
    resolver: yupResolver(ForgotPasswordValidationSchema),
  });

  const onSubmit: SubmitHandler<ForgotPasswordValidationSchema> = (formData) => {
    clearErrors();
    onForgotPassword(formData);
  };

  const { email } = watch();

  return (
    <div className="flex h-full flex-col gap-y-4">
      <div className="mb-4 flex flex-col gap-2">
        <Typography variant="xxxl" className="text-center font-bold">
          {t('auth.forgotPassword')}
        </Typography>
        <Typography variant="s" className="text-center">
          {t('auth.forgot.description')}
        </Typography>
      </div>
      <Form
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col gap-y-4 sm:flex-initial"
      >
        <Input
          {...register('email')}
          label={t('auth.email')}
          placeholder={t('auth.enterYourEmail')}
          withBG
          id="email"
          errorMessage={errors.email?.message}
        />
        <div className="mt-auto space-y-4 sm:mt-0">
          <Button type="submit" fullWidth disabled={!email} className="!mt-9 font-medium" loading={loading}>
            {t('auth.forgot.submit')}
          </Button>
          {maybeRenderError(error)}
        </div>
      </Form>
      <Typography align="center">
        {t('auth.forgot.backTo')}{' '}
        <TypedLink to={Routes.auth.sign_in} className="font-bold text-accept-main hover:text-accept-hover">
          {t('auth.signIn')}
        </TypedLink>
      </Typography>
    </div>
  );
};

import { AppLogo } from '@app/assets';
import { Routes } from '@app/constants/routes';
import { TypedLink } from '@app/router';
import { clsxm } from '@app/styles/clsxm';

export const HomeButton: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <TypedLink to={Routes.index}>
      <AppLogo className={clsxm('text-accept-dark-blue', className)} />
    </TypedLink>
  );
};

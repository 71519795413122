import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';

import {
  FileDropZoneWrapper,
  FileDropZoneWrapperProps,
} from '@app/components/file-drop-zone-wrapper/FileDropZoneWrapper';
import styles from './ImageDropZone.module.scss';
import loaderStyle from './../image-item/ImageItem.module.scss';
import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { clsxm } from '@app/styles/clsxm';

export const ImageDropZone: React.FC<Omit<FileDropZoneWrapperProps, 'children'>> = ({
  onMultipleChange,
  className,
  loading,
  ...rest
}) => {
  const renderIcon = useMemo(() => {
    if (loading) {
      return (
        <div className={clsxm(loaderStyle.Wrapper, 'rounded-lg')}>
          <CircularProgress className="!text-accept-main" />
        </div>
      );
    }
    return <AddIcon className="text-accept-dark-blue" />;
  }, [loading]);

  return (
    <FileDropZoneWrapper
      multiple
      className={clsx(styles.DropImageZone, 'relative h-[100px]', className)}
      onMultipleChange={onMultipleChange}
      {...rest}
    >
      {renderIcon}
    </FileDropZoneWrapper>
  );
};

import { Container } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const VCardStepper: React.FC<{
  activeStep: number;
  setActiveStep: (v: number) => void;
}> = ({ activeStep, setActiveStep }) => {
  const { t } = useTranslation();
  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Container className="flex gap-3 sm:gap-4 xl:rounded-xl">
      <Box
        className={clsxm(
          'flex-1 rounded-lg border border-gray-200 py-2 px-[2px] text-center text-xs font-semibold text-slate-500 transition-all',
          'hover:cursor-pointer hover:border-accept-hover sm:px-4',
          !activeStep && 'border-accept-main bg-accept-light text-accept-text'
        )}
        onClick={() => handleStep(0)}
      >
        {t('qr.stepInput.generalInfoTab')}
      </Box>
      <Box
        className={clsxm(
          'flex-1 rounded-lg border border-gray-200 py-2 px-[2px] text-center text-xs font-semibold text-slate-500 transition-all',
          'hover:cursor-pointer hover:border-accept-hover sm:px-4',
          !!activeStep && 'border-accept-main bg-accept-light text-accept-text'
        )}
        onClick={() => handleStep(1)}
      >
        {t('qr.stepInput.designTab')}
      </Box>
    </Container>
  );
};

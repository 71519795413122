import { Autocomplete, Checkbox, OptionItem, Select } from '@app/components';
import { QRCodeOutDtoSchema } from '@app/domain/qr-code/api/qr-code.schema.api';
import { MenuItem } from '@mui/material';
import { buildSortParam } from '@app/utils/api.util';
import { useGetQRCodes } from '@app/domain/qr-code/api/qr-code.hooks.api';
import {
  useCustomerAnalyticsFilterAfterDateQueryParam,
  useCustomerAnalyticsFilterPeriodQueryParam,
  useCustomerAnalyticsFilterQrIdsQueryParam,
} from '@app/domain/analytics/analytics.url.hooks';
import { useTranslation } from 'react-i18next';
import { EAnalyticsPeriodAfterDate } from '@app/domain/analytics/analytics.types';
import { format } from 'date-fns';
import { oneOfEnum } from '@app/utils/yup.utils';
import {
  calcAnalyticsIntervalEnum,
  renderFriendlyAnalyticsInterval,
  renderFriendlyAnalyticsPeriod,
} from '@app/domain/analytics/analytics.utils';

export const QRAnalyticsGlobalFilters: React.FC = () => {
  const { t } = useTranslation();
  const [qrIds, setQrIds] = useCustomerAnalyticsFilterQrIdsQueryParam();
  const [periodAfterDate, setPeriodAfterDate] = useCustomerAnalyticsFilterPeriodQueryParam();
  const [afterDate] = useCustomerAnalyticsFilterAfterDateQueryParam(periodAfterDate);
  const intervalEnum = calcAnalyticsIntervalEnum(periodAfterDate);

  const { data: qrCodes, dataUpdatedAt } = useGetQRCodes({
    sort: buildSortParam<QRCodeOutDtoSchema>('createdDate', 'desc'),
    page: 0,
    size: 500,
  });
  const selectedCodes: { value: string; label: string }[] = !qrCodes
    ? []
    : qrCodes.result.filter((e) => qrIds.includes(e.id)).map((e) => ({ value: e.id, label: e.name }));

  return (
    <div className="z-10 flex flex-wrap justify-end gap-4 bg-accept-light-bg px-4 py-2 lg:sticky lg:top-16 lg:px-0">
      <Autocomplete
        className="flex-1 basis-80"
        multiple
        browserAutocompleteOff
        disableCloseOnSelect
        value={selectedCodes}
        InputProps={{
          placeholder: t('qrAnalytics.qrCodeTitle'),
          classes: { root: 'flex-wrap' },
        }}
        isOptionEqualToValue={({ value: optionValue }, { value }) => optionValue === value}
        onChange={(_, value) => {
          setQrIds(value.map((e) => e.value));
        }}
        optionsUpdatedAt={dataUpdatedAt}
        getOptions={async () => {
          return (
            qrCodes?.result.map((e) => ({
              value: e.id,
              label: e.name,
            })) || []
          );
        }}
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props} selected={selected}>
            <Checkbox label={option.label} checked={selected} disableRipple className="scale-75 py-0" />
          </MenuItem>
        )}
      />
      <div className="flex min-w-[260px] flex-col gap-4">
        <Select
          value={periodAfterDate}
          onChange={(e) => {
            const newPeriod = oneOfEnum(EAnalyticsPeriodAfterDate).validateSync(e.target.value);
            setPeriodAfterDate(newPeriod);
          }}
          size="small"
          renderValue={(value) => {
            return (
              <span className="truncate text-xs text-slate-800">
                <span className="mr-1 truncate text-gray-500">{t('qrAnalytics.period')}:</span>
                <span className="font-semibold">
                  {renderFriendlyAnalyticsPeriod(value as EAnalyticsPeriodAfterDate, t)}
                </span>
              </span>
            );
          }}
        >
          {(Object.keys(EAnalyticsPeriodAfterDate) as Array<keyof typeof EAnalyticsPeriodAfterDate>).map((key) => {
            return (
              <OptionItem value={EAnalyticsPeriodAfterDate[key]} key={key}>
                {renderFriendlyAnalyticsPeriod(EAnalyticsPeriodAfterDate[key], t)}
              </OptionItem>
            );
          })}
        </Select>
        <div className="text-right text-xs italic text-gray-500">
          {format(afterDate, 'PP')} - {t('qrAnalytics.today')}, {renderFriendlyAnalyticsInterval(intervalEnum, t)}
        </div>
      </div>
    </div>
  );
};

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export type GetAllObjectLeaves<T> = T extends Record<string, infer V> ? GetAllObjectLeaves<V> : T;
export type DeepMutable<T> = T extends object ? { -readonly [K in keyof T]: DeepMutable<T[K]> } : T;

export type AsPlainObject<T> = T extends object
  ? {
      [K in keyof T]: AsPlainObject<T[K]>;
    }
  : T;

export const mutable = <T>(t: T) => t as DeepMutable<T>;

export const inferIdentity =
  <BaseType>() =>
  <T extends BaseType>(value: T): T =>
    value;

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

/**
 * chack if second type can extend first one
 * @usage checkTypeCompliance<SomeDto, SomeSchema>();
 * This will throw compiler error in case Schema is not compatible to Dto
 */
export const checkTypeCompliance = <BaseType, T extends BaseType>(): void | T => {};

export type ObjectValues<T> = T[keyof T];

export type WithNullableKeys<T, K extends keyof T> = Omit<T, K> & { [k in K]: T[K] | null };
export type NullablePartial<T> = { [P in keyof T]?: T[P] | null };

import {
  GET_DASHBOARD_MY_STATS_QUERY,
  GET_MY_QR_CODES_QUERY,
  GET_TEMPLATES_QUERY,
} from '@app/constants/query-api-configs';
import { DashboardApi } from '@app/domain/dashboard/api/dashboard.api';
import { useQuery, useQueryClient } from 'react-query';

export const useGetDashboardMyStats = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [GET_DASHBOARD_MY_STATS_QUERY.name],
    queryFn: async () => DashboardApi.getMyStats(),
    refetchInterval: 15e3,
    onSuccess: async () => {
      queryClient.invalidateQueries(GET_MY_QR_CODES_QUERY.name);
      queryClient.invalidateQueries(GET_TEMPLATES_QUERY.name);
    },
  });
};

import { CustomerSubscriptionOutDto, ECurrency, EPlanCycle, EPlanStatus, EPlanType } from '@app/swagger-types';
import { checkTypeCompliance } from '@app/utils/type.utils';
import { oneOfEnum, oneOfEnumOptional } from '@app/utils/yup.utils';
import { InferType, object, string } from 'yup';

export const CustomerSubscriptionOutDtoSchema = object({
  planType: oneOfEnum(EPlanType),
  planStatus: oneOfEnum(EPlanStatus),
  currency: oneOfEnum(ECurrency),
  planCycle: oneOfEnumOptional(EPlanCycle),
  startOfSubscriptionDate: string().required(),
  endOfSubscriptionDate: string().required(),
});
export type CustomerSubscriptionOutDtoSchema = InferType<typeof CustomerSubscriptionOutDtoSchema>;
checkTypeCompliance<CustomerSubscriptionOutDto, CustomerSubscriptionOutDtoSchema>();
checkTypeCompliance<CustomerSubscriptionOutDtoSchema, CustomerSubscriptionOutDto>();

import { AddressIcon, EmailIcon, MobilePhoneIcon, WebsiteIcon } from '@app/assets/icons/vcard';
import { VCardFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { VCardOutDto } from '@app/swagger-types';
import { Divider } from '@mui/material';
import { getFriendlyAdreess } from '../../scan.utils';
import { VCardInfoItem } from './VCardInfoItem';

export const VCardContacts: React.FC<{ vcard?: VCardOutDto | VCardFormSchema | null }> = ({ vcard }) => {
  const friendlyAddress = getFriendlyAdreess(vcard);
  return (
    <div className="mb-6 flex flex-col gap-2">
      {vcard?.phoneNumber && (
        <>
          <VCardInfoItem
            titleColor={vcard.designTitleColor}
            textColor={vcard.designTextColor}
            textHoverColor={vcard.designTextHoverColor}
            title="Mobile phone"
            icon={<MobilePhoneIcon />}
            text={vcard.phoneNumber}
            iconColor={vcard?.designIconColor}
            clickable
            hrefPrefix="tel:"
          />
          <Divider flexItem />
        </>
      )}
      {vcard?.alternativePhoneNumber && (
        <>
          <VCardInfoItem
            titleColor={vcard.designTitleColor}
            textColor={vcard.designTextColor}
            textHoverColor={vcard.designTextHoverColor}
            title="Alternative phone"
            icon={<MobilePhoneIcon />}
            text={vcard.alternativePhoneNumber}
            iconColor={vcard?.designIconColor}
            clickable
            hrefPrefix="tel:"
          />
          <Divider flexItem />
        </>
      )}
      {vcard?.email && (
        <>
          <VCardInfoItem
            title="Email"
            icon={<EmailIcon />}
            text={vcard.email}
            titleColor={vcard.designTitleColor}
            textColor={vcard.designTextColor}
            textHoverColor={vcard.designTextHoverColor}
            iconColor={vcard?.designIconColor}
            clickable
            hrefPrefix="mailto:"
          />
          <Divider flexItem />
        </>
      )}
      {vcard?.website && (
        <>
          <VCardInfoItem
            title="Website"
            icon={<WebsiteIcon />}
            text={vcard.website}
            titleColor={vcard.designTitleColor}
            textColor={vcard.designTextColor}
            textHoverColor={vcard.designTextHoverColor}
            iconColor={vcard?.designIconColor}
            clickable={vcard.website?.startsWith('http')}
          />
          <Divider flexItem />
        </>
      )}
      {vcard && friendlyAddress && (
        <VCardInfoItem
          title="Address"
          icon={<AddressIcon />}
          text={friendlyAddress}
          clickable
          hrefPrefix="https://maps.google.com/?q="
          titleColor={vcard.designTitleColor}
          textColor={vcard.designTextColor}
          textHoverColor={vcard.designTextHoverColor}
          iconColor={vcard?.designIconColor}
        />
      )}
    </div>
  );
};

import { Button, Container, Typography } from '@app/components';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { EmailApi } from '@app/domain/email/api/EmailApi';
import { FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';

export const UnsubscribeEmailPage: React.FC = () => {
  const { t } = useTranslation();
  const [token] = useQueryParam('token', withDefault(StringParam, null));

  const infoQuery = useQuery({
    queryFn: async () => {
      if (!token) {
        return;
      }
      return EmailApi.getEmailPreferences(token);
    },
    onSuccess: (data) => {
      if (!data) {
        return;
      }
      setStatePreferences({
        accountNotification: data.accountNotification,
        qrNotification: data.qrNotification,
        marketingNotification: data.marketingNotification,
      });
    },
    refetchOnWindowFocus: false,
  });
  const savedPreference = infoQuery.data;

  const [statePreferences, setStatePreferences] = useState({
    accountNotification: savedPreference?.accountNotification || false,
    qrNotification: savedPreference?.qrNotification || false,
    marketingNotification: savedPreference?.marketingNotification || false,
  });

  const handleEmailPreferences = (key: keyof typeof statePreferences, value: boolean) => {
    setStatePreferences((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const unsubscribeMutation = useMutation({
    mutationFn: async () => {
      if (!token) {
        return;
      }
      await EmailApi.saveEmailPreferences({
        token,
        ...statePreferences,
      });
      return true;
    },
    onSuccess: () => {
      toast.success(t('toaster.changesSaved'));
    },
  });

  return (
    <div className="flex flex-col items-center justify-center pt-16">
      {!token && <div>Invalid token. Check the link.</div>}
      {infoQuery.isLoading && <ContentLoader loading entity="Email preferences" isEmpty={false} />}
      {savedPreference && (
        <div>
          <div className="mb-8 rounded bg-gray-200 p-2 text-center text-gray-600 ring-1 ring-gray-400">
            {savedPreference.email}
          </div>
          <Container className="flex flex-col gap-6 p-6 lg:rounded-xl">
            <Typography variant="m" className="font-bold">
              {t('account.sectionEmailPreferences.title')}
            </Typography>
            <div className="flex flex-col gap-4 lg:flex-row lg:gap-12">
              <div>
                <FormControlLabel
                  onChange={() => {
                    handleEmailPreferences('accountNotification', !statePreferences.accountNotification);
                  }}
                  checked={statePreferences.accountNotification}
                  control={<Switch />}
                  label={t('account.sectionEmailPreferences.accountNotifications')}
                />
              </div>
              <div>
                <FormControlLabel
                  onChange={() => {
                    handleEmailPreferences('qrNotification', !statePreferences.qrNotification);
                  }}
                  checked={statePreferences.qrNotification}
                  control={<Switch />}
                  label={t('account.sectionEmailPreferences.qrNotifications')}
                />
              </div>
              <div>
                <FormControlLabel
                  onChange={() => {
                    handleEmailPreferences('marketingNotification', !statePreferences.marketingNotification);
                  }}
                  checked={statePreferences.marketingNotification}
                  control={<Switch />}
                  label={t('account.sectionEmailPreferences.marketing')}
                />
              </div>
            </div>
            <div>
              <Button
                onClick={() => {
                  // TODO save
                  unsubscribeMutation.mutate();
                }}
                color="secondary"
                variant="outlined"
                className="w-[220px]"
                loading={unsubscribeMutation.isLoading}
              >
                {t('dialog.saveChanges')}
              </Button>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

import {
  BigRoundCornerIcon,
  FullCircleCornerIcon,
  // TODO remove redundant assets
  // MediumRareRoundCornerIcon,
  // MediumRoundCornerIcon,
  SligthRoundCornerIcon,
} from '@app/assets';
import { clsxm } from '@app/styles/clsxm';
import { ECornerType } from '@app/swagger-types';
import { Box } from '@mui/material';
import styles from './Pattern.module.scss';

const CORNER_ICON_BY_CODE = {
  [ECornerType.SQUARE]: <SligthRoundCornerIcon />,
  // TODO remove unsupported types
  // [ECornerType.MEDIUM_ROUND]: void (<MediumRoundCornerIcon />),
  // [ECornerType.MEDIUM_RARE_ROUND]: void (<MediumRareRoundCornerIcon />),
  [ECornerType.ROUNDED]: <BigRoundCornerIcon />,
  [ECornerType.FULL_CIRCLE]: <FullCircleCornerIcon />,
};

export const CornerContainer: React.FC<{
  type: ECornerType;
  onClick: (value: ECornerType) => void;
  selected?: boolean;
}> = ({ type, selected, onClick }) => {
  const icon = CORNER_ICON_BY_CODE[type];
  if (!icon) {
    return null;
  }
  return (
    <Box
      className={clsxm(
        styles.root,
        'rounded-xl border-[1.5px] border-transparent bg-gray-100 p-4 sm:p-5',
        'hover:cursor-pointer',
        selected && 'border-accept-main bg-white'
      )}
      onClick={() => {
        onClick(type);
      }}
    >
      {icon}
    </Box>
  );
};

import { Button, Container, Form, Typography } from '@app/components';
import { BackButton } from '@app/components/buttons/back-button/BackButton';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { Routes } from '@app/constants/routes';
import { PlanLimitTooltip } from '@app/domain/plan/components/PlanLimitTooltip';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';
import { QRCodeRenderer } from '@app/domain/qr-code/components/QRCodeRenderer';
import { extractStylePropsFromQRTemplateForm } from '@app/domain/qr-code/qr-code.utils';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useTypedNavigate } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { DesignOutDto } from '@app/swagger-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DesignFormSchema } from '../api/template.form.schema.api';
import { useCreateTemplate, useUpdateTemplate } from '../api/template.hooks.api';
import { DEFAULT_TEMPLATE_FORM_VALUES } from '../constants';
import { TemplateFrameStep } from './form/TemplateFrameStep';
import { TemplateLogoStep } from './form/TemplateLogoStep';
import { TemplatePatternStep } from './form/TemplatePatternStep';
import { TemplateTitleStep } from './form/TemplateTitleStep';
import { TemplateStepper, TEMPLATE_STEPPER_STEPS_TYPE } from './TemplateStepper';

export const TemplateForm: React.FC<{
  isDesktop: boolean;
  mode: 'CREATE' | 'EDIT';
  defaultValues?: DesignOutDto;
}> = ({ mode, isDesktop, defaultValues }) => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();

  const [activeStep, setActiveStep] = useState<TEMPLATE_STEPPER_STEPS_TYPE>('Title');
  const { mutate: createTemplate, isLoading } = useCreateTemplate();
  const { mutate: updateTemplate, isLoading: updateLoading } = useUpdateTemplate();

  const formMethods = useForm<DesignFormSchema>({
    resolver: yupResolver(DesignFormSchema),
    defaultValues: {
      // TODO: set current default values
      template: { title: 'Template' },
      ...DEFAULT_TEMPLATE_FORM_VALUES,
      ...defaultValues,
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const goBack = () => {
    navigate({ to: Routes.customer.templates.index, preserveQueryParams: true });
  };

  const renderActiveStep = useMemo(() => {
    switch (activeStep) {
      case 'Title':
        return <TemplateTitleStep />;
      case 'Logo':
        return <TemplateLogoStep />;
      case 'Frames':
        return <TemplateFrameStep />;
      case 'Pattern':
        return <TemplatePatternStep />;
      default:
        return null;
    }
  }, [activeStep]);

  useEffect(() => {
    if (errors?.template) {
      setActiveStep('Title');
    }
  }, [errors]);

  const onSubmit: SubmitHandler<DesignFormSchema> = useHandler((data) => {
    if (!canCreateTemplate) {
      return;
    }
    const formData = {
      ...data,
      frameText: data?.frameText || '',
      libraryId: data.libraryId === NO_LOGO_ID ? null : data.libraryId,
    };

    if (mode === 'CREATE') {
      createTemplate(formData, {
        onSuccess: () => {
          toast.success('Template successfully created');
          goBack();
        },
      });
    } else if (defaultValues?.template?.id) {
      updateTemplate(
        {
          ...formData,
          templateId: defaultValues?.template?.id,
        },
        {
          onSuccess: () => {
            toast.success(t('toaster.changesSaved'));
            goBack();
          },
        }
      );
    }
  });

  const formTitle = mode === 'CREATE' ? t('template.createTemplate') : t('template.editTemplate');
  const { canCreateTemplate } = usePlanLimits();

  const formLatestValue = watch();

  return (
    <FormProvider {...formMethods}>
      <Form
        control={control}
        className={clsxm('flex flex-col gap-4 xl:flex-row xl:px-6 xl:pb-6')}
        onSubmit={handleSubmit(onSubmit)}
      >
        {isDesktop ? (
          <>
            <div className="flex flex-1 flex-col gap-4">
              <Container rounded>
                <div className="mb-6 flex items-center">
                  <BackButton onClick={goBack} />
                  <Typography variant="xxl" className="font-bold">
                    {formTitle}
                  </Typography>
                </div>
                <TemplateStepper activeStep={activeStep} onChange={(step) => setActiveStep(step)} />
              </Container>
              <Container rounded>{renderActiveStep}</Container>
            </div>
            <Container className="flex w-[35%] min-w-[400px] flex-col gap-4" rounded>
              <Typography variant="xl" className="font-bold">
                {t('qr.preview')}
              </Typography>
              <div className="my-16 flex justify-center">
                <QRCodeRenderer
                  asPreview
                  qrTemplateId={defaultValues?.id}
                  {...extractStylePropsFromQRTemplateForm(formLatestValue)}
                  frameClassName="min-w-[250px] max-w-[300px] flex-1 bg-transparent p-0"
                />
              </div>
              <PlanLimitTooltip condition={!canCreateTemplate} divWrap>
                <Button fullWidth type="submit" loading={isLoading || updateLoading} disabled={!canCreateTemplate}>
                  {t('dialog.save')}
                </Button>
              </PlanLimitTooltip>
            </Container>
          </>
        ) : (
          <>
            <Container className="flex items-center">
              <BackButton size="small" onClick={goBack} />
              <Typography variant="l" className="font-bold">
                {formTitle}
              </Typography>
            </Container>
            <Container>
              <TemplateStepper activeStep={activeStep} onChange={(step) => setActiveStep(step)} />
            </Container>
            <Container>{renderActiveStep}</Container>
            <Container>
              <Typography className="mb-2 text-center font-bold">Preview</Typography>
              <div className="flex justify-center">
                <QRCodeRenderer
                  asPreview
                  qrTemplateId={defaultValues?.id}
                  {...extractStylePropsFromQRTemplateForm(formLatestValue)}
                  frameClassName="min-h-[160px] min-w-[160px] max-w-[250px] flex-1"
                />
              </div>
            </Container>
            <Container className="flex justify-center">
              <PlanLimitTooltip condition={!canCreateTemplate} divWrap>
                <Button fullWidth type="submit" loading={isLoading || updateLoading} disabled={!canCreateTemplate}>
                  {t('dialog.save')}
                </Button>
              </PlanLimitTooltip>
            </Container>
          </>
        )}
      </Form>
    </FormProvider>
  );
};

import { useMemo } from 'react';

import { useQuery } from 'react-query';
import { GET_ADMIN_QRS_QUERY } from '@app/constants/query-api-configs';

import { useBasicQueryControls } from '@app/hooks/useBasicQueryControls.hook';

import { Typography } from '@mui/material';

import { Table } from '@app/components';
import { useTableColumns } from './useTableColumns';
import { DateParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { QRCodeApi } from '@app/domain/qr-code/api/qr-code.api';
import { ECodeType } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { calcSpecialQRSearch } from '../../admin-qr.utils';

export const AdminQRTable: React.VFC = ({}) => {
  const {
    values: { page, sort = GET_ADMIN_QRS_QUERY.defaultSort, size },
    handlers: { handlePageChange, handleSortChange, handleSizeChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      size: 10,
    },
    prefix: GET_ADMIN_QRS_QUERY.tablePrefix,
  });
  const [search] = useQueryParam(GET_ADMIN_QRS_QUERY.searchParam, withDefault(StringParam, ''));
  const [type] = useQueryParam(GET_ADMIN_QRS_QUERY.typeParam, withDefault(StringParam, ''));
  const [afterDate] = useQueryParam(GET_ADMIN_QRS_QUERY.afterDateParam, withDefault(DateParam, undefined));

  const { data, isFetching } = useQuery(
    [GET_ADMIN_QRS_QUERY.name, { page, sort, size, search, type, afterDate }],
    () => {
      // TODO auto validate params
      const validType = oneOfEnum(ECodeType).isValidSync(type) ? [type] : undefined;
      const { idStartsWith, exactMatchById, exactMatchByEmail, isSpecial } = calcSpecialQRSearch(search);
      return QRCodeApi.adminGetAll({
        sort,
        type: validType,
        page: Math.ceil((page || 1) - 1),
        size,
        // use term for univarsal search. Very slow on big volume
        // term: search,
        idStartsWith,
        exactMatchById,
        exactMatchByEmail,
        afterDate: isSpecial ? undefined : afterDate?.toJSON(),
      });
    },
    { keepPreviousData: true, ...GET_ADMIN_QRS_QUERY.config }
  );

  const columns = useTableColumns();

  const leftContent = useMemo(() => {
    return (
      <div>
        <Typography variant="xl" className="font-bold">
          QRs Table ({data?.total})
        </Typography>
      </div>
    );
  }, [data?.total]);

  const rightContent = useMemo(() => {
    return (
      <div>
        {/* TODO search */}
        {/* <Button variant="contained" color="primary">
          Add New
        </Button> */}
      </div>
    );
  }, []);

  return (
    <Table
      cols={columns}
      tableData={data?.result ?? []}
      leftHeaderContent={leftContent}
      rightHeaderContent={rightContent}
      notFoundMessage="No users on file."
      currentPage={page}
      loading={isFetching}
      pageSize={size}
      onSizeChange={handleSizeChange}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
    />
  );
};

import { Routes } from '@app/constants/routes';

import { MyRouteObject } from '@app/models/router';
import { QRCodeModule } from './QRCodeModule';
import { ScanQRCodePage } from './pages/ScanQRCodePage';

export const QRCodeRoutes: MyRouteObject = {
  path: Routes.index,
  element: <QRCodeModule />,
  children: [
    { path: Routes.qr_code.scanned, element: <ScanQRCodePage /> },
    { path: Routes.qr_code.old_scanned, element: <ScanQRCodePage /> },
    { path: Routes.qr_code.root_scanned, element: <ScanQRCodePage /> },
  ],
};

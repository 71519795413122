import clsx from 'clsx';
import { forwardRef, useState } from 'react';
import styles from './ColorPickerInput.module.scss';
import { Popover, PopoverOrigin } from '@mui/material';

import { Input, InputProps } from '../input/Input';
import { useUncontrolled } from '@app/hooks/useUncontrolled.hook';
import { IconButton } from '@app/components/buttons/icon-button/IconButton';
import { ColorPickerBaseProps } from 'react-colorful/dist/types';
import { SketchPicker } from 'react-color';
import { omit } from 'lodash';

export type ColorPickerInputProps = ColorPickerBaseProps<string> & {
  inputProps?: InputProps;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disableOpenOnFocus?: boolean;
};

export const ColorPickerInput = forwardRef<HTMLInputElement, ColorPickerInputProps>(
  ({ inputProps, color, anchorOrigin, transformOrigin, onChange, disableOpenOnFocus }, ref) => {
    const [_value, handleColorChange] = useUncontrolled({
      value: color,
      rule: (val) => typeof val === 'string',
      onChange,
    });
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [anchorProps] = useState<{
      anchorOrigin: PopoverOrigin;
      transformOrigin: PopoverOrigin;
    }>({
      anchorOrigin: anchorOrigin ?? {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: transformOrigin ?? {
        vertical: 'top',
        horizontal: 'center',
      },
    });

    const handleClose = () => {
      setAnchorEl(null);
    };

    const removeNonHexChars = (value: string) => value.toUpperCase().replaceAll(/[^A-F0-9]/g, '');
    const validateValue = (value: string) => {
      const hexOnly = removeNonHexChars(value);

      if (!hexOnly) {
        return '#000000';
      }

      // condition 1
      if (hexOnly.length === 3) {
        const [a, b, c] = hexOnly;
        return `#${a}${a}${b}${b}${c}${c}`;
      }

      // condition 2
      if (hexOnly.length < 6) {
        return `#${hexOnly}${'0'.repeat(6 - hexOnly.length)}`;
      }

      return `#${hexOnly}`;
    };

    const handleChange = (color: string) => {
      const value = validateValue(color);
      handleColorChange(value || '');
    };

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (inputProps?.disabled || disableOpenOnFocus) {
        return null;
      }
      setAnchorEl(event.currentTarget);
    };

    return (
      <>
        <Input
          ref={ref}
          value={_value || ''}
          inputProps={{
            className: 'pr-[38px] uppercase',
          }}
          onChange={(e) => {
            handleColorChange(e.currentTarget.value || '');
          }}
          withBG
          disabled={inputProps?.disabled}
          endAdornment={
            <IconButton
              disableRipple
              onClick={handleOpen}
              variant="transparent"
              className={styles.EndAdornmentIconWrapper}
            >
              <div
                className="h-6 w-6 rounded"
                style={{ background: _value || inputProps?.placeholder || '#000' }}
              ></div>
            </IconButton>
          }
          onBlur={(e) => {
            handleChange(e.currentTarget.value);
            inputProps?.onBlur && inputProps.onBlur(e);
          }}
          {...omit(inputProps, 'onBlur')}
        />

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorProps.anchorOrigin}
          transformOrigin={anchorProps.transformOrigin}
          classes={{
            paper: clsx(styles.Paper),
          }}
        >
          <SketchPicker width="220px" color={_value} onChange={(col) => handleChange(col.hex)} />
        </Popover>
      </>
    );
  }
);

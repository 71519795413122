import { Button } from '@app/components';
import { BillingApi } from '@app/domain/billing/api/billing.api';
import { clsxm } from '@app/styles/clsxm';
import { useMutation } from 'react-query';

export const CustomerPortalButton: React.FC<{
  className?: string;
}> = ({ className }) => {
  const {
    data: customerPortal,
    isLoading,
    mutate: mutateUpdatePaymentMethod,
  } = useMutation({
    mutationFn: async () => BillingApi.goToCustomerPortal({ returnURL: window.location.href }),
  });

  if (customerPortal) {
    window.location.href = customerPortal.url;
  }

  const isPortalLoading = isLoading || Boolean(customerPortal);

  return (
    <div className={clsxm(className)}>
      <Button
        size="small"
        variant="outlined"
        loading={isPortalLoading}
        onClick={() => {
          mutateUpdatePaymentMethod();
        }}
      >
        Stripe Customer Portal
      </Button>
    </div>
  );
};

import { useMutation, UseMutationOptions } from 'react-query';
import { AuthApi } from '@app/auth/api/auth.api';
import { ConfirmPasswordReqDto, ForgotPasswordReqDto } from '@app/auth/api/auth.dto';
import { ChangePasswordInDto } from '@app/swagger-types';
import { useStore } from '@app/store/useStore.hook';

export const useForgotPassword = (options: UseMutationOptions<unknown, Error, ForgotPasswordReqDto> = {}) => {
  return useMutation(
    async (Dto) => {
      return await AuthApi.forgotPassword(Dto);
    },
    {
      ...options,
    }
  );
};
export const useConfirmPassword = (options: UseMutationOptions<unknown, Error, ConfirmPasswordReqDto> = {}) => {
  return useMutation(
    async (Dto) => {
      return await AuthApi.confirmPassword(Dto);
    },
    {
      ...options,
    }
  );
};

export const useChangePassword = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, ChangePasswordInDto> = {}) => {
  const getMe = useStore.useGetMe();

  return useMutation(
    async (Dto) => {
      return await AuthApi.changePassword(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await getMe();

        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

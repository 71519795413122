import { Tooltip } from '@app/components';
import { IS_PROD_ENV } from '@app/environment/typed-env';
import { clsxm } from '@app/styles/clsxm';
import { format } from 'date-fns';

export const ActiveCampaignBadge: React.FC<{
  activeCampaignId?: string | null;
  lastActiveCampaignSyncedDate?: string | null;
}> = ({ activeCampaignId, lastActiveCampaignSyncedDate }) => {
  return (
    <div
      className={clsxm(
        'rounded px-1 text-center',
        activeCampaignId ? 'bg-blue-200 text-blue-800' : 'italic text-gray-400'
      )}
    >
      {activeCampaignId ? (
        <Tooltip
          title={`ActiveCampaign, last sync ${
            lastActiveCampaignSyncedDate ? format(new Date(lastActiveCampaignSyncedDate), 'PP pp') : '-'
          }`}
        >
          <a href={getLinkForStripe(activeCampaignId)} target="_blank" rel="noreferrer">
            AC: {activeCampaignId}
          </a>
        </Tooltip>
      ) : (
        'no AC'
      )}
    </div>
  );
};

const getLinkForStripe = (activeCampaignId: string) => {
  if (IS_PROD_ENV) {
    return `https://trueqrcode.activehosted.com/app/contacts/${activeCampaignId}`;
  }
  // TODO use DEV special link when ready
  return `https://trueqrcode.activehosted.com/app/contacts/${activeCampaignId}`;
};

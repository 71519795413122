import { apiService } from '@app/services/api-service';
import { ChartAnalyticOutDto, OverallAnalyticOutDto, ScansByLocationOutDto } from '@app/swagger-types';

export abstract class AnalyticsApi {
  static async getOverall(params?: IAnalyticsGlobalBaseFilters) {
    // TODO validate
    const { data } = await apiService.get<OverallAnalyticOutDto>('/private/analytics/overall', { params });
    return data;
  }
  static async getAmountOfScans(params?: IAnalyticsGlobalDetailedFilters) {
    // TODO validate
    const { data } = await apiService.get<ChartAnalyticOutDto>('/private/analytics/charts/amount-of-scans', { params });
    return data;
  }
  static async getAmountOfUsers(params?: IAnalyticsGlobalDetailedFilters) {
    // TODO validate
    const { data } = await apiService.get<ChartAnalyticOutDto>('/private/analytics/charts/amount-of-users', { params });
    return data;
  }
  static async getScansByDaytime(params?: IAnalyticsGlobalDetailedFilters) {
    // TODO validate
    const { data } = await apiService.get<ChartAnalyticOutDto>('/private/analytics/charts/scans-by-daytime', {
      params,
    });
    return data;
  }
  static async getScansByLocation(params?: IAnalyticsGlobalBaseFilters) {
    // TODO validate
    const { data } = await apiService.get<ScansByLocationOutDto>('/private/analytics/scans-by-location', {
      params,
    });
    data.withLocation.sort((a, b) =>
      a.totalScans === b.totalScans
        ? a.country === b.country
          ? a.city > b.city
            ? 1
            : -1
          : a.country > b.country
          ? 1
          : -1
        : a.totalScans < b.totalScans
        ? 1
        : -1
    );
    return data;
  }
}

export interface IAnalyticsGlobalBaseFilters {
  qrCodeIds?: string[];
  afterDate?: string;
}

export interface IAnalyticsGlobalDetailedFilters extends IAnalyticsGlobalBaseFilters {
  intervalMinutes?: number;
}

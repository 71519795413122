import { useStore } from '@app/store/useStore.hook';
import { uploadImage } from '@app/utils/file.utils';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { StyledAvatarEditor } from './StyledAvatarEditor';

export const ProfileAvatarEditor: React.FC<
  React.ComponentProps<typeof StyledAvatarEditor> & {
    cropSize?: number;
    setLoading?: (loading: boolean) => void;
    setAvatarId?: (avatarId?: string) => void;
    avatarId?: string | null;
  }
> = ({ setAvatarId, setLoading, avatarId, ...props }) => {
  const [file, setFile] = useState<File | null>(null);
  const user = useStore().currentUser;

  const { mutate: loadImage } = useMutation(
    async (file: File | null) => {
      if (!file) {
        return;
      }
      return await uploadImage({ file });
    },
    {
      onMutate() {
        setLoading && setLoading(true);
      },
      onSuccess(data) {
        if (data) {
          setFile(data.file);
          setAvatarId && setAvatarId(data.imageId);
        }
      },
      onSettled() {
        setLoading && setLoading(false);
      },
    }
  );

  const handleDelete = useCallback(() => {
    if (!user) {
      return;
    }
    setAvatarId && setAvatarId(undefined);
    // TODO fix BE issue with PATCH and null fields.
    // Currently PATCH ignores NULL values.
    // So, to remove optional avatarId we have to use PUT with all fields of user.
    // Expected - use PATCH { avatarId: null }
    // await clientApis.AuthApi.replaceMe({
    //   ...user,
    //   avatarId: undefined,
    // });
    // reloadSession();
  }, [setAvatarId, user]);

  return (
    <StyledAvatarEditor {...props} onChange={loadImage} onDelete={handleDelete} imageId={!file ? avatarId || '' : ''} />
  );
};

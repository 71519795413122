import { CommaArrayParam } from '@app/extensions/use-query-params/CommaArrayParam';
import { useMemo } from 'react';
import { createEnumParam, DateTimeParam, useQueryParam, withDefault } from 'use-query-params';
import { EAnalyticsPeriodAfterDate } from './analytics.types';
import { calcAnalyticsAfterDate } from './analytics.utils';

// prefix "af" stands for "analytics filters"

export const ANALYTICS_QUERY_PARAM_QR_IDS = 'af-qr-ids';
export const useCustomerAnalyticsFilterQrIdsQueryParam = () =>
  useQueryParam(ANALYTICS_QUERY_PARAM_QR_IDS, withDefault(CommaArrayParam, [] as string[]));

const DEFAULT_PERIOD = EAnalyticsPeriodAfterDate.LAST_7_DAYS;
const AnalyticsPeriodAfterDate = createEnumParam(Object.values(EAnalyticsPeriodAfterDate));
export const useCustomerAnalyticsFilterPeriodQueryParam = () =>
  useQueryParam('af-period', withDefault(AnalyticsPeriodAfterDate, DEFAULT_PERIOD));

export const useCustomerAnalyticsFilterAfterDateQueryParam = (periodAfterDate: EAnalyticsPeriodAfterDate) => {
  const [_afterDate, setAfterDate] = useQueryParam('af-after-date', DateTimeParam);
  const memoDate = useMemo(() => _afterDate || calcAnalyticsAfterDate(periodAfterDate), [_afterDate, periodAfterDate]);
  const afterDate = _afterDate || memoDate;
  return [afterDate, setAfterDate] as const;
};

export const useCustomerAnalyticsShouldShowResetFilters = () => {
  const [qrIds, setQrIds] = useCustomerAnalyticsFilterQrIdsQueryParam();
  const [periodAfterDate, setPeriodAfterDate] = useCustomerAnalyticsFilterPeriodQueryParam();
  const shouldShowReset = qrIds?.length > 0 || periodAfterDate !== DEFAULT_PERIOD;
  const resetFilters = () => {
    setQrIds(null);
    setPeriodAfterDate(DEFAULT_PERIOD);
  };
  return { shouldShowReset, resetFilters };
};

export const useCustomerAnalyticsGlobalFilterParams = () => {
  const [qrCodeIds] = useCustomerAnalyticsFilterQrIdsQueryParam();
  const [periodAfterDate] = useCustomerAnalyticsFilterPeriodQueryParam();
  const [afterDate] = useCustomerAnalyticsFilterAfterDateQueryParam(periodAfterDate);
  return {
    qrCodeIds,
    periodAfterDate,
    afterDate,
  };
};

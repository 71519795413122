import { Button, Container, Typography } from '@app/components';
import { useStore } from '@app/store/useStore.hook';
import { FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateCustomer } from '../api/user.hooks.api';

export const AccountQRCodePreferences: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser: user } = useStore(({ currentUser }) => ({
    currentUser,
  }));
  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();
  const [useGPS, setUseGPS] = useState(user?.shouldAskForGPSCoordinates || false);

  const disableSubmitButton = user?.shouldAskForGPSCoordinates === useGPS;

  return (
    <Container className="flex flex-col gap-6 p-6 lg:rounded-xl">
      <Typography variant="m" className="font-bold">
        {t('account.sectionQRPreferences.title')}
      </Typography>
      <div className="flex flex-col gap-4 lg:flex-row lg:gap-12">
        <div>
          <FormControlLabel
            onChange={() => {
              setUseGPS((prev) => !prev);
            }}
            checked={useGPS}
            control={<Switch />}
            label={t('account.sectionQRPreferences.useGPSBasedLocation')}
          />
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            if (user) {
              updateCustomer(
                { shouldAskForGPSCoordinates: useGPS },
                {
                  onSuccess: () => {
                    toast.success(t('toaster.changesSaved'));
                  },
                }
              );
            }
          }}
          color="secondary"
          disabled={disableSubmitButton}
          variant="outlined"
          loading={isLoading}
          className="w-[220px]"
        >
          {t('dialog.saveChanges')}
        </Button>
      </div>
    </Container>
  );
};

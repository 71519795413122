import { useMemo, useCallback } from 'react';
import { useRoutes } from 'react-router-dom';

import { AuthStatus } from '@app/auth/api/auth.type';
import { AuthUserSchema } from '@app/auth/api/auth.dto';
import { ERole } from '@app/swagger-types';

import { useStore } from '@app/store/useStore.hook';

import { TypedRouteObject } from '../types';

export const useFiltredRoutes = <TAuthStatus extends AuthStatus, TRole extends ERole[]>(
  routes: TypedRouteObject<TAuthStatus, TRole>[]
) => {
  const { currentUser, authStatus } = useStore(({ currentUser, authStatus }) => ({
    currentUser,
    authStatus,
  }));

  const guardCheck = useCallback(
    <R extends TypedRouteObject<TAuthStatus, TRole>>({
      route,
      authStatus,
      currentUser,
    }: {
      route: R;
      currentUser: AuthUserSchema | null;
      authStatus: AuthStatus;
    }) => {
      {
        // AUTH STATUS GUARD
        if (route.authStatus && route.authStatus !== authStatus) {
          return false;
        }

        // ROLE GUARD
        if (route.role) {
          const userRole = currentUser?.role;
          if (!userRole) {
            return false;
          }

          return route.role.includes(userRole);
        }

        return true;
      }
    },
    []
  );

  const filtredRoutes = useMemo(() => {
    const deepFilter = (routeObjects: TypedRouteObject<TAuthStatus, TRole>[]) => {
      const filteredRoutes: TypedRouteObject<TAuthStatus, TRole>[] = routeObjects.reduce<
        TypedRouteObject<TAuthStatus, TRole>[]
      >((filteredRoutes, currentRoute) => {
        if (!guardCheck({ route: currentRoute, currentUser, authStatus })) {
          return filteredRoutes;
        }

        if (currentRoute.children) {
          return [...filteredRoutes, { ...currentRoute, children: deepFilter(currentRoute.children) }];
        }

        return [...filteredRoutes, currentRoute];
      }, []);

      return filteredRoutes;
    };

    return deepFilter(routes);
  }, [authStatus, currentUser, guardCheck, routes]);

  return useRoutes(filtredRoutes);
};

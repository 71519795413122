import { UserBaseOutDto } from '@app/swagger-types';
import { checkTypeCompliance } from '@app/utils/type.utils';
import { boolean, InferType, object, string } from 'yup';

export const EmailPreferenceInfoOutSchema = object({
  email: string().required(),
  accountNotification: boolean().default(false),
  qrNotification: boolean().default(false),
  marketingNotification: boolean().default(false),
});
export type EmailPreferenceInfoOutSchema = InferType<typeof EmailPreferenceInfoOutSchema>;

export const EmailPreferenceInDtoSchema = object({
  token: string().required(),
  accountNotification: boolean(),
  qrNotification: boolean(),
  marketingNotification: boolean(),
});
export type EmailPreferenceInDtoSchema = InferType<typeof EmailPreferenceInDtoSchema>;

checkTypeCompliance<
  Pick<UserBaseOutDto, 'accountNotification' | 'qrNotification' | 'marketingNotification'>,
  EmailPreferenceInfoOutSchema
>();
checkTypeCompliance<
  Pick<UserBaseOutDto, 'accountNotification' | 'qrNotification' | 'marketingNotification'>,
  EmailPreferenceInDtoSchema
>();

import { QrDtoMapper } from '@app/domain/qr-code/api/qr-code.mapper';
import { QRCodePublicOutDtoSchema } from '@app/domain/qr-code/api/qr-code.schema.api';
import { apiService, validateAndAssert } from '@app/services/api-service';
import { ScanInDto } from '@app/swagger-types';

export abstract class ScanApi {
  static async scanById(qrCodeId: string, body: ScanInDto) {
    const res = await validateAndAssert(
      apiService.post(`/public/scans/${qrCodeId.toUpperCase()}`, body),
      QRCodePublicOutDtoSchema
    );
    QrDtoMapper.mutateResponseForForm(res);
    return res;
  }
}

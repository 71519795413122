import { useQueryParamsPrefix } from '@app/hooks/useQueryParamsPrefix.hook';
import { ArrayParam, StringParam } from '@app/utils/use-query-params.utils';
import { withDefault } from 'use-query-params';
import { EQRCodeSort } from './qr-code.types';

export const useQRCodeQueryParams = () => {
  return useQueryParamsPrefix({
    paramConfigMap: {
      term: withDefault(StringParam, ''),
      sort: withDefault(StringParam, EQRCodeSort.MOST_RECENT),
      type: withDefault(ArrayParam, []),
    },
  });
};

import { Button } from '@app/components';
import { UIStyledSection } from '../components/UIStyledSection';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ButtonProps } from '@app/components/buttons/button/Button';

export const UITabButtons: React.FC = () => {
  const render = (buttons: ButtonPropsRender[]) =>
    buttons.map(({ text, ...b }, i) => (
      <Button key={i} {...b}>
        {text}
      </Button>
    ));

  return (
    <UIStyledSection title="UISectionButtons">
      <div className="flex gap-4">
        <div className="flex flex-col gap-4 pb-6">
          <div className="font-bold">Contained</div>
          <div className="flex w-fit flex-col gap-4">{render(CONTAINER_BUTTONS)}</div>
        </div>
        <div className="flex flex-col gap-4 pb-6">
          <div className="font-bold">Outlined</div>
          <div className="flex w-fit flex-col gap-4">{render(OUTLINED_BUTTONS)}</div>
        </div>
        <div className="flex flex-col gap-4 pb-6">
          <div className="font-bold">Text</div>
          <div className="flex w-fit flex-col gap-4">{render(TEXT_BUTTONS)}</div>
        </div>
        <div className="flex flex-col gap-4 pb-6">
          <div className="font-bold">With Right Icon</div>
          <div className="flex w-fit flex-col gap-4">{render(WITH_RIGHT_ICON_BUTTONS)}</div>
        </div>
        <div className="flex flex-col gap-4 pb-6">
          <div className="font-bold">With Left Icon</div>
          <div className="flex w-fit flex-col gap-4">{render(WITH_LEFT_ICON_BUTTONS)}</div>
        </div>
      </div>
    </UIStyledSection>
  );
};

type ButtonPropsRender = ButtonProps & { text: string };

const CONTAINER_BUTTONS: ButtonPropsRender[] = [
  { size: 'large', text: 'Default large', variant: 'contained' },
  { size: 'medium', text: 'Default medium', variant: 'contained' },
  { size: 'small', text: 'Default small', variant: 'contained' },
  { text: 'Default disabled', variant: 'contained', disabled: true },
];

const OUTLINED_BUTTONS: ButtonPropsRender[] = [
  { size: 'large', text: 'Outlined large', variant: 'outlined' },
  { size: 'medium', text: 'Outlined medium', variant: 'outlined' },
  { size: 'small', text: 'Outlined small', variant: 'outlined' },
  { text: 'Outlined disabled', variant: 'outlined', disabled: true },
];

const TEXT_BUTTONS: ButtonPropsRender[] = [
  { size: 'large', text: 'Text large', variant: 'text' },
  { size: 'medium', text: 'Text medium', variant: 'text' },
  { size: 'small', text: 'Text small', variant: 'text' },
  { text: 'Text disabled', variant: 'text', disabled: true },
];

const WITH_RIGHT_ICON_BUTTONS: ButtonPropsRender[] = [
  { size: 'large', text: 'Large default', endIcon: <ChevronRightIcon /> },
  { size: 'medium', text: 'Medium outlined', variant: 'outlined', endIcon: <ChevronRightIcon /> },
  { size: 'small', text: 'Text small', variant: 'text', endIcon: <ChevronRightIcon /> },
  { text: 'Disabled icon', disabled: true, endIcon: <ChevronRightIcon /> },
];

const WITH_LEFT_ICON_BUTTONS: ButtonPropsRender[] = [
  { size: 'large', text: 'Large default', startIcon: <ChevronLeftIcon /> },
  { size: 'medium', text: 'Medium outlined', variant: 'outlined', startIcon: <ChevronLeftIcon /> },
  { size: 'small', text: 'Text small', variant: 'text', startIcon: <ChevronLeftIcon /> },
  { text: 'Disabled icon', disabled: true, startIcon: <ChevronLeftIcon /> },
];

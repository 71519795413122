import { ECurrency } from '@app/swagger-types';

const nf = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });
export const numberWithCommas = (x: number | string) => {
  return nf.format(+x);
};

export const renderCurrencyAsSymbol = (currency: ECurrency) => {
  switch (currency) {
    case ECurrency.EUR:
      return '€';
    case ECurrency.USD:
      return '$';
    case ECurrency.GBP:
      return '£';
  }
  return String(currency);
};

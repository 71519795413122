import { APIPaginationQueryParams } from '@app/models/api';
import { apiService, validateAndAssert } from '@app/services/api-service';
import {
  BillingInDto,
  CustomerPortalInDto,
  CustomerPortalOutDto,
  PageResponseInvoiceOutDto,
  PaymentMethodInfoOutDto,
} from '@app/swagger-types';
import { NullablePartial } from '@app/utils/type.utils';
import { BillingInfoOutDtoSchema } from './billing.schema';

export abstract class BillingApi {
  static async getMyInfo() {
    return validateAndAssert(apiService.get('/private/billing-info'), BillingInfoOutDtoSchema);
  }
  static async patchMyInfo(dto: NullablePartial<BillingInDto>) {
    return validateAndAssert(apiService.patch('/private/billing-info', dto), BillingInfoOutDtoSchema);
  }
  static async getMyPaymentMethod() {
    // TODO validate
    const { data } = await apiService.get<PaymentMethodInfoOutDto>('/private/billing-info/payment-method');
    return data;
  }
  static async goToCustomerPortal(dto: CustomerPortalInDto) {
    // TODO validate
    const { data } = await apiService.post<CustomerPortalOutDto, CustomerPortalInDto>(
      '/private/billing-info/customer-portal',
      dto
    );
    return data;
  }
  static async goToUpdatePaymentMethod(dto: CustomerPortalInDto) {
    // TODO validate
    const { data } = await apiService.post<CustomerPortalOutDto, CustomerPortalInDto>(
      '/private/billing-info/customer-portal/payment-method',
      dto
    );
    return data;
  }
  static async getMyInvoices(params: APIPaginationQueryParams) {
    // TODO validate
    const { data } = await apiService.get<PageResponseInvoiceOutDto>('/private/billing-info/invoices', { params });
    return data;
  }
}

import { AuthUserSchema } from '@app/auth/api/auth.dto';
import { SuperAdminDto } from '@app/models/super-admin';
import { ERole } from '@app/swagger-types';

export const isAdmin = (user: AuthUserSchema): user is SuperAdminDto => {
  return user.role === ERole.ROLE_ADMIN;
};

export const isCustomer = (user: AuthUserSchema): user is SuperAdminDto => {
  return user.role === ERole.ROLE_CUSTOMER;
};

export const renderFriendlyRole = (role: ERole) => {
  switch (role) {
    case ERole.ROLE_ADMIN:
      return 'Admin';
    case ERole.ROLE_CUSTOMER:
      return 'Customer';
    default:
      return role;
  }
};

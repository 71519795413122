import { Input, Typography } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QRCodeFormSchema, regexUrlWithoutProtocol } from '../../../api/qr-code.form.schema.api';

export const QRCodeWebsiteUrl: React.FC<{
  className?: string;
  hint?: string;
}> = ({ className, hint }) => {
  const { t } = useTranslation();
  const { control, trigger } = useFormContext<QRCodeFormSchema>();

  return (
    <div className={clsxm('h-full bg-white px-5 py-6 xl:rounded-xl', className)}>
      <Typography className="pb-6 font-bold sm:text-l">{t('qr.websiteInput.title')}</Typography>
      <Controller
        name="websiteUrl"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            onChange={(e) => {
              onChange(e.currentTarget.value.replace(' ', ''));
              if (fieldState?.error?.message) {
                trigger('websiteUrl');
              }
            }}
            onBlur={() => {
              if (regexUrlWithoutProtocol.test(value || '')) {
                onChange(`https://${value}`);
                trigger('websiteUrl');
                return;
              }
              trigger('websiteUrl');
            }}
            value={value || ''}
            label={t('qr.websiteInput.enterURL')}
            withBG
            placeholder="https://www.example.com"
            labelProps={{
              className: 'font-normal text-gray-800',
            }}
            inputProps={{
              className: 'font-normal bg-transparent',
            }}
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
      {hint && <div className="pt-4 text-s font-normal text-gray-500">{hint}</div>}
    </div>
  );
};

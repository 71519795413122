import { useDebouncedCallback } from 'use-debounce';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { Input, InputProps } from '../input/Input';
import { useUncontrolled } from '@app/hooks/useUncontrolled.hook';

export interface SearchInputProps extends Omit<InputProps, 'onChange' | 'size'> {
  debounce?: number;
  className?: string;
  onDebounce?: (text: string) => void;
  onChange?: (text: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  debounce = 300,
  className,
  value,
  defaultValue = '',
  onChange,
  onDebounce,
  ...props
}) => {
  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    rule: (val) => typeof val === 'string',
    onChange,
  });

  const debounced = useDebouncedCallback((value) => {
    if (onDebounce) {
      onDebounce(value);
    }
  }, debounce);

  return (
    <Input
      onChange={(e) => {
        handleChange(e.target.value);
        if (onDebounce) {
          debounced(e.target.value);
        }
      }}
      className={className}
      inputSize="small"
      startAdornment={
        <div className="flex min-w-[32px]">
          <div className="flex p-[7px]">
            <SearchIcon />
          </div>
        </div>
      }
      endAdornment={
        <div className="min-w-[32px]">
          {Boolean(_value) && (
            <div onClick={() => handleChange('')} className="cursor-pointer" aria-hidden>
              <CloseIcon />
            </div>
          )}
        </div>
      }
      value={_value}
      {...props}
    />
  );
};

import { Routes } from '@app/constants/routes';

import { UserManagementModule } from './UserManagementModule';
import { AdminProfile } from './pages/AdminProfile';
import { Admins } from './pages/Admins';
import { UsersPage } from './pages/UsersPage';
import { TypedNavigate } from '@app/router';

export const UserManagementRoutes = {
  path: Routes.admin.user_management.index,
  element: <UserManagementModule />,
  children: [
    { path: Routes.admin.user_management.users, element: <UsersPage /> },
    { path: Routes.admin.user_management.admins, element: <Admins /> },
    { path: Routes.admin.user_management.admin_profile, element: <AdminProfile /> },
    { index: true, path: '*', element: <TypedNavigate to={Routes.admin.user_management.users} /> },
  ],
};

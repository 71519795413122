import { EmptyPlaceholderIcon } from '@app/assets/icons/vcard';
import { Avatar, Typography } from '@app/components';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { useGetLibraryById } from '@app/domain/library/api/library.hooks.api';
import { MultilinkFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { QRCodeSocialLinks } from '@app/domain/qr-code/components/QRCodeSocialLinks';
import { clsxm } from '@app/styles/clsxm';
import { MultilinkOutDto } from '@app/swagger-types';
import { useTranslation } from 'react-i18next';
import { QRCodePreviewWrap } from '../../QRCodePreviewWrap';

export const MultilinkPreview: React.FC<{
  multilink?: MultilinkOutDto | null;
  multilinkFromForm?: MultilinkFormSchema | null;
  isRenderFromForm?: boolean;
}> = ({ multilink, multilinkFromForm, isRenderFromForm }) => {
  const { t } = useTranslation();
  const currentMultilink = isRenderFromForm ? multilinkFromForm : multilink;

  const { data: library } = useGetLibraryById(
    multilinkFromForm?.libraryId !== NO_LOGO_ID ? multilinkFromForm?.libraryId : undefined,
    {
      enabled: Boolean(isRenderFromForm),
    }
  );

  const fileId = isRenderFromForm ? library?.file?.id : multilink?.library?.file?.id;

  const mockDescription = !currentMultilink?.title && t('qr.multilinkInput.descriptionPlaceholder');
  const visibleDescription = currentMultilink?.description || mockDescription;
  const showDemoLinks =
    !currentMultilink?.title && !currentMultilink?.socialLinks?.length && !currentMultilink?.customLinks?.length;

  const linkStyle = currentMultilink
    ? {
        buttonBgColor: currentMultilink.buttonBgColor,
        buttonTextColor: currentMultilink.buttonTextColor,
        buttonHoverColor: currentMultilink.buttonHoverColor,
        buttonBorderColor: currentMultilink.buttonBorderColor,
      }
    : undefined;

  return (
    <QRCodePreviewWrap
      isRenderFromForm={isRenderFromForm}
      style={{
        backgroundColor: currentMultilink?.designBgColor,
      }}
    >
      <div className="flex items-center justify-center">
        <div
          style={{
            backgroundColor: currentMultilink?.designBgColor,
          }}
          className={clsxm('mt-[50px] mb-6 h-[70px] w-[70px] overflow-hidden rounded-[10px]')}
        >
          <Avatar
            isPublic
            imageId={fileId}
            className="rounded-none"
            skeletonVariant="rectangular"
            height="100%"
            width="100%"
          >
            <EmptyPlaceholderIcon />
          </Avatar>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Typography
          variant="l"
          className="block text-center font-bold text-white"
          style={{ wordBreak: 'break-word', color: currentMultilink?.designTitleColor }}
        >
          {currentMultilink?.title || t('qr.multilinkInput.title')}
        </Typography>
        {visibleDescription && (
          <Typography
            variant="xs"
            className="block text-center text-white"
            style={{ wordBreak: 'break-word', color: currentMultilink?.designDescriptionColor }}
          >
            {visibleDescription}
          </Typography>
        )}
      </div>
      <div className="px-4 pt-8">
        {!showDemoLinks && (
          <QRCodeSocialLinks links={currentMultilink?.customLinks} className="mb-2" style={linkStyle} />
        )}
        <QRCodeSocialLinks links={currentMultilink?.socialLinks} showDemoLinks={showDemoLinks} style={linkStyle} />
      </div>
    </QRCodePreviewWrap>
  );
};

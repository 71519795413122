import { UISectionBarCharts } from './charts/UISectionBarCharts';
import { UISectionLineCharts } from './charts/UISectionLineCharts';
import { UISectionWorldMap } from './charts/UISectionWorldMap';

export const UITabCharts: React.FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <UISectionLineCharts />
      <UISectionBarCharts />
      <UISectionWorldMap />
    </div>
  );
};

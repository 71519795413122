import { useMutation, useQuery, useQueryClient } from 'react-query';
import { BillingApi } from './billing.api';
import { BillingOutDtoSchema } from './billing.schema';

const QUERY_KEY_BILLING_INFO = 'QUERY_KEY_BILLING_INFO';
type TQueryDataForBillingInfo = Awaited<ReturnType<typeof BillingApi.getMyInfo>>;

export const useGetMyBillingInfo = () => {
  return useQuery<TQueryDataForBillingInfo>({
    queryKey: QUERY_KEY_BILLING_INFO,
    queryFn: async () => BillingApi.getMyInfo(),
  });
};

export const usePatchMyBillingInfo = ({ onSuccess }: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: BillingOutDtoSchema) => {
      return await BillingApi.patchMyInfo(dto);
    },
    onSuccess: (res) => {
      queryClient.setQueryData<TQueryDataForBillingInfo>(QUERY_KEY_BILLING_INFO, res);
      queryClient.invalidateQueries(QUERY_KEY_BILLING_INFO);
      onSuccess?.();
    },
  });
};

export const calcSpecialQRSearch = (search?: string) => {
  let idStartsWith: string | undefined = undefined;
  let exactMatchById: string | undefined = undefined;
  let exactMatchByEmail: string | undefined = undefined;
  let isSpecial = false;
  if (search) {
    if (search.includes('@')) {
      exactMatchByEmail = search;
      isSpecial = true;
    } else if (search.length === 8 || search.length === 21) {
      exactMatchById = search;
      isSpecial = true;
    } else {
      idStartsWith = search;
    }
  }
  return { idStartsWith, exactMatchById, exactMatchByEmail, isSpecial };
};

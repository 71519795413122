import { UIStyledSection } from '../components/UIStyledSection';
import { Input, InputProps } from '@app/components';
import PeopleIcon from '@mui/icons-material/People';
import { InputAdornment } from '@mui/material';

export const UITabInputs: React.FC = () => {
  const render = (inputs: InputPropsRender[]) => inputs.map((b, i) => <Input key={i} {...b} />);

  return (
    <UIStyledSection title="UISectionInputs">
      <div className="flex flex-col gap-4 bg-slate-300 p-4">
        <div className="flex gap-4">
          <div className="flex flex-col gap-4 pb-6">
            <div className="font-bold">Contained</div>
            <div className="grid grid-cols-3 gap-4">{render(CONTAINER_ICON_BUTTONS)}</div>
          </div>
        </div>
      </div>
    </UIStyledSection>
  );
};

type InputPropsRender = InputProps;

const CONTAINER_ICON_BUTTONS: InputPropsRender[] = [
  { label: 'Large', placeholder: 'Text', inputSize: 'large' },
  { label: 'Medium', placeholder: 'Text', inputSize: 'medium' },
  { label: 'Small', placeholder: 'Text', inputSize: 'small' },
  { label: 'With end icon', placeholder: 'Password', type: 'password' },
  {
    label: 'With start icon',
    placeholder: 'Text',
    startAdornment: (
      <InputAdornment position="start" className="ml-2 -mr-2">
        <PeopleIcon />
      </InputAdornment>
    ),
  },
  { label: 'With error', placeholder: 'Text', errorMessage: 'Some error' },
  { label: 'Disabled', placeholder: 'Disabled', disabled: true, value: 'Disabled input' },
  { placeholder: 'Without label', inputSize: 'small' },
];

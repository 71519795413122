import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { Routes } from '@app/constants/routes';

import { SignUpFormValidationSchema } from '@app/auth/api/auth.form';

import { Typography } from '@mui/material';

import { TypedLink } from '@app/router';
import { Input, Button, Form, Checkbox } from '@app/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { DividerWithText } from '@app/components/divider/DividerWithText';
import { useMutation } from 'react-query';
import { AuthApi } from '@app/auth/api/auth.api';
import { useStore } from '@app/store/useStore.hook';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { useTranslation } from 'react-i18next';
import { getLanguageForApi, LANGUAGE_CODES } from '@app/i18n/i18.utils';
import { GoogleButton } from '@app/components/buttons/google-button/GoogleButton';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useEffect, useState } from 'react';
import {
  AUTH_QUERY_PARAM_AUTO_LINK_QR_ID,
  AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_POPUP,
  AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_SOURCE,
  AUTH_QUERY_PARAM_FOR_PREFILL_EMAIL,
  AUTH_QUERY_PARAM_LANGUAGE,
} from '../auth.constants';
import { gtag } from '@app/gtag/gtag';
import { AgreeWithTermsText } from '../components/AgreeWithTermsText';
import { EGTAGRegistrationOrigin, EGTAGRegistrationPopUp } from '@app/gtag/gtag.type';
import { oneOfEnum } from '@app/utils/yup.utils';
import { ELanguage } from '@app/swagger-types';
import { useGoogleClickId } from '@app/gtag/useGoogleClickId';
import { PARAM_GOOGLE_CLICK_ID } from '@app/gtag/google.constant';

export const SignUp = () => {
  const setCurrentUser = useStore().setCurrentUser;
  const {
    t,
    i18n: { resolvedLanguage, changeLanguage },
  } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    clearErrors,
    setValue,
  } = useForm<SignUpFormValidationSchema>({
    resolver: yupResolver(SignUpFormValidationSchema),
  });

  const { email, privacyPolicy } = watch();
  const [paramLanguage] = useQueryParam(AUTH_QUERY_PARAM_LANGUAGE, withDefault(StringParam, null));
  const [prefillEmail] = useQueryParam(AUTH_QUERY_PARAM_FOR_PREFILL_EMAIL, withDefault(StringParam, null));
  const [autoLinkQRID] = useQueryParam(AUTH_QUERY_PARAM_AUTO_LINK_QR_ID, withDefault(StringParam, null));
  const [forwardedSource] = useQueryParam(AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_SOURCE, withDefault(StringParam, null));
  const [forwardedPopup] = useQueryParam(AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_POPUP, withDefault(StringParam, null));
  const { googleClickId } = useGoogleClickId();

  // "as" is fine here because any string may be useful for analytics
  const origin = (forwardedSource as EGTAGRegistrationOrigin | undefined) || EGTAGRegistrationOrigin.DIRECT;
  // "as" is fine here because any string may be useful for analytics
  const popup = forwardedPopup as EGTAGRegistrationPopUp | undefined;

  const forwardedLanguage = oneOfEnum(ELanguage).isValidSync(paramLanguage) ? paramLanguage : null;
  const currentLanguage = getLanguageForApi(resolvedLanguage);
  const finalLanguage = forwardedLanguage || currentLanguage;

  useEffect(() => {
    if (!forwardedLanguage || forwardedLanguage === currentLanguage) {
      console.log('already', forwardedLanguage === currentLanguage);
      return;
    }
    const neededLang = LANGUAGE_CODES[forwardedLanguage];
    if (!neededLang) {
      return;
    }
    changeLanguage(neededLang);
  }, [forwardedLanguage, currentLanguage, changeLanguage]);

  useEffect(() => {
    if (prefillEmail) {
      setValue('email', prefillEmail);
      setValue('privacyPolicy', true);
    }
  }, [prefillEmail, setValue]);

  const {
    mutate: signUp,
    isLoading,
    error,
  } = useMutation(
    async (formData: SignUpFormValidationSchema) => {
      const { data } = await AuthApi.signUp({ ...formData, language: finalLanguage });
      return data;
    },
    {
      onSuccess: (res) => {
        setCurrentUser(res);
        gtag.signUp({
          authentication_method: 'email',
          user_email: res.email,
          user_id: res.id,
          origin,
          popup,
        });
      },
    }
  );

  const [autoAttempts, setAutoAttempts] = useState(0);

  useEffect(() => {
    if (!prefillEmail || isLoading || autoAttempts > 0) {
      return;
    }
    if (email === prefillEmail) {
      setAutoAttempts((i) => i + 1);
      signUp({
        [AUTH_QUERY_PARAM_AUTO_LINK_QR_ID]: autoLinkQRID,
        [PARAM_GOOGLE_CLICK_ID]: googleClickId,
        email,
      });
    }
  }, [prefillEmail, email, autoLinkQRID, isLoading, signUp, autoAttempts, googleClickId]);

  const onSubmit: SubmitHandler<SignUpFormValidationSchema> = async (formData) => {
    clearErrors();
    signUp({ ...formData, [AUTH_QUERY_PARAM_AUTO_LINK_QR_ID]: autoLinkQRID, [PARAM_GOOGLE_CLICK_ID]: googleClickId });
  };

  return (
    <div className="flex h-full flex-col gap-y-4">
      <div className="mb-4 flex flex-col gap-2">
        <Typography variant="xxxl" className="text-center font-bold">
          {t('auth.signUpStep.getStartedForFree')}
        </Typography>
        <Typography variant="s" className="text-center">
          {t('auth.signUpStep.description')}
        </Typography>
      </div>
      <GoogleButton autoLinkQRID={autoLinkQRID} origin={origin} popup={popup} />
      <DividerWithText text={t('auth.or')} />
      <Form
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col gap-y-4 sm:flex-initial"
      >
        <Input
          {...register('email')}
          label={t('auth.email')}
          placeholder={t('auth.enterYourEmail')}
          id="email"
          withBG
          errorMessage={errors.email?.message}
        />
        <div className="mt-auto space-y-4 sm:mt-0">
          <Controller
            name="privacyPolicy"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={Boolean(field.value)}
                size="small"
                errorMessage={errors?.privacyPolicy?.message}
                disableRipple
                containerClassName="ml-1"
                color="primary"
                label={<AgreeWithTermsText />}
              />
            )}
          />
          <Button
            loading={isLoading}
            disabled={!email || !privacyPolicy}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            {t('auth.signUpStep.proceed')}
          </Button>
          {maybeRenderError(error as Error)}
        </div>
      </Form>
      <Typography align="center">
        {t('auth.signUpStep.alreadyHaveAnAccount')}{' '}
        <TypedLink to={Routes.auth.sign_in} className="font-bold text-accept-main hover:text-accept-hover">
          {t('auth.signIn')}
        </TypedLink>
      </Typography>
    </div>
  );
};

import { APIQueryParams } from '@app/models/api';
import { apiService, validateAndAssert } from '@app/services/api-service';
import { TemplateDeleteInDto } from '@app/swagger-types';
import { pageableData } from '@app/utils/yup.utils';
import { DesignOutDtoSchema } from './template.schema.api';
import { OverriddenDesignInDto } from './template.types.api';

export abstract class TemplateApi {
  static async getTemplates(params: APIQueryParams) {
    return validateAndAssert(apiService.get('/private/qr-template', { params }), pageableData(DesignOutDtoSchema));
  }
  static async createTemplate(dto: OverriddenDesignInDto) {
    return validateAndAssert(apiService.post('/private/qr-template', dto), DesignOutDtoSchema);
  }
  static async updateTemplate({ templateId, ...dto }: OverriddenDesignInDto & { templateId: string }) {
    return validateAndAssert(apiService.put(`/private/qr-template/${templateId}`, dto), DesignOutDtoSchema);
  }
  static async deleteTemplate(id: string) {
    return apiService.delete(`/private/qr-template/${id}`);
  }
  static async multiDeleteTemplates(dto: TemplateDeleteInDto) {
    return apiService.put('/private/qr-template/multi-delete', dto);
  }
  static async getById(id: string) {
    return validateAndAssert(apiService.get(`/private/qr-template/${id}`), DesignOutDtoSchema);
  }
}

import { AppLogo, FiRrArrowSmallRight, FiRrCheck, FiRrCrossSmall } from '@app/assets';
import { Button } from '@app/components';
import { EGTAGRegistrationOrigin, EGTAGRegistrationPopUp } from '@app/gtag/gtag.type';
import { Dialog } from '@app/hoc';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';
import { useCurrentLanguage } from '@app/i18n/useCurrentLanguage';
import { Trans, useTranslation } from 'react-i18next';
import { getRawImageUrl } from './getRawImageUrl.util';
import { EWidgetSignUpTriggerSource } from './widget.constants';
import { widgetPrepareSignUpUrl } from './widget.utils';

const ORIGIN = EGTAGRegistrationOrigin.GENERATOR;

export const QRWidgetSignUpDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  triggerSource: EWidgetSignUpTriggerSource | null;
  autoLinkQRID: string | null;
  googleClickId?: string;
}> = ({ open, onClose, triggerSource, autoLinkQRID, googleClickId }) => {
  const language = useCurrentLanguage();
  const isMobile = useIsMobile();
  const { header, description, button, backgroundImageUrl, popup } = useGetElementsBasedOnTriggerSource(triggerSource);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      hideHeader
      paperClassName="rounded-2xl text-accept-dark-blue"
      maxWidth="md"
      fullScreen={isMobile}
    >
      {isMobile && (
        <div className="flex w-full justify-end pt-6 pr-6">
          <div
            className="cursor-pointer"
            onClick={() => {
              onClose();
            }}
            aria-hidden
          >
            <FiRrCrossSmall />
          </div>
        </div>
      )}
      <div className="grid h-full md:grid-cols-3">
        <div className="h-full p-10 md:col-span-2">
          <div className="flex h-full flex-col justify-between gap-8 md:justify-start">
            <div>
              <div className="flex flex-col gap-8">
                <div>
                  <AppLogo />
                </div>
                <div className="text-xl font-bold">{header}</div>
                <div>{description}</div>
              </div>
            </div>
            <div>
              <a
                href={widgetPrepareSignUpUrl({
                  language,
                  autoLinkQRID,
                  googleClickId,
                  origin: ORIGIN,
                  popup,
                  prefillEmail: null,
                })}
              >
                <Button endIcon={<FiRrArrowSmallRight className="fill-white" />} className="px-16">
                  {button}
                </Button>
              </a>
            </div>
          </div>
        </div>
        {/* right side */}
        <div
          className="relative col-span-1 hidden bg-[#C3D1E4] bg-cover p-6 md:block"
          style={{ backgroundImage: `url(${backgroundImageUrl})`, backgroundPositionX: 'center' }}
        >
          <div className="flex w-full justify-end">
            <div
              className="cursor-pointer"
              onClick={() => {
                onClose();
              }}
              aria-hidden
            >
              <FiRrCrossSmall />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const useGetElementsBasedOnTriggerSource = (
  source: EWidgetSignUpTriggerSource | null
): {
  header: JSX.Element;
  description: JSX.Element;
  button: JSX.Element;
  backgroundImageUrl: string;
  popup: EGTAGRegistrationPopUp;
} => {
  const { t } = useTranslation();

  let header = <>{t('widget.signUpDialog.createAccountToContinue')}</>;
  let description = <></>;
  let button = <>{t('widget.signUpDialog.buttonFreeSignUp')}</>;
  let backgroundImageUrl = '';
  let popup = EGTAGRegistrationPopUp.DOWNLOAD;
  switch (source) {
    case EWidgetSignUpTriggerSource.ON_SELECT_MULTILINK:
      popup = EGTAGRegistrationPopUp.MULTILINK;
      header = <>{t('widget.signUpDialog.multilink.header')}</>;
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.multilink.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.multilink.benefit1')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.multilink.benefit2')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.multilink.benefit3')}
            </div>
          </div>
        </div>
      );
      button = <>{t('widget.signUpDialog.buttonCreateAccount')}</>;
      backgroundImageUrl = getRawImageUrl.rawImageSrcMultilink;
      break;
    case EWidgetSignUpTriggerSource.ON_SELECT_PDF:
      popup = EGTAGRegistrationPopUp.PDF;
      header = <>{t('widget.signUpDialog.pdf.header')}</>;
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.pdf.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.pdf.benefit1')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.pdf.benefit2')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.pdf.benefit3')}
            </div>
          </div>
        </div>
      );
      button = <>{t('widget.signUpDialog.buttonCreateAccount')}</>;
      backgroundImageUrl = getRawImageUrl.rawImageSrcPdf;
      break;
    case EWidgetSignUpTriggerSource.ON_SELECT_VCARD:
      popup = EGTAGRegistrationPopUp.VCARD_PLUS;
      header = <>{t('widget.signUpDialog.vCard.header')}</>;
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.vCard.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.vCard.benefit1')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.vCard.benefit2')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.vCard.benefit3')}
            </div>
          </div>
        </div>
      );
      button = <>{t('widget.signUpDialog.buttonCreateAccount')}</>;
      backgroundImageUrl = getRawImageUrl.rawImageSrcVCard;
      break;
    case EWidgetSignUpTriggerSource.ON_SELECT_MORE_FRAMES:
      popup = EGTAGRegistrationPopUp.FRAME;
      header = (
        <Trans i18nKey="widget.signUpDialog.frames.header">
          Attract more scans with <span className="text-accept-main">frames</span>
        </Trans>
      );
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.frames.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.frames.benefit1')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.frames.benefit2')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.frames.benefit3')}
            </div>
          </div>
        </div>
      );
      backgroundImageUrl = getRawImageUrl.rawImageSrcFrames;
      break;
    case EWidgetSignUpTriggerSource.ON_SELECT_MORE_PATTERNS:
    case EWidgetSignUpTriggerSource.ON_SELECT_MORE_COLORS:
      if (source === EWidgetSignUpTriggerSource.ON_SELECT_MORE_COLORS) {
        popup = EGTAGRegistrationPopUp.COLOR;
      } else {
        popup = EGTAGRegistrationPopUp.PATTERN;
      }
      header = (
        <Trans i18nKey={'widget.signUpDialog.shapes.header'}>
          Stay fresh with different <span className="text-accept-main">shapes & colors</span>
        </Trans>
      );
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.shapes.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.shapes.benefit1')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.shapes.benefit2')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.shapes.benefit3')}
            </div>
          </div>
        </div>
      );
      backgroundImageUrl = getRawImageUrl.rawImageSrcPatterns;
      break;
    case EWidgetSignUpTriggerSource.ON_SELECT_MORE_LOGOS:
      popup = EGTAGRegistrationPopUp.LOGO;
      header = (
        <Trans i18nKey={'widget.signUpDialog.logos.header'}>
          Spotlight for your <span className="text-accept-main">logo</span>
        </Trans>
      );
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.logos.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.logos.benefit1')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.logos.benefit2')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.logos.benefit3')}
            </div>
          </div>
        </div>
      );
      backgroundImageUrl = getRawImageUrl.rawImageSrcLogos;
      break;
    case EWidgetSignUpTriggerSource.ON_SCAN_TRACKING:
      popup = EGTAGRegistrationPopUp.SCAN_TRACKING;
      header = <>{t('widget.signUpDialog.scans.header')}</>;
      description = (
        <div className="flex flex-col gap-4">
          <div>{t('widget.signUpDialog.scans.description')}</div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.scans.benefit1')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.scans.benefit2')}
            </div>
            <div className="flex gap-2">
              <FiRrCheck className="w-4 fill-accept-main" />
              {t('widget.signUpDialog.scans.benefit3')}
            </div>
          </div>
        </div>
      );
      backgroundImageUrl = getRawImageUrl.rawImageSrcScans;
      break;
  }
  return { header, description, button, backgroundImageUrl, popup };
};

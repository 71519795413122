import { ChartAnalyticOutDto, ChartResultsOutDto } from '@app/swagger-types';
import { format } from 'date-fns';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export const QRDaytimeChartBare: React.FC<{
  data: ChartAnalyticOutDto;
}> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={250}>
      <BarChart width={400} height={400} data={data.results} margin={{ top: 16 }}>
        <CartesianGrid stroke="#E5E7EB" vertical={false} />
        <XAxis
          dataKey={X_AXIS_KEY}
          tickFormatter={(date) => format(new Date(date), 'hh a')}
          tickLine={false}
          axisLine={false}
          padding={{ left: 32, right: 32 }}
        />
        <YAxis tickLine={false} axisLine={false} />
        <Bar
          type="linear"
          dataKey={DATA_KEY}
          fill="#9AD396"
          radius={8}
          activeBar={{
            fill: '#2C9424',
            filter: 'drop-shadow(0px 2px 4px rgba(50, 50, 71, 0.5))',
          }}
        />
        <Tooltip cursor={false} labelFormatter={(date) => format(new Date(date), 'hh a')} wrapperClassName="rounded" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const DATA_KEY: keyof ChartResultsOutDto = 'total';
const X_AXIS_KEY: keyof ChartResultsOutDto = 'date';

import { clsxm } from '@app/styles/clsxm';
import { useMemo } from 'react';
import { QR_CODE_STEPPER_STEPS_TYPE } from './QRCodeFormStepper';
import styles from './QRCodeFormStepper.module.scss';

export const QRCodeFormStepSvg: React.FC<{
  step: QR_CODE_STEPPER_STEPS_TYPE;
  isActive: boolean;
  isCompleted: boolean;
}> = ({ step, isActive = false, isCompleted = false }) => {
  const currentStep = useMemo(() => {
    switch (step) {
      case 'Type':
        return (
          <svg
            width="100%"
            preserveAspectRatio="none"
            height="100%"
            viewBox="0 0 174 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_6210_94663" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 4C0 1.79086 1.79086 0 4 0H155.91C157.219 0 158.446 0.640859 159.194 1.71574L172.411 20.7157C173.366 22.0888 173.366 23.9112 172.411 25.2843L159.194 44.2843C158.446 45.3591 157.219 46 155.91 46H4C1.79086 46 0 44.2091 0 42V4Z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 4C0 1.79086 1.79086 0 4 0H155.91C157.219 0 158.446 0.640859 159.194 1.71574L172.411 20.7157C173.366 22.0888 173.366 23.9112 172.411 25.2843L159.194 44.2843C158.446 45.3591 157.219 46 155.91 46H4C1.79086 46 0 44.2091 0 42V4Z"
              fill="currentColor"
              className={clsxm(styles.svgBackground, isCompleted && 'text-gray-100', isActive && 'text-accept-light')}
            />
            <path
              d="M172.411 20.7157L173.232 20.1447L172.411 20.7157ZM172.411 25.2843L173.232 25.8553L172.411 25.2843ZM159.194 44.2843L160.014 44.8553L159.194 44.2843ZM159.194 1.71574L160.014 1.14468L159.194 1.71574ZM155.91 -1H4V1H155.91V-1ZM158.373 2.28681L171.59 21.2868L173.232 20.1447L160.014 1.14468L158.373 2.28681ZM171.59 21.2868C172.306 22.3166 172.306 23.6834 171.59 24.7132L173.232 25.8553C174.426 24.139 174.426 21.861 173.232 20.1447L171.59 21.2868ZM171.59 24.7132L158.373 43.7132L160.014 44.8553L173.232 25.8553L171.59 24.7132ZM4 47H155.91V45H4V47ZM-1 4V42H1V4H-1ZM158.373 43.7132C157.812 44.5194 156.892 45 155.91 45V47C157.547 47 159.08 46.1989 160.014 44.8553L158.373 43.7132ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1ZM4 45C2.34315 45 1 43.6569 1 42H-1C-1 44.7614 1.23858 47 4 47V45ZM155.91 1C156.892 1 157.812 1.48064 158.373 2.28681L160.014 1.14468C159.08 -0.198927 157.547 -1 155.91 -1V1Z"
              fill="currentColor"
              className={clsxm(styles.svgBorder, isActive && 'text-accept-main')}
              mask="url(#path-1-inside-1_6210_94663)"
            />
          </svg>
        );
      case 'Done':
        return (
          <svg
            width="100%"
            preserveAspectRatio="none"
            height="100%"
            viewBox="0 0 155 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_6210_144908" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.37175 6.28426C-0.473225 3.63211 1.42461 0 4.65537 0H151C153.209 0 155 1.79086 155 4V42C155 44.2091 153.209 46 151 46H4.65537C1.42461 46 -0.473222 42.3679 1.37175 39.7157L11.411 25.2843C12.3662 23.9112 12.3662 22.0888 11.411 20.7157L1.37175 6.28426Z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.37175 6.28426C-0.473225 3.63211 1.42461 0 4.65537 0H151C153.209 0 155 1.79086 155 4V42C155 44.2091 153.209 46 151 46H4.65537C1.42461 46 -0.473222 42.3679 1.37175 39.7157L11.411 25.2843C12.3662 23.9112 12.3662 22.0888 11.411 20.7157L1.37175 6.28426Z"
              fill="currentColor"
              className={clsxm(styles.svgBackground, isCompleted && 'text-gray-100', isActive && 'text-accept-light')}
            />
            <path
              d="M11.411 25.2843L12.2319 25.8553L11.411 25.2843ZM11.411 20.7157L10.5901 21.2868L11.411 20.7157ZM1.37175 39.7157L0.550845 39.1447L1.37175 39.7157ZM151 -1H4.65537V1H151V-1ZM156 42V4H154V42H156ZM4.65537 47H151V45H4.65537V47ZM10.5901 24.7132L0.550845 39.1447L2.19266 40.2868L12.2319 25.8553L10.5901 24.7132ZM10.5901 21.2868C11.3065 22.3166 11.3065 23.6834 10.5901 24.7132L12.2319 25.8553C13.4259 24.139 13.4259 21.861 12.2319 20.1447L10.5901 21.2868ZM0.550842 6.85532L10.5901 21.2868L12.2319 20.1447L2.19265 5.71319L0.550842 6.85532ZM4.65537 45C2.2323 45 0.808925 42.2759 2.19266 40.2868L0.550845 39.1447C-1.75537 42.4599 0.616917 47 4.65537 47V45ZM154 42C154 43.6569 152.657 45 151 45V47C153.762 47 156 44.7614 156 42H154ZM4.65537 -1C0.616917 -1 -1.75537 3.54014 0.550842 6.85532L2.19265 5.71319C0.808923 3.72408 2.2323 1 4.65537 1V-1ZM151 1C152.657 1 154 2.34315 154 4H156C156 1.23857 153.762 -1 151 -1V1Z"
              fill="currentColor"
              className={clsxm(styles.svgBorder, isActive && 'text-accept-main')}
              mask="url(#path-1-inside-1_6210_144908)"
            />
          </svg>
        );
      default:
        return (
          <svg
            width="100%"
            preserveAspectRatio="none"
            height="100%"
            viewBox="0 0 174 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_6210_49546" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M156.194 1.71574C155.446 0.640859 154.219 0 152.91 0H4.65537C1.42461 0 -0.473223 3.63211 1.37175 6.28426L11.411 20.7157C12.3662 22.0888 12.3662 23.9112 11.411 25.2843L1.37175 39.7157C-0.473225 42.3679 1.42461 46 4.65537 46H152.91C154.219 46 155.446 45.3591 156.194 44.2843L169.411 25.2843C170.366 23.9112 170.366 22.0888 169.411 20.7157L156.194 1.71574Z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M156.194 1.71574C155.446 0.640859 154.219 0 152.91 0H4.65537C1.42461 0 -0.473223 3.63211 1.37175 6.28426L11.411 20.7157C12.3662 22.0888 12.3662 23.9112 11.411 25.2843L1.37175 39.7157C-0.473225 42.3679 1.42461 46 4.65537 46H152.91C154.219 46 155.446 45.3591 156.194 44.2843L169.411 25.2843C170.366 23.9112 170.366 22.0888 169.411 20.7157L156.194 1.71574Z"
              fill="currentColor"
              className={clsxm(styles.svgBackground, isCompleted && 'text-gray-100', isActive && 'text-accept-light')}
            />
            <path
              d="M11.411 20.7157L10.5901 21.2868H10.5901L11.411 20.7157ZM11.411 25.2843L12.2319 25.8553H12.2319L11.411 25.2843ZM169.411 25.2843L170.232 25.8553L169.411 25.2843ZM169.411 20.7157L170.232 20.1447L169.411 20.7157ZM1.37175 39.7157L0.550842 39.1447L1.37175 39.7157ZM156.194 1.71574L157.015 1.14468L156.194 1.71574ZM156.194 44.2843L157.015 44.8553L156.194 44.2843ZM4.65537 1H152.91V-1H4.65537V1ZM12.2319 20.1447L2.19265 5.7132L0.550844 6.85533L10.5901 21.2868L12.2319 20.1447ZM12.2319 25.8553C13.4259 24.139 13.4259 21.861 12.2319 20.1447L10.5901 21.2868C11.3065 22.3166 11.3065 23.6834 10.5901 24.7132L12.2319 25.8553ZM2.19265 40.2868L12.2319 25.8553L10.5901 24.7132L0.550842 39.1447L2.19265 40.2868ZM152.91 45H4.65537V47H152.91V45ZM168.59 24.7132L155.373 43.7132L157.015 44.8553L170.232 25.8553L168.59 24.7132ZM168.59 21.2868C169.307 22.3166 169.307 23.6834 168.59 24.7132L170.232 25.8553C171.426 24.139 171.426 21.861 170.232 20.1447L168.59 21.2868ZM155.373 2.28681L168.59 21.2868L170.232 20.1447L157.015 1.14468L155.373 2.28681ZM0.550842 39.1447C-1.75537 42.4599 0.616917 47 4.65537 47V45C2.2323 45 0.808923 42.2759 2.19265 40.2868L0.550842 39.1447ZM152.91 1C153.892 1 154.812 1.48064 155.373 2.28681L157.015 1.14468C156.08 -0.198927 154.547 -1 152.91 -1V1ZM152.91 47C154.547 47 156.08 46.1989 157.015 44.8553L155.373 43.7132C154.812 44.5194 153.892 45 152.91 45V47ZM4.65537 -1C0.616917 -1 -1.75537 3.54014 0.550844 6.85533L2.19265 5.7132C0.808924 3.72408 2.2323 1 4.65537 1V-1Z"
              fill="currentColor"
              className={clsxm(styles.svgBorder, isActive && 'text-accept-main')}
              mask="url(#path-1-inside-1_6210_49546)"
            />
          </svg>
        );
    }
  }, [isActive, isCompleted, step]);

  return <div className="absolute left-0 right-0 top-0 bottom-0 -z-10">{currentStep}</div>;
};

import { PaymentMethodInfoOutDto } from '@app/swagger-types';
import { cardBrandToImgUrl } from '@app/utils/payment.utils';
import { useTranslation } from 'react-i18next';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { SepaIcon } from './icons';

export const PaymentMethodDetails: React.FC<{
  info: PaymentMethodInfoOutDto;
}> = ({ info }) => {
  const { t } = useTranslation();
  const { cardMethod, sepaMethod } = info;

  if (cardMethod) {
    const cardUrl = cardBrandToImgUrl(cardMethod?.brand || null)?.black;

    return (
      <div className="flex gap-4">
        <div className="flex h-16 w-16 items-center justify-center rounded bg-gray-100">
          {cardUrl ? <img src={cardUrl} alt="card icon" width={48} height={48} /> : <CreditCardIcon />}
        </div>
        <div className="flex flex-col gap-4">
          <div className="font-bold">**** {cardMethod.last4}</div>
          <div className="text-xs text-gray-400">
            {t('billing.cardExpires')} {autoPrefixWithZero(cardMethod.expMonth)}/{cardMethod.expYear}
          </div>
        </div>
      </div>
    );
  }

  if (sepaMethod) {
    return (
      <div className="flex gap-4">
        <div className="flex h-16 w-16 items-center justify-center rounded bg-gray-100">
          <SepaIcon width={48} height={48} />
        </div>
        <div className="flex flex-col gap-4">
          <div className="font-bold">**** {sepaMethod.last4}</div>
        </div>
      </div>
    );
  }
  return null;
};

const autoPrefixWithZero = (digits: number) => `${digits}`.padStart(2, '0');

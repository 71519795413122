import { Typography } from '@app/components';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { GalleryEditor } from '@app/components/gallery-editor/GalleryEditor';
import { useCreateLibrary, useDeleteLibrary, useGetLibrary } from '@app/domain/library/api/library.hooks.api';
import { clsxm } from '@app/styles/clsxm';
import { ELibraryType } from '@app/swagger-types';
import { Slider } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DesignFormSchema } from '../../api/template.form.schema.api';

export const TemplateLogoStep: React.FC = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext<DesignFormSchema>();
  const libraryId = watch().libraryId;

  const { data: logos, isLoading } = useGetLibrary({ libraryType: ELibraryType.DESIGN_LOGO });

  const { mutate: createLogos, isLoading: creatingLogos } = useCreateLibrary();
  const { mutate: deleteLogo, isLoading: deletingLogo } = useDeleteLibrary();

  const images = logos?.result.map((r) => ({ imageId: r?.file?.id, libraryId: r.id, id: r.id })) || [];
  const selectedImageId = logos?.result.find((l) => l.id === libraryId)?.file?.id;

  return (
    <div className="flex flex-col gap-4">
      <Typography variant="m" className="font-bold">
        {t('qr.stepCustomize.logo.tabTitle')}
      </Typography>
      <Typography variant="xxs" className="text-gray-500">
        {t('qr.stepCustomize.logo.description')}
      </Typography>

      <div className={clsxm(deletingLogo && 'pointer-events-none opacity-70')}>
        <GalleryEditor
          value={images}
          deleteOnServer={false}
          withNoImg
          dropZoneLoading={isLoading || creatingLogos}
          selectedImageId={selectedImageId || NO_LOGO_ID}
          onClickImage={(img) => {
            if (img.id === NO_LOGO_ID) {
              setValue('libraryId', NO_LOGO_ID);
              return;
            }
            const logo = logos?.result.find((l) => l.file?.id === img.imageId);
            if (logo) {
              setValue('libraryId', logo.id || NO_LOGO_ID);
            }
          }}
          handleUploadIds={(v) => {
            createLogos(
              { fileIds: v, libraryType: ELibraryType.DESIGN_LOGO },
              {
                onSuccess: (data) => {
                  try {
                    const lastUploadLogo = data.result[data.result.length - 1];
                    if (lastUploadLogo) {
                      setValue('libraryId', lastUploadLogo.id);
                    }
                  } catch {}
                },
              }
            );
          }}
          handleDeleteId={(id) => {
            const logo = logos?.result?.find((l) => l?.file?.id === id);
            if (logo && logo?.id) {
              deleteLogo(logo.id);
            }
          }}
          onChange={(v) => {
            if (!v.find((img) => img.imageId === selectedImageId && selectedImageId !== NO_LOGO_ID)) {
              setValue('libraryId', NO_LOGO_ID);
            }
          }}
          noImgText={t('qr.stepCustomize.logo.noLogo')}
        />
      </div>

      {/* TODO implement differently */}
      {!'READY' && libraryId && libraryId !== NO_LOGO_ID ? (
        <div className="flex flex-col gap-2">
          <Typography variant="s">Logo size</Typography>
          <Controller
            name="logoSize"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Slider
                classes={{ root: clsxm('sm:max-w-[400px]') }}
                disableSwap
                onChange={(_, v) => {
                  if (v !== value) {
                    onChange(v);
                  }
                }}
                value={value}
                min={15}
                max={25}
                valueLabelDisplay="auto"
              />
            )}
          />
        </div>
      ) : null}
    </div>
  );
};

import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { ELibraryType } from '@app/swagger-types';
import { useGetLibrary, useCreateLibrary, useDeleteLibrary } from './api/library.hooks.api';

export const useLibraryEditor = (libraryType: ELibraryType, selectedLibraryId: string = NO_LOGO_ID) => {
  const { data: libraries, isLoading } = useGetLibrary({ libraryType });

  const { mutate: createLibrary, isLoading: isCreatingLibrary } = useCreateLibrary();
  const { mutate: deleteLibrary, isLoading: isDeletingLibrary } = useDeleteLibrary();

  const images = libraries?.result.map((r) => ({ imageId: r?.file?.id, libraryId: r.id })) || [];
  const selectedImageId = libraries?.result.find((l) => l.id === selectedLibraryId)?.file?.id;

  return {
    libraryType,
    isLoading,
    images,
    libraries,
    selectedImageId,
    createLibrary,
    isCreatingLibrary,
    deleteLibrary,
    isDeletingLibrary,
  };
};

import { GET_SCANNED_QR_CODE_BY_ID_QUERY } from '@app/constants/query-api-configs';
import { QRCodeApi } from '@app/domain/qr-code/api/qr-code.api';
import { getDeviceId, setDeviceId } from '@app/domain/qr-code/qr-code.utils';
import { useQuery, UseQueryOptions } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { getDeviceCoords } from '../scan.utils';
import { ScanApi } from './scan.api';
import { QRCodePublicOutDtoSchema } from '@app/domain/qr-code/api/qr-code.schema.api';
import { QRCodeOutDto } from '@app/swagger-types';
import { IS_LOCALHOST } from '@app/environment/typed-env';

export const useScanQRCode = (
  id?: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof doScanById>> | null,
    Error,
    Awaited<ReturnType<typeof doScanById>> | null,
    (typeof GET_SCANNED_QR_CODE_BY_ID_QUERY.name | string | undefined)[]
  >
) => {
  return useQuery(
    [GET_SCANNED_QR_CODE_BY_ID_QUERY.name, id],
    async () => {
      if (!id) {
        return null;
      }
      const deviceId = getDeviceId() || uuidv4();
      const publicQrCode = await QRCodeApi.getPublicById(id);

      setDeviceId(deviceId);

      if (publicQrCode.shouldAskForGPSCoordinates) {
        try {
          const coords = await getDeviceCoords();
          if (coords) {
            return doScanById(publicQrCode, id, deviceId, coords);
          }
          return await doScanById(publicQrCode, id, deviceId);
        } catch {
          return await doScanById(publicQrCode, id, deviceId);
        }
      }

      return await doScanById(publicQrCode, id, deviceId);
    },
    {
      staleTime: GET_SCANNED_QR_CODE_BY_ID_QUERY.config.staleTime,
      ...options,
    }
  );
};

const doScanById = async (
  qr: QRCodePublicOutDtoSchema,
  id: string,
  deviceId: string,
  coords?: { lat: number; lng: number; accuracy: number }
) => {
  const scan = await ScanApi.scanById(id, {
    deviceId,
    gps: coords ? { lat: coords.lat, lng: coords.lng, accuracy: coords.accuracy } : undefined,
  });

  if (IS_LOCALHOST) {
    Object.assign(window, { sc: ScanApi, v4: uuidv4 });
    console.log(`await sc.scanById('${id}', {
      deviceId: '${deviceId}' || v4(),
    })`);
    console.log(`for (let i; i < 10; i++) {
      const r = await sc.scanById('${id}', {
        deviceId: v4(),
      });
      console.log(i, r);
  }`);
  }

  // TODO improve type, rely on schema validation
  const unsafeScan = scan as QRCodeOutDto;
  return { qr, scan: unsafeScan };
};

import { Button, Form, Input, Typography } from '@app/components';
import { useHandler } from '@app/hooks/useHandler.hook';
import { clsxm } from '@app/styles/clsxm';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { QRCodeNameFormSchema } from '../../api/qr-code.form.schema.api';
import { useUpdateQRCodeName } from '../../api/qr-code.hooks.api';

export const ChangeQRCodeNameModal: React.FC<NiceModalHocProps & { qrCodeId: string; name: string }> = NiceModal.create(
  ({ name, qrCodeId }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const { mutate: updateName, isLoading } = useUpdateQRCodeName();

    const {
      control,
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<QRCodeNameFormSchema>({
      resolver: yupResolver(QRCodeNameFormSchema),
      defaultValues: { name },
    });

    const closeDialog = () => {
      modal.remove();
    };

    const onSubmit: SubmitHandler<QRCodeNameFormSchema> = useHandler(({ name }) => {
      updateName(
        { id: qrCodeId, name },
        {
          onSuccess: () => {
            toast.success(t('toaster.changesSaved'));
            closeDialog();
          },
        }
      );
    });

    return (
      <Modal open={modal.visible} onClose={closeDialog} onClick={(e) => e.stopPropagation()}>
        <div
          className={clsxm(
            'absolute top-[50%] left-[50%] w-full max-w-[calc(100%-24px)] -translate-x-[50%] -translate-y-[50%] overflow-hidden rounded-3xl bg-white p-8',
            'sm:max-w-auto sm:w-auto sm:min-w-[450px] sm:p-12'
          )}
        >
          <Form control={control} onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <Typography variant="xl" className="font-bold">
              {t('dialog.changeName')}
            </Typography>
            <Input withBG label={t('dialog.name')} {...register('name')} errorMessage={errors.name?.message || ''} />
            <div className="flex justify-between gap-4">
              <Button onClick={closeDialog} disabled={isLoading} className="flex-1" variant="outlined">
                {t('dialog.cancel')}
              </Button>
              <Button loading={isLoading} className="flex-1" type="submit">
                {t('dialog.update')}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
);

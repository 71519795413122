import { ColorPickerInput, Typography } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { ECornerType, EPatternType } from '@app/swagger-types';
import { Divider } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DesignFormSchema } from '../../api/template.form.schema.api';
import { CornerContainer } from '../pattern/CornerContainer';
import { PatternContainer } from '../pattern/PatternContainer';

export const TemplatePatternStep: React.FC = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext<DesignFormSchema>();
  const { patternType, cornerType } = watch();

  return (
    <div className="space-y-6">
      <Typography variant="m" className="font-bold">
        {t('qr.stepCustomize.pattern.tabTitle')}
      </Typography>
      <div className={clsxm('grid grid-cols-3 gap-4 sm:grid-cols-4 lg:grid-cols-5')}>
        {(Object.keys(EPatternType) as Array<keyof typeof EPatternType>).map((key) => {
          return (
            <PatternContainer
              key={key}
              selected={patternType === EPatternType[key]}
              type={EPatternType[key]}
              onClick={(v) => {
                setValue('patternType', v);
              }}
            />
          );
        })}
        <div className="col-span-2 grid gap-2">
          <Controller
            name="frameBackgroundColor"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <ColorPickerInput
                inputProps={{ label: t('qr.design.frameBackgroundColor'), placeholder: '#000000', ...field }}
                color={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="frameTextColor"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <ColorPickerInput
                inputProps={{ label: t('qr.design.frameTextColor'), placeholder: '#000000', ...field }}
                color={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="col-span-3 grid gap-2 sm:col-[3/-1] lg:col-[4/-1]">
          <Controller
            name="patternColor"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <ColorPickerInput
                inputProps={{ label: t('qr.design.patternColor'), placeholder: '#000000', ...field }}
                color={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="backgroundColor"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <ColorPickerInput
                inputProps={{ label: t('qr.design.backgroundColor'), placeholder: '#000000', ...field }}
                color={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>
      <Divider />
      <div className={clsxm('grid grid-cols-3 gap-4 sm:grid-cols-4 lg:grid-cols-5')}>
        {(Object.keys(ECornerType) as Array<keyof typeof ECornerType>).map((key) => {
          return (
            <CornerContainer
              key={key}
              selected={cornerType === ECornerType[key]}
              type={ECornerType[key]}
              onClick={(v) => {
                setValue('cornerType', v);
              }}
            />
          );
        })}
        <div className="col-span-3 sm:col-[4/-1]">
          <Controller
            name="cornerColor"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <ColorPickerInput
                inputProps={{ label: t('qr.design.cornerColor'), placeholder: '#000000', ...field }}
                color={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

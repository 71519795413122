import { Routes } from '@app/constants/routes';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { useTypedNavigate } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { EPlanType } from '@app/swagger-types';

const FINAL_TIME_FOR_BLACK_FRIDAY = new Date('2023-11-25T23:59:59');
export const useIsBlackFridayAvailable = () => {
  const { planType, isExpired } = useActivePlan();
  const isTooLate = Date.now() > FINAL_TIME_FOR_BLACK_FRIDAY.getTime();
  if (isTooLate) {
    return false;
  }
  if (!(planType === EPlanType.TRIAL || isExpired)) {
    return false;
  }
  return true;
};

export const BlackFridayBanner: React.FC = () => {
  const navigate = useTypedNavigate();
  const isBlackFridayAvailable = useIsBlackFridayAvailable();

  if (!isBlackFridayAvailable) {
    return null;
  }

  const renderActionButton = (className: string) => (
    <a
      href={Routes.customer.account.subscription}
      onClick={(e) => {
        e.preventDefault();
        navigate({ to: Routes.customer.account.subscription });
      }}
      className={clsxm(
        'rounded bg-[#FFB91D] px-4 py-1 text-center text-xl font-bold text-black transition hover:bg-[#ffd06b] active:bg-[#dc9e17] md:py-2',
        className
      )}
    >
      Activate
    </a>
  );

  return (
    <div className="flex items-center justify-between gap-2 bg-zinc-800 px-2 py-2 text-white md:gap-4 md:px-6">
      <div className="flex flex-col">
        {/* #region  /**=========== black friday =========== */}
        <div className="flex gap-2">
          <div
            className="text-l sm:text-xxxl"
            style={{
              fontWeight: 'bold',
              color: '#fff5e0',
              textShadow: '0 0 12px #FFB91D',
            }}
          >
            BLACK
          </div>
          <div
            className="text-l sm:text-xxxl"
            style={{
              color: '#ffcb5e',
              textShadow: '0 0 12px #FFB91D',
            }}
          >
            FRIDAY
          </div>
        </div>
        {/* #endregion  /**======== black friday =========== */}
        {renderActionButton('block lg:hidden')}
      </div>
      <div className="flex items-center gap-4">
        <div className="flex flex-col items-end lg:items-center">
          <div className="text-l font-bold md:text-xxxl">50% OFF On all plans</div>
          <div className="italic">use code: BLACKFRIDAY</div>
        </div>
        {renderActionButton('hidden lg:block')}
      </div>
      <div className="hidden self-start lg:block">Limited until 25 November</div>
    </div>
  );
};

import { FiRrArrowSmallRight, FiRrCheck, FiRrCrossSmall } from '@app/assets';
import { Button, Form, Input } from '@app/components';
import { GoogleButton } from '@app/components/buttons/google-button/GoogleButton';
import { QRCodeApi } from '@app/domain/qr-code/api/qr-code.api';
import { QRDownloadHandlerFn } from '@app/domain/qr-code/components/qr-code-internal.types';
import { getQRfileNameForDownload } from '@app/domain/qr-code/qr-code.utils';
import { IS_PROD_ENV, IS_LOCALHOST } from '@app/environment/typed-env';
import { gtag } from '@app/gtag/gtag';
import { EGTAGRegistrationOrigin, EGTAGRegistrationPopUp } from '@app/gtag/gtag.type';
import { Dialog } from '@app/hoc';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';
import { useCurrentLanguage } from '@app/i18n/useCurrentLanguage';
import { email } from '@app/utils/yup.utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InferType, object } from 'yup';
import { widgetPrepareSignUpUrl } from './widget.utils';

const INITIAL_SECONDS_DELAY = IS_LOCALHOST ? 0 : IS_PROD_ENV ? 12 : 3;

const FormSchema = object({
  email: email().required('Required field'),
});
type FormSchema = InferType<typeof FormSchema>;

export const QRWidgetDownloadDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  serverSavedQRID?: string;
  googleClickId?: string;
  scheduledIdForDownload?: string;
  downloadHandler?: QRDownloadHandlerFn;
}> = ({ open, onClose, serverSavedQRID, scheduledIdForDownload, downloadHandler, googleClickId }) => {
  const { t } = useTranslation();
  const language = useCurrentLanguage();
  const isMobile = useIsMobile();
  const [secondsLeft, setSecondsLeft] = useState(INITIAL_SECONDS_DELAY);
  const { control, handleSubmit } = useForm<FormSchema>({
    resolver: yupResolver(FormSchema),
  });
  const [linkForDownload, setLinkForDownload] = useState('');

  useEffect(() => {
    if (!downloadHandler || !scheduledIdForDownload || !serverSavedQRID) {
      return;
    }
    if (scheduledIdForDownload !== serverSavedQRID) {
      return;
    }
    let mounted = true;
    downloadHandler({ skipAutoDownload: true, expectedQrId: serverSavedQRID, onlyServerSideRendering: true }).then(
      ({ canvasDataURL }) => {
        if (!mounted) {
          return;
        }
        setLinkForDownload(canvasDataURL);
      }
    );
    return () => {
      mounted = false;
    };
  }, [downloadHandler, scheduledIdForDownload, serverSavedQRID]);

  useEffect(() => {
    if (secondsLeft < 1) {
      return;
    }
    let mounted = true;
    setTimeout(() => {
      if (!mounted) {
        return;
      }
      setSecondsLeft(secondsLeft - 1);
    }, 1e3);

    return () => {
      mounted = false;
    };
  }, [secondsLeft]);

  useEffect(() => {
    if (secondsLeft > 0 || !linkForDownload || !scheduledIdForDownload) {
      return;
    }
    if (linkForDownload === 'mock') {
      return;
    }
    const link = document.createElement('a');
    link.download = getQRfileNameForDownload(scheduledIdForDownload);
    link.href = linkForDownload;
    link.click();
    QRCodeApi.registerDownload(scheduledIdForDownload);
  }, [secondsLeft, linkForDownload, scheduledIdForDownload]);

  const maybeRenderLink = () => {
    if (secondsLeft > 0 || !linkForDownload) {
      return <>{t('widget.downloadDialog.pleaseDoNotRefreshOrExit')}</>;
    }
    return (
      <>
        <a
          href={linkForDownload}
          download="qr-code.png"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 underline"
        >
          {t('widget.downloadDialog.clickHere')}
        </a>{' '}
        {t('widget.downloadDialog.ifDownloadNotStartedAutomatically')}
      </>
    );
  };

  const onSubmit: SubmitHandler<FormSchema> = (data) => {
    if (!serverSavedQRID) {
      return;
    }
    gtag.attemptSignUp({
      authentication_method: 'email',
      user_email: data.email,
      origin: EGTAGRegistrationOrigin.GENERATOR,
      popup: EGTAGRegistrationPopUp.DOWNLOAD,
    });
    window.location.href = widgetPrepareSignUpUrl({
      language,
      prefillEmail: data.email,
      autoLinkQRID: serverSavedQRID,
      googleClickId,
      origin: EGTAGRegistrationOrigin.GENERATOR,
      popup: EGTAGRegistrationPopUp.DOWNLOAD,
    });
  };

  const shownSecondsLeft = linkForDownload ? secondsLeft : Math.max(secondsLeft, 1);
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      hideHeader
      paperClassName="text-accept-dark-blue shadow-none bg-transparent rounded-none"
      maxWidth="md"
      fullScreen={isMobile}
    >
      <div className="flex flex-col gap-4">
        <div className="rounded-2xl bg-gray-200 p-4 text-center font-bold shadow">
          {t('widget.downloadDialog.downloadingReadyInSeconds', {
            count: shownSecondsLeft,
          })}{' '}
          {maybeRenderLink()}
        </div>
        <div className="rounded-2xl bg-white p-8 shadow">
          {isMobile && (
            <div className="flex w-full justify-end md:pr-6 md:pt-6">
              <div
                className="cursor-pointer"
                onClick={() => {
                  onClose();
                }}
                aria-hidden
              >
                <FiRrCrossSmall />
              </div>
            </div>
          )}
          <div className="grid md:grid-cols-2">
            {/* left side */}
            <div className="flex flex-col gap-4 md:px-4">
              <div className="text-xl font-bold">{t('widget.downloadDialog.title')}</div>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <FiRrCheck className="w-4 fill-accept-main" />
                  {t('widget.downloadDialog.benefit1')}
                </div>
                <div className="flex gap-2">
                  <FiRrCheck className="w-4 fill-accept-main" />
                  {t('widget.downloadDialog.benefit2')}
                </div>
                <div className="flex gap-2">
                  <FiRrCheck className="w-4 fill-accept-main" />
                  {t('widget.downloadDialog.benefit3')}
                </div>
                <div className="flex gap-2">
                  <FiRrCheck className="w-4 fill-accept-main" />
                  {t('widget.downloadDialog.benefit4')}
                </div>
                <div className="flex gap-2">
                  <FiRrCheck className="w-4 fill-accept-main" />
                  {t('widget.downloadDialog.benefit5')}
                </div>
              </div>
            </div>
            {/* right side */}
            <div className="border-l-gray-200 md:border-l md:pl-8 md:pr-4">
              {!isMobile && (
                <div className="flex w-full justify-end">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      onClose();
                    }}
                    aria-hidden
                  >
                    <FiRrCrossSmall />
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-4 py-4">
                <div>
                  <GoogleButton
                    autoLinkQRID={serverSavedQRID}
                    origin={EGTAGRegistrationOrigin.GENERATOR}
                    popup={EGTAGRegistrationPopUp.DOWNLOAD}
                    onPreClick={() => {
                      gtag.attemptSignUp({
                        authentication_method: 'google',
                        origin: EGTAGRegistrationOrigin.GENERATOR,
                        popup: EGTAGRegistrationPopUp.DOWNLOAD,
                      });
                    }}
                  />
                </div>
                <div className="py-2">
                  <div className="flex items-center">
                    <hr className="flex-1" />
                    <div className="px-2">{t('auth.or')}</div>
                    <hr className="flex-1" />
                  </div>
                </div>
                <Form
                  control={control}
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-1 flex-col gap-y-4 sm:flex-initial"
                >
                  <div className="flex flex-col gap-4 text-xl font-bold">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { value, onChange, ...field }, fieldState }) => (
                        <Input
                          {...field}
                          label={t('auth.email')}
                          placeholder={t('auth.enterYourEmail')}
                          name="email"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value.replaceAll(/\s+/gi, ''));
                          }}
                          withBG
                          errorMessage={fieldState?.error?.message || ''}
                        />
                      )}
                    />
                    <Button endIcon={<FiRrArrowSmallRight className="fill-white" />} className="px-16" type="submit">
                      {t('widget.downloadDialog.saveForFree')}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

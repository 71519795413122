// TODO rename all enums to include prefix E
export enum AuthType {
  TOKEN = 'TOKEN',
  COOKIE = 'COOKIE',
}

export enum VerificationType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum AuthStatus {
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_IN_WELCOME = 'SIGNED_IN_WELCOME',
  USER_BANNED = 'USER_BANNED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN = 'UNKNOWN',
}

import { FiRsBuilding, FiRsMarker } from '@app/assets';
import { Container, PlaceholderBox, Typography } from '@app/components';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { clsxm } from '@app/styles/clsxm';
import { useTranslation } from 'react-i18next';
import { useCustomerAnalyticsGetScansByLocation } from '@app/domain/analytics/analytics.query.hooks';
import { useCustomerAnalyticsGlobalFilterParams } from '@app/domain/analytics/analytics.url.hooks';
import { QRAnalyticsMapBare } from '@app/domain/analytics/components/QRAnalyticsMapBare';

export const QRAnalyticsScansByLocation: React.FC = () => {
  const { t } = useTranslation();
  const { afterDate, qrCodeIds } = useCustomerAnalyticsGlobalFilterParams();
  const { data, isLoading } = useCustomerAnalyticsGetScansByLocation({ afterDate, qrCodeIds });

  const renderContent = () => {
    if (isLoading || !data) {
      return <ContentLoader loading />;
    }
    const items = data.withLocation;
    if (!items.length) {
      return <PlaceholderBox variant="primary" title={t('placeholder.noScans')} />;
    }
    const visibleItems = items.slice(0, 10);
    const hasMoreItems = items.length > visibleItems.length;
    return (
      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex flex-col rounded-xl border border-gray-200 pb-3">
          <div className="flex gap-6 border-b border-gray-200 p-4">
            <div className="flex gap-2">
              <FiRsMarker />
              <span>{t('qrAnalytics.countries')}</span> <b className="text-primary">{data.totalCountries}</b>
            </div>
            <div className="flex gap-2">
              <FiRsBuilding />
              <span>{t('qrAnalytics.cities')}</span> <b className="text-primary">{data.totalCities}</b>
            </div>
          </div>
          <div className="overflow-auto">
            <table className="w-full">
              <thead>
                <tr className="font-bold">
                  <td className="p-4">{t('qrAnalytics.countryName')}</td>
                  <td className="p-4">{t('qrAnalytics.cityName')}</td>
                  <td className="p-4">{t('qrAnalytics.scans')}</td>
                  <td className="p-4">{t('qrAnalytics.users')}</td>
                </tr>
              </thead>
              <tbody>
                {visibleItems.map((e, i) => {
                  return (
                    <tr key={i} className={clsxm(i % 2 === 0 && 'bg-gray-50')}>
                      <td className="p-4">{e.country}</td>
                      <td className="p-4">{e.city}</td>
                      <td className="p-4">{e.totalScans}</td>
                      <td className="p-4">{e.totalUsers}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {hasMoreItems && <div className="text-center italic text-gray-400">{t('qrAnalytics.andOthers')}</div>}
          </div>
        </div>
        <QRAnalyticsMapBare locations={data.withLocation} />
      </div>
    );
  };

  return (
    <Container className="flex min-h-[340px] flex-col gap-4 lg:rounded-xl">
      <Typography className="font-bold sm:text-l">{t('qrAnalytics.scansByLocation')}</Typography>
      {renderContent()}
    </Container>
  );
};

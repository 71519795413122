import { EditIcon } from '@app/assets';
import { CurrencyIcon, EmailIcon, WebsiteIcon } from '@app/assets/icons/vcard';
import { Container, IconButton, Typography } from '@app/components';
import { ProfileAvatarEditor } from '@app/components/styled-avatar-editor/ProfileAvatarEditor';
import { useStore } from '@app/store/useStore.hook';
import { clsxm } from '@app/styles/clsxm';
import { renderFriendlyLanguage } from '@app/utils/render-friendly-text.utils';
import NiceModal from '@ebay/nice-modal-react';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateCustomer } from '../api/user.hooks.api';
import { AccountBillingInfoDialog } from './AccountBillingInfoDialog';
import { AccountInfoModal } from './AccountInfoModal';
import { useGetMyBillingInfo } from '@app/domain/billing/api/billing.hooks.api';

export const AccountInfo: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser: user, isDesktop } = useStore(({ currentUser, currentBreakpointDevice: { isDesktop } }) => ({
    currentUser,
    isDesktop,
  }));

  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();
  const avatarSize = isDesktop ? 96 : 56;

  const { data: { billing } = {} } = useGetMyBillingInfo();

  const billingContact = billing ? (
    <>
      <b>
        {billing?.firstName} {billing?.lastName}
      </b>{' '}
      {billing?.billingEmail}
    </>
  ) : (
    '-'
  );
  const invoiceDetails =
    [billing?.companyName, billing?.country, billing?.state, billing?.city, billing?.streetAddress, billing?.zipCode]
      .filter(Boolean)
      .join(', ') || '-';

  return (
    <Container className="flex flex-col gap-6 p-6 lg:flex-row lg:rounded-xl">
      <div className={clsxm('flex gap-4 font-bold text-accept-dark-blue')}>
        <div className={clsxm(isLoading && 'pointer-events-none opacity-60')}>
          <ProfileAvatarEditor
            width={avatarSize}
            height={avatarSize}
            avatarId={user?.avatarId}
            cropSize={avatarSize * 2}
            setAvatarId={(avatarId) => {
              if (user) {
                updateCustomer(
                  { avatarId: avatarId || null },
                  {
                    onSuccess: () => {
                      toast.success(avatarId ? t('toaster.avatarUpdated') : t('toaster.avatarDeleted'));
                    },
                  }
                );
              }
            }}
          />
        </div>
        <Typography variant="l" className="mt-5 lg:mt-10">
          {user?.firstName} {user?.lastName}
        </Typography>
      </div>
      <Divider flexItem orientation={isDesktop ? 'vertical' : 'horizontal'} />
      <div className="space-y-4 text-gray-800">
        <div className="flex items-center gap-3">
          <Typography variant="m" className="font-bold">
            {t('account.sectionInfo.title')}
          </Typography>
          <IconButton
            onClick={() => {
              if (user) {
                NiceModal.show(AccountInfoModal, { defaultValues: user });
              }
            }}
            size="small"
            variant="transparent"
          >
            <EditIcon />
          </IconButton>
        </div>
        <div className="space-y-3">
          <div className="flex items-center gap-3">
            <EmailIcon className="text-accept-text" />
            <span>{user?.email}</span>
          </div>
          <div className="flex items-center gap-3">
            <WebsiteIcon className="text-accept-text" />
            <span>
              {t('account.sectionInfo.language')}: {`${user?.language ? renderFriendlyLanguage(user.language) : ''}`}
            </span>
          </div>
          <div className="flex items-center gap-3">
            <CurrencyIcon className="text-accept-text" />
            <span>
              {t('account.sectionInfo.currency')}: {user?.currency}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 text-gray-800">
        <div className="flex items-center gap-3">
          <Typography variant="m" className="font-bold">
            {t('account.sectionBilling.title')}
          </Typography>
          <IconButton
            onClick={() => {
              if (user) {
                NiceModal.show(AccountBillingInfoDialog, { defaultValues: billing || undefined });
              }
            }}
            size="small"
            variant="transparent"
          >
            <EditIcon />
          </IconButton>
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-gray-500">{t('account.sectionBilling.billingContact')}</div>
          <div>{billingContact}</div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-gray-500">{t('account.sectionBilling.invoiceDetails')}</div>
          <div>{invoiceDetails}</div>
        </div>
      </div>
    </Container>
  );
};

import { clsxm } from '@app/styles/clsxm';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { Typography } from '@app/components';

// TODO refactor - add more common classes based on usage, rounded by default (smaller round on mobile)
// TODO refactor - there are redundant rounded-xl for "xl:" modifier
export const Container: React.FC<{ className?: string; rounded?: boolean; title?: string; isLoading?: boolean }> = ({
  children,
  rounded,
  className,
  title,
  isLoading,
}) => {
  return (
    <div className={clsxm('bg-white px-5 py-6', rounded && 'rounded-xl', className)}>
      {title && <Typography className="font-bold sm:text-l">{title}</Typography>}
      {isLoading && <ContentLoader loading />}
      {children}
    </div>
  );
};

import { AnalyticsApi } from '@app/domain/analytics/api/analytics.api';
import { useQuery } from 'react-query';
import { EAnalyticsPeriodAfterDate } from './analytics.types';
import { calcAnalyticsIntervalEnum, calcAnalyticsIntervalMinutes } from './analytics.utils';

interface IAnalyticsGlobalFiltersHookProps {
  qrCodeIds?: string[];
  periodAfterDate: EAnalyticsPeriodAfterDate;
  afterDate: Date;
}

export const useCustomerAnalyticsGetOverall = ({
  qrCodeIds,
  afterDate,
}: { qrCodeIds?: string[]; afterDate?: Date } = {}) => {
  return useQuery({
    queryKey: ['useCustomerAnalyticsGetOverall', qrCodeIds, afterDate],
    queryFn: async () => AnalyticsApi.getOverall({ qrCodeIds, afterDate: afterDate?.toJSON() }),
    refetchInterval: 15e3,
  });
};

export const useCustomerAnalyticsGetAmountOfScans = ({
  afterDate,
  periodAfterDate,
  qrCodeIds,
}: IAnalyticsGlobalFiltersHookProps) => {
  return useQuery(
    ['useCustomerAnalyticsGetAmountOfScans', afterDate, periodAfterDate, qrCodeIds],
    async () => {
      const intervalMinutes = calcAnalyticsIntervalMinutes(calcAnalyticsIntervalEnum(periodAfterDate));
      return await AnalyticsApi.getAmountOfScans({ intervalMinutes, afterDate: afterDate.toJSON(), qrCodeIds });
    },
    {
      staleTime: 10e3,
      keepPreviousData: true,
    }
  );
};

export const useCustomerAnalyticsGetAmountOfUsers = ({
  afterDate,
  periodAfterDate,
  qrCodeIds,
}: IAnalyticsGlobalFiltersHookProps) => {
  return useQuery(
    ['useCustomerAnalyticsGetAmountOfUsers', afterDate, periodAfterDate, qrCodeIds],
    async () => {
      const intervalMinutes = calcAnalyticsIntervalMinutes(calcAnalyticsIntervalEnum(periodAfterDate));
      return await AnalyticsApi.getAmountOfUsers({ intervalMinutes, afterDate: afterDate.toJSON(), qrCodeIds });
    },
    {
      staleTime: 10e3,
      keepPreviousData: true,
    }
  );
};

export const useCustomerAnalyticsGetScansByDaytime = ({
  afterDate,
  qrCodeIds,
}: Omit<IAnalyticsGlobalFiltersHookProps, 'periodAfterDate'>) => {
  return useQuery(
    ['useCustomerAnalyticsGetScansByDaytime', afterDate, qrCodeIds],
    async () => {
      return await AnalyticsApi.getScansByDaytime({ intervalMinutes: 60, afterDate: afterDate.toJSON(), qrCodeIds });
    },
    {
      staleTime: 10e3,
      keepPreviousData: true,
    }
  );
};

export const useCustomerAnalyticsGetScansByLocation = ({
  afterDate,
  qrCodeIds,
}: Omit<IAnalyticsGlobalFiltersHookProps, 'periodAfterDate'>) => {
  return useQuery(
    ['useCustomerAnalyticsGetScansByLocation', afterDate, qrCodeIds],
    async () => {
      return await AnalyticsApi.getScansByLocation({ afterDate: afterDate.toJSON(), qrCodeIds });
    },
    {
      staleTime: 10e3,
      keepPreviousData: true,
    }
  );
};

import { Routes } from '@app/constants/routes';

import { MyRouteObject } from '@app/models/router';
import { EmailModule } from './EmailModule';
import { UnsubscribeEmailPage } from './pages/UnsubscribeEmailPage';

export const EmailRoutes: MyRouteObject = {
  path: Routes.index,
  element: <EmailModule />,
  children: [{ path: Routes.unsubscribe_email.index, element: <UnsubscribeEmailPage /> }],
};

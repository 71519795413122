import { QRCodeInDtoOverride } from '@app/swagger-override-types';
import { QRCodeOutDto } from '@app/swagger-types';
import { QRCodeOutDtoSchema, QRCodePublicOutDtoSchema } from './qr-code.schema.api';

export class QrDtoMapper {
  static mutateResponseForForm(res: QRCodeOutDtoSchema | QRCodePublicOutDtoSchema) {
    // TODO remove hotfix after backend extends links with libraryId
    const out: QRCodeOutDto = res as any;
    if (out.multilink) {
      out.multilink.customLinks?.forEach((e) => {
        if (e.library?.id) {
          const r: any = e;
          r.libraryId = e.library.id;
        }
      });
    }
  }

  static mutateFormForRequest(dto: QRCodeInDtoOverride) {
    if (dto.multilink && !dto.multilink.libraryId) {
      const asOut: QRCodeOutDto = dto as any;
      if (asOut.multilink) {
        asOut.multilink.library = null as any;
        dto.multilink.libraryId = null as any;
      }
    }
    if (dto.vcard && !dto.vcard.libraryId) {
      const asOut: QRCodeOutDto = dto as any;
      if (asOut.vcard) {
        asOut.vcard.library = null as any;
        dto.vcard.libraryId = null as any;
      }
    }
  }
}

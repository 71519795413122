import { intervalToDuration, formatDuration, Duration, differenceInDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

type PropType = {
  expireDate: Date;
  tickDuration?: number;
  formatD?: string[];
  delimiter?: string;
  renderer?: (duration: Duration) => React.ReactNode;
  onExpire?: (isExpired: boolean) => void;
  compact?: boolean;
  zero?: boolean;
};

/**
 * TODO refactor. Handle proper days display when "formatD" includes "days".
 * Avoid repeated calcs.
 */
export const Countdown: React.FC<PropType> = ({
  expireDate,
  tickDuration = 1000,
  formatD = ['hours', 'minutes'],
  delimiter = ' : ',
  renderer,
  onExpire,
  compact = true,
  zero,
}) => {
  const isExpired = new Date() >= expireDate;
  const startDate = isExpired ? expireDate : new Date();
  const endDate = expireDate;
  const [remainingTime, setRemaining] = useState<{ duration: Duration; totalDays: number }>({
    duration: intervalToDuration({
      start: startDate,
      end: endDate,
    }),
    totalDays: differenceInDays(endDate, startDate),
  });

  useEffect(() => {
    if (onExpire) {
      onExpire(isExpired);
    }
    if (isExpired) {
      setRemaining({ duration: intervalToDuration({ start: expireDate, end: expireDate }), totalDays: 0 });
    } else {
      const start = new Date();
      const end = expireDate;
      const totalDays = differenceInDays(end, start);
      setRemaining({ duration: intervalToDuration({ start, end }), totalDays });
    }
  }, [isExpired, onExpire, expireDate]);

  useInterval(
    () => {
      const currentDate = new Date();
      const start = currentDate >= expireDate ? expireDate : currentDate;
      const end = expireDate;
      const totalDays = differenceInDays(end, start);
      setRemaining({ duration: intervalToDuration({ start, end }), totalDays });
    },
    isExpired ? null : tickDuration
  );

  if (renderer) {
    return <>{renderer(remainingTime.duration)}</>;
  }

  if (compact) {
    const baseFormat = formatDuration(remainingTime.duration, { format: formatD, delimiter, zero });
    const shortFormat = baseFormat
      .replace(/months?/i, 'mo')
      .replace(/days?/i, 'd')
      .replace(/hours?/i, 'h')
      .replace(/minutes?/i, 'm')
      .replace(/seconds?/i, 's')
      .replaceAll(/(\d)\s+(\w)/gi, '$1$2');
    const totalDays = remainingTime.totalDays;
    const maybeDaysPrefix = totalDays > 0 ? `${totalDays}d${shortFormat ? ' : ' : ''}` : '';
    return (
      <span>
        {maybeDaysPrefix}
        {shortFormat}
      </span>
    );
  }

  // TODO handle days > 30
  return <span>{formatDuration(remainingTime.duration, { format: formatD, delimiter, zero })}</span>;
};

import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { Tooltip, Button } from '@app/components';
import { useTranslation } from 'react-i18next';

/**
 * @NOTE Make sure to wrap child button in a div or span
 * to make tooltip work on disabled button OR set property "divWrap = true".
 */
export const PlanLimitTooltip: React.FC<{
  children: React.ComponentProps<typeof Tooltip>['children'];
  condition?: boolean;
  divWrap?: boolean;
}> = ({ children, condition, divWrap }) => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();

  const content = divWrap ? <div>{children}</div> : children;
  if (!condition) {
    return <>{content}</>;
  }

  return (
    <Tooltip
      classes={CLASSES}
      title={
        condition ? (
          <a href={Routes.customer.account.subscription}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate({ to: Routes.customer.account.subscription });
              }}
              className="w-[220px]"
            >
              {t('subscription.buttonUpgradePlan')}
            </Button>
          </a>
        ) : (
          false
        )
      }
    >
      {content}
    </Tooltip>
  );
};

const CLASSES = {
  tooltip: /*tw*/ 'bg-transparent shadow-none',
} as const;

import { MultiLinksIcon, PdfIcon } from '@app/assets';
import { VCardPlusIcon, WebsiteUrlIcon } from '@app/assets';
import { ECodeType, MultilinkOutDto, VCardOutDto } from '@app/swagger-types';
import { QRCodeFormSchema } from './api/qr-code.form.schema.api';
import { QRCodeTypeItemType } from './qr-code.types';

export const CODE_TYPE_ITEM_WEBSITE: QRCodeTypeItemType = {
  icon: <WebsiteUrlIcon />,
  value: ECodeType.WEBSITE,
};

export const CODE_TYPE_ITEMS_OTHERS: QRCodeTypeItemType[] = [
  {
    icon: <MultiLinksIcon />,
    value: ECodeType.MULTILINK,
  },
  {
    icon: <PdfIcon />,
    value: ECodeType.PDF,
  },
  {
    icon: <VCardPlusIcon />,
    value: ECodeType.V_CARD,
  },
];

// TODO trigger pdf field when ready
export const QR_CODE_INPUT_STEP_FORM_FIELDS: Array<keyof QRCodeFormSchema> = [
  'websiteUrl',
  'multilink',
  'pdf',
  'vcard',
];
export const QR_CODE_DONE_STEP_FORM_FIELDS: Array<keyof QRCodeFormSchema> = ['name'];

export const DEFAULT_MULTILINK_FORM_VALUES: Pick<
  MultilinkOutDto,
  | 'title'
  | 'designBgColor'
  | 'designTitleColor'
  | 'designDescriptionColor'
  | 'buttonBgColor'
  | 'buttonTextColor'
  | 'buttonHoverColor'
  | 'buttonBorderColor'
> = {
  // required
  title: '',
  // design
  designBgColor: '#F3F4F6',
  designTitleColor: '#1F2937',
  designDescriptionColor: '#6B7280',
  buttonBgColor: '#FFFFFF',
  buttonTextColor: '#000000',
  buttonHoverColor: '#48B240',
  buttonBorderColor: '#D1D5DB',
};

export const DEFAULT_VCARD_FORM_VALUES: Pick<
  VCardOutDto,
  | 'fullName'
  | 'phoneNumber'
  | 'designPrimaryColor'
  | 'designSecondaryColor'
  | 'designNameColor'
  | 'designTextColor'
  | 'designIconColor'
  | 'designTextHoverColor'
  | 'designTitleColor'
  | 'buttonText'
  | 'buttonSize'
  | 'buttonBgColor'
  | 'buttonTextColor'
  | 'buttonHoverColor'
  | 'buttonBorderColor'
> = {
  // required
  fullName: '',
  phoneNumber: '',
  // design
  designPrimaryColor: '#132A4D',
  designSecondaryColor: '#F3F4F6',
  designNameColor: '#FFFFFF',
  designTextColor: '#132a4d',
  designIconColor: '#455773',
  designTextHoverColor: '#132a4d',
  designTitleColor: '#6b7280',
  buttonText: 'Add Contact',
  buttonSize: 14,
  buttonBgColor: '#FFFFFF',
  buttonTextColor: '#000000',
  buttonHoverColor: '#48B240',
  buttonBorderColor: '#E5E7EB',
};

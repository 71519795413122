import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import produce, { Draft } from 'immer';
import localForage from 'localforage';
import create, { GetState, State, StateCreator, StoreApi } from 'zustand';

import { createAuthSlice } from './auth/createAuthSlice';
import { createMainUISlice } from './main-ui/createMainUISlice';
import { StoreState } from './storeTypes';
import {
  devtools,
  NamedSet,
  persist,
  StateStorage,
  StoreApiWithDevtools,
  StoreApiWithPersist,
} from 'zustand/middleware';

const immer =
  <
    T extends State,
    CustomSetState extends NamedSet<T>,
    CustomGetState extends GetState<T>,
    CustomStoreApi extends StoreApi<T>
  >(
    config: StateCreator<
      T,
      (partial: ((draft: Draft<T>) => void) | T, replace?: boolean, name?: string) => void,
      CustomGetState,
      CustomStoreApi
    >
  ): StateCreator<T, CustomSetState, CustomGetState, CustomStoreApi> =>
  (set, get, api) =>
    config(
      (partial, replace, name) => {
        const nextState = typeof partial === 'function' ? produce(partial as (state: Draft<T>) => T) : (partial as T);
        return set(nextState, replace, name);
      },
      get,
      api
    );

export const useStore = createSelectorHooks(
  create<
    StoreState,
    NamedSet<StoreState>,
    GetState<StoreState>,
    StoreApiWithPersist<StoreState> & StoreApiWithDevtools<StoreState>
  >(
    devtools(
      persist(
        immer((set, get, api) => ({
          ...createAuthSlice(set as NamedSet<StoreState>, get, api),
          ...createMainUISlice(set as NamedSet<StoreState>, get, api),
          _hasHydrated: false,
        })),
        {
          name: 'persisted-global-store',
          getStorage: () => localForage as StateStorage,
          partialize: (state) => ({ theme: state.theme }),
          onRehydrateStorage: () => () => {
            useStore.setState({ _hasHydrated: true });
          },
        }
      ),
      {
        name: 'global-store',
      }
    )
  )
);

import { capitalize } from 'lodash';

import { Typography, Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

type PropsTypes = {
  entity?: string;
  isEmpty?: boolean;
  loading?: boolean;
  error?: unknown;
};
export const ContentLoader: React.FC<PropsTypes> = ({ entity, isEmpty, loading, error }) => {
  const { t } = useTranslation();

  if (!loading && !isEmpty) {
    return null;
  }

  if (error) {
    let msg: string = t('error.unknownServerError');
    if (error instanceof Error) {
      // TODO handle different error codes from server errros
      msg = error.message;
    }

    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h5" className="highlight-zinc-200 font-bold text-red-500">
          {msg}
        </Typography>
      </Box>
    );
  }

  return (
    <Box flex={1} display="flex" justifyContent="center" alignItems="center">
      {loading ? (
        <CircularProgress />
      ) : (
        isEmpty && (
          <Typography variant="h5" className="highlight-zinc-200">
            {capitalize(entity)} {t('error.notFound')}
          </Typography>
        )
      )}
    </Box>
  );
};

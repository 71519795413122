export const Routes = {
  index: '/',
  widget: '/widget',
  admin: {
    index: '/admin',
    manage_accounts: '/admin/manage-accounts',
    test_page: '/admin/test-page',
    user_management: {
      index: '/admin/user-management',
      users: '/admin/user-management/users',
      admins: '/admin/user-management/admins',
      admin_profile: '/admin/user-management/admins/:id',
    },
    qr: {
      index: '/admin/qr',
      edit: '/admin/qr/:id',
    },
    email: {
      index: '/admin/email',
    },
    brief: {
      index: '/admin/brief',
    },
    settings: '/admin/settings',
  },
  customer: {
    index: '/c',
    create_qr_code: '/c/create-qr-code',
    dashboard: '/c/dashboard',
    my_qr_codes: {
      index: '/c/my-qr-codes',
      edit: '/c/my-qr-codes/:id/edit',
    },
    analytics: { index: '/c/analytics' },
    templates: {
      index: '/c/templates',
      create: '/c/templates/create',
      edit: '/c/templates/:id/edit',
    },
    help: '/c/help',
    success_payment: '/c/success-payment',
    account: {
      index: '/c/account',
      settings: '/c/account/settings',
      subscription: '/c/account/subscription',
      billing: '/c/account/billing',
    },
  },
  qr_code: {
    scanned: '/qr/:id',
    // route to handle old URL for previously printed QRs
    root_scanned: '/:id',
    old_scanned: '/preview/:id',
  },
  render: {
    index: '/render',
    qr: '/render/qr/:id',
  },
  auth: {
    index: '/auth',
    sign_up: '/auth/sign-up',
    sign_in: '/auth/sign-in',
    forgot_password: '/auth/forgot-password',
    reset_password: '/auth/reset-password',
    oauth2_authorization: '/auth/oauth2/authorization',
  },
  authSpecial: {
    index: '/',
    claim_qr: '/claim',
    expired_qr: '/expired',
  },
  unsubscribe_email: {
    index: '/unsubscribe-email',
  },
  uiKit: {
    index: '/ui-kit',
  },
  devKit: {
    index: '/dev-kit',
  },
  sample: {
    index: '/sample',
  },
} as const;

import { FiRrArrowSmallRight } from '@app/assets';
import { Button, Container, Typography } from '@app/components';
import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { useTranslation } from 'react-i18next';
import {
  TotalQrsDashboardSvg,
  TotalScansDashboardSvg,
  TotalUsersDashboardSvg,
} from '../../dashboard/assets/dashboardAssets';
import { useGetDashboardMyStats } from '../../../../../domain/dashboard/api/dashboard.query.hooks';
import { useCustomerAnalyticsGetOverall } from '@app/domain/analytics/analytics.query.hooks';
import { OverallAnalyticOutDto } from '@app/swagger-types';

interface SharedProps {
  fromDashboardPage?: boolean;
  qrCodeIds?: string[];
  afterDate?: Date;
}

export const QRAnalyticsOverall: React.FC<SharedProps> = ({ fromDashboardPage, qrCodeIds, afterDate }) => {
  if (fromDashboardPage) {
    return <DashboardWrap {...{ qrCodeIds, afterDate }} />;
  }
  return <AnalyticsWrap {...{ qrCodeIds, afterDate }} />;
};

const DashboardWrap: React.FC<SharedProps> = ({ qrCodeIds, afterDate }) => {
  const { data } = useGetDashboardMyStats();
  return <OverallStatsBare data={data} fromDashboardPage {...{ qrCodeIds, afterDate }} />;
};
const AnalyticsWrap: React.FC<SharedProps> = ({ qrCodeIds, afterDate }) => {
  const { data } = useCustomerAnalyticsGetOverall({ qrCodeIds, afterDate });
  return <OverallStatsBare data={data} />;
};

const OverallStatsBare: React.FC<
  SharedProps & {
    data?: OverallAnalyticOutDto;
  }
> = ({ fromDashboardPage, data }) => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();

  return (
    <Container className="flex flex-col gap-4 lg:rounded-xl">
      <Typography className="font-bold sm:text-l">{t('qrAnalytics.sectionTitle')}</Typography>
      <div className="grid gap-4 md:grid-cols-3">
        <div className="flex justify-between rounded-xl border border-gray-200 p-4 font-bold">
          <div className="flex flex-col justify-between">
            <div>{fromDashboardPage ? t('qrAnalytics.totalQRCodes') : t('qrAnalytics.scannedQRCodes')}</div>
            <div className="text-xxxl">{data?.totalQRCodes ?? '-'}</div>
          </div>
          <div className="flex flex-col justify-center">
            <TotalQrsDashboardSvg />
          </div>
        </div>
        <div className="flex justify-between rounded-xl border border-gray-200 p-4 font-bold">
          <div className="flex flex-col justify-between">
            <div>{t('qrAnalytics.totalScans')}</div>
            <div className="text-xxxl">{data?.totalScans ?? '-'}</div>
          </div>
          <div className="flex flex-col justify-center">
            <TotalScansDashboardSvg />
          </div>
        </div>
        <div className="flex justify-between rounded-xl border border-gray-200 p-4 font-bold">
          <div className="flex flex-col justify-between">
            <div>{t('qrAnalytics.totalUsers')}</div>
            <div className="text-xxxl">{data?.totalUsers ?? '-'}</div>
          </div>
          <div className="flex flex-col justify-center">
            <TotalUsersDashboardSvg />
          </div>
        </div>
      </div>
      {fromDashboardPage && (
        <div className="flex justify-end">
          <a href={Routes.customer.analytics.index} onClick={(e) => e.preventDefault()} aria-hidden>
            <Button
              className="group"
              endIcon={<FiRrArrowSmallRight className="fill-accept-main group-hover:fill-accept-hover" />}
              color="primary"
              variant="text"
              onClick={() => {
                navigate({ to: Routes.customer.analytics.index });
              }}
            >
              {t('qrAnalytics.seeAllAnalytics')}
            </Button>
          </a>
        </div>
      )}
    </Container>
  );
};

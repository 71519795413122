import styles from './AdminModule.module.scss';
import { Outlet } from 'react-router-dom';

import { clsxm } from '@app/styles/clsxm';

import { Header } from '@app/layout/header/Header';
import { AdminSidebarMenu } from '@app/layout/admin-sidebar-menu/AdminSidebarMenu';

export const AdminModule = () => {
  return (
    <div className={styles.root}>
      <Header />
      <div className={styles['app-content']}>
        <AdminSidebarMenu />
        <main className={clsxm(styles.main)}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

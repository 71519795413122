import { Input } from '@app/components';
import { Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QRCodeFormSchema } from '../../api/qr-code.form.schema.api';

export const QRCodeDoneStep: React.FC = () => {
  const { t } = useTranslation();
  const { control, trigger } = useFormContext<QRCodeFormSchema>();

  return (
    <div className="h-full space-y-6 bg-white px-5 py-6 xl:rounded-xl">
      <Typography className="font-bold sm:text-l">{t('qr.stepDone.almostReady')}</Typography>
      <Controller
        name="name"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            onChange={(e) => {
              onChange(e);
              if (fieldState?.error?.message) {
                trigger('websiteUrl');
              }
            }}
            value={value || ''}
            label={t('qr.stepDone.enterNameForYourQRCode')}
            withBG
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
    </div>
  );
};

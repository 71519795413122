import { Outlet, useLocation } from 'react-router-dom';
import { clsxm } from '@app/styles/clsxm';
import { LanguageSwitcher } from '@app/components';
import { HomeButton } from '@app/components/buttons/home-button/HomeButton';
import { Routes } from '@app/constants/routes';
import { useTranslation } from 'react-i18next';
import { HELPCENTER_LINK_EXTERNAL } from '../customer/pages/help/help.constants';

export const AuthModule = () => {
  const { t } = useTranslation();
  const r = useLocation();

  const isClaimOrExpirePage =
    r.pathname.startsWith(Routes.authSpecial.expired_qr) || r.pathname.startsWith(Routes.authSpecial.claim_qr);

  return (
    <div className="flex min-h-screen flex-col bg-white px-6 py-10 sm:px-[75px] sm:py-12">
      <div className="z-20 flex justify-between gap-4">
        <HomeButton />
        <LanguageSwitcher />
      </div>
      <div className={clsxm('z-10 flex flex-1 basis-full justify-center py-16', 'sm:items-center md:basis-[500px]')}>
        <div className={clsxm('w-full', isClaimOrExpirePage ? 'max-w-lg' : 'max-w-sm')}>
          <Outlet />
        </div>
      </div>
      <div className="flex justify-center gap-4">
        {t('support.haveAQuestion')}
        <a className="font-bold text-accept-main" href={HELPCENTER_LINK_EXTERNAL} target="_blank" rel="noreferrer">
          {t('support.contactSupport')}
        </a>
      </div>
    </div>
  );
};

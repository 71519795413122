import { typedEnv } from '@app/environment/typed-env';
import { ApiService } from './api.service';

export * from './api-schema-validations';

export const apiService = new ApiService();

export const publicApiService = new ApiService({
  baseURL: typedEnv.REACT_APP_API_BASE_URL,
});

export const briefApiService = new ApiService({
  baseURL: typedEnv.REACT_APP_API_BRIEF_SERVICE_BASE_URL,
  headers: {
    authorization: 'todo-secret-auth-header',
  },
});

import styles from './IconButton.module.scss';
import { get } from 'lodash';
import React from 'react';

import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps, CircularProgress } from '@mui/material';

import { ButtonColor } from '../ButtonColors';
import { withQA } from '@app/debug/withQA';
import { clsxm } from '@app/styles/clsxm';

export type IconButtonProps = {
  color?: ButtonColor;
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'outlined' | 'transparent';
  circle?: boolean;
  loading?: boolean;
} & Omit<MuiIconButtonProps, 'color' | 'size'>;

export const IconButton: React.FC<IconButtonProps> = withQA(
  {
    name: 'IconButton',
    disabled: false,
  },
  ({ children, color = 'primary', circle = false, className, variant, loading, size, disabled, ...props }) => {
    const classNames = clsxm(
      styles.button,
      get(styles, `${variant}-${color}`),
      get(styles, `${size}`),
      circle && 'rounded-[50%]',
      className
    );

    return (
      <MuiIconButton {...props} className={classNames} disabled={disabled || loading}>
        {children}
        {loading && (
          <div className={styles['loader-container']}>
            <CircularProgress size="" className={clsxm(styles.loader)} />
          </div>
        )}
      </MuiIconButton>
    );
  }
);

import { IS_AUTH_METHOD_COOKIE } from '@app/environment/typed-env';
import { AuthResDtoSchema, ConfirmPasswordReqDto, ForgotPasswordReqDto, SignInReqDto, SignUpReqDto } from './auth.dto';
import { AuthType } from './auth.type';

import { apiService } from '@app/services/api-service';
import { ChangePasswordInDto } from '@app/swagger-types';

export abstract class AuthApi {
  static async me() {
    return apiService.get<AuthResDtoSchema>('/private/auth/me');
  }

  static async signIn(dto: Omit<SignInReqDto, 'authType'>) {
    return apiService.post<AuthResDtoSchema, SignInReqDto>('/public/auth/sign-in/', {
      ...dto,
      authType: IS_AUTH_METHOD_COOKIE ? AuthType.COOKIE : AuthType.TOKEN,
    });
  }

  static async signUp(dto: Omit<SignUpReqDto, 'authType'>) {
    return apiService.post<AuthResDtoSchema, SignUpReqDto>('/public/auth/sign-up/', {
      ...dto,
      authType: IS_AUTH_METHOD_COOKIE ? AuthType.COOKIE : AuthType.TOKEN,
    });
  }

  static async signOut() {
    return apiService.post<AuthResDtoSchema>('/private/auth/sign-out/');
  }

  static async forgotPassword(dto: ForgotPasswordReqDto) {
    return apiService.post('/public/auth/forgot-password/step-1', dto);
  }

  static async confirmPassword(dto: ConfirmPasswordReqDto) {
    return apiService.post('/public/auth/forgot-password/step-2', dto);
  }

  static async changePassword(dto: ChangePasswordInDto) {
    return apiService.patch('/private/auth/me/password', dto);
  }

  static async deleteAccount() {
    return apiService.delete('/private/auth/me/delete');
  }
}

import { apiService, publicApiService, validateAndAssert } from '@app/services/api-service';
import { EmailPreferenceInfoOutSchema } from './email.schema';
import type { EmailPreferenceInDtoSchema } from './email.schema';
import { EEmailTopic, ELanguage, PageResponseEmailReportOutDto } from '@app/swagger-types';
import { APIPaginationQueryParams } from '@app/models/api';

export abstract class EmailApi {
  static async getEmailPreferences(token: string) {
    return validateAndAssert(
      publicApiService.get(`/public/email-preferences?token=${token}`),
      EmailPreferenceInfoOutSchema
    );
  }

  static async saveEmailPreferences(body: EmailPreferenceInDtoSchema) {
    return validateAndAssert(publicApiService.put(`/public/email-preferences`, body), EmailPreferenceInfoOutSchema);
  }

  static async unsubscribeEmailViaToken(token: string) {
    return publicApiService.post(`/public/unsubscribe-email?token=${token}`);
  }

  static async adminGetEmailReports(params: APIEmailReportQueryParams) {
    const { data } = await apiService.get<PageResponseEmailReportOutDto>(`/private/email-reports`, { params });
    return data;
  }
}

export type APIEmailReportQueryParams = APIPaginationQueryParams & {
  recipientEmail?: string;
  topic?: EEmailTopic;
  language?: ELanguage;
};

import { ApiService } from '@app/services/api-service/api.service';
import { ECodeType, QRCodeInDto, QRCodeOutDto } from '@app/swagger-types';

const publicApiService = new ApiService();

export abstract class WidgetApi {
  static async generateQRCode({
    websiteUrl,
    design,
    publicLogoType,
    googleClickId,
  }: { websiteUrl: string } & Pick<QRCodeInDto, 'design' | 'publicLogoType' | 'googleClickId'>) {
    // TODO validate DTOs
    const { data } = await publicApiService.post<QRCodeOutDto, QRCodeInDto>('/public/qr-code/', {
      name: 'qr-code',
      codeType: ECodeType.WEBSITE,
      website: {
        url: websiteUrl,
      },
      design,
      publicLogoType,
      googleClickId,
    });
    return data;
  }
}

import { clsxm } from '@app/styles/clsxm';
import React from 'react';

import { isString } from '@app/utils/assertion.utils';

import { Typography } from '@mui/material';

import styles from './Page.module.scss';

interface PropTypes {
  title?: string | React.ReactNode;
  className?: string;
  contentClassName?: string;

  sidebarComponent?: React.ReactNode;
}

export const Page: React.FC<PropTypes> = ({ title, children, className, contentClassName, sidebarComponent }) => {
  return (
    <>
      <div className={clsxm(styles['page-root'], className)}>
        <div className={clsxm(styles['page-content'], contentClassName)}>
          {title ? isString(title) ? <Typography variant="h4">{title}</Typography> : title : null}

          {children}
        </div>
        {sidebarComponent ? sidebarComponent : null}
      </div>
    </>
  );
};

import { Button } from '@app/components';
import { ButtonProps } from '@app/components/buttons/button/Button';
import React, { CSSProperties, useEffect, useState } from 'react';

export const DownloadButton: React.FC<
  ButtonProps & {
    hoverColor: string;
    bgColor: string;
    text: string;
    style: CSSProperties;
  }
> = ({ style, text, bgColor, hoverColor, ...props }) => {
  const [hoverBgColor, setHoverBgColor] = useState(bgColor);

  useEffect(() => {
    setHoverBgColor(bgColor);
  }, [bgColor]);

  const handleMouseEnter = () => {
    setHoverBgColor(hoverColor);
  };

  const handleMouseLeave = () => {
    setHoverBgColor(bgColor);
  };

  const buttonStyles = {
    ...style,
    backgroundColor: hoverBgColor,
  };

  return (
    <Button {...props} style={buttonStyles} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {text}
    </Button>
  );
};

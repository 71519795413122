import { useEffect, useMemo } from 'react';

// import {
//   openFileById,
//   getFileMimeType,
//   saveFileById,
//   removeExtensionFromName,
//   combineFileExtension,
// } from '@app/utils/file.utils';

import { Button, IconButton, TableColumn } from '@app/components';

import { Switch, Typography } from '@mui/material';
import { EUserStatus, UserStatusInDto } from '@app/swagger-types';
import { UserApi } from '../user.api';
import { useMutation, useQueryClient } from 'react-query';
import { GET_ADMIN_QRS_QUERY, GET_USERS_QUERY } from '@app/constants/query-api-configs';
import { useStore } from '@app/store/useStore.hook';
import { NestedKeyOf } from '@app/utils/type.utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { CustomerAdminOutSchema } from '..';
import { FORMAT } from '@app/constants/formats';
import { format } from 'date-fns';
import { clsxm } from '@app/styles/clsxm';
import { StripeIdBadge } from '@app/domain/subscription/StripeIdBadge';
import { CustomerSubscriptionCell } from './CustomerSubscriptionCell';
import { ActiveCampaignBadge } from '../components/ActiveCampaignBadge';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';

// interface Args {
//   changeUserStatus: UseMutationResult<unknown, unknown, { userId: string; body: UserStatusInDto }>;
// }

const getEntityField = (v: NestedKeyOf<CustomerAdminOutSchema>) => v;

export const useTableColumns = () => {
  const { currentUser } = useStore();
  const queryClient = useQueryClient();

  // const changeUserStatus = useHandler(async (userId: string, body: UserStatusInDto) => {
  //   await UserApi.patchStatus(userId, body);
  //   queryClient.invalidateQueries(GET_USERS_QUERY.name);
  // });

  const changeUserStatus = useMutation(
    async ({ userId, body }) => {
      await UserApi.patchStatus(userId, body);
      queryClient.invalidateQueries(GET_USERS_QUERY.name);
    },
    {
      // When mutate is called:
      onMutate: async (args: { userId: string; body: UserStatusInDto }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(GET_USERS_QUERY.name);

        // Snapshot the previous value
        const previousData = queryClient.getQueryData<GET_USERS_QUERY['schema']>(GET_USERS_QUERY.name, {
          exact: false,
        });

        // Optimistically update to the new value
        if (previousData) {
          queryClient.setQueriesData<GET_USERS_QUERY['schema']>(
            { queryKey: GET_USERS_QUERY.name },
            {
              ...previousData,
              result: previousData.result.map((e) => {
                if (e.id === args.userId) {
                  console.log('update', e, args.body.status);
                  return {
                    ...e,
                    status: args.body.status,
                  } as typeof e;
                }
                return e;
              }),
            }
          );
        }

        // Return a context object with the snapshotted value
        return {
          rollback: () => {
            previousData && queryClient.setQueryData(GET_USERS_QUERY.name, previousData);
          },
        };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, args, context) => {
        context?.rollback();
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(GET_USERS_QUERY.name);
      },
    }
  );

  // const [viewLoading, setViewLoading] = useState('');
  // const [downloadLoading, setDownloadLoading] = useState('');

  // const handleViewClick = useCallback(async (file: FileDto, rowId: string, mimeType: string) => {
  //   setViewLoading(rowId);

  //   try {
  //     await openFileById(file.id, mimeType);
  //   } catch (error) {
  //   } finally {
  //     setViewLoading('');
  //   }
  // }, []);

  // const handleDownloadClick = useCallback(async (file: FileDto, rowId: string, title: string, mimeType: string) => {
  //   setDownloadLoading(rowId);
  //   try {
  //     await saveFileById(file.id, mimeType, combineFileExtension(file.fileName, removeExtensionFromName(title)));
  //   } catch (error) {
  //   } finally {
  //     setDownloadLoading('');
  //   }
  // }, []);

  const signInMutation = useMutation({
    mutationFn: async (customerId: string) => UserApi.generateAuthToken(customerId),
  });

  const signInAsCustomer = useStore.useSignInAsCustomer();
  useEffect(() => {
    const { data } = signInMutation;
    if (!data) {
      return;
    }
    signInAsCustomer(data.accessToken);
  }, [signInMutation, signInAsCustomer]);

  const tableColumns = useMemo<TableColumn<CustomerAdminOutSchema>[]>(() => {
    return [
      {
        headerName: 'Name',
        field: getEntityField('firstName'),
        align: 'left',
        firstClickSort: 'asc',
        render: ({ firstName, lastName, id, deletedEmail, totalCodes, email, status }) => {
          const isNotActive = status !== EUserStatus.ACTIVE;
          return (
            <div className="flex items-center justify-between gap-2">
              <Typography variant="xs" className={clsxm(deletedEmail && 'rounded bg-red-300 p-1 font-bold')} noWrap>
                {firstName} {lastName}
              </Typography>
              <div className="flex flex-col gap-2">
                <Button
                  size="small"
                  loading={signInMutation.isLoading}
                  className="px-1 text-xs"
                  onClick={() => {
                    signInMutation.mutate(id);
                  }}
                  disabled={isNotActive}
                >
                  {isNotActive ? 'no sign in, not active' : 'sign in'}
                </Button>
                {email && (totalCodes || 0) > 0 && <ViewQrCodesButton email={email} />}
              </div>
            </div>
          );
        },
      },
      {
        headerName: 'Email',
        field: getEntityField('email'),
        align: 'left',
        firstClickSort: 'asc',
        render: ({ email, deletedEmail, customerId, chargebeeId, activeCampaignId, lastActiveCampaignSyncedDate }) => (
          <div className="flex flex-col gap-4">
            <Typography variant="xs" className={clsxm(deletedEmail && 'rounded bg-red-300 p-1 font-bold')}>
              {email || deletedEmail}
            </Typography>
            <StripeIdBadge customerId={customerId} chargebeeId={chargebeeId} />
            <ActiveCampaignBadge
              activeCampaignId={activeCampaignId}
              lastActiveCampaignSyncedDate={lastActiveCampaignSyncedDate}
            />
          </div>
        ),
      },
      {
        headerName: 'QR Codes',
        field: getEntityField('totalCodes'),
        align: 'center',
        render: ({ totalCodes }) => (
          <Typography variant="xs" className="font-bold">
            {totalCodes}
          </Typography>
        ),
      },
      {
        headerName: 'Scans',
        field: getEntityField('totalScans'),
        align: 'center',
        render: ({ totalScans }) => (
          <Typography variant="xs" className="font-bold">
            {totalScans}
          </Typography>
        ),
      },
      {
        headerName: 'Language',
        field: getEntityField('language'),
        align: 'left',
        render: ({ language }) => <Typography variant="xs">{language}</Typography>,
      },
      {
        headerName: 'Subscription',
        field: getEntityField('subscription.planType'),
        disableSort: true,
        align: 'left',
        render: ({ id, subscription, stripeSyncedDate, chargebeeId }) => {
          return (
            <CustomerSubscriptionCell
              customerId={id}
              chargebeeId={chargebeeId}
              subscription={subscription}
              stripeSyncedDate={stripeSyncedDate}
            />
          );
        },
      },
      {
        headerName: 'Status',
        field: getEntityField('status'),
        align: 'center',
        render: ({ status, id }) => {
          if (id === currentUser?.id) {
            // do not allow user to ban itself
            return 'it is me';
          }

          const renderStatus = () => {
            if (status === EUserStatus.DELETED) {
              return <div className="rounded bg-red-300 p-1 font-bold text-red-900">{status}</div>;
            }
            const isActive = status === EUserStatus.ACTIVE;
            if (isActive || status === EUserStatus.BANNED) {
              return (
                <>
                  <Switch
                    checked={isActive}
                    onChange={() => {
                      changeUserStatus.mutate({
                        userId: id,
                        body: { status: isActive ? EUserStatus.BANNED : EUserStatus.ACTIVE },
                      });
                    }}
                  />
                  {status}
                </>
              );
            }
            return status;
          };
          return <Typography variant="xs">{renderStatus()}</Typography>;
        },
      },
      {
        headerName: 'Created date',
        field: getEntityField('createdDate'),
        align: 'left',
        render: ({ createdDate }) => (
          <Typography variant="xs">{createdDate ? format(new Date(createdDate), FORMAT.FULL_TIME) : '-'}</Typography>
        ),
      },
      // {
      //   headerName: 'Address',
      //   field: 'address',
      //   align: 'center',
      //   render: ({ address }) => <Typography variant="xs">{address}</Typography>,
      // },
      // {
      //   headerName: 'Phone',
      //   field: getEntityField('phone'),
      //   align: 'right',
      //   render: ({ phone }) => <Typography variant="xs">{phone ?? '-'}</Typography>,
      // },
      {
        headerName: 'Actions',
        // "actions" used as key, not for entity field
        field: 'actions',
        disableSort: true,
        align: 'right',
        render: ({ id, firstName, lastName, email }) => (
          <ConfirmDialog
            title="Delete User"
            text={`You are about to delete ${firstName} ${lastName} ${email}`}
            onConfirm={() => {
              changeUserStatus.mutate({
                userId: id,
                body: { status: EUserStatus.DELETED },
              });
            }}
            trigger={
              <IconButton>
                <DeleteIcon />
              </IconButton>
            }
          />
        ),
      },
      // {
      //   headerName: 'Actions',
      //   align: 'right',
      //   field: 'actions',
      //   disableSort: true,
      //   render: (data) => {
      //     const { openable, mimeType } = getFileMimeType(data.file.fileName);
      //     return (
      //       <div className={styles.ButtonWrapper}>
      //         {openable && (
      //           <IconButton
      //             variant="opacity"
      //             color="gray"
      //             onClick={() => handleViewClick(data.file, data.id, mimeType)}
      //             loading={viewLoading === data.id}
      //           >
      //             <ViewIcon />
      //           </IconButton>
      //         )}

      //         <IconButton
      //           variant="opacity"
      //           color="gray"
      //           onClick={() => handleDownloadClick(data.file, data.id, data.title, mimeType)}
      //           loading={downloadLoading === data.id}
      //         >
      //           <DownloadIcon />
      //         </IconButton>
      //         <IconButton variant="opacity" color="gray" onClick={() => handleBillRemoveClick(data)}>
      //           <DeleteIcon className="deleteIcon" />
      //         </IconButton>
      //       </div>
      //     );
      //   },
    ];
  }, [changeUserStatus, currentUser, signInMutation]);

  return tableColumns;
};

const ViewQrCodesButton: React.FC<{ email: string }> = ({ email }) => {
  const navigate = useTypedNavigate();
  return (
    <Button
      size="small"
      variant="outlined"
      className="px-1 text-xs"
      onClick={() => {
        navigate({ to: Routes.admin.qr.index, queryParams: { [GET_ADMIN_QRS_QUERY.searchParam]: email } });
      }}
    >
      view qr codes
    </Button>
  );
};

import iso from 'iso-3166-1';

const uniqueCountries: string[] = [];
/**
 * make sure to filter countries to avoid React.key conflicts for Autocomplete and other places
 * because there are duplicated countries in names but different codes
 * for example "Congo"
 * [
 *     {
 *         "country": "Congo",
 *         "alpha2": "CG",
 *         "alpha3": "COG",
 *         "numeric": "178"
 *     },
 *     {
 *         "country": "Congo",
 *         "alpha2": "CD",
 *         "alpha3": "COD",
 *         "numeric": "180"
 *     }
 * ]
 */
export const ALL_COUNTRIES = iso.all().filter((c) => {
  if (uniqueCountries.includes(c.country)) {
    return false;
  }
  uniqueCountries.push(c.country);
  return true;
});

import { clsxm } from '@app/styles/clsxm';
import { UIStyledSection } from '../components/UIStyledSection';

export const UITabColors: React.FC = () => {
  const render = (colors: string[]) =>
    colors.map((e, i) => (
      <div key={i} className="flex flex-col gap-2">
        <div>{e.replace('bg-', '')}</div>
        <div className={clsxm(e, 'p-4')} />
      </div>
    ));

  return (
    <UIStyledSection title="Colors">
      <div className="flex flex-col gap-4 pb-6">
        <div className="font-bold">Black</div>
        <div className="grid grid-cols-4 gap-4">{render(COLORS_BLACK)}</div>
      </div>
      <div className="flex flex-col gap-4 pb-6">
        <div className="font-bold">Gray</div>
        <div className="grid grid-cols-4 gap-4">{render(COLORS_GRAY)}</div>
      </div>
      <div className="flex flex-col gap-4 pb-6">
        <div className="font-bold">Accept 1</div>
        <div className="grid grid-cols-4 gap-4">{render(COLORS_ACCEPT_1)}</div>
      </div>
      <div className="flex flex-col gap-4 pb-6">
        <div className="font-bold">Accept 2</div>
        <div className="grid grid-cols-4 gap-4">{render(COLORS_ACCEPT_2)}</div>
      </div>
      <div className="flex flex-col gap-4 pb-6">
        <div className="font-bold">Feedback colors</div>
        <div className="grid grid-cols-4 gap-4">{render(COLORS_FEEDBACK)}</div>
      </div>
    </UIStyledSection>
  );
};

const COLORS_BLACK = /*tw*/ ['bg-gray-800', 'bg-gray-700', 'bg-gray-500'];
const COLORS_GRAY = /*tw*/ ['bg-gray-200', 'bg-gray-100', 'bg-gray-50'];
const COLORS_ACCEPT_1 = /*tw*/ [
  'bg-accept-light',
  'bg-accept-medium',
  'bg-accept-hover',
  'bg-accept-main',
  'bg-accept-text',
];
const COLORS_ACCEPT_2 = /*tw*/ [
  'bg-accept-dark-blue',
  'bg-accept-menu-item-bg',
  'bg-accept-menu-item',
  'bg-accept-light-bg',
  'bg-black',
];
const COLORS_FEEDBACK = /*tw*/ ['bg-success', 'bg-warning', 'bg-error', 'bg-info'];

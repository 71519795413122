import React, { cloneElement, useMemo, useState, useCallback } from 'react';
import { Modal, Typography } from '@mui/material';
import styles from './ConfirmDialog.module.scss';
import { clsxm } from '@app/styles/clsxm';
import { Button } from '@app/components';
import { useTranslation } from 'react-i18next';

interface PropTypes {
  trigger: React.ReactElement;
  onConfirm: () => void;
  cancelText?: string;
  disabled?: boolean;
}

export const ConfirmLogoutDialog: React.FC<PropTypes> = ({ trigger, onConfirm, disabled }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const clickTrigger = useMemo(() => {
    return cloneElement(trigger, {
      onClick: (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        if (disabled) {
          return;
        }
        setOpen(true);
      },
    });
  }, [trigger, setOpen, disabled]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    onConfirm();
    handleClose();
  }, [handleClose, onConfirm]);

  return (
    <>
      {clickTrigger}
      <Modal open={open} onClose={handleClose} onClick={(e) => e.stopPropagation()}>
        <div className={clsxm(styles.Root, 'min-h-0 w-[350px] rounded-3xl bg-white p-8')}>
          <div className={clsxm(styles.Content, 'p-0')}>
            <Typography variant="xl" className="mb-6 text-center font-bold">
              {t('auth.confirmLogOut.logOut')}
            </Typography>
            <div className="mt-2 flex w-full flex-col gap-4">
              <Button onClick={handleSubmit} variant="contained">
                {t('auth.confirmLogOut.yesLogOut')}
              </Button>
              <Button onClick={handleClose} variant="outlined">
                {t('auth.confirmLogOut.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { SignUpFormValidationSchema } from '@app/auth/api/auth.form';

import { Typography } from '@mui/material';

import { Input, Button, Form, Checkbox } from '@app/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { DividerWithText } from '@app/components/divider/DividerWithText';
import { useMutation } from 'react-query';
import { AuthApi } from '@app/auth/api/auth.api';
import { useStore } from '@app/store/useStore.hook';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { useTranslation } from 'react-i18next';
import { GoogleButton } from '@app/components/buttons/google-button/GoogleButton';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { AUTH_QUERY_PARAM_AUTO_LINK_QR_ID } from '../auth.constants';
import { gtag } from '@app/gtag/gtag';
import { useCurrentLanguage } from '@app/i18n/useCurrentLanguage';
import { AgreeWithTermsText } from '../components/AgreeWithTermsText';
import { EGTAGRegistrationOrigin } from '@app/gtag/gtag.type';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';

export const ClaimQRPage = () => {
  const { t } = useTranslation();
  const language = useCurrentLanguage();
  const user = useStore.useCurrentUser();
  const setCurrentUser = useStore.useSetCurrentUser();
  const navigate = useTypedNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    clearErrors,
  } = useForm<SignUpFormValidationSchema>({
    resolver: yupResolver(SignUpFormValidationSchema),
  });

  const { email, privacyPolicy } = watch();
  const [autoLinkQRID] = useQueryParam(AUTH_QUERY_PARAM_AUTO_LINK_QR_ID, withDefault(StringParam, null));

  const {
    mutate: signUp,
    isLoading,
    error,
  } = useMutation(
    async (formData: SignUpFormValidationSchema) => {
      const { data } = await AuthApi.signUp({ ...formData, language });
      return data;
    },
    {
      onSuccess: (res) => {
        setCurrentUser(res);
        gtag.signUp({
          authentication_method: 'email',
          user_email: res.email,
          user_id: res.id,
          origin: EGTAGRegistrationOrigin.CLAIM,
        });
      },
    }
  );

  const onSubmit: SubmitHandler<SignUpFormValidationSchema> = async (formData) => {
    clearErrors();
    signUp({ ...formData, [AUTH_QUERY_PARAM_AUTO_LINK_QR_ID]: autoLinkQRID });
  };

  const renderBody = () => {
    if (!user) {
      return (
        <div className="flex h-full w-full max-w-sm flex-col gap-y-4">
          <GoogleButton autoLinkQRID={autoLinkQRID} origin={EGTAGRegistrationOrigin.CLAIM} />
          <DividerWithText text={t('auth.or')} />
          <Form
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-1 flex-col gap-y-4 sm:flex-initial"
          >
            <Input
              {...register('email')}
              label={t('auth.email')}
              placeholder={t('auth.enterYourEmail')}
              id="email"
              withBG
              errorMessage={errors.email?.message}
            />
            <div className="mt-auto space-y-4 sm:mt-0">
              <Controller
                name="privacyPolicy"
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={Boolean(field.value)}
                    size="small"
                    errorMessage={errors?.privacyPolicy?.message}
                    disableRipple
                    containerClassName="ml-1"
                    color="primary"
                    label={<AgreeWithTermsText />}
                  />
                )}
              />
              <Button
                loading={isLoading}
                disabled={!email || !privacyPolicy}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {t('qr.expired.buttonClaimAndActivate')}
              </Button>
              {maybeRenderError(error as Error)}
            </div>
          </Form>
        </div>
      );
    }
    return (
      <div className="flex h-full w-full max-w-sm flex-col items-center gap-y-4">
        <Button
          onClick={() => {
            navigate({ to: Routes.customer.account.subscription });
          }}
          className="w-[220px]"
        >
          {t('subscription.buttonUpgradePlan')}
        </Button>
      </div>
    );
  };

  return (
    <div className="flex h-full flex-col items-center gap-y-4">
      <div className="mb-4 flex flex-col gap-2">
        <Typography variant="4xl" className="text-center font-bold">
          {t('qr.expired.qrCodeNoLongerValid')}
        </Typography>
        <Typography variant="s" className="text-center text-gray-500">
          {t('qr.expired.activateYourQr')}
        </Typography>
      </div>
      {renderBody()}
    </div>
  );
};

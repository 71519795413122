// URL validity should be proven by constucting it, because regex may be to complex
// and not include all needed cases as per specifications
// e.g. https://web.site/path/withächaracter

export const validateURL = (maybeUrl: string) => {
  try {
    const url = new URL(maybeUrl);
    return { url: url, isValid: Boolean(url) };
  } catch (error) {
    if (error instanceof TypeError) {
      return { error, isValid: false };
    }
    return { error: new Error('invalid url provided'), isValid: false };
  }
};

import { Typography } from '@app/components';
import { UIStyledSection } from '../components/UIStyledSection';

export const UITabTypography: React.FC = () => {
  return (
    <UIStyledSection title="Typography">
      <Typography variant="xxxl" fontWeight={700}>
        3XL Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <Typography variant="xxl" fontWeight={700}>
        2XL Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <Typography variant="xl" fontWeight={700}>
        XL Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <Typography variant="l" fontWeight={500}>
        L Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <Typography variant="m" fontWeight={600}>
        M BOLD Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <Typography variant="m">m Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
      <Typography variant="s" fontWeight={600}>
        S BOLD Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <Typography variant="s">s Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
      <Typography variant="xs" fontWeight={700}>
        XS BOLD Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <Typography variant="xs">XS Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
      <Typography variant="xxs" fontWeight={700}>
        XXS BOLD Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </Typography>
      <Typography variant="xxs">XXS Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
    </UIStyledSection>
  );
};

import styles from './UserHeaderInfo.module.scss';
import { capitalize } from 'lodash';
import { useMemo, useState } from 'react';

import { useQueryClient } from 'react-query';

import { AuthUserSchema } from '@app/auth/api/auth.dto';
import { useStore } from '@app/store/useStore.hook';
import { clsxm } from '@app/styles/clsxm';

import { Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Button, Avatar } from '@app/components';
import { LogoutIcon, ProfileIcon } from '@app/assets';
import { ConfirmLogoutDialog } from '@app/hoc/confirm-dialog/ConfirmLogoutDialog';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { isCustomer } from '@app/auth/auth.utils';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { Trans, useTranslation } from 'react-i18next';
import { SmartProgressBar } from '@app/components/progress/SmartProgressBar';
import { GET_USERS_QUERY } from '@app/constants/query-api-configs';
import { CustomerPortalButton } from './CustomerPortalButton';
import { StripeIdBadge } from '@app/domain/subscription/StripeIdBadge';

import { ActiveCampaignBadge } from '@app/domain/user/components/ActiveCampaignBadge';

type InjectedProps = {
  user: AuthUserSchema;
  isMobileLayout?: boolean;
};

export const UserHeaderInfo: React.FC<InjectedProps> = ({ user, isMobileLayout }) => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const queryClient = useQueryClient();
  const signOut = useStore.useSignOut();
  const hasAdminTokens = useStore.useHasAdminTokens();
  const restoreAdminTokens = useStore.useRestoreAdminTokens();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut();
    queryClient.removeQueries();

    //   replace({ path: Routes.SIGN_IN }); IF 404 solution used
  };

  const avatarIdWithCropSize = useMemo(() => {
    if (user?.avatarId) {
      return `${user.avatarId}?cropSize=112`;
    }
    return;
  }, [user?.avatarId]);

  const { isTrial, isExpired } = useActivePlan();
  const isExpiredTrial = isTrial && isExpired;

  return (
    <div className={styles.Container} data-cy="user-header-avatar">
      {isCustomer(user) && (
        <div className="hidden flex-col gap-2 lg:flex">
          <div className="text-white lg:text-accept-dark-blue">
            <Trans
              i18nKey={'header.amountOfCodes'}
              values={{ amount: user?.totalCodes, max: user?.currentPlanDetails?.amountMaxQrCodes }}
            >
              <b>of</b> QR codes
            </Trans>
          </div>
          <div>
            <SmartProgressBar value={user?.totalCodes} max={user?.currentPlanDetails?.amountMaxQrCodes} />
          </div>
        </div>
      )}
      <Menu
        elevation={0}
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: 'p-2 flex flex-col text-gray-500 gap-2',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: styles.Paper }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        {!isExpiredTrial && isCustomer(user) && (
          <MenuItem
            className="gap-3 px-2 hover:text-accept-hover"
            onClick={() => {
              navigate({ to: Routes.customer.account.settings });
              handleClose();
            }}
          >
            <ProfileIcon className="h-[20px] w-[20px]" />
            <div>{t('account.pageTitle')}</div>
          </MenuItem>
        )}
        <ConfirmLogoutDialog
          trigger={
            <MenuItem className="gap-3 px-2 hover:text-accept-hover">
              <LogoutIcon className="h-[20px] w-[20px]" />
              <div>{t('auth.logOut')}</div>
            </MenuItem>
          }
          onConfirm={handleLogout}
        />
        {hasAdminTokens && (
          <MenuItem
            className="gap-3 px-2 hover:text-accept-hover"
            onClick={() => {
              const redirectUrl = `${Routes.admin.user_management.users}?${
                GET_USERS_QUERY.searchParam
              }=${encodeURIComponent(user.email)}`;
              restoreAdminTokens(redirectUrl);
            }}
          >
            <LogoutIcon className="h-[20px] w-[20px]" />
            <div>switch to admin</div>
          </MenuItem>
        )}
        {hasAdminTokens && (
          <div className="flex flex-col items-center gap-4">
            <div className="p-1">{user.email}</div>
            <StripeIdBadge customerId={user.customerId} chargebeeId={user.chargebeeId} />
            <ActiveCampaignBadge
              activeCampaignId={user.activeCampaignId}
              lastActiveCampaignSyncedDate={user.lastActiveCampaignSyncedDate}
            />
            {user.customerId && <CustomerPortalButton />}
          </div>
        )}
      </Menu>
      <Button
        variant="text"
        size="small"
        disableRipple
        className={clsxm(styles.MenuButton, 'space-x-2 py-0 pr-0')}
        endIcon={
          !isMobileLayout ? (
            anchorEl ? (
              <KeyboardArrowUpIcon className={styles.ButtonIcon} />
            ) : (
              <KeyboardArrowDownIcon className={styles.ButtonIcon} />
            )
          ) : null
        }
        onClick={handleClick}
      >
        <Avatar
          className={styles.Avatar}
          id="header-avatar-filter"
          width={38}
          height={38}
          imageId={avatarIdWithCropSize}
        >
          <span className="text-accept-dark-blue">{capitalize(user?.firstName?.slice(0, 1) || 'A')}</span>
        </Avatar>
      </Button>
    </div>
  );
};

import { clsxm } from '@app/styles/clsxm';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton, IconButtonProps } from '../icon-button/IconButton';

export const BackButton: React.FC<IconButtonProps> = ({ size = 'medium', className, ...props }) => {
  return (
    <IconButton size={size} variant="transparent" className={clsxm('mr-2', className)} {...props}>
      <ArrowBackIosNewIcon />
    </IconButton>
  );
};

import { PdfFilledIcon } from '@app/assets';
import { Button, Input } from '@app/components';
import { BriefApi } from '@app/domain/brief/brief.api';
import { Page } from '@app/hoc';
import { clsxm } from '@app/styles/clsxm';
import { friendlyByteSize } from '@app/utils/file.utils';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { OpenInNew } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useMutation } from 'react-query';

const INITIAL_DOC_URL =
  'https://docs.google.com/spreadsheets/d/1dI08nYx7TX6r7yM61SuMlEIbz-fn4MCBJ4wJ0ZXCV2M/edit#gid=0';

export const AdminBriefPage: React.FC = () => {
  const [name, setName] = useState(new Date().toJSON().replaceAll(/:/g, '-').slice(0, 19));
  const [url, setUrl] = useState(INITIAL_DOC_URL);

  const {
    mutate: generateBrief,
    isLoading,
    data,
    error,
  } = useMutation({
    mutationFn: async () => {
      // await new Promise((res) => setTimeout(res, 1e3));
      return await BriefApi.generateBrief({
        url,
        exportName: name,
      });
    },
  });

  const isDisabled = !name || !url;
  return (
    <Page>
      <div className="flex flex-col gap-4">
        <Input label="Export Name" value={name} onChange={(e) => setName(e.target.value)} disabled={isLoading} />
        <Input label="Sheet URL" value={url} onChange={(e) => setUrl(e.target.value)} disabled={isLoading} />
        <div>
          <Button disabled={isDisabled} onClick={() => generateBrief()} loading={isLoading}>
            Generate PDFs
          </Button>
        </div>
      </div>
      {isLoading && (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      )}
      {maybeRenderError(error as Error)}
      {data && (
        <div>
          <div className="flex flex-col gap-2 py-2">
            <div className="flex gap-2 items-center">
              <b>Rendered pages:</b>
              <div className="text-sky-800 bg-sky-300 rounded px-2">{data.totalRows}</div>
            </div>
            <div className="flex gap-2">
              {data.pagedErrors.length > 0 ? (
                <div className="text-red-800 bg-red-300 rounded px-2">Render errors: {data.pagedErrors.length}</div>
              ) : (
                <div className="text-green-800 bg-green-300 rounded px-2">No render errors</div>
              )}
              {data.spreadsheetErrors.length > 0 ? (
                <div className="text-red-800 bg-red-300 rounded px-2">
                  Spreadsheet parsing errors: {data.spreadsheetErrors.length}
                </div>
              ) : (
                <div className="text-green-800 bg-green-300 rounded px-2">No spreadsheet errors</div>
              )}
            </div>
            <div className="flex gap-2 items-center">
              <b>Generated files:</b>
            </div>
            <div className="flex flex-col gap-2 py-2 items-start">
              {data.persistedFiles.length < 1 && (
                <div className="text-red-800 bg-red-300 rounded px-2">No files generated</div>
              )}
              {data.persistedFiles.map((e, i) => (
                <a
                  href={BriefApi.getLinkForFile(e.name)}
                  target="_blank"
                  rel="noreferrer"
                  className={clsxm('flex gap-1 items-center rounded p-1', 'hover:bg-sky-100')}
                  key={i}
                >
                  <OpenInNew />
                  <PdfFilledIcon />
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-800">{e.name}</div>
                    <div className="text-xs">{friendlyByteSize(e.bytes)}</div>
                  </div>
                </a>
              ))}
            </div>
          </div>
          {data.pagedErrors.length > 0 && (
            <div className="flex flex-col gap-2 py-2">
              <b>Render errors:</b>
              <div className="flex flex-col gap-2">
                {data.pagedErrors.map((e, i) => {
                  const errorType = e.row !== undefined ? 'Sticker' : 'Brief';
                  let maybeRowColDetails: JSX.Element | null = null;
                  if (e.row !== undefined) {
                    maybeRowColDetails = (
                      <>
                        {' '}
                        on row {e.row} on col {e.col}
                      </>
                    );
                  }
                  return (
                    <div key={i} className="text-red-800 bg-red-300 rounded p-2">
                      {errorType} Error on page {e.pageIndex}
                      {maybeRowColDetails}: {e.errors.join(', ')}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {data.spreadsheetErrors.length > 0 && (
            <div className="flex flex-col gap-2 py-2">
              <b>Spreadsheet parsing errors:</b>
              <div className="flex flex-col gap-2">
                {data.spreadsheetErrors.map((e, i) => (
                  <div key={i} className="text-red-800 bg-red-300 rounded p-2">
                    Error on row index {e.rowIndex}: {e.error}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </Page>
  );
};

import { Button, Container, Form, Typography } from '@app/components';
import {
  QrCodeFormStepper,
  QR_CODE_STEPPER_STEPS,
  QR_CODE_STEPPER_STEPS_TYPE,
} from '@app/domain/qr-code/components/stepper/QRCodeFormStepper';
import { useHandler } from '@app/hooks/useHandler.hook';
import { clsxm } from '@app/styles/clsxm';
import { useMemo, useState } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SelectQRCodeTypeContainer } from '@app/domain/qr-code/components/type/SelectQRCodeTypeContainer';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { yupResolver } from '@hookform/resolvers/yup';
import { ECodeType, LinkInDto, MultilinkInDto, PDFInDto, VCardInDto, VCardLinkInDto } from '@app/swagger-types';
import { QRCodeInputContainer } from '@app/domain/qr-code/components/input/QRCodeInputContainer';
import { QRCodeCustomizeContainer } from '@app/domain/qr-code/components/customize/QRCodeCustomizeContainer';
import { DEFAULT_TEMPLATE_FORM_VALUES } from '@app/domain/template/constants';
import { QRCodeDoneStep } from '@app/domain/qr-code/components/done/QRCodeDoneStep';
import { toast } from 'react-toastify';
import { isKeyInObject } from '@app/utils/object.utils';
import { QR_CODE_DONE_STEP_FORM_FIELDS, QR_CODE_INPUT_STEP_FORM_FIELDS } from '@app/domain/qr-code/constants';
import { QRCodePreviewSection } from '@app/domain/qr-code/components/QRCodePreview.section';
import { useCreateQRCode, useUpdateQRCode } from '@app/domain/qr-code/api/qr-code.hooks.api';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { pick } from 'lodash';
import { DesignFormSchema } from '@app/domain/template/api/template.form.schema.api';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { BackButton } from '@app/components/buttons/back-button/BackButton';
import { QRCodeInDtoOverride } from '@app/swagger-override-types';
import { useTranslation } from 'react-i18next';
import { PlanLimitTooltip } from '@app/domain/plan/components/PlanLimitTooltip';
import { usePlanLimits } from '@app/domain/plan/hooks/usePlanLimits';

export const QRCodeForm: React.FC<{ isEditMode?: boolean; defaultValues: QRCodeFormSchema & { id?: string } }> = ({
  isEditMode = false,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<QR_CODE_STEPPER_STEPS_TYPE>(!isEditMode ? 'Type' : 'Input');
  const [completedSteps, setCompletedSteps] = useState<QR_CODE_STEPPER_STEPS_TYPE[]>(!isEditMode ? [] : ['Type']);

  const { mutate: createQRCode, isLoading } = useCreateQRCode();
  const { mutate: updateQRCode, isLoading: updateLoading } = useUpdateQRCode();

  const navigate = useTypedNavigate();

  const formMethods = useForm<QRCodeFormSchema>({
    resolver: yupResolver(QRCodeFormSchema),
    defaultValues,
  });

  const sendRequest = useHandler((formData: QRCodeInDtoOverride) => {
    if (!isEditMode) {
      createQRCode(formData, {
        onSuccess: () => {
          toast.success(t('toaster.qrCreated'));
          navigate({ to: Routes.customer.my_qr_codes.index });
        },
      });
    } else if (defaultValues?.id) {
      updateQRCode(
        { id: defaultValues.id, ...formData },
        {
          onSuccess: () => {
            toast.success(t('toaster.qrUpdated'));
            navigate({ to: Routes.customer.my_qr_codes.index });
          },
        }
      );
    }
  });

  const { control, handleSubmit, trigger, watch } = formMethods;
  const { codeType } = watch();

  const onSubmit: SubmitHandler<QRCodeFormSchema> = useHandler((data) => {
    if (!canSaveQRCode) {
      return;
    }
    // TODO refactor to keep design fields under nested field of form, so to have nested fields instead of flat schema
    // to avoid picking and omiting in different places
    const designValues = pick(data, Object.keys(DesignFormSchema.omit(['template']).fields));
    const formData = {
      codeType: data.codeType,
      name: data.name,
      templateId: data?.templateId,
      design: !data?.templateId
        ? {
            ...DEFAULT_TEMPLATE_FORM_VALUES,
            ...designValues,
            frameText: designValues?.frameText || '',
            libraryId: designValues?.libraryId === NO_LOGO_ID ? undefined : designValues.libraryId,
          }
        : undefined,
    };

    if (data.codeType === ECodeType.WEBSITE) {
      const codeDto: QRCodeInDtoOverride = {
        ...formData,
        website: { url: data?.websiteUrl || '' },
      };
      sendRequest(codeDto);
      return;
    }
    if (data?.multilink && data.codeType === ECodeType.MULTILINK) {
      const value = data.multilink;
      const multilink: MultilinkInDto = {
        ...value,
        // TODO validate guard to make sure value matches dto in future after dto changes
        customLinks: value.customLinks?.filter((l): l is LinkInDto => Boolean(l.linkType && l.url && l.title)) || [],
        // TODO validate guard to make sure value matches dto in future after dto changes
        socialLinks:
          value.socialLinks
            ?.filter((l): l is LinkInDto => Boolean(l.linkType && l.url))
            .map((e) => {
              if (e.title) {
                return e;
              }
              return {
                ...e,
                title: 'social',
              };
            }) || [],
        libraryId: value.libraryId === NO_LOGO_ID ? undefined : value.libraryId,
        description: value.description || '',
      };
      const codeDto: QRCodeInDtoOverride = {
        ...formData,
        multilink,
      };
      sendRequest(codeDto);
      return;
    }
    if (data?.pdf && data.codeType === ECodeType.PDF) {
      const value = data.pdf;
      const pdf: PDFInDto = {
        ...value,
      };
      const codeDto: QRCodeInDtoOverride = {
        ...formData,
        pdf,
      };
      sendRequest(codeDto);
      return;
    }
    if (data?.vcard && data.codeType === ECodeType.V_CARD) {
      const value = data.vcard;
      const vcard: VCardInDto = {
        ...value,
        // TODO validate guard to make sure value matches dto in future after dto changes
        links: value.links?.filter((l): l is VCardLinkInDto => Boolean(l.linkType && l.url)),
        libraryId: value.libraryId === NO_LOGO_ID ? undefined : value.libraryId,
        alternativePhoneNumber: value.alternativePhoneNumber ?? undefined,
        email: value.email ?? undefined,
        website: value.website ?? undefined,
        companyName: value.companyName ?? undefined,
        jobPosition: value.jobPosition ?? undefined,
        street: value.street ?? undefined,
        postalCode: value.postalCode ?? undefined,
        city: value.city ?? undefined,
        state: value.state ?? undefined,
        country: value.country ?? undefined,
      };
      const codeDto: QRCodeInDtoOverride = {
        ...formData,
        vcard,
      };
      sendRequest(codeDto);
      return;
    }

    toast.warning('API in progress');
  });

  const onErrorSubmit: SubmitErrorHandler<QRCodeFormSchema> = useHandler((errors) => {
    const inputStepHasErrors = isKeyInObject(QR_CODE_INPUT_STEP_FORM_FIELDS, errors);
    const doneStepHasErrors = isKeyInObject(QR_CODE_DONE_STEP_FORM_FIELDS, errors);

    if (inputStepHasErrors) {
      if (doneStepHasErrors) {
        setCompletedSteps((prev) => [...new Set([...prev.filter((s) => s !== QR_CODE_STEPPER_STEPS[3])])]);
      } else {
        setCompletedSteps((prev) => [...new Set([...prev, QR_CODE_STEPPER_STEPS[3]])]);
      }

      setActiveStep('Input');
    } else if (doneStepHasErrors) {
      if (inputStepHasErrors) {
        setCompletedSteps((prev) => [...new Set([...prev.filter((s) => s !== QR_CODE_STEPPER_STEPS[1])])]);
      } else {
        setCompletedSteps((prev) => [...new Set([...prev, QR_CODE_STEPPER_STEPS[1]])]);
      }

      setCompletedSteps((prev) => [...new Set([...prev.filter((s) => s !== QR_CODE_STEPPER_STEPS[3])])]);
      setActiveStep('Done');
    }

    return;
  });

  const onChangeActiveStep = useHandler(async (step: QR_CODE_STEPPER_STEPS_TYPE, skipValidation?: boolean) => {
    if (step === activeStep) {
      return;
    }

    const currentStepIndex = QR_CODE_STEPPER_STEPS.findIndex((s) => s === activeStep);

    if (!skipValidation) {
      if (QR_CODE_STEPPER_STEPS[currentStepIndex] === 'Input') {
        const isInvalidInputStep = await trigger(QR_CODE_INPUT_STEP_FORM_FIELDS, { shouldFocus: true });
        if (!isInvalidInputStep) {
          setCompletedSteps((prev) => [...new Set(prev.filter((s) => s !== QR_CODE_STEPPER_STEPS[currentStepIndex]))]);
          return;
        }
      } else if (QR_CODE_STEPPER_STEPS[currentStepIndex] === 'Done') {
        const isValidDoneStep = await trigger('name', { shouldFocus: true });

        if (!isValidDoneStep) {
          setCompletedSteps((prev) => [...new Set(prev.filter((s) => s !== QR_CODE_STEPPER_STEPS[currentStepIndex]))]);
          return;
        }
      }

      setCompletedSteps((prev) => [...new Set([...prev, QR_CODE_STEPPER_STEPS[currentStepIndex]])]);
    }

    setActiveStep(step);
  });

  const renderActiveStep = useMemo(() => {
    switch (activeStep) {
      case 'Type':
        return <SelectQRCodeTypeContainer isEditMode={isEditMode} />;
      case 'Input':
        return <QRCodeInputContainer />;
      case 'Customize':
        return <QRCodeCustomizeContainer />;
      case 'Done':
        return <QRCodeDoneStep />;
      default:
        return null;
    }
  }, [activeStep, isEditMode]);

  const { canCreateQRCode } = usePlanLimits();
  const canSaveQRCode = isEditMode || canCreateQRCode;

  const showPreviewForVcard = codeType === ECodeType.V_CARD && activeStep === 'Input';

  // TODO: intagrate with Edit QR Code
  const formTitle = isEditMode ? t('qr.editQRCode') : t('qr.createQRCode');
  const isDoneStep = activeStep === 'Done';

  return (
    <FormProvider {...formMethods}>
      <Form
        control={control}
        className={clsxm('flex h-full flex-col gap-4 pb-8 xl:h-auto xl:flex-row xl:px-6 xl:pb-6')}
        onSubmit={handleSubmit(onSubmit, onErrorSubmit)}
      >
        <div className="flex flex-1 flex-col gap-4">
          <Container className="xl:rounded-xl">
            <div className="mb-6 flex items-center">
              {isEditMode && (
                <BackButton
                  onClick={() => {
                    navigate({ to: Routes.customer.my_qr_codes.index, preserveQueryParams: true });
                  }}
                />
              )}
              <Typography variant="xxl" className="font-bold">
                {formTitle}
              </Typography>
            </div>
            <QrCodeFormStepper
              onChange={onChangeActiveStep}
              completedSteps={completedSteps}
              activeStep={activeStep}
              disabled={!canSaveQRCode}
            />
          </Container>
          <div className="flex flex-1 flex-col gap-4 xl:flex-initial xl:rounded-xl">{renderActiveStep}</div>
        </div>
        <div
          className={clsxm(
            'hidden flex-1 flex-col gap-4 bg-white p-6 font-bold ',
            (activeStep === 'Customize' || showPreviewForVcard) && 'flex min-h-[250px] xl:hidden'
          )}
        >
          <Typography variant="xl" className="text-center">
            {t('qr.preview')}
          </Typography>
          <div className="flex flex-1 items-center justify-center">
            <QRCodePreviewSection activeStep={activeStep} qrId={defaultValues.id} />
          </div>
        </div>
        <Container className="flex w-full flex-col gap-6 xl:w-[35%] xl:min-w-[400px] xl:rounded-xl">
          <div className="sticky top-20 max-h-[calc(100vh-120px)] space-y-6 overflow-auto">
            <div className="hidden flex-col gap-6 font-bold xl:flex">
              <Typography variant="xl">{t('qr.preview')}</Typography>
              <div className="flex min-h-[400px] flex-1 items-center justify-center">
                <QRCodePreviewSection activeStep={activeStep} qrId={defaultValues.id} />
              </div>
            </div>
            <div className="mb-2 flex justify-between gap-4 xl:flex-col">
              <Button
                onClick={() => {
                  const currentStepIndex = QR_CODE_STEPPER_STEPS.findIndex((s) => s === activeStep);
                  onChangeActiveStep(QR_CODE_STEPPER_STEPS[currentStepIndex - 1], true);
                }}
                variant="outlined"
                fullWidth
                disabled={isLoading || updateLoading}
                className={clsxm('hidden xl:order-2', activeStep !== 'Type' && 'inline-flex')}
              >
                {t('qr.buttonBack')}
              </Button>
              <PlanLimitTooltip condition={!canSaveQRCode} divWrap>
                <Button
                  onClick={async () => {
                    if (activeStep === 'Done') {
                      handleSubmit(onSubmit, onErrorSubmit)();
                      return;
                    }

                    const currentStepIndex = QR_CODE_STEPPER_STEPS.findIndex((s) => s === activeStep);
                    onChangeActiveStep(QR_CODE_STEPPER_STEPS[currentStepIndex + 1]);
                  }}
                  disabled={!canSaveQRCode}
                  loading={isLoading || updateLoading}
                  fullWidth
                  className="xl:order-1"
                  endIcon={!isDoneStep && <ChevronRightIcon />}
                >
                  {isDoneStep ? (
                    !isEditMode ? (
                      <span>
                        {t('qr.buttonConfirm')}{' '}
                        <span className="ml-1 hidden sm:inline-block">{t('qr.buttonAndSave')}</span>
                      </span>
                    ) : (
                      <span>{t('qr.buttonSaveChanges')}</span>
                    )
                  ) : null}
                  {!isDoneStep && <span>{t('qr.buttonNext')}</span>}
                </Button>
              </PlanLimitTooltip>
            </div>
          </div>
        </Container>
      </Form>
    </FormProvider>
  );
};

import { useMemo, useEffect, useCallback, useState } from 'react';

import { useStore } from '@app/store/useStore.hook';

import { DarkMode, LightMode } from '@mui/icons-material';
import { createTheme, IconButton } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { getDesignTokens } from '@app/styles/material-theme';

import { clsxm } from '@app/styles/clsxm';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { IS_LOCALHOST, IS_PROD_ENV } from '@app/environment/typed-env';
import { Button } from '@app/components';

export const ThemeWrapper: React.FC = ({ children }) => {
  const { theme, setTheme } = useStore(({ theme, setTheme }) => ({ theme, setTheme }));
  const hasHydrated = useStore.use_hasHydrated();

  const muiTheme = useMemo(() => createTheme(getDesignTokens(theme)), [theme]);

  const isDarkTheme = theme === 'dark';

  // TAILWIND RELATED
  useEffect(() => {
    if (isDarkTheme) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkTheme]);

  const handleChangeTheme = useCallback(() => {
    setTheme(isDarkTheme ? 'light' : 'dark');
  }, [setTheme, isDarkTheme]);

  if (!hasHydrated) {
    return null;
  }

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline enableColorScheme />
      <ThemeProvider theme={muiTheme}>
        <DevMenu handleChangeTheme={handleChangeTheme} isDarkTheme={isDarkTheme} />
        <div className={isDarkTheme ? 'dark' : ''}>{children}</div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const DevMenu: React.FC<{
  handleChangeTheme: () => void;
  isDarkTheme: boolean;
}> = ({ handleChangeTheme, isDarkTheme }) => {
  const [visible, setVisible] = useState(true);
  if (!IS_LOCALHOST && IS_PROD_ENV) {
    return null;
  }

  if (!visible) {
    return null;
  }

  return (
    <div className={clsxm('absolute', 'left-1/2', 'top-3', 'group z-[2000]')}>
      {IS_LOCALHOST && IS_PROD_ENV && (
        <div className="fixed left-0 top-0 w-full rounded-b bg-orange-400 p-2 px-8 text-center font-bold text-orange-900 md:left-80 md:w-auto">
          ⚠️ PROD ENVIRONMENT ⚠️
        </div>
      )}
      <div className={'absolute -right-2 -top-2 hidden rounded bg-zinc-600/80 px-6 pt-16 pb-4 group-hover:block'}>
        <TypedLink
          to={Routes.uiKit.index}
          className="mb-4 block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
        >
          UI Kit
        </TypedLink>
        <TypedLink
          to={Routes.devKit.index}
          className="mb-4 block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
        >
          Dev Kit
        </TypedLink>
        <TypedLink
          to={Routes.index}
          className="mb-4 block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
        >
          Home
        </TypedLink>
        <TypedLink
          to={Routes.widget}
          className="mb-4 block rounded bg-zinc-700 p-2 text-center text-white hover:bg-primary"
        >
          Widget
        </TypedLink>
        <Button variant="outlined" size="small" onClick={() => setVisible(false)}>
          Hide This
        </Button>
      </div>
      <IconButton
        onClick={handleChangeTheme}
        className={clsxm(isDarkTheme ? 'bg-zinc-700' : 'bg-white', 'rounded-full')}
      >
        {isDarkTheme ? <DarkMode /> : <LightMode />}
      </IconButton>
    </div>
  );
};

import { Button, Container, Typography } from '@app/components';
import NiceModal from '@ebay/nice-modal-react';
import { useTranslation } from 'react-i18next';
import { ChangePasswordModal } from './ChangePasswordModal';
import { DeleteMyAccountButton } from './DeleteMyAccountButton';

export const AccountSecurity: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 lg:flex-row">
      <Container className="flex flex-1 flex-col gap-6 p-6 lg:rounded-xl">
        <Typography variant="m" className="font-bold">
          {t('account.sectionSecurity.title')}
        </Typography>
        <div>
          <Button
            onClick={() => {
              NiceModal.show(ChangePasswordModal);
            }}
            color="secondary"
            variant="outlined"
            className="w-[220px]"
          >
            {t('account.changePassword.title')}
          </Button>
        </div>
      </Container>
      <Container className="flex flex-1 flex-col gap-6 p-6 lg:rounded-xl">
        <Typography variant="m" className="font-bold">
          {t('account.sectionManage.title')}
        </Typography>
        <div>
          <DeleteMyAccountButton />
        </div>
      </Container>
    </div>
  );
};

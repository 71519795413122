import React, { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';
import { Input, Button, Form } from '@app/components';
import { ConfirmPasswordReqDto } from '@app/auth/api/auth.dto';
import { ConfirmPasswordValidationSchema } from '@app/auth/api/auth.form';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { StringParam, useQueryParams } from 'use-query-params';
import { HELPCENTER_LINK_EXTERNAL } from '@app/modules/customer/pages/help/help.constants';
import { useTranslation } from 'react-i18next';

interface Props {
  onConfirmPassword: (data: ConfirmPasswordReqDto) => void;
  loading: boolean;
  error?: Error | null;
}

export const ConfirmPasswordForm: React.FC<Props> = ({ onConfirmPassword, loading, error }) => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();

  const [{ code, email }] = useQueryParams({ email: StringParam, code: StringParam });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    clearErrors,
  } = useForm<ConfirmPasswordValidationSchema>({
    resolver: yupResolver(ConfirmPasswordValidationSchema),
  });

  const onSubmit: SubmitHandler<ConfirmPasswordValidationSchema> = (formData) => {
    clearErrors();
    onConfirmPassword({
      newPassword: formData.newPassword,
      email: email || '',
      confirmCode: code || '',
    });
  };

  const inputRegister = useMemo(() => {
    const fn: typeof register = (key, ...args) => {
      return {
        ...register(key, ...args),
        errorMessage: errors[key]?.message,
      };
    };
    return fn;
  }, [register, errors]);

  const { newPassword, confirmNewPassword } = watch();

  return (
    <div className="flex h-full flex-col gap-y-4">
      <Typography variant="xxxl" className="mb-[14px] text-center font-bold">
        {t('auth.resetPassword.changePassword')}
      </Typography>
      <Form
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col gap-y-4 sm:flex-initial"
      >
        <Input
          {...inputRegister('newPassword')}
          label={t('auth.resetPassword.newPassword')}
          placeholder={t('auth.resetPassword.createNewPassword')}
          id="password"
          type="password"
          withBG
        />
        <Input
          {...inputRegister('confirmNewPassword')}
          label={t('auth.resetPassword.confirmPassword')}
          placeholder={t('auth.resetPassword.enterYourNewPassword')}
          id="new-password"
          withBG
          type="password"
        />

        <div className="mt-auto space-y-4 sm:mt-0">
          <Button
            type="submit"
            fullWidth
            className="!mt-9"
            disabled={!newPassword || !confirmNewPassword}
            loading={loading}
          >
            {t('auth.resetPassword.saveChanges')}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              navigate({ to: Routes.auth.sign_in });
            }}
            fullWidth
            disabled={loading}
          >
            {t('dialog.cancel')}
          </Button>
          {maybeRenderError(error)}
        </div>
      </Form>
      <Typography align="center">
        {t('support.doYouNeedHelp')}{' '}
        <a href={HELPCENTER_LINK_EXTERNAL} className="font-bold text-accept-main hover:text-accept-hover">
          {t('support.customerSupport')}
        </a>
      </Typography>
    </div>
  );
};

import { Tooltip } from '@app/components';
import { PaymentMethodDetails } from '@app/domain/billing/PaymentMethodDetails';
import { ALL_CARD_BRANDS } from '@app/utils/payment.utils';
import { UIStyledSection } from '../../components/UIStyledSection';

export const UISectionPaymentMethodDetails: React.FC = () => {
  return (
    <UIStyledSection title="PaymentMethodDetails">
      <div className="flex flex-wrap gap-4 rounded bg-white p-2">
        {CASES.map((e, i) => {
          return (
            <div key={i} className="flex gap-4 p-2 hover:ring-1">
              <Tooltip title={JSON.stringify(e.info)}>
                <div>
                  <PaymentMethodDetails info={e.info} />
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </UIStyledSection>
  );
};

const CASES: React.ComponentProps<typeof PaymentMethodDetails>[] = [
  ...ALL_CARD_BRANDS.map((brand, i) => {
    return {
      info: {
        cardMethod: {
          last4: '4242',
          expMonth: 5 + i,
          expYear: new Date().getFullYear() + i + 1,
          brand,
        },
      },
    };
  }),
  {
    info: {
      cardMethod: {
        last4: '4242',
        expMonth: 12,
        expYear: new Date().getFullYear() + 1,
        brand: 'unknown-or-typo',
      },
    },
  },
  {
    info: {
      sepaMethod: {
        last4: '4242',
        bankCode: 'todo',
        branchCode: 'todo',
        country: 'todo',
      },
    },
  },
];

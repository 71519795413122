import { UserApi } from '@app/domain/user/user.api';
import { getLanguageForApi, LANGUAGE_CODES, LANGUAGE_ITEMS } from '@app/i18n/i18.utils';
import { useStore } from '@app/store/useStore.hook';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionItem, Select } from '../select';

export const LanguageSwitcher: React.FC = () => {
  const {
    i18n: { changeLanguage, resolvedLanguage: rawLanguage },
  } = useTranslation();
  const user = useStore.useCurrentUser();
  const getMe = useStore.useGetMe();
  const patchMe = useStore.usePatchCurrentUser();

  const [effectLanguage, setEffectLanguage] = useState(rawLanguage);
  const resolvedLanguage = user ? LANGUAGE_CODES[user.language] : rawLanguage || rawLanguage;

  useEffect(() => {
    if (!user) {
      return;
    }
    const neededLanguage = getLanguageForApi(effectLanguage);
    if (!neededLanguage || user.language === neededLanguage) {
      return;
    }
    let mounted = true;
    patchMe({ language: neededLanguage });
    UserApi.patchCustomer({
      language: neededLanguage,
    }).then(() => {
      if (!mounted) {
        return true;
      }
      // refresh in background for eventual stability
      getMe();
    });
    return () => {
      mounted = false;
    };
  }, [user, effectLanguage, getMe, patchMe]);

  return (
    <div className="flex items-center">
      <Select
        value={resolvedLanguage}
        onChange={(event) => {
          user ? setEffectLanguage(event.target.value) : changeLanguage(event.target.value);
        }}
      >
        {LANGUAGE_ITEMS.map((lng) => (
          <OptionItem value={lng.value} key={lng.value}>
            <div className="flex items-center gap-3">
              {lng.icon}
              {lng.title}
            </div>
          </OptionItem>
        ))}
      </Select>
    </div>
  );
};

import { Container, Input, Typography } from '@app/components';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { Controller, useFormContext } from 'react-hook-form';

export const QRCodeAddress: React.FC = () => {
  const { control, trigger } = useFormContext<QRCodeFormSchema>();

  return (
    <Container className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:rounded-xl">
      <Typography className="font-bold sm:text-l md:col-span-2">Address</Typography>
      <Controller
        name="vcard.street"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            label="Street"
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.street');
              }
              onChange(e);
            }}
            withBG
            placeholder="e.g. Spring Avenue 9/18"
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
      <Controller
        name="vcard.postalCode"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            label="Postal code"
            withBG
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.postalCode');
              }
              onChange(e);
            }}
            placeholder="e.g. 10005"
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
      <Controller
        name="vcard.city"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            label="City"
            withBG
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.city');
              }
              onChange(e);
            }}
            placeholder="e.g. New York City"
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
      <Controller
        name="vcard.state"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            label="State"
            withBG
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.state');
              }
              onChange(e);
            }}
            placeholder="e.g. New York "
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
      <Controller
        name="vcard.country"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            label="Country"
            withBG
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.country');
              }
              onChange(e);
            }}
            placeholder="e.g. United States"
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
    </Container>
  );
};

import { checkTypeCompliance } from '@app/utils/type.utils';
import { array, InferType, number, object, string } from 'yup';
import { BriefGenerateOutDto } from './brief.type';

export const BriefGenerateOutDtoSchema = object({
  totalRows: number().default(0),
  spreadsheetErrors: array(
    object({
      rowIndex: number().required(),
      error: string().required(),
    })
  ).default([]),
  // IPagedError
  pagedErrors: array(
    object({
      pageIndex: number().required(),
      row: number(),
      col: number(),
      // IData
      data: object({
        companyName: string().required(),
        streetName: string().required(),
        postCode: string().required(),
        name1: string().required(),
        name2: string().required(),
        logoUrl: string().required(),
      }),
      errors: array(string().required()).default([]),
    })
  ).default([]),
  persistedFiles: array(
    object({
      name: string().required(),
      bytes: number().default(0),
    })
  ).default([]),
});
export type BriefGenerateOutDtoSchema = InferType<typeof BriefGenerateOutDtoSchema>;
checkTypeCompliance<BriefGenerateOutDto, BriefGenerateOutDtoSchema>();

import { useCallback } from 'react';
import { withDefault, NumberParam, StringParam } from 'use-query-params';

import { useQueryParamsPrefix } from '@app/hooks/useQueryParamsPrefix.hook';

interface InitialValues {
  page?: number;
  search?: string;
  sort?: string;
  size?: number;
}
interface Args {
  initialValues?: InitialValues;
  prefix?: string;
}

export const useBasicQueryControls = ({ initialValues, prefix }: Args) => {
  const [query, setQuery] = useQueryParamsPrefix({
    paramConfigMap: {
      page: withDefault(NumberParam, initialValues?.page),
      search: withDefault(StringParam, initialValues?.search),
      sort: withDefault(StringParam, initialValues?.sort),
      size: withDefault(NumberParam, initialValues?.size),
    },
    prefix,
  });

  const handlePageChange = useCallback(
    (newPage: number) => {
      setQuery({ page: newPage }, 'pushIn');
    },
    [setQuery]
  );

  const handleSizeChange = useCallback(
    (newSize: number) => {
      setQuery({ size: newSize }, 'replaceIn');
    },
    [setQuery]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setQuery({ search: value.length ? value : undefined, page: initialValues?.page || 1 }, 'replaceIn');
    },
    [initialValues?.page, setQuery]
  );

  const handleSortChange = useCallback(
    (value: string) => {
      setQuery({ sort: value }, 'replaceIn');
    },
    [setQuery]
  );

  return {
    values: {
      page: query.page,
      search: query.search,
      sort: query.sort,
      size: query.size,
    },
    handlers: {
      handlePageChange,
      handleSearchChange,
      handleSortChange,
      handleSizeChange,
    },
  };
};

import { Typography } from '@mui/material';

import { ApiValidationError } from '@app/services/error-manager';
import axios from 'axios';

export const maybeRenderError = (error?: Error | null) => {
  if (!error) {
    return;
  }
  let msg = 'Something Went Wrong';
  if (error instanceof ApiValidationError) {
    msg = error.errors[0]?.message || msg;
  } else {
    const maybeResponseError = String(axios.isAxiosError(error) ? error.response?.data.error : '');
    msg = maybeResponseError || error.message || msg;
  }
  // TODO variant error
  return (
    <div className="flex justify-center">
      <Typography className="text-center text-xs font-medium text-red-500" style={{ wordBreak: 'break-word' }}>
        {msg}
      </Typography>
    </div>
  );
};

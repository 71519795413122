import { ClockIcon, DeleteIcon, EditIcon, WebsiteUrlIcon } from '@app/assets';
import { Button, Checkbox, Container, IconButton } from '@app/components';
import { Divider } from '@mui/material';
import { QRCodeOutDtoOverridden } from '../qr-code.types';
import { renderFriendlyQRCodeTypes } from '@app/utils/render-friendly-text.utils';
import { format } from 'date-fns';
import { FORMAT } from '@app/constants/formats';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { ChangeQRCodeNameModal } from './modals/ChangeQRCodeNameModal';
import { extractStylePropsFromQRDesign, getQRfileNameForDownload } from '../qr-code.utils';
import { ECodeType } from '@app/swagger-types';
import { QRCodeRenderer, QRRendererProps } from './QRCodeRenderer';
import { clsxm } from '@app/styles/clsxm';
import { useTranslation } from 'react-i18next';
import { DownloadQRCodeModal } from './modals/DownloadQRCodeModal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TypedLink } from '@app/router';
import { Routes } from '@app/constants/routes';
import { ANALYTICS_QUERY_PARAM_QR_IDS } from '@app/domain/analytics/analytics.url.hooks';

// TODO after release
// import { LabelIcon } from '@app/assets';

export const QRCodeContainer: React.FC<{
  qrCode: QRCodeOutDtoOverridden;
  handleDeleteQRCode: (id: string) => void;
  handleSelectQRCode?: (qrCode: QRCodeOutDtoOverridden, selected: boolean) => void;
  handleNavigate: (qrCodeId: string) => void;
  selected?: boolean;
  className?: string;
}> = ({ qrCode, handleDeleteQRCode, handleSelectQRCode, handleNavigate, selected = false, className }) => {
  const { t } = useTranslation();
  const handleSelectCheckbox = useCallback(() => {
    if (!handleSelectQRCode) {
      return;
    }
    handleSelectQRCode(qrCode, !selected);
  }, [handleSelectQRCode, qrCode, selected]);

  const isSelectable = Boolean(handleSelectQRCode);

  const rendererProps: QRRendererProps = {
    qrId: qrCode.id,
    ...(qrCode.design ? extractStylePropsFromQRDesign(qrCode.design) : undefined),
    publicLogoType: qrCode.publicLogoType,
    fileName: getQRfileNameForDownload(qrCode.id, qrCode.name),
  };

  return (
    <Container rounded className={clsxm('grid grid-cols-1 gap-3 p-0 lg:grid-cols-[1fr_2px_auto] lg:gap-0', className)}>
      <div className="flex gap-2 p-4 lg:items-center">
        {isSelectable && (
          <div className="hidden lg:block">
            <Checkbox checked={selected} onChange={handleSelectCheckbox} />
          </div>
        )}
        {
          <div className="w-[100px]">
            <QRCodeRenderer {...rendererProps} />
          </div>
        }
        <div className="flex flex-1 flex-col gap-2 text-xs text-gray-500">
          <div className="flex justify-between gap-4">
            <div>
              <div className="text-xxs text-primary">{renderFriendlyQRCodeTypes(qrCode.codeType)}</div>
              <div className="mt-1 flex items-center gap-1 font-bold text-gray-800">
                <span>{qrCode.name}</span>
                <IconButton
                  onClick={() => {
                    NiceModal.show(ChangeQRCodeNameModal, { name: qrCode.name, qrCodeId: qrCode.id });
                  }}
                  size="small"
                  className="text-gray-500 hover:text-gray-800"
                  variant="transparent"
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
            {isSelectable && (
              <div className="lg:hidden">
                <Checkbox checked={selected} onChange={handleSelectCheckbox} />
              </div>
            )}
          </div>
          {qrCode.codeType === ECodeType.WEBSITE ? (
            <div className="flex items-center gap-2 overflow-hidden">
              <div className="flex items-center gap-2">
                <WebsiteUrlIcon className="h-4 min-h-[16px] w-4 min-w-[16px] text-gray-700" />
                <span className="overflow-hidden" style={{ wordBreak: 'break-word' }}>
                  {qrCode.website?.url || ''}
                </span>
              </div>
            </div>
          ) : null}

          <div className="flex items-center gap-2">
            <ClockIcon className="min-h-4 min-w-4 h-4 w-4 text-gray-700" />
            {format(new Date(qrCode.createdDate), FORMAT.DATE_YEAR)}
          </div>
        </div>
      </div>
      <Divider flexItem className="lg:hidden" />
      <Divider flexItem orientation="vertical" className="hidden py-4 lg:block" />
      <div className="flex flex-col justify-between gap-3 pb-4 lg:py-4">
        <div className="flex flex-1 items-center justify-between gap-4 px-3 lg:px-12">
          <div className="flex items-center rounded-[10px] bg-gray-100 px-3 py-2 text-xxs text-gray-500">
            {t('qr.scans')}
            <span className="ml-4 text-m font-bold text-gray-800">{qrCode.amountOfScans || 0}</span>
          </div>
          <TypedLink to={Routes.customer.analytics.index} queryParams={{ [ANALYTICS_QUERY_PARAM_QR_IDS]: qrCode.id }}>
            <Button className="lg:px-1" endIcon={<ArrowForwardIcon />} color="secondary" variant="text">
              {t('qrAnalytics.pageTitle')}
            </Button>
          </TypedLink>
        </div>
        <Divider flexItem />
        <div className="flex flex-1 items-center justify-between gap-4 px-3 lg:px-12">
          <div className="flex items-center gap-2 sm:gap-4">
            <IconButton
              size="small"
              onClick={() => {
                handleNavigate(qrCode.id);
              }}
              variant="transparent"
            >
              <EditIcon />
            </IconButton>
            {/* TODO implement after release */}
            {/* <IconButton size="small" disabled variant="transparent">
              <LabelIcon />
            </IconButton> */}

            <ConfirmDialog
              trigger={
                <IconButton size="small" variant="transparent">
                  <DeleteIcon />
                </IconButton>
              }
              onConfirm={() => {
                handleDeleteQRCode(qrCode.id);
              }}
              containerClassName="max-w-[350px] text-center"
              title={`${t('dialog.deleteQuestionTitle')} ${t('qr.entityName')}?`}
              text={`${t('dialog.areYouSureToDeleteThis')} ${t('qr.entityName')}?"`}
              confirmText={t('dialog.yesDelete')}
            />
          </div>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              NiceModal.show(DownloadQRCodeModal, { rendererProps });
            }}
          >
            {t('qr.buttonDownload')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

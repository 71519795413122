import { Tooltip } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ComposableMap, Geographies, Geography, Marker, ProjectionConfig } from 'react-simple-maps';

export interface IWorldMapLocation {
  city: string;
  cityLatitude: number;
  cityLongitude: number;
  totalScans: number;
  topLocations?: IWorldMapLocation[];
  hasMoreLocations?: boolean;
}

export const QRAnalyticsMapBare: React.FC<{
  locations: IWorldMapLocation[];
  className?: string;
}> = ({ locations, className }) => {
  const { t } = useTranslation();
  const memoCalc = useMemo(() => {
    const fit = calcCenterAndZoom(locations);
    const projectionConfig: ProjectionConfig = {
      // scale: 180, // 180 is full map fit, bigger number will zoom closer
      scale: fit.scale,
      center: fit.center,
    };
    // console.log('memo locations', locations, projectionConfig, fit);
    const markers: IWorldMapLocation[] = [];
    const mergedIndices: number[] = [];
    for (let i = 0; i < locations.length; i++) {
      if (mergedIndices.includes(i)) {
        continue;
      }
      const me = locations[i];
      if (!me) {
        continue;
      }
      let hasMerges = false;
      const group: IWorldMapLocation[] = [me];
      for (let j = i + 1; j < locations.length; j++) {
        if (mergedIndices.includes(j)) {
          continue;
        }
        const other = locations[j];
        if (!other) {
          continue;
        }
        const distance = calcDistance(me, other);
        if (distance < 5) {
          hasMerges = true;
          mergedIndices.push(j);
          group.push(other);
        }
      }
      if (hasMerges) {
        mergedIndices.push(i);
        // console.log('to merge group', group);
        markers.push(createMarker(group));
      }
    }
    markers.push(...locations.filter((_, i) => !mergedIndices.includes(i)));
    markers.sort((a, b) => (a.totalScans < b.totalScans ? 1 : -1));
    const maxScans = markers.reduce((a, v) => Math.max(a, v.totalScans), 1);
    const MIN_SIZE = 8;
    const MAX_SIZE = 15;
    const calcMarkerSize = (markerScans: number) => {
      const delta = MAX_SIZE - MIN_SIZE;
      const res = MIN_SIZE + (markerScans / maxScans) * delta;
      if (res < MIN_SIZE) {
        return MIN_SIZE;
      }
      if (res > MAX_SIZE) {
        return MAX_SIZE;
      }
      return res;
    };
    return { calcMarkerSize, projectionConfig, fit, markers };
  }, [locations]);

  return (
    <div className={clsxm('relative w-full', className)}>
      <ComposableMap projectionConfig={memoCalc.projectionConfig}>
        <Geographies geography={'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json'}>
          {({ geographies }: any) =>
            geographies.map((geo: any) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                className="fill-gray-200 stroke-white hover:fill-accept-light focus:outline-none"
              />
            ))
          }
        </Geographies>
        {memoCalc.markers.map(
          ({ city, cityLatitude, cityLongitude, totalScans, topLocations, hasMoreLocations }, i) => (
            <Marker key={i} coordinates={[cityLongitude, cityLatitude]}>
              <Tooltip
                disableInteractive
                title={
                  <div className="flex flex-col gap-2 text-m">
                    <div className="font-bold">Total scans{topLocations ? `: ${totalScans}` : null}</div>
                    {topLocations ? (
                      topLocations.map((e, i) => (
                        <div key={i}>
                          {e.city}: {e.totalScans}
                        </div>
                      ))
                    ) : (
                      <div>
                        {city}: {totalScans}
                      </div>
                    )}
                    {hasMoreLocations && <div className="font-normal italic">{t('qrAnalytics.andOthers')}</div>}
                  </div>
                }
                classes={TOOLTIP_STYLE}
              >
                <circle
                  r={memoCalc.calcMarkerSize(totalScans)}
                  fill={topLocations ? '#0a5d04' : '#2C9424'}
                  stroke={topLocations ? '#abd7a8' : '#fff'}
                  strokeWidth={2}
                />
              </Tooltip>
            </Marker>
          )
        )}
      </ComposableMap>
    </div>
  );
};

const TOOLTIP_STYLE = { tooltip: /*tw*/ 'bg-accept-dark-blue text-white' } as const;

const calcCenterAndZoom = (locations: IWorldMapLocation[]) => {
  let minLng = Infinity;
  let maxLng = -Infinity;
  let minLat = Infinity;
  let maxLat = -Infinity;
  locations.forEach((e) => {
    minLng = Math.min(e.cityLongitude, minLng);
    maxLng = Math.max(e.cityLongitude, maxLng);
    minLat = Math.min(e.cityLatitude, minLat);
    maxLat = Math.max(e.cityLatitude, maxLat);
  });
  const deltaLng = maxLng - minLng;
  const deltaLat = maxLat - minLat;
  const centerLng = minLng + deltaLng / 2;
  const centerLat = minLat + deltaLat / 2;
  const center: [number, number] = [centerLng, centerLat];
  const scale = 180 + (1 - Math.max(deltaLng, deltaLat) / 360) * 90;
  return { center, scale };
};

const calcDistance = (a: IWorldMapLocation, b: IWorldMapLocation) =>
  Math.sqrt((a.cityLongitude - b.cityLongitude) ** 2 + (a.cityLatitude - b.cityLatitude) ** 2);

/**
 * merge multiple locations into a single marker
 */
const createMarker = (group: IWorldMapLocation[]): IWorldMapLocation => {
  const first = group[0];
  if (!first) {
    throw new Error('group for marker is empty');
  }
  const res = { ...first };
  let totalScans = 0;
  let minLng = Infinity;
  let minLat = Infinity;
  let maxLng = -Infinity;
  let maxLat = -Infinity;
  for (let i = 0; i < group.length; i++) {
    const e = group[i];
    totalScans += e.totalScans;
    minLng = Math.min(minLng, e.cityLongitude);
    minLat = Math.min(minLat, e.cityLatitude);
    maxLng = Math.max(maxLng, e.cityLongitude);
    maxLat = Math.max(maxLat, e.cityLatitude);
  }
  res.totalScans = totalScans;
  res.cityLongitude = minLng + (maxLng - minLng) / 2;
  res.cityLatitude = minLat + (maxLat - minLat) / 2;
  res.hasMoreLocations = group.length > MAX_TOP_LOCATIONS;
  res.topLocations = group.slice(0, MAX_TOP_LOCATIONS).sort((a, b) => (a.totalScans < b.totalScans ? 1 : -1));
  return res;
};
const MAX_TOP_LOCATIONS = 5;

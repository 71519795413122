import { clsxm } from '@app/styles/clsxm';
import { getStepTranslationKey } from '@app/i18n/i18.utils';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TEMPLATE_STEPPER_STEPS_TYPE } from './TemplateStepper';

export const TemplateStep: React.FC<{
  isActive: boolean;
  step: TEMPLATE_STEPPER_STEPS_TYPE;
  onChange: (step: TEMPLATE_STEPPER_STEPS_TYPE) => void;
}> = ({ isActive, step, onChange }) => {
  const { t } = useTranslation();

  const tKey = getStepTranslationKey(step);
  if (!tKey) {
    return null;
  }

  return (
    <Box
      className={clsxm(
        'flex-1 rounded-lg border border-gray-200 py-2 px-[2px] text-center text-xs font-semibold text-slate-500 transition-all',
        'hover:cursor-pointer hover:border-accept-hover sm:px-4',
        isActive && 'border-accept-main bg-accept-light text-accept-text'
      )}
      onClick={() => onChange(step)}
    >
      {t(tKey)}
    </Box>
  );
};

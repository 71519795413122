import { Button, Container } from '@app/components';
import { IWorldMapLocation, QRAnalyticsMapBare } from '@app/domain/analytics/components/QRAnalyticsMapBare';
import { clsxm } from '@app/styles/clsxm';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UIStyledSection } from '../../components/UIStyledSection';
import { MOCK_CITIES } from './world-map.data';

export const UISectionWorldMap: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [rerender, setRerender] = useState(0);
  const [showBorder, setShowBorder] = useState(false);

  const memoData = useMemo(() => {
    void rerender;
    return regenMockData();
  }, [rerender]);

  return (
    <UIStyledSection title="Line charts">
      <div className="flex gap-4">
        <Button
          onClick={() => {
            regenMockData();
            setRerender((i) => i + 1);
          }}
        >
          randomize data
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setIsLoading((v) => !v);
          }}
        >
          toggle loader
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setShowBorder((v) => !v);
          }}
        >
          toggle border
        </Button>
      </div>
      <Container
        className="flex min-h-[600px] flex-col gap-4 lg:rounded-xl"
        isLoading={isLoading}
        title={t('qrAnalytics.scansByLocation')}
      >
        {!isLoading && (
          <QRAnalyticsMapBare locations={memoData} className={clsxm('mx-auto max-w-2xl', showBorder && 'ring-1')} />
        )}
      </Container>
    </UIStyledSection>
  );
};

const regenMockData = () => {
  const mockData: IWorldMapLocation[] = [];
  const isUseRealCoordinates = Math.random() > 0.5;
  const length = isUseRealCoordinates
    ? Math.floor(1 + Math.random()) * MOCK_CITIES.length
    : 1 + Math.floor(Math.random() * MOCK_CITIES.length) * 5;
  const scale = 0.1 + Math.random() * 0.9;
  const shiftX = (2 * Math.random() - 1) * (1 - scale) * 180;
  const shiftY = (2 * Math.random() - 1) * (1 - scale) * 90;
  for (let i = 0; i < length; i++) {
    const city = isUseRealCoordinates ? MOCK_CITIES[i] : MOCK_CITIES[Math.floor(Math.random() * MOCK_CITIES.length)];
    if (!city) {
      throw new Error('city index out of range');
    }
    const x = isUseRealCoordinates
      ? city.longitude
      : 2 * (i / length - 0.5) * 180 * 0.8 * (2 * Math.random() - 1) * scale + shiftX;
    const y = isUseRealCoordinates
      ? city.latitude
      : 2 * (i / length - 0.5) * 90 * 0.9 * (2 * Math.random() - 1) * scale + shiftY;
    mockData.push({
      city: city.city,
      // -90, 90
      cityLatitude: y,
      // -180, 180
      cityLongitude: x,
      totalScans: 1 + Math.floor(Math.random() * 100),
    });
  }
  mockData.sort((a, b) => (a.totalScans < b.totalScans ? 1 : -1));
  // console.log('mock map data', MOCK_DATA);
  return mockData;
};

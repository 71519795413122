import { validateURL } from '@app/utils/url.util';
import { InferType, object, string } from 'yup';

export const WebsiteURLFormSchema = object({
  websiteUrl: string()
    .required('Required field')
    .test('invalid-website-url', 'Invalid website url', (websiteUrl) => {
      return validateURL(websiteUrl || '').isValid;
    }),
});
export type WebsiteURLFormSchema = InferType<typeof WebsiteURLFormSchema>;

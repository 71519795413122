import { FeedbackSuccessManWithFlagSvg } from '@app/assets/feedback';
import { Button, Container } from '@app/components';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { Routes } from '@app/constants/routes';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { CurrentSubscriptionTitleStatus } from '@app/domain/user/components/subscription/CurrentSubscriptionTitleStatus';
import { SubscriptionPriceContainer } from '@app/domain/user/components/subscription/SubscriptionPriceContainer';
import { gtag } from '@app/gtag/gtag';
import { useTypedNavigate } from '@app/router';
import { useStore } from '@app/store/useStore.hook';
import { clsxm } from '@app/styles/clsxm';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const AccountSuccessPaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const { plan, isExpired, isTrial } = useActivePlan();
  const user = useStore.useCurrentUser();
  const getMe = useStore.useGetMe();
  const navigate = useTypedNavigate();

  const isPaymentPending = !user || user.currentSubscriptionId === user.previousSubscriptionId || isTrial || isExpired;

  useEffect(() => {
    if (!isPaymentPending) {
      return;
    }
    let mounted = true;
    setTimeout(() => {
      getMe();
    }, 1e3);
    const loop = async () => {
      if (!mounted) {
        return;
      }
      getMe().finally(() => {
        if (!mounted) {
          return;
        }
        setTimeout(loop, 1e3);
      });
    };
    loop();
    return () => {
      mounted = false;
    };
  }, [isPaymentPending, getMe]);

  useEffect(() => {
    if (isPaymentPending) {
      return;
    }
    const price = plan?.price;
    if (!plan || !user || !price) {
      return;
    }
    gtag.purchase({
      user_email: user.email,
      user_id: user.id,
      ecommerce: {
        invoice_id: plan.lastInvoiceId,
        value: price.price ?? 0,
        currency: price.planCurrency,
        items: [
          {
            item_id: plan.id,
            item_name: `${String(plan.name).toUpperCase()}_${price.planCycle}_${price.planCurrency}`,
            price: price.price ?? 0,
            quantity: 1,
          },
        ],
      },
    });
  }, [plan, user, isPaymentPending]);

  const renderGreetings = () => {
    if (isPaymentPending) {
      return <ContentLoader loading />;
    }
    return (
      <>
        <div className="flex justify-center">
          <FeedbackSuccessManWithFlagSvg className="max-w-full" />
        </div>
        <div className="text-xl font-bold">{t('subscription.subscribedToSuccessfuly', { planName: plan?.name })}</div>
      </>
    );
  };

  return (
    <div className="h-full px-0 py-6 lg:px-6">
      <Container rounded className={clsxm('h-full w-full rounded-none lg:rounded-xl')}>
        <div className="flex h-full flex-col items-center justify-between">
          <div className="flex w-full flex-col justify-between gap-4 rounded p-2 py-3 ring-1 ring-gray-200 lg:flex-row">
            {isPaymentPending ? (
              <ContentLoader loading />
            ) : (
              <>
                <CurrentSubscriptionTitleStatus className="text-xxl" />
                {plan && <SubscriptionPriceContainer planPrice={plan.price} />}
              </>
            )}
          </div>
          <div className="flex flex-col gap-8">
            {renderGreetings()}
            <Button
              disabled={isPaymentPending}
              onClick={() => {
                navigate({
                  to: Routes.customer.account.subscription,
                  replace: true,
                });
              }}
            >
              {t('dialog.continue')}
            </Button>
          </div>
          <div />
        </div>
      </Container>
    </div>
  );
};

import { Box, Collapse, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { EQRCodeSort } from '../../qr-code.types';
import { useTranslation } from 'react-i18next';

export const QRCodeMobileSort: React.FC<{ sortValue: string; handleSortValue: (v: string) => void }> = ({
  sortValue,
  handleSortValue,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  return (
    <div>
      <Box
        onClick={() => {
          setOpen((prev) => !prev);
        }}
        className="flex items-center justify-between gap-4 hover:cursor-pointer"
      >
        <Typography className="font-semibold">{t('search.sortBy')}</Typography>
        <span>
          <KeyboardArrowDownIcon className={clsxm('text-8 transition-all', !open && '-rotate-90')} />
        </span>
      </Box>
      <Collapse in={open} className="pl-2">
        <RadioGroup
          className="mt-2"
          value={sortValue}
          onChange={(e) => {
            handleSortValue(e.currentTarget.value);
          }}
        >
          <FormControlLabel
            classes={{ label: clsxm('text-s text-gray-500', sortValue === EQRCodeSort.MOST_RECENT && 'text-gray-800') }}
            value={EQRCodeSort.MOST_RECENT}
            control={<Radio />}
            label={t('search.mostRecent')}
          />
          <FormControlLabel
            classes={{ label: clsxm('text-s text-gray-500', sortValue === EQRCodeSort.NAME_A_Z && 'text-gray-800') }}
            value={EQRCodeSort.NAME_A_Z}
            control={<Radio />}
            label={t('search.nameAZ')}
          />
          <FormControlLabel
            classes={{
              label: clsxm('text-s text-gray-500', sortValue === EQRCodeSort.MOST_SCANNED && 'text-gray-800'),
            }}
            value={EQRCodeSort.MOST_SCANNED}
            control={<Radio />}
            label={t('search.mostScanned')}
          />
          <FormControlLabel
            classes={{
              label: clsxm('text-s text-gray-500', sortValue === EQRCodeSort.LAST_CREATED && 'text-gray-800'),
            }}
            value={EQRCodeSort.LAST_CREATED}
            control={<Radio />}
            label={t('search.lastCreated')}
          />
        </RadioGroup>
      </Collapse>
    </div>
  );
};

import { Routes } from '@app/constants/routes';

import { AuthStatus } from '@app/auth/api/auth.type';
import { MyRouteObject } from '@app/models/router';

import { AuthModule } from './AuthModule';
import { ForgotPassword } from './pages/ForgotPassword.page';
import { SignInPage } from './pages/SignIn.page';
import { SignUp } from './pages/SignUp.page';
import { TypedNavigate } from '@app/router';
import { ResetPassword } from './pages/ResetPassword.page';
import { OAuth2AuthorizationPage } from './pages/OAuth2Authorization.page';
import { ExpiredQRPage } from './pages/ExpiredQRPage';
import { ClaimQRPage } from './pages/ClaimQRPage';

export const AuthRoutes: MyRouteObject = {
  path: Routes.auth.index,
  element: <AuthModule />,
  authStatus: AuthStatus.UNAUTHORIZED,
  children: [
    { path: Routes.auth.sign_in, element: <SignInPage /> },
    { path: Routes.auth.sign_up, element: <SignUp /> },
    { path: Routes.auth.forgot_password, element: <ForgotPassword /> },
    { path: Routes.auth.reset_password, element: <ResetPassword /> },
    { path: Routes.auth.oauth2_authorization, element: <OAuth2AuthorizationPage /> },

    // default
    { index: true, path: '*', element: <TypedNavigate to={Routes.auth.sign_in} /> },
  ],
};

/**
 * for routes accessible by any auth state
 */
export const AuthPublicRoutes: MyRouteObject = {
  path: Routes.authSpecial.index,
  element: <AuthModule />,
  children: [
    // qr related
    { path: Routes.authSpecial.claim_qr, element: <ClaimQRPage /> },
    { path: Routes.authSpecial.expired_qr, element: <ExpiredQRPage /> },
  ],
};

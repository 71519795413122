import { Button, Container, PlaceholderBox, Typography } from '@app/components';
import { useDeleteQRCode, useGetQRCodes } from '@app/domain/qr-code/api/qr-code.hooks.api';
import { QRCodeContainer } from '@app/domain/qr-code/components/QRCodeContainer';
import { clsxm } from '@app/styles/clsxm';
import { useHandler } from '@app/hooks/useHandler.hook';
import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { toast } from 'react-toastify';
import { FiRrArrowSmallRight } from '@app/assets';
import { QRCodeOutDto } from '@app/swagger-types';
import { buildSortParam } from '@app/utils/api.util';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { useTranslation } from 'react-i18next';

export const MyQRCodesDashboard: React.FC = () => {
  const { t } = useTranslation();
  const { data: qrCodes, isFetching } = useGetQRCodes({
    page: 0,
    sort: buildSortParam<QRCodeOutDto>('createdDate', 'desc'),
    size: 4,
  });
  const navigate = useTypedNavigate();

  const handleEditNavigate = useHandler((qrCodeId: string) => {
    navigate({ to: Routes.customer.my_qr_codes.edit, params: { id: qrCodeId }, preserveQueryParams: true });
  });

  const { mutate: deleteQRCode, isLoading } = useDeleteQRCode();

  const handleDeleteQRCode = useHandler((id: string) => {
    deleteQRCode(id, {
      onSuccess: () => {
        toast.success(t('toaster.qrDeleted'));
      },
    });
  });

  const showLoader = (isFetching || isLoading) && !qrCodes;

  return (
    <Container className="flex flex-col gap-4 lg:rounded-xl">
      <Typography className="font-bold sm:text-l">{t('myQRCodes.pageTitle')}</Typography>
      <div className="relative flex flex-1 flex-col gap-4">
        {!qrCodes?.total && !isLoading ? (
          // TODO show link to create QR code
          <PlaceholderBox variant="primary" title={t('placeholder.noQRsCreated')} />
        ) : null}

        {showLoader ? (
          <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
            <ContentLoader isEmpty={false} loading entity="QR Codes" />
          </div>
        ) : null}

        <div className={clsxm('flex flex-col gap-4', showLoader && 'pointer-events-none opacity-80')}>
          {qrCodes?.result.map((qrCode) => (
            <QRCodeContainer
              key={qrCode.id}
              qrCode={qrCode}
              handleDeleteQRCode={handleDeleteQRCode}
              handleNavigate={handleEditNavigate}
              className="border border-gray-200"
            />
          ))}
        </div>

        <div className="flex justify-end">
          <a href={Routes.customer.my_qr_codes.index} onClick={(e) => e.preventDefault()} aria-hidden>
            <Button
              className="group"
              endIcon={<FiRrArrowSmallRight className="fill-accept-main group-hover:fill-accept-hover" />}
              color="primary"
              variant="text"
              onClick={() => {
                navigate({ to: Routes.customer.my_qr_codes.index });
              }}
            >
              {t('myQRCodes.viewAllQRCodes')}
            </Button>
          </a>
        </div>
      </div>
    </Container>
  );
};

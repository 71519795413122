import { toNumber } from 'lodash';

export const parseNumbersFromString = (s: string) => toNumber(s.replace(/[^\d.-]/g, ''));

export const capitalizeWords = (input: string) => {
  const words = input.split(' ');
  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return '';
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(' ');
};

import { UIStyledSection } from '../components/UIStyledSection';
import { Autocomplete, Checkbox, OptionItem, Select } from '@app/components';
import { MenuItem } from '@mui/material';
import { useState } from 'react';

export const UITabSelects: React.FC = () => {
  const [values, setValues] = useState<{ value: string; label: string }[]>([]);

  return (
    <UIStyledSection title="UISectionSelects">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-3 items-end gap-4 pb-6">
          <Select
            multiple
            defaultValue={[]}
            inputProps={{
              label: 'Multiple select with label',
            }}
          >
            <OptionItem value={1}>Option 1</OptionItem>
            <OptionItem value={2}>Option 2</OptionItem>
            <OptionItem value={3}>Option 3</OptionItem>
          </Select>
          <Select
            size="large"
            defaultValue={1}
            inputProps={{
              label: 'Large select with label',
            }}
          >
            <OptionItem value={1}>Option 1</OptionItem>
            <OptionItem value={2}>Option 2</OptionItem>
            <OptionItem value={3}>Option 3</OptionItem>
          </Select>
          <Select size="small">
            <OptionItem value={1}>Option 1</OptionItem>
            <OptionItem value={2}>Option 2</OptionItem>
            <OptionItem value={3}>Option 3</OptionItem>
          </Select>
        </div>
        <div className="grid grid-cols-3 items-end gap-4 pb-6">
          <Autocomplete
            className="my-1"
            browserAutocompleteOff
            disableCloseOnSelect
            InputProps={{
              placeholder: 'placeholder...',
              label: 'Autocomplete',
            }}
            isOptionEqualToValue={({ value: optionValue }, { value }) => optionValue === value}
            getOptions={async () => [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
            ]}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} selected={selected}>
                {option.label}
              </MenuItem>
            )}
          />
          <Autocomplete
            multiple
            browserAutocompleteOff
            disableCloseOnSelect
            value={values}
            InputProps={{
              placeholder: 'Placeholder...',
              label: 'multiple autocomplete',
              classes: { root: 'flex-wrap' },
            }}
            isOptionEqualToValue={({ value: optionValue }, { value }) => optionValue === value}
            onChange={(_, value) => {
              setValues(value || []);
            }}
            getOptions={async () => [
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
            ]}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} selected={selected}>
                <Checkbox label={option.label} checked={selected} disableRipple className="scale-75 py-0" />
              </MenuItem>
            )}
          />
        </div>
      </div>
    </UIStyledSection>
  );
};

import {
  CirclesPatternIcon,
  Merged1PatternIcon,
  Merged2PatternIcon,
  RoundedLine1PatternIcon,
  RoundedLine2PatternIcon,
  SquarePatternIcon,
} from '@app/assets';
import { clsxm } from '@app/styles/clsxm';
import { EPatternType } from '@app/swagger-types';
import { Box } from '@mui/material';
import styles from './Pattern.module.scss';

// TODO remove unsupported types
// TODO remove files
// import {
//   CircleLinePatternIcon,
//   DifferentCirclesPatternIcon,
//   MediumRoundedLinePatternIcon,
//   SmallCirclesPatternIcon,
// } from '@app/assets';
// [EPatternType.CIRCLE_LINE]: void (<CircleLinePatternIcon />),
// [EPatternType.DIFFERENT_CIRCLES]: void (<DifferentCirclesPatternIcon />),
// [EPatternType.MEDIUM_ROUNDED_LINE]: void (<MediumRoundedLinePatternIcon />),
// [EPatternType.SMALL_CIRCLES]: void (<SmallCirclesPatternIcon />),

const PATTERN_ICON_BY_CODE = {
  [EPatternType.DOTS]: <CirclesPatternIcon />,
  [EPatternType.CLASSY]: <Merged1PatternIcon />,
  [EPatternType.CLASSY_ROUNDED]: <Merged2PatternIcon />,
  [EPatternType.EXTRA_ROUNDED]: <RoundedLine1PatternIcon />,
  [EPatternType.ROUNDED]: <RoundedLine2PatternIcon />,
  [EPatternType.SQUARE]: <SquarePatternIcon />,
};

export const PatternContainer: React.FC<{
  type: EPatternType;
  onClick: (value: EPatternType) => void;
  selected?: boolean;
  className?: string;
}> = ({ type, selected, onClick, className }) => {
  const icon = PATTERN_ICON_BY_CODE[type];
  if (!icon) {
    return null;
  }

  return (
    <Box
      className={clsxm(
        styles.root,
        'rounded-xl border-[1.5px] border-transparent bg-gray-100 p-4 sm:p-5',
        'hover:cursor-pointer',
        selected && 'border-accept-main bg-white',
        className
      )}
      onClick={() => {
        onClick(type);
      }}
    >
      {icon}
    </Box>
  );
};

import { Button, Typography } from '@app/components';
import { PlanLimitOutDto } from '@app/swagger-types';
import { Collapse } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlanLimitOutDtoKey } from '../..';
import { SUBSCRIPTION_PLAN_LIMIT_KEYS } from '../../user.const';
import { IncludeItem } from './IncludeItem';

export const SubscriptionPlanIncludes: React.FC<{
  planLimit: PlanLimitOutDto;
  isShowAllFeatures: boolean;
  setIsShowAllFeatures: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ planLimit, isShowAllFeatures, setIsShowAllFeatures }) => {
  const { t } = useTranslation();

  const memoFeatures = useMemo(() => {
    const variants: Record<PlanLimitOutDtoKey, string> = {
      ['dedicatedCustomerSuccessManager']: t('subscription.feature.dedicatedCustomerSuccessManager'),
      ['emailSupport']: t('subscription.feature.emailSupport'),
      ['knowledgeBase']: t('subscription.feature.knowledgeBase'),
      ['ccpaCompliant']: t('subscription.feature.ccpaCompliant'),
      ['gdprCompliant']: t('subscription.feature.gdprCompliant'),
      ['hasBulkQrCodeGeneration']: t('subscription.feature.bulkQRCodeGeneration'),
      ['hasQrDesignCustomisations']: t('subscription.feature.qrDesignCustomisations'),
      ['hasTrackScanGPS']: t('subscription.feature.tracksScansGPSCoordinates'),
      ['hasTrackScanDevice']: t('subscription.feature.tracksScansDeviceType'),
      ['hasTrackScanLocation']: t('subscription.feature.tracksScansLocations'),
      ['hasTrackScanTimeOfDay']: t('subscription.feature.tracksScansTimeOfDay'),
      ['hasRealTimeDataDashboard']: t('subscription.feature.realTimeDataDashboard'),
      ['hasLabels']: t('subscription.feature.labels'),
      ['hasTemplates']: t('subscription.feature.templates'),
    };

    return variants;
  }, [t]);

  return (
    <>
      <div className="mx-5 space-y-3">
        <Typography variant="m" className="!mb-5 font-semibold text-gray-800">
          {t('subscription.includes')}:
        </Typography>
        <IncludeItem
          isAvailable
          text={
            <>
              <span className="font-bold">{planLimit.amountMaxScans || '∞'}</span> {t('subscription.feature.maxScans')}
            </>
          }
        />

        {SUBSCRIPTION_PLAN_LIMIT_KEYS.slice(0, 2).map((key) => (
          <IncludeItem key={key} isAvailable={planLimit[key]} text={memoFeatures[key]} />
        ))}
        <Collapse in={isShowAllFeatures}>
          <div className="space-y-3">
            {SUBSCRIPTION_PLAN_LIMIT_KEYS.slice(2).map((key) => (
              <IncludeItem key={key} isAvailable={planLimit[key]} text={memoFeatures[key]} />
            ))}
          </div>
        </Collapse>
      </div>
      <Button
        className="mx-[2px] mb-[2px] rounded-[11px] rounded-t-none border-gray-100 bg-gray-100"
        color="secondary"
        size="large"
        variant="outlined"
        onClick={() => {
          setIsShowAllFeatures((prev) => !prev);
        }}
      >
        {!isShowAllFeatures ? t('subscription.seeAllFeatures') : t('subscription.seeLessFeatures')}
      </Button>
    </>
  );
};

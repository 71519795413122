import { useMemo } from 'react';

import { useQuery } from 'react-query';
import { CUSTOMER_BILLING_GET_INVOICES } from '@app/constants/query-api-configs';

import { useBasicQueryControls } from '@app/hooks/useBasicQueryControls.hook';

import { Typography } from '@mui/material';

import { Table } from '@app/components';
import { useTableColumns } from './useTableColumns';
import { BillingApi } from '@app/domain/billing/api/billing.api';
import { useTranslation } from 'react-i18next';

export const CustomerInvoicesTable: React.VFC = ({}) => {
  const { t } = useTranslation();
  const {
    values: { page, sort = CUSTOMER_BILLING_GET_INVOICES.defaultSort, size },
    handlers: { handlePageChange, handleSortChange, handleSizeChange },
  } = useBasicQueryControls({
    initialValues: {
      page: 1,
      size: 10,
    },
    prefix: CUSTOMER_BILLING_GET_INVOICES.tablePrefix,
  });
  const { data, isFetching } = useQuery(
    [CUSTOMER_BILLING_GET_INVOICES.name, { page, sort, size }],
    () => {
      return BillingApi.getMyInvoices({
        sort,
        page: Math.ceil((page || 1) - 1),
        size,
      });
    },
    { keepPreviousData: true, ...CUSTOMER_BILLING_GET_INVOICES.config }
  );

  const columns = useTableColumns();

  const leftContent = useMemo(() => {
    return (
      <div>
        <Typography variant="xl" className="font-bold">
          {t('billing.invoiceTable.billingAndInvoices')} {data?.total && `(${data?.total})`}
        </Typography>
      </div>
    );
  }, [data?.total, t]);

  return (
    <Table
      idKey="invoiceId"
      cols={columns}
      tableData={data?.result ?? []}
      leftHeaderContent={leftContent}
      notFoundMessage={t('billing.invoiceTable.placeholderNoInvoices')}
      currentPage={page}
      loading={isFetching}
      pageSize={size}
      onSizeChange={handleSizeChange}
      total={data?.total}
      onPagination={handlePageChange}
      currentSort={sort}
      onSortChanged={handleSortChange}
    />
  );
};

import { PaginatedResponse } from '@app/api/api.type';
import { PARAM_GOOGLE_CLICK_ID } from '@app/gtag/google.constant';
import { apiService, validateAndAssert } from '@app/services/api-service';
import { QRCodeInDtoOverride } from '@app/swagger-override-types';
import { QrCodeAdminInDto, QRCodeAdminOutDto, QrCodeMultiDeleteInDto } from '@app/swagger-types';
import { pageableData } from '@app/utils/yup.utils';
import { APIQRCodeQueryParams } from '../qr-code.types';
import { QrDtoMapper } from './qr-code.mapper';
import { QRCodeOutDtoSchema, QRCodePublicOutDtoSchema } from './qr-code.schema.api';

export abstract class QRCodeApi {
  static async create(dto: QRCodeInDtoOverride) {
    return validateAndAssert(apiService.post('/private/qr-code', dto), QRCodeOutDtoSchema);
  }
  static async claim({ qrId, googleClickId }: { qrId: string; googleClickId?: string }) {
    const maybeClickIdParam = googleClickId ? `?${PARAM_GOOGLE_CLICK_ID}=${googleClickId}` : '';
    return validateAndAssert(apiService.put(`/private/qr-code/claim/${qrId}${maybeClickIdParam}`), QRCodeOutDtoSchema);
  }
  static async getAll(params: APIQRCodeQueryParams) {
    return validateAndAssert(apiService.get('/private/qr-code', { params }), pageableData(QRCodeOutDtoSchema));
  }
  static async delete(id: string) {
    return apiService.delete(`/private/qr-code/${id}`);
  }
  static async multiDelete(dto: QrCodeMultiDeleteInDto) {
    return apiService.put('/private/qr-code/multi-delete', dto);
  }
  static async getById(id: string) {
    const res = await validateAndAssert(apiService.get(`/private/qr-code/${id.toUpperCase()}`), QRCodeOutDtoSchema);
    QrDtoMapper.mutateResponseForForm(res);
    return res;
  }

  static async update({ id, ...dto }: QRCodeInDtoOverride & { id: string }) {
    QrDtoMapper.mutateFormForRequest(dto);
    return validateAndAssert(apiService.patch(`/private/qr-code/${id}`, dto), QRCodeOutDtoSchema);
  }

  static async updateName({ id, name }: { name: string; id: string }) {
    return validateAndAssert(
      apiService.patch(`/private/qr-code/${id}/rename`, null, { params: { name, id } }),
      QRCodeOutDtoSchema
    );
  }
  static async getPublicById(qrId: string) {
    const res = await validateAndAssert(
      apiService.get(`/public/qr-code/${qrId.toUpperCase()}`),
      QRCodePublicOutDtoSchema
    );
    QrDtoMapper.mutateResponseForForm(res);
    return res;
  }
  static async registerDownload(qrId: string) {
    await apiService.post(`/public/qr-code/${qrId.toUpperCase()}/register-download`);
  }

  /* #region  /**=========== ADMIN =========== */
  static async adminGetAll(
    params: APIQRCodeQueryParams & {
      afterDate?: string;
      idStartsWith?: string;
      exactMatchById?: string;
      exactMatchByEmail?: string;
    }
  ) {
    // TODO validate dto
    const { data } = await apiService.get<PaginatedResponse<QRCodeAdminOutDto>>('/private/admin/qr-codes', { params });
    return data;
  }

  static async adminGetById(id: string) {
    // TODO validate dto
    const { data } = await apiService.get<QRCodeAdminOutDto>(`/private/admin/qr-codes/${id}`);
    return data;
  }

  static async adminPatchById(id: string, payload: QrCodeAdminInDto) {
    // TODO validate dto
    const { data } = await apiService.patch<QRCodeAdminOutDto>(`/private/admin/qr-codes/${id}`, payload);
    return data;
  }
  /* #endregion  /**======== ADMIN =========== */
}

import { useStore } from '@app/store/useStore.hook';
import { Button } from '@app/components';
import { DevKitSection } from '../DevKitSection';
import { useMemo, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import { clsxm } from '@app/styles/clsxm';
import { DEV_KIT_AUTH_OVERRIDE_LOCALSTORAGE_KEY } from '../devkit.constants';

const DEFAULT_VALUE = '{\n  \n}';
export const AuthOverrideDevKitSection: React.FC = () => {
  const { currentUser } = useStore();
  const [rawOverride, setRawOverride] = useState(
    localStorage.getItem('DEV_KIT_AUTH_OVERRIDE_LOCALSTORAGE_KEY') || DEFAULT_VALUE
  );

  const isValueValid = useMemo(() => {
    try {
      JSON.parse(rawOverride);
      return true;
    } catch {
      return false;
    }
  }, [rawOverride]);

  if (!currentUser) {
    // TODO auth via modal
    return <DevKitSection title="Authentication override">not authenticated</DevKitSection>;
  }

  const persistOverride = (value = rawOverride) => {
    setRawOverride(value);
    if (!value || value === DEFAULT_VALUE) {
      localStorage.removeItem(DEV_KIT_AUTH_OVERRIDE_LOCALSTORAGE_KEY);
      return;
    }
    localStorage.setItem(DEV_KIT_AUTH_OVERRIDE_LOCALSTORAGE_KEY, value);
  };

  return (
    <DevKitSection title="Authentication override">
      <TextareaAutosize
        value={rawOverride}
        onChange={(e) => setRawOverride(e.target.value)}
        className="w-full p-2 ring-1 ring-slate-500"
        minRows={5}
      />
      <div className="flex gap-4">
        <Button
          onClick={() => {
            localStorage.setItem(DEV_KIT_AUTH_OVERRIDE_LOCALSTORAGE_KEY, rawOverride);
            window.location.reload();
          }}
          disabled={!isValueValid}
        >
          apply and reload
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            persistOverride(DEFAULT_VALUE);
          }}
        >
          reset
        </Button>
        <div className={clsxm(isValueValid ? 'text-green-500' : 'text-red-500')}>
          {isValueValid ? 'valid JSON' : 'invalid JSON'}
        </div>
      </div>
    </DevKitSection>
  );
};

import { clsxm } from '@app/styles/clsxm';
import { TemplateStep } from './TemplateStep';

export const TEMPLATE_STEPPER_STEPS = ['Title', 'Logo', 'Frames', 'Pattern', 'Templates'] as const;
export type TEMPLATE_STEPPER_STEPS_TYPE = typeof TEMPLATE_STEPPER_STEPS[number];

export const TemplateStepper: React.FC<{
  activeStep: TEMPLATE_STEPPER_STEPS_TYPE;
  onChange: (step: TEMPLATE_STEPPER_STEPS_TYPE) => void;
  withTemplatesStep?: boolean;
  className?: string;
}> = ({ activeStep, onChange, className, withTemplatesStep = false }) => {
  return (
    <div className={clsxm('flex gap-[6px] sm:gap-2', className)}>
      {TEMPLATE_STEPPER_STEPS.map((step) => {
        if (step === 'Title' && withTemplatesStep) {
          return null;
        }

        if (step === 'Templates' && !withTemplatesStep) {
          return null;
        }

        return <TemplateStep key={step} step={step} isActive={activeStep === step} onChange={onChange} />;
      })}
    </div>
  );
};

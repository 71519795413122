import { Routes } from '@app/constants/routes';

import { RenderModule } from './RenderModule';
import { MyRouteObject } from '@app/models/router';
import { inferIdentity } from '@app/utils/type.utils';
import { RenderQRPage } from './pages/RenderQRPage';

export const RenderRoutes = inferIdentity<MyRouteObject>()({
  path: Routes.render.index,
  element: <RenderModule />,
  children: [{ path: Routes.render.qr, element: <RenderQRPage /> }],
});

import { useEffect, useState } from 'react';

export const VCardInfoItem: React.FC<{
  title: string;
  text: string;
  icon: React.ReactNode;
  titleColor?: string;
  textColor?: string;
  textHoverColor?: string;
  iconColor?: string;
  clickable?: boolean;
  hrefPrefix?: string;
}> = ({ icon, text, title, titleColor, textColor, textHoverColor, iconColor, clickable, hrefPrefix }) => {
  const [currentTextColor, setCurrentTextColor] = useState(textHoverColor);

  useEffect(() => {
    setCurrentTextColor(textColor);
  }, [textColor]);

  const handleMouseEnter = () => {
    setCurrentTextColor(textHoverColor);
  };

  const handleMouseLeave = () => {
    setCurrentTextColor(textColor);
  };

  const body = (
    <>
      <div className="min-w-5 min-h-5 mt-[2px] h-5 w-5" style={{ color: iconColor }}>
        {icon}
      </div>
      <div className="flex flex-1 flex-col gap-[2px] text-xxs">
        <span style={{ color: titleColor }}>{title}</span>
        <span
          className="overflow-hidden font-medium text-accept-dark-blue"
          style={{ wordBreak: 'break-word', color: currentTextColor }}
        >
          {text}
        </span>
      </div>
    </>
  );

  if (clickable) {
    return (
      <a
        className="flex w-full gap-3 overflow-hidden cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        href={hrefPrefix ? `${hrefPrefix}${text}` : text}
        target="_blank"
        rel="noreferrer"
      >
        {body}
      </a>
    );
  }

  return (
    <div
      className="flex w-full gap-3 overflow-hidden"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="none"
    >
      {body}
    </div>
  );
};

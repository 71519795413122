import { object, InferType, string, boolean, number } from 'yup';

import { UserOutSchema } from '@app/auth/api/auth.schema';
import { checkTypeCompliance } from '@app/utils/type.utils';
import { CustomerAdminOutDto, ECurrency, ELanguage, ERole, EUserStatus } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { CustomerSubscriptionOutDtoSchema } from '../subscription/api/subscription.schema';

export const CustomerOutSchema = UserOutSchema.concat(
  object({
    company: string(),
  })
);
export type CustomerOutSchema = InferType<typeof CustomerOutSchema>;

// TODO fix dependency loop: UserOutSchema imports CurrentCustomerOutDto which imports UserOutSchema
// FIXME: updated type when be ready API
export type CurrentCustomerOutDto = Omit<UserOutSchema, 'firstName' | 'lastName' | 'endOfSubscriptionDate'> & {
  firstName?: string;
  lastName?: string;
  endOfSubscriptionDate?: string;
};

checkTypeCompliance<CurrentCustomerOutDto, UserOutSchema>();

export const CustomerAdminOutSchema = object({
  id: string().defined(),
  firstName: string().nullable(),
  lastName: string().nullable(),
  email: string().nullable(),
  deletedEmail: string().nullable(),
  language: oneOfEnum(ELanguage),
  currency: oneOfEnum(ECurrency),
  totalCodes: number(),
  totalScans: number(),
  shouldAskForGPSCoordinates: boolean(),
  accountNotification: boolean(),
  qrNotification: boolean(),
  marketingNotification: boolean(),
  role: oneOfEnum(ERole),
  phone: string().nullable(),
  address: string().nullable(),
  birthDate: string().nullable(),
  avatarId: string().nullable(),
  status: oneOfEnum(EUserStatus),
  createdDate: string().required(),
  company: string().nullable(),
  stripeSyncedDate: string().nullable(),
  customerId: string().nullable(),
  chargebeeId: string().nullable(),
  activeCampaignId: string().nullable(),
  lastActiveCampaignSyncedDate: string().nullable(),
  subscription: CustomerSubscriptionOutDtoSchema,
  hasDisputedPayment: boolean().default(false),
});
export type CustomerAdminOutSchema = InferType<typeof CustomerAdminOutSchema>;
checkTypeCompliance<CustomerAdminOutDto, CustomerAdminOutSchema>();
checkTypeCompliance<CustomerAdminOutSchema, CustomerAdminOutDto>();

import { UserBaseOutSchema } from '@app/auth/api/auth.schema';
import { LibraryOutDtoSchema } from '@app/domain/library/api/library.schema.api';
import { ECornerType, EFrameType, EPatternType } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { boolean, InferType, number, object, string } from 'yup';

export const TemplateOutDtoSchema = object({
  id: string().required(),
  title: string().required(),
  createdDate: string().required(),
  createdBy: UserBaseOutSchema,
});
export type TemplateOutDtoSchema = InferType<typeof TemplateOutDtoSchema>;

export const DesignOutDtoSchema = object().shape({
  id: string().required(),
  createdBy: UserBaseOutSchema.nullable(),
  template: TemplateOutDtoSchema.nullable(),
  logoSize: number().required(),
  frameType: oneOfEnum(EFrameType),
  frameText: string().nullable(),
  frameTextSize: number().required(),
  frameTextColor: string().required(),
  backgroundColor: string().required(),
  // TODO check references
  frameBackgroundColor: string().required(),
  patternType: oneOfEnum(EPatternType),
  cornerType: oneOfEnum(ECornerType),
  patternColor: string().required(),
  cornerColor: string().required(),
  createdDate: string().required(),
  library: LibraryOutDtoSchema.nullable(),
  reusable: boolean(),
  isReusable: boolean().required(),
});
export type DesignOutDtoSchema = InferType<typeof DesignOutDtoSchema>;

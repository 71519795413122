import { QrCodeIcon } from '@app/assets';
import { Button, Container, Typography } from '@app/components';
import { Routes } from '@app/constants/routes';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { SubscriptionApi } from '@app/domain/subscription/api/subscription.api';
import { useIsBlackFridayAvailable } from '@app/modules/customer/BlackFridayBanner';
import { clsxm } from '@app/styles/clsxm';
import { EPlanCycle, EPlanStatus, EPlanType, PlanOutDto } from '@app/swagger-types';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SubscriptionPlanIncludes } from './SubscriptionPlanIncludes';
import { SubscriptionPriceContainer } from './SubscriptionPriceContainer';

export const SubscriptionPlanContainer: React.FC<{
  plan: PlanOutDto;
  selectedPlanCycle: EPlanCycle;
  isShowAllFeatures: boolean;
  setIsShowAllFeatures: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ plan, selectedPlanCycle, isShowAllFeatures, setIsShowAllFeatures }) => {
  const { t } = useTranslation();
  const {
    plan: activePlan,
    planDetails,
    planType: activePlanType,
    isExpired,
    planStatus,
    scheduledUpdate,
  } = useActivePlan();
  const monthlyPlanPrice = plan.prices.find((p) => p.planCycle === EPlanCycle.MONTHLY);
  const planPrice = plan.prices.find((p) => p.planCycle === selectedPlanCycle);

  const isBlackFridayAvailable = useIsBlackFridayAvailable();
  const subscribeMutation = useMutation({
    mutationFn: async () => {
      if (!planPrice) {
        return;
      }
      const res = await SubscriptionApi.subscribeViaStripeGateway({
        planId: plan.id,
        planCycle: selectedPlanCycle,
        currency: planPrice.planCurrency,
        successRedirectURL: `${window.location.origin}${Routes.customer.success_payment}`,
        errorRedirectURL: window.location.href,
        allowPromotionCodes: isBlackFridayAvailable,
      });
      return res;
    },
  });
  const resubscribeMutation = useMutation(
    async () => {
      return await SubscriptionApi.resubscribeCurrentPlan();
    },
    {
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  // TODO after release
  // const disabledReason = '';
  let isButtonDisabled =
    Boolean(scheduledUpdate) || !planPrice || subscribeMutation.isLoading || resubscribeMutation.isLoading;
  const gatewayData = subscribeMutation.data;
  if (gatewayData) {
    isButtonDisabled = true;
    if (gatewayData.url) {
      window.location.href = gatewayData.url;
    } else {
      // case for scheduled downgrade
      window.location.reload();
    }
  }

  const isCurrentPlan = plan.id === activePlan?.id && planDetails?.planType !== EPlanType.TRIAL;
  const activeCycle = activePlan?.price?.planCycle;
  const isCurrentCycle = planPrice ? planPrice.planCycle === activeCycle : undefined;
  const isCurrentPlanCanceledStillActive = isCurrentPlan && !isExpired && planStatus === EPlanStatus.CANCELED;
  const isCurrentPlanCanceledExpired = isCurrentPlan && isExpired && planStatus === EPlanStatus.CANCELED;
  const isPlanForUpgrade = isCurrentPlan
    ? activeCycle === EPlanCycle.MONTHLY
    : checkIsPlanForUpgrade({
        activePlan: {
          planType: activePlanType,
          planCycle: activeCycle,
        },
        targetPlan: {
          planType: plan.type,
          planCycle: planPrice?.planCycle,
        },
      });

  const renderButton = () => {
    const upgradeButton = (
      <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
        {t('subscription.upgradeTo')} {plan.name}
      </Button>
    );
    if (isExpired) {
      return upgradeButton;
    }
    if (isCurrentPlan) {
      if (isCurrentPlanCanceledExpired) {
        return (
          <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
            {t('subscription.upgradeTo')} {plan.name}
          </Button>
        );
      }
      if (isCurrentCycle) {
        return isCurrentPlanCanceledStillActive ? (
          <Button className="mx-5" disabled={isButtonDisabled} onClick={() => resubscribeMutation.mutate()}>
            {t('subscription.renewPlan')}
          </Button>
        ) : (
          <Button className="mx-5" disabled>
            {t('subscription.currentPlan')}
          </Button>
        );
      }
      if (isPlanForUpgrade) {
        return (
          <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
            {t('subscription.changeToYearly')}
          </Button>
        );
      }
      return (
        <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
          {t('subscription.changeToMonthly')}
        </Button>
      );
    }
    if (isPlanForUpgrade) {
      return upgradeButton;
    }
    return (
      <Button className="mx-5" disabled={isButtonDisabled} onClick={() => subscribeMutation.mutate()}>
        {t('subscription.downgradeTo')} {plan.name}
      </Button>
    );
  };

  return (
    <Container
      rounded
      className={clsxm('w-full p-0 pt-6', isCurrentPlan && !isExpired && 'ring-1 ring-accept-menu-item-bg')}
    >
      <Typography variant="xl" className="mb-5 px-6 text-center font-bold text-gray-800">
        {plan.name}
      </Typography>
      <Divider />
      <div className="mt-5 flex flex-col gap-5">
        {planPrice ? (
          <SubscriptionPriceContainer
            planPrice={planPrice}
            monthlyPlanPrice={monthlyPlanPrice}
            compareToPlanCycle={selectedPlanCycle}
          />
        ) : null}
        <div className="flex items-center justify-center">
          <div className="flex items-center gap-2 rounded-xl bg-gray-100 p-2 font-semibold text-accept-dark-blue">
            <QrCodeIcon />
            <span>
              {plan.planLimit.amountMaxQrCodes} {t('subscription.dynamicQRCodes')}
            </span>
          </div>
        </div>
        {renderButton()}

        <SubscriptionPlanIncludes
          planLimit={plan.planLimit}
          isShowAllFeatures={isShowAllFeatures}
          setIsShowAllFeatures={setIsShowAllFeatures}
        />
      </div>
    </Container>
  );
};

const checkIsPlanForUpgrade = ({
  activePlan,
  targetPlan,
}: {
  activePlan: {
    planType?: EPlanType;
    planCycle?: EPlanCycle;
  };
  targetPlan: {
    planType?: EPlanType;
    planCycle?: EPlanCycle;
  };
}): boolean => {
  if (activePlan.planType === EPlanType.TRIAL) {
    return true;
  }
  if (!activePlan.planType || !activePlan.planCycle) {
    return false;
  }
  if (!targetPlan.planType || !targetPlan.planCycle) {
    return false;
  }
  if (activePlan.planCycle === EPlanCycle.YEARLY && targetPlan.planCycle === EPlanCycle.MONTHLY) {
    return false;
  }
  const { isTargetHigher } = checkPlanTypeHierarchy(activePlan.planType, targetPlan.planType);
  return isTargetHigher;
};

const checkPlanTypeHierarchy = (
  activeType: EPlanType,
  targetType: EPlanType
): {
  areEqual: boolean;
  isTargetHigher: boolean;
  isTargetLower: boolean;
} => {
  if (activeType === EPlanType.TRIAL || targetType === EPlanType.TRIAL) {
    throw new Error('trial type is out of hierarchy');
  }
  const indexActive = PLAN_TYPE_HIERARCHY_FROM_LOW_TO_HIGH.indexOf(activeType);
  const indexTarget = PLAN_TYPE_HIERARCHY_FROM_LOW_TO_HIGH.indexOf(targetType);
  return {
    areEqual: indexActive === indexTarget,
    isTargetHigher: indexTarget > indexActive,
    isTargetLower: indexTarget < indexActive,
  };
};

const PLAN_TYPE_HIERARCHY_FROM_LOW_TO_HIGH = [EPlanType.BASIC, EPlanType.STARTER, EPlanType.PREMIUM];

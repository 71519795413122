import { Divider } from '@mui/material';

export const DividerWithText: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="my-1 flex items-center gap-3">
      <div className="flex-1">
        <Divider className="bg-gray-300" />
      </div>
      <div className="text-s text-gray-800">{text}</div>
      <div className="flex-1">
        <Divider className="bg-gray-300" />
      </div>
    </div>
  );
};

import { ColorPickerInput, Container, Typography } from '@app/components';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { Divider } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const MultilinkDesign: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<QRCodeFormSchema>();

  return (
    <Container className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:rounded-xl">
      <Typography className="font-bold sm:text-l md:col-span-2">{t('qr.stepInput.designTab')}</Typography>
      <Controller
        name="multilink.designBgColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Background color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="multilink.designTitleColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Title color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="multilink.designDescriptionColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Description color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />

      <Divider className="my-2 md:col-span-2" />
      <Typography variant="m" className="font-bold md:col-span-2">
        Button
      </Typography>
      <Controller
        name="multilink.buttonBgColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Background color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="multilink.buttonTextColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Text color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="multilink.buttonHoverColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Hover color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="multilink.buttonBorderColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Border color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
    </Container>
  );
};

import { Routes } from '@app/constants/routes';

import { MyRouteObject } from '@app/models/router';

import { RootModule } from './RootModule';
import { AdminRoutes } from './admin/AdminRoutes';
import { AuthPublicRoutes, AuthRoutes } from './auth/AuthRoutes';
import { TypedNavigate } from '@app/router';
import { UIKitRoutes } from './ui-kit/UIKitRoutes';
import { DevKitRoutes } from './dev-kit/DevKitRoutes';
import { CustomerRoutes } from './customer/CustomerRoutes';
import { QRCodeRoutes } from './qr-code/QRCodeRoutes';
import { WidgetRoutes } from '@app/qr-widget/WidgetRoutes';
import { EmailRoutes } from './email/EmailRoutes';
import { RenderRoutes } from './render/RenderRoutes';

export const RootRoutes: MyRouteObject[] = [
  {
    path: Routes.index,
    element: <RootModule />,
    children: [
      AdminRoutes,
      CustomerRoutes,
      AuthRoutes,
      AuthPublicRoutes,
      EmailRoutes,
      UIKitRoutes,
      DevKitRoutes,
      WidgetRoutes,
      RenderRoutes,
      // make sure QR routes are at the end because of /:id route here
      QRCodeRoutes,
    ],
  },
  // IF YOU PREFER 404 ROOT PAGES => (require manual redirect after sign-in/sign-up/logout in responding pages/actions)
  // { path: '*', element: <NotFoundPage /> },
  { index: true, path: '*', element: <TypedNavigate to={Routes.index} /> },
];

import { Typography } from '@app/components';
import { BackButton } from '@app/components/buttons/back-button/BackButton';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { Routes } from '@app/constants/routes';
import { useGetTemplateById } from '@app/domain/template/api/template.hooks.api';
import { TemplateForm } from '@app/domain/template/components/TemplateForm';
import { useTypedNavigate, useTypedParams } from '@app/router';
import { Theme, useMediaQuery } from '@mui/material';

export const EditTemplatePage: React.FC = () => {
  const navigate = useTypedNavigate();
  const isDesktop = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up('xl');
  });
  const { id } = useTypedParams(Routes.customer.templates.edit);
  const { data: template, isLoading } = useGetTemplateById(id);

  if (isLoading || !template) {
    return (
      <div className="relative h-full">
        {!template && !isLoading && (
          <div className="mt-2 flex items-center px-6 lg:px-12">
            <BackButton
              onClick={() => {
                navigate({ to: Routes.customer.templates.index, preserveQueryParams: true });
              }}
            />
            <Typography variant="xxl" className="font-bold">
              Edit template
            </Typography>
          </div>
        )}
        <div className="absolute top-[30%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
          <ContentLoader isEmpty={!template} loading={isLoading} entity="Template" />
        </div>
      </div>
    );
  }

  return <TemplateForm isDesktop={isDesktop} mode="EDIT" defaultValues={template} />;
};

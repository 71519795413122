import { EmptyPrimaryIcon } from '@app/assets';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../typography/Typography';
import styles from './PlaceholderBox.module.scss';

export const PlaceholderBox: React.FC<{
  variant: 'primary';
  title?: string;
  subtitle?: string;
  noSearch?: boolean;
  customIcon?: React.ReactNode;
}> = ({ variant, title, subtitle }) => {
  const { t } = useTranslation();

  const currentIcon = useMemo(() => {
    switch (variant) {
      case 'primary':
        return <EmptyPrimaryIcon />;
      default:
        return t('placeholder.youHaveNoData');
    }
  }, [variant, t]);

  return (
    <div className="flex h-full max-h-[80%] flex-1 flex-col items-center justify-center gap-2">
      <div className={styles.svgContainer}>{currentIcon}</div>
      <Typography className="text-s sm:text-m">{title}</Typography>
      <div className="max-w-[300px] px-4 text-center text-xs sm:max-w-[400px] sm:text-m">{subtitle}</div>
    </div>
  );
};

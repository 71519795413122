import { TFunction } from 'i18next';
import { EAnalyticsInterval, EAnalyticsPeriodAfterDate } from './analytics.types';

export const calcAnalyticsAfterDate = (period: EAnalyticsPeriodAfterDate) => {
  const d = new Date();
  d.setUTCHours(0);
  d.setUTCMinutes(0);
  d.setUTCSeconds(0);
  d.setUTCMilliseconds(0);
  const offsetTodayMinutes = d.getTimezoneOffset();
  let shift = -7;
  switch (period) {
    case EAnalyticsPeriodAfterDate.LAST_7_DAYS:
      shift = -7;
      break;
    case EAnalyticsPeriodAfterDate.LAST_30_DAYS:
      shift = -30;
      break;
    case EAnalyticsPeriodAfterDate.LAST_6_MONTHS:
      shift = -30 * 6;
      break;
    case EAnalyticsPeriodAfterDate.LAST_12_MONTHS:
      shift = -365;
      break;
  }
  // decrease shift by 1 because today included
  // e.g. 7 days means 6 previous + 1 today
  shift += 1;
  d.setUTCDate(d.getUTCDate() + shift);
  // important - use UTC offset as for today to avoid daylight time changes
  // if we don't manually define offset as for today - some old dates may be calculated in wrong start time
  // which will shift day start time due to different offset
  d.setUTCMinutes(d.getUTCMinutes() + offsetTodayMinutes);
  return d;
};

export const calcAnalyticsIntervalEnum = (period: EAnalyticsPeriodAfterDate) => {
  switch (period) {
    case EAnalyticsPeriodAfterDate.LAST_7_DAYS:
      return EAnalyticsInterval.DAILY;
    case EAnalyticsPeriodAfterDate.LAST_30_DAYS:
      return EAnalyticsInterval.DAILY;
    case EAnalyticsPeriodAfterDate.LAST_6_MONTHS:
      return EAnalyticsInterval.WEEKLY;
    case EAnalyticsPeriodAfterDate.LAST_12_MONTHS:
      return EAnalyticsInterval.MONTHLY;
    default:
      return EAnalyticsInterval.MONTHLY;
  }
};

const DAY_MINUTES = 60 * 24;
const WEEK_MINUTES = DAY_MINUTES * 7;
const MONTH_MINUTES = DAY_MINUTES * 30;
export const calcAnalyticsIntervalMinutes = (intervalEnum: EAnalyticsInterval) => {
  switch (intervalEnum) {
    case EAnalyticsInterval.DAILY:
      return DAY_MINUTES;
    case EAnalyticsInterval.WEEKLY:
      return WEEK_MINUTES;
    case EAnalyticsInterval.MONTHLY:
      return MONTH_MINUTES;
    default:
      return MONTH_MINUTES;
  }
};

export const renderFriendlyAnalyticsPeriod = (enumValue: EAnalyticsPeriodAfterDate, t: TFunction<'translation'>) => {
  const variants = {
    [EAnalyticsPeriodAfterDate.LAST_7_DAYS]: t('qrAnalytics.last7days'),
    [EAnalyticsPeriodAfterDate.LAST_30_DAYS]: t('qrAnalytics.last30days'),
    [EAnalyticsPeriodAfterDate.LAST_6_MONTHS]: t('qrAnalytics.last6months'),
    [EAnalyticsPeriodAfterDate.LAST_12_MONTHS]: t('qrAnalytics.last12months'),
  };
  return variants[enumValue] || String(enumValue);
};

export const renderFriendlyAnalyticsInterval = (enumValue: EAnalyticsInterval, t: TFunction<'translation'>) => {
  const variants = {
    [EAnalyticsInterval.DAILY]: t('qrAnalytics.intervalDaily'),
    [EAnalyticsInterval.WEEKLY]: t('qrAnalytics.intervalWeekly'),
    [EAnalyticsInterval.MONTHLY]: t('qrAnalytics.intervalMonthly'),
  };
  return variants[enumValue] || String(enumValue);
};

import styles from './Tooltip.module.scss';
import clsx from 'clsx';

import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

export type TooltipProps = MuiTooltipProps & {
  variant?: 'light' | 'dark';
};

export const Tooltip: React.FC<TooltipProps> = ({ variant = 'light', ...props }) => {
  return (
    <MuiTooltip
      {...props}
      classes={{
        ...props?.classes,
        tooltip: clsx(styles.Tooltip, variant === 'light' ? styles.Light : styles.Dark, props.classes?.tooltip),
      }}
    />
  );
};

import { clsxm } from '@app/styles/clsxm';
import { UIStyledSection } from '../components/UIStyledSection';

export const UITabShadows: React.FC = () => {
  const render = (shadows: string[]) =>
    shadows.map((e, i) => (
      <div key={i} className="flex flex-col gap-2">
        <div>{e.replace('shadow-', '')}</div>
        <div className={clsxm(e, 'p-10')} />
      </div>
    ));

  return (
    <UIStyledSection title="Shadows">
      <div className="flex flex-col gap-4 pb-6">
        <div className="grid grid-cols-4 gap-4">{render(SHADOWS)}</div>
      </div>
    </UIStyledSection>
  );
};

const SHADOWS = /*tw*/ ['shadow-block', 'shadow-1', 'shadow-bg', 'shadow-header', 'shadow-segmented-shadow'];

import styles from './Select.module.scss';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';

import { Input, InputProps } from '@app/components';

export interface SelectProps<T> extends Omit<MuiSelectProps<T>, 'inputProps' | 'size'> {
  inputProps?: InputProps;
  size?: 'large' | 'medium' | 'small';
  withPlaceholder?: boolean;
}

function SelectComponent<T>(
  { inputProps, children, disabled, MenuProps, className, withPlaceholder, size = 'medium', ...props }: SelectProps<T>,
  ref: React.Ref<unknown>
) {
  return (
    <MuiSelect
      input={<Input {...inputProps} />}
      variant={inputProps?.variant}
      disabled={disabled}
      MenuProps={{
        classes: { paper: clsx(props.multiple ? styles.SelectPaperMultiple : styles.SelectPaper, className) },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        elevation: 1,
        ...MenuProps,
      }}
      classes={{
        select: clsx(
          styles.Select,
          styles[capitalize(size)],
          withPlaceholder && props.value ? styles.WithPlaceholder : null,
          'pr-[38px]'
        ),
        disabled: styles.Disabled,
      }}
      ref={ref}
      IconComponent={(props) => {
        return !disabled ? (
          <div className={clsx(styles.ArrowIconWrapper, styles[capitalize(size)])}>
            <div
              className={clsx(
                inputProps?.variant === 'filled' ? styles.ArrowNoBg : null,
                styles.ArrowIcon,
                props.className.includes('select-iconOpen') ? styles.Expanded : null,
                styles[capitalize(size)]
              )}
            >
              <KeyboardArrowDownIcon />
            </div>
          </div>
        ) : null;
      }}
      {...props}
    >
      {children}
    </MuiSelect>
  );
}

export const Select = React.forwardRef(SelectComponent) as <T>(
  p: React.PropsWithChildren<SelectProps<T>> & { ref?: React.Ref<unknown> }
) => React.ReactElement;

import { PARAM_GOOGLE_CLICK_ID } from '@app/gtag/google.constant';
import { EGTAGRegistrationOrigin, EGTAGRegistrationPopUp } from '@app/gtag/gtag.type';
import {
  AUTH_QUERY_PARAM_AUTO_LINK_QR_ID,
  AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_POPUP,
  AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_SOURCE,
  AUTH_QUERY_PARAM_FOR_PREFILL_EMAIL,
  AUTH_QUERY_PARAM_LANGUAGE,
} from '@app/modules/auth/auth.constants';
import { ELanguage } from '@app/swagger-types';
import { WIDGET_SIGNUP_URL } from './widget.constants';

export const widgetPrepareSignUpUrl = ({
  language,
  autoLinkQRID,
  googleClickId,
  origin,
  popup,
  prefillEmail,
}: {
  language: ELanguage;
  autoLinkQRID: string | null;
  googleClickId?: string;
  origin: EGTAGRegistrationOrigin.GENERATOR;
  popup: EGTAGRegistrationPopUp;
  prefillEmail: string | null;
}) => {
  const url = new URL(WIDGET_SIGNUP_URL);
  url.searchParams.set(AUTH_QUERY_PARAM_LANGUAGE, language);
  if (autoLinkQRID) {
    url.searchParams.set(AUTH_QUERY_PARAM_AUTO_LINK_QR_ID, autoLinkQRID);
  }
  if (googleClickId) {
    url.searchParams.set(PARAM_GOOGLE_CLICK_ID, googleClickId);
  }
  if (prefillEmail) {
    url.searchParams.set(AUTH_QUERY_PARAM_FOR_PREFILL_EMAIL, prefillEmail);
  }
  url.searchParams.set(AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_SOURCE, origin);
  if (popup) {
    url.searchParams.set(AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_POPUP, popup);
  }

  return url.toString();
};

import { PlanLimitOutDtoKey } from './user.type';

// TODO uncomment features after imlementation
export const SUBSCRIPTION_PLAN_LIMIT_KEYS: PlanLimitOutDtoKey[] = [
  'hasTemplates',
  // 'hasLabels',
  'hasRealTimeDataDashboard',
  'hasTrackScanTimeOfDay',
  'hasTrackScanLocation',
  'hasTrackScanDevice',
  'hasTrackScanGPS',
  'hasQrDesignCustomisations',
  // 'hasBulkQrCodeGeneration',
  'gdprCompliant',
  'ccpaCompliant',
  'knowledgeBase',
  'emailSupport',
  'dedicatedCustomerSuccessManager',
];

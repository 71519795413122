import { Button } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useCustomerAnalyticsGlobalFilterParams,
  useCustomerAnalyticsShouldShowResetFilters,
} from '../../../../domain/analytics/analytics.url.hooks';
import { QRAnalyticsDaytimeChart } from './components/QRAnalyticsDaytimeChart';
import { QRAnalyticsGlobalFilters } from './components/QRAnalyticsGlobalFilters';
import { QRAnalyticsOverall } from './components/QRAnalyticsOverall';
import { QRAnalyticsScansByLocation } from './components/QRAnalyticsScansByLocation';
import { QRAnalyticsTotalScansChart } from './components/QRAnalyticsTotalScansChart';
import { QRAnalyticsTotalUsersChart } from './components/QRAnalyticsTotalUsersChart';

export const AnalyticsPage: React.FC = () => {
  const { t } = useTranslation();
  const { shouldShowReset, resetFilters } = useCustomerAnalyticsShouldShowResetFilters();
  const { afterDate, qrCodeIds } = useCustomerAnalyticsGlobalFilterParams();

  return (
    <div className="relative flex h-full flex-col gap-4 pb-32 lg:p-6">
      <div className="flex items-center justify-between gap-4">
        <Typography variant="xl" className="px-6 pt-6 font-bold lg:p-0 lg:text-xxl">
          {t('qrAnalytics.pageTitle')}
        </Typography>
        <Button
          variant="text"
          color="secondary"
          onClick={() => resetFilters()}
          startIcon={<Close />}
          disabled={!shouldShowReset}
          className={clsxm('transition', !shouldShowReset && 'opacity-0')}
        >
          {t('qrAnalytics.resetFilters')}
        </Button>
      </div>
      <QRAnalyticsGlobalFilters />
      <QRAnalyticsOverall qrCodeIds={qrCodeIds} afterDate={afterDate} />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <QRAnalyticsTotalScansChart />
        <QRAnalyticsTotalUsersChart />
      </div>
      <QRAnalyticsDaytimeChart />
      <QRAnalyticsScansByLocation />
    </div>
  );
};

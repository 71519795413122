import { useCallback, useMemo, useRef, useState } from 'react';
import { Avatar, AvatarProps } from '../avatar/Avatar';
import { FileUploadInput } from '../file-upload-input/FileUploadInput';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { clsxm } from '@app/styles/clsxm';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { IconButton } from '../buttons/icon-button/IconButton';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  onChange?: (file: File | null) => void;
  onDelete?: () => void;
  imageId?: string;
  wrapperClassName?: string;
  cropSize?: number;
};

export const StyledAvatarEditor: React.FC<PropTypes & Omit<AvatarProps, 'src' | 'onChange'>> = ({
  onChange,
  onDelete,
  imageId,
  wrapperClassName,
  cropSize,
  ...props
}) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState<string | undefined>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pickImageHandler = useCallback(() => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const setImageHandler = useCallback(
    (file: File) => {
      setPreview(URL.createObjectURL(file));
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      onChange && onChange(file);
    },
    [onChange]
  );

  const handleDelete = useCallback(() => {
    setPreview(undefined);
    onChange && onChange(null);
    onDelete && onDelete();
  }, [onChange, onDelete]);

  const imageIdWithCropSize = useMemo(() => {
    if (imageId && cropSize) {
      return `${imageId}?cropSize=${cropSize}`;
    }
    return imageId;
  }, [cropSize, imageId]);

  return (
    <div className={clsxm('flex flex-col items-center gap-2', wrapperClassName)}>
      <Avatar src={preview} {...props} imageId={imageIdWithCropSize} />
      <FileUploadInput onChange={setImageHandler} ref={fileInputRef} />
      <div className="flex items-center gap-2">
        <IconButton variant="transparent" onClick={pickImageHandler}>
          <FileUploadOutlinedIcon color="inherit" />
        </IconButton>
        <ConfirmDialog
          title="Delete Profile Photo?"
          text="Are you sure you want to delete a photo?"
          confirmText={t('dialog.delete')}
          onConfirm={handleDelete}
          trigger={
            <IconButton variant="transparent" disabled={!preview && !imageId}>
              <DeleteOutlinedIcon color="inherit" />
            </IconButton>
          }
        />
      </div>
    </div>
  );
};

import { useEffect, useState } from 'react';

import { memoizedImageBackgroundDownloader } from '@app/utils/image.utils';

/**
 * @returns imageUrl, loading, error
 */
export const useBackgroundImageDownload = (imageId?: string | null, isPublic = false) => {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!imageId) {
      return setImageUrl('');
    }

    let isMount = true;

    setLoading(true);
    setError(false);

    memoizedImageBackgroundDownloader(imageId, isPublic)
      .then((res) => {
        isMount && setImageUrl(res);
      })
      .catch((err) => {
        console.error('failed to load img', err, { imageId, isPublic });
        isMount && setError(true);
      })
      .finally(() => {
        isMount && setLoading(false);
      });

    return () => {
      isMount = false;
    };
  }, [imageId, isPublic]);

  return [imageUrl, loading, error] as const;
};

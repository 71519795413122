import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { SubscriptionStatusBadge } from '@app/domain/subscription/SubscriptionStatusBadge';
import { clsxm } from '@app/styles/clsxm';
import { EPlanCycle } from '@app/swagger-types';
import { capitalizeWords } from '@app/utils/string.utils';
import { useTranslation } from 'react-i18next';

export const CurrentSubscriptionTitleStatus: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { t } = useTranslation();
  const { planType, planStatus, endOfSubscriptionDate, planCycle } = useActivePlan();

  return (
    <div className={clsxm('px-4 py-[2px] text-xl font-bold', className)}>
      <span>{capitalizeWords(String(planType))}</span>{' '}
      {planCycle === EPlanCycle.MONTHLY && `(${t('subscription.monthly')})`}
      {planCycle === EPlanCycle.YEARLY && `(${t('subscription.yearly')})`}
      <SubscriptionStatusBadge
        planStatus={planStatus}
        endOfSubscriptionDate={endOfSubscriptionDate}
        shortVariant
        className="ml-2 font-normal"
      />
    </div>
  );
};

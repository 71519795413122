import { ColorPickerInput, Container, Input, Typography } from '@app/components';
import { QRCodeFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { Divider } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

export const QRCodeDesign: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<QRCodeFormSchema>();

  return (
    <Container className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:rounded-xl">
      <Typography className="font-bold sm:text-l md:col-span-2">{t('qr.stepInput.designTab')}</Typography>
      <Controller
        name="vcard.designPrimaryColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Primary color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="vcard.designSecondaryColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Secondary color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="vcard.designNameColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Name color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="vcard.designTitleColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Title color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="vcard.designTextColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Text color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="vcard.designIconColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Icon color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="vcard.designTextHoverColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Text hover color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />

      <Divider className="my-2 md:col-span-2" />
      <Typography variant="m" className="font-bold md:col-span-2">
        Button
      </Typography>
      <div className="grid gap-4 md:col-span-2 md:grid-cols-4">
        <div className="md:col-span-3">
          <Controller
            name="vcard.buttonText"
            control={control}
            render={({ field: { value, ...field }, fieldState }) => (
              <Input
                {...field}
                value={value || ''}
                withBG
                label="Custom text"
                errorMessage={fieldState?.error?.message || ''}
              />
            )}
          />
        </div>
        <Controller
          name="vcard.buttonSize"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <NumberFormat
              {...field}
              allowNegative={false}
              placeholder="0"
              label="Size"
              autoComplete="off"
              withBG
              value={value || ''}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (e.key === 'ArrowUp') {
                  onChange((value || 0) + 1);
                } else if (e.key === 'ArrowDown' && value) {
                  onChange(value - 1);
                }
              }}
              onValueChange={(values) => {
                onChange(Math.max(Number(values.value), 0));
              }}
              customInput={Input}
            />
          )}
        />
      </div>
      <Controller
        name="vcard.buttonBgColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Background color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="vcard.buttonTextColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Text color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="vcard.buttonHoverColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Hover color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
      <Controller
        name="vcard.buttonBorderColor"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => (
          <ColorPickerInput
            {...field}
            color={value || ''}
            inputProps={{
              withBG: true,
              label: 'Border color',
              errorMessage: fieldState?.error?.message || '',
            }}
          />
        )}
      />
    </Container>
  );
};

import { AuthUserSchema } from '@app/auth/api/auth.dto';
import { Button, Form, Input, OptionItem, Select, Typography } from '@app/components';
import { useHandler } from '@app/hooks/useHandler.hook';
import { clsxm } from '@app/styles/clsxm';
import { ECurrency, ELanguage } from '@app/swagger-types';
import { renderFriendlyLanguage } from '@app/utils/render-friendly-text.utils';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserAccountInfoFormSchema } from '../api/user.form.schema.api';
import { useUpdateCustomer } from '../api/user.hooks.api';

export const AccountInfoModal: React.FC<
  NiceModalHocProps & {
    defaultValues: AuthUserSchema;
  }
> = NiceModal.create(({ defaultValues }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const { mutate: updateCustomer, isLoading } = useUpdateCustomer();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserAccountInfoFormSchema>({
    resolver: yupResolver(UserAccountInfoFormSchema),
    defaultValues,
  });

  const closeDialog = () => {
    modal.remove();
  };

  const onSubmit: SubmitHandler<UserAccountInfoFormSchema> = useHandler((data) => {
    updateCustomer(
      { ...defaultValues, ...data },
      {
        onSuccess: () => {
          toast.success(t('toaster.changesSaved'));
          closeDialog();
        },
      }
    );
  });

  return (
    <Modal open={modal.visible} onClose={closeDialog} onClick={(e) => e.stopPropagation()}>
      <div
        className={clsxm(
          'absolute top-[50%] left-[50%] w-full max-w-[calc(100%-24px)] -translate-x-[50%] -translate-y-[50%] overflow-hidden rounded-3xl bg-white px-8 py-6',
          'sm:max-w-auto sm:w-auto sm:min-w-[450px]'
        )}
      >
        <Form control={control} onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <Typography variant="l" className="font-bold">
            {t('account.sectionInfo.editAccountInfo')}
          </Typography>
          <div className="grid gap-3 sm:grid-cols-2">
            <Input
              withBG
              label={t('account.sectionInfo.firstName')}
              placeholder={t('account.sectionInfo.placeholderFirstName')}
              {...register('firstName')}
              errorMessage={errors.firstName?.message || ''}
            />
            <Input
              withBG
              label={t('account.sectionInfo.lastName')}
              placeholder={t('account.sectionInfo.placeholderLastName')}
              {...register('lastName')}
              errorMessage={errors.lastName?.message || ''}
            />
          </div>
          <Input
            withBG
            label={t('account.sectionInfo.email')}
            disabled
            {...register('email')}
            errorMessage={errors.email?.message || ''}
          />
          <Controller
            name="language"
            control={control}
            render={({ field: { value, ...field } }) => (
              <Select
                {...field}
                value={value || ''}
                inputProps={{
                  label: t('account.sectionInfo.language'),
                  withBG: true,
                  errorMessage: errors?.language?.message || '',
                }}
                className="truncate"
                displayEmpty
                renderValue={(value) => {
                  if (!value) {
                    return <span className="text-xs text-gray-500 opacity-40">Select language</span>;
                  }
                  return <span>{renderFriendlyLanguage(value)}</span>;
                }}
              >
                {ORDERED_LANGUAGES.map((key) => {
                  return (
                    <OptionItem value={ELanguage[key]} key={key} className="min-h-fit">
                      {renderFriendlyLanguage(ELanguage[key])}
                    </OptionItem>
                  );
                })}
              </Select>
            )}
          />
          <Controller
            name="currency"
            control={control}
            render={({ field: { value, ...field } }) => (
              <Select
                {...field}
                value={value || ''}
                inputProps={{
                  label: t('account.sectionInfo.currency'),
                  withBG: true,
                  errorMessage: errors?.currency?.message || '',
                }}
                className="truncate"
                displayEmpty
                renderValue={(value) => {
                  if (!value) {
                    return <span className="text-xs text-gray-500 opacity-40">Select currency</span>;
                  }
                  return <span>{value}</span>;
                }}
              >
                {(Object.keys(ECurrency) as Array<keyof typeof ECurrency>).map((key) => {
                  return (
                    <OptionItem value={ECurrency[key]} key={key} className="min-h-fit">
                      {ECurrency[key]}
                    </OptionItem>
                  );
                })}
              </Select>
            )}
          />
          <div className="!mt-6 flex justify-between gap-4">
            <Button onClick={closeDialog} disabled={isLoading} className="flex-1" variant="outlined">
              {t('dialog.cancel')}
            </Button>
            <Button loading={isLoading} className="flex-1" type="submit">
              {t('dialog.save')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
});

const ORDERED_LANGUAGES = [
  ELanguage.ENGLISH,
  ELanguage.FRENCH,
  ELanguage.GERMAN,
  ELanguage.DUTCH,
  ELanguage.SPANISH,
  ELanguage.ITALIAN,
];

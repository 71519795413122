import { useCallback, useState } from 'react';
import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import { LinearProgress, CircularProgress, Box } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { InsertPhotoOutlined } from '@mui/icons-material';
import styles from './ImageItem.module.scss';
import { Avatar, IconButton, Typography } from '@app/components';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { DeleteIcon } from '@app/assets';
import { clsxm } from '@app/styles/clsxm';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { useTranslation } from 'react-i18next';

export interface ImageItemType {
  id: string;
  imageId?: string;
  previewUrl?: string;
  file?: File;
}

export const NO_LOGO_ID = 'NO_LOGO_ID' as const;

interface PropTypes {
  img: ImageItemType;
  disabled?: boolean;
  progress?: number;
  onDeleteEnd: (image: ImageItemType) => Promise<void>;
  onClick?: (image: ImageItemType) => void;
  selected?: boolean;
  noImgText?: string;
}

export const ImageItem: React.FC<PropTypes> = ({
  img,
  disabled,
  progress,
  onDeleteEnd,
  onClick,
  selected,
  noImgText,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const sortable = useSortable({
    id: img.id,
    disabled,
    animateLayoutChanges: (args) => {
      const { isSorting, wasDragging } = args;

      if (isSorting || wasDragging) {
        return defaultAnimateLayoutChanges(args);
      }

      return true;
    },
  });
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = sortable;

  const renderProgress = () => {
    if (typeof progress === 'number') {
      return (
        <div className={styles.Wrapper}>
          <LinearProgress value={progress} className={styles.ProgressLoader} variant="determinate" />
        </div>
      );
    }
  };

  const renderLoader = () => {
    if (loading) {
      return (
        <div className={styles.Wrapper}>
          <CircularProgress className="!text-accent" />
        </div>
      );
    }
    return null;
  };

  const deleteImage = useCallback(async () => {
    setLoading(true);
    try {
      await onDeleteEnd(img);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [img, onDeleteEnd]);

  const clickImage = useCallback(() => {
    onClick && onClick(img);
  }, [img, onClick]);

  return (
    <Box
      className={clsxm(
        styles.Item,
        !loading && !disabled && !isDragging && styles.ItemHover,
        isDragging && styles.ImageDrag,
        'border-[1.5px] border-transparent',
        selected && 'border-accept-main'
      )}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1000 : 0,
      }}
      onClick={clickImage}
      {...attributes}
      {...listeners}
    >
      {typeof progress !== 'number' && !isDragging && !loading && img.id !== NO_LOGO_ID && (
        <ConfirmDialog
          trigger={
            <IconButton variant="transparent" disableRipple className={clsxm(styles.DeleteButton, 'rounded bg-white')}>
              <DeleteIcon />
            </IconButton>
          }
          onConfirm={deleteImage}
          title={t('qr.stepCustomize.logo.deleteLogo')}
          confirmText={t('qr.stepCustomize.logo.yesDelete')}
        />
      )}

      {renderProgress()}
      {renderLoader()}

      {img.id === NO_LOGO_ID ? (
        <Avatar
          className="bg-gray-100"
          alt="avatar"
          variant="rounded"
          skeletonVariant="rectangular"
          width="100%"
          height={100}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <DoNotDisturbAltIcon className="text-4xl text-gray-300" />
            <Typography variant="xs" className="text-[#63758F]">
              {noImgText}
            </Typography>
          </div>
        </Avatar>
      ) : (
        <Avatar
          alt="avatar"
          variant="rounded"
          skeletonVariant="rectangular"
          width="100%"
          height={100}
          src={img.previewUrl}
          imageId={!img.previewUrl ? img.imageId : undefined}
        >
          <InsertPhotoOutlined />
        </Avatar>
      )}
    </Box>
  );
};

import { FileDto } from '@app/models/file';
import { ELibraryType } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { InferType, object, string } from 'yup';

export const LibraryOutDtoSchema = object({
  id: string().required(),
  libraryType: oneOfEnum(ELibraryType),
  createdDate: string().required(),
  file: FileDto,
});
export type LibraryOutDtoSchema = InferType<typeof LibraryOutDtoSchema>;

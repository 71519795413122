import { string, object, bool, InferType } from 'yup';

import { password, email } from '@app/utils/yup.utils';
import { AUTH_QUERY_PARAM_AUTO_LINK_QR_ID } from '@app/modules/auth/auth.constants';
import { PARAM_GOOGLE_CLICK_ID } from '@app/gtag/google.constant';

export const SignInFormValidationSchema = object({
  email: email(),
  password: password(),
});
export type SignInFormValidationSchema = InferType<typeof SignInFormValidationSchema>;

export const SignUpFormValidationSchema = object({
  email: email(),
  privacyPolicy: bool().oneOf([true], 'Accept Term And Privacy Policy').defined('Accept Term And Privacy Policy'),
  [AUTH_QUERY_PARAM_AUTO_LINK_QR_ID]: string().nullable(),
  [PARAM_GOOGLE_CLICK_ID]: string().nullable(),
});
export type SignUpFormValidationSchema = InferType<typeof SignUpFormValidationSchema>;

export const ConfirmCodeValidationSchema = object({
  confirmCode: string().required('Required Field'),
});
export type ConfirmCodeValidationSchema = InferType<typeof ConfirmCodeValidationSchema>;

export const ForgotPasswordValidationSchema = object({
  email: string().required('Required Field'),
});
export type ForgotPasswordValidationSchema = InferType<typeof ForgotPasswordValidationSchema>;

export const ConfirmPasswordValidationSchema = object({
  newPassword: password(),
  confirmNewPassword: password().test(
    'password-and-confirm-password-the-same',
    'The confirmed password must be the same as the password',
    (confirmPassword, { parent: { newPassword } }: { parent: { newPassword: string } }) => {
      return confirmPassword === newPassword;
    }
  ),
});
export type ConfirmPasswordValidationSchema = InferType<typeof ConfirmPasswordValidationSchema>;

import { useTranslation } from 'react-i18next';
import { useCustomerAnalyticsGetScansByDaytime } from '@app/domain/analytics/analytics.query.hooks';
import { QRDaytimeChartBare } from './QRDaytimeChartBare';
import { useCustomerAnalyticsGlobalFilterParams } from '@app/domain/analytics/analytics.url.hooks';
import { Container } from '@app/components';

export const QRAnalyticsDaytimeChart: React.FC = () => {
  const { t } = useTranslation();
  const { afterDate, qrCodeIds } = useCustomerAnalyticsGlobalFilterParams();
  const { data, isLoading } = useCustomerAnalyticsGetScansByDaytime({ afterDate, qrCodeIds });
  return (
    <Container
      className="flex min-h-[340px] flex-col gap-4 lg:rounded-xl"
      isLoading={isLoading}
      title={t('qrAnalytics.scansByDaytime')}
    >
      {data && <QRDaytimeChartBare data={data} />}
    </Container>
  );
};

import { truncate } from 'lodash';
import Draggable from 'react-draggable';

import { clsxm } from '@app/styles/clsxm';

import { Dialog as MuiDialog, DialogProps, DialogTitle, Typography, Paper, PaperProps } from '@mui/material';
import { useIsMobile } from '@app/hooks/useIsMobile.hook';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"], .not-draggable'}>
      <Paper {...props} />
    </Draggable>
  );
}

type Props = {
  headerTitle?: string;
  headerSubtitle?: string;
  headerEntity?: string;
  paperClassName?: string;
  hideHeader?: boolean;
  headerContent?: React.ReactNode;
} & DialogProps;

export const Dialog: React.FC<Props> = ({
  children,
  open,
  headerTitle,
  headerEntity,
  onClose,
  paperClassName,
  hideHeader,
  headerContent,
  headerSubtitle,
  ...props
}) => {
  const isMobile = useIsMobile();

  return (
    <MuiDialog
      open={open}
      PaperProps={{
        className: clsxm('rounded-[40px]', paperClassName),
      }}
      onClose={onClose}
      {...props}
      {...(!isMobile
        ? {
            PaperComponent: PaperComponent,
            'aria-labelledby': 'draggable-dialog-title',
          }
        : {})}
      disablePortal
    >
      {!hideHeader && (
        <DialogTitle id="draggable-dialog-title" className="cursor-move">
          <div>
            <Typography noWrap>{truncate(headerTitle, { length: 120 })}</Typography>
            {!!headerSubtitle && <Typography noWrap>{truncate(headerSubtitle, { length: 120 })}</Typography>}
          </div>

          {headerEntity && <Typography>{headerEntity}</Typography>}
          {headerContent}
        </DialogTitle>
      )}

      {children}
    </MuiDialog>
  );
};

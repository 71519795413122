import { ECornerType, EFrameType, EPatternType } from '@app/swagger-types';
import { oneOfEnum } from '@app/utils/yup.utils';
import { boolean, InferType, number, object, string } from 'yup';

export const TemplateFormSchema = object({
  title: string().required('Required field'),
});
export type TemplateFormSchema = InferType<typeof TemplateFormSchema>;

export const DesignFormSchema = object({
  template: TemplateFormSchema,
  libraryId: string(),
  logoSize: number(),
  frameType: oneOfEnum(EFrameType),
  frameText: string().nullable(),
  frameTextSize: number(),
  frameTextColor: string().required('Required field'),
  frameBackgroundColor: string().required('Required field'),
  backgroundColor: string().required('Required field'),
  patternType: oneOfEnum(EPatternType),
  cornerType: oneOfEnum(ECornerType),
  patternColor: string().required('Required field'),
  cornerColor: string().required('Required field'),
  reusable: boolean(),
});
export type DesignFormSchema = InferType<typeof DesignFormSchema>;

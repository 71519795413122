import { TemplateForm } from '@app/domain/template/components/TemplateForm';
import { Theme, useMediaQuery } from '@mui/material';

export const CreateTemplatePage: React.FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up('xl');
  });

  return <TemplateForm isDesktop={isDesktop} mode="CREATE" />;
};

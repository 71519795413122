import { useAuthorizedUser } from '@app/auth/useAuthorizedUser';
import { Button } from '@app/components';
import { Countdown } from '@app/components/countdown/Countdown';
import { Routes } from '@app/constants/routes';
import { useHandler } from '@app/hooks/useHandler.hook';
import { useTypedNavigate } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { EPlanType } from '@app/swagger-types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SHOULD_SHOW_OLD_BLOCK = false;

export const TrialPeriodCounter: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuthorizedUser();
  const navigate = useTypedNavigate();
  const [isExpired, setIsExpired] = useState(false);
  const isTrial = currentUser?.currentPlanDetails?.planType === EPlanType.TRIAL;
  const expireHandler = useHandler((isExpired: boolean) => setIsExpired(isExpired));

  if (!isTrial || isExpired || !currentUser || !currentUser.currentPlanDetails?.endOfCurrentSubscription) {
    return null;
  }

  const expireDate = new Date(currentUser.currentPlanDetails?.endOfCurrentSubscription);

  return (
    <div>
      <div
        className={clsxm(
          'mx-6 mb-6 flex flex-col items-center justify-center gap-3 rounded-xl bg-accept-menu-item-bg p-4 text-xs text-gray-100',
          'lg:mx-1 lg:mb-2'
        )}
      >
        {SHOULD_SHOW_OLD_BLOCK ? (
          <>
            {isExpired ? <div>{t('trial.yourQRCodesExpired')}</div> : <div>{t('trial.yourQRCodesWillExpireIn')}</div>}
            <div className="font-bold">
              <AccessTimeIcon />{' '}
              <span>
                <Countdown expireDate={expireDate} onExpire={expireHandler} />
              </span>
            </div>
          </>
        ) : (
          <div>{t('trial.morePossibilities')}</div>
        )}
        {!isExpired && (
          <Button
            onClick={() => {
              navigate({ to: Routes.customer.account.subscription });
            }}
            variant="outlined"
            size="small"
            className="w-full"
          >
            {t('trial.upgradeNow')}
          </Button>
        )}
      </div>
    </div>
  );
};

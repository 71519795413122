import { Container, Input, Typography } from '@app/components';
import {
  QRCodeFormSchema,
  regexUrlWithoutProtocol,
  regexUrlWithProtocol,
} from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { lazy, Suspense } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const PhoneInput = lazy(() => import('@app/components/fields/phone-input/PhoneInput'));

export const QRCodeContactDetails: React.FC = () => {
  const { control, trigger } = useFormContext<QRCodeFormSchema>();

  return (
    <Container className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:rounded-xl">
      <Typography className="font-bold md:col-span-2 md:text-l">Contact details</Typography>
      <Controller
        name="vcard.phoneNumber"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Suspense fallback={null}>
            <PhoneInput
              {...field}
              value={value || ''}
              label="Phone number"
              withBG
              onChange={(e) => {
                if (fieldState?.error?.message) {
                  trigger('vcard.phoneNumber');
                }
                onChange(e || '');
              }}
              placeholder="e.g. +1 800 000 000"
              errorMessage={fieldState?.error?.message || ''}
            />
          </Suspense>
        )}
      />
      <Controller
        name="vcard.alternativePhoneNumber"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Suspense fallback={null}>
            <PhoneInput
              {...field}
              value={value || ''}
              onChange={(e) => {
                if (fieldState?.error?.message) {
                  trigger('vcard.alternativePhoneNumber');
                }
                onChange(e || '');
              }}
              label="Alternative phone number"
              withBG
              placeholder="e.g. +1 809 999 999"
              errorMessage={fieldState?.error?.message || ''}
            />
          </Suspense>
        )}
      />
      <Controller
        name="vcard.email"
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.email');
              }
              onChange(e);
            }}
            label="Email"
            withBG
            placeholder="e.g. name@gmail.com"
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
      <Controller
        name="vcard.website"
        control={control}
        render={({ field: { value, onChange, onBlur, ...field }, fieldState }) => (
          <Input
            {...field}
            value={value || ''}
            onChange={(e) => {
              if (fieldState?.error?.message) {
                trigger('vcard.website');
              }
              onChange(e);
            }}
            onBlur={(e) => {
              const value = e.target.value;
              if (regexUrlWithoutProtocol.test(value)) {
                // use both regex to ignore incomplete urls like "facebook" without ".com"
                if (!regexUrlWithProtocol.test(value)) {
                  // TODO reusable util, check other places
                  onChange(`https://${value}`);
                  trigger('vcard.website');
                }
              }
              onBlur();
            }}
            label="Website"
            withBG
            placeholder="e.g. https://website.com"
            errorMessage={fieldState?.error?.message || ''}
          />
        )}
      />
    </Container>
  );
};

import { Container, Typography } from '@app/components';
import { TranslationKey } from '@app/locales/defaultTranslation';
import { EWidgetSignUpTriggerSource } from '@app/qr-widget/widget.constants';
import { clsxm } from '@app/styles/clsxm';
import { ECodeType } from '@app/swagger-types';
import { SwipeableDrawer } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QRCodeFormSchema } from '../../api/qr-code.form.schema.api';
import { CODE_TYPE_ITEMS_OTHERS, CODE_TYPE_ITEM_WEBSITE } from '../../constants';
import { QRCodeTypeItemType } from '../../qr-code.types';
import { SelectQRCodeType } from './SelectQRCodeType';

export const SelectQRCodeTypeContainer: React.FC<{
  isEditMode?: boolean;
  className?: string;
  forWidgetMode?: boolean;
  onSignUpTrigger?: (triggerSource: EWidgetSignUpTriggerSource) => void;
}> = ({ isEditMode, className, forWidgetMode, onSignUpTrigger }) => {
  const { t } = useTranslation();
  const { watch, setValue, clearErrors } = useFormContext<QRCodeFormSchema>();
  const codeType = forWidgetMode ? ECodeType.WEBSITE : watch().codeType;

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const renderTypeItem = ({ value, icon }: QRCodeTypeItemType, hideOnMobile?: boolean) => {
    const { titleKey, subTitleKey } = getTranslationKeyForType(value);
    if (!titleKey || !subTitleKey) {
      return null;
    }
    const isWebsite = value === ECodeType.WEBSITE;
    return (
      <SelectQRCodeType
        key={value}
        value={value}
        className={clsxm(hideOnMobile && 'hidden sm:block')}
        forWidgetMode={forWidgetMode && isWebsite}
        onExpand={
          isWebsite
            ? () => {
                setIsOpenDrawer(true);
              }
            : undefined
        }
        title={t(titleKey)}
        subTitle={t(subTitleKey)}
        isActive={codeType === value}
        icon={icon}
        onClick={(v) => {
          if (forWidgetMode && !isWebsite) {
            if (v === ECodeType.V_CARD) {
              onSignUpTrigger?.(EWidgetSignUpTriggerSource.ON_SELECT_VCARD);
            }
            if (v === ECodeType.MULTILINK) {
              onSignUpTrigger?.(EWidgetSignUpTriggerSource.ON_SELECT_MULTILINK);
            }
            if (v === ECodeType.PDF) {
              onSignUpTrigger?.(EWidgetSignUpTriggerSource.ON_SELECT_PDF);
            }
          }
          if (!isEditMode) {
            setValue('codeType', v);
            clearErrors();
          }
        }}
      />
    );
  };

  return (
    <Container className={clsxm('flex h-full flex-col gap-4 xl:rounded-xl', className)}>
      {!forWidgetMode && (
        <div className="flex items-center justify-between gap-4">
          <Typography className="font-bold sm:text-l">{t('qr.stepType.selectYourQRCodeType')}</Typography>
          {/* TODO: integrate thid button */}
          {/* <Button
          variant="outlined"
          disabled
          className="hidden py-2 xl:inline-flex"
          size="medium"
          startIcon={<BulkIcon />}
        >
          Create Bulk
        </Button> */}
        </div>
      )}
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
        {renderTypeItem(CODE_TYPE_ITEM_WEBSITE)}
        {CODE_TYPE_ITEMS_OTHERS.map((e) => renderTypeItem(e, true))}
        {/* TODO handle other code types for mobile */}
        <SwipeableDrawer
          anchor={'bottom'}
          open={isOpenDrawer}
          disableSwipeToOpen
          onOpen={() => setIsOpenDrawer(true)}
          onClose={() => setIsOpenDrawer(false)}
          classes={{
            paper: /*tw*/ 'bg-transparent',
          }}
        >
          <div className="rounded-t-2xl flex flex-col gap-4 p-4 py-8 bg-white">
            {CODE_TYPE_ITEMS_OTHERS.map((e) => renderTypeItem(e))}
          </div>
        </SwipeableDrawer>
      </div>
    </Container>
  );
};

const getTranslationKeyForType = (type: ECodeType) => {
  if (type === ECodeType.WEBSITE) {
    const titleKey: TranslationKey = 'qr.stepType.websiteURL';
    const subTitleKey: TranslationKey = 'qr.stepType.websiteDescription';
    return { titleKey, subTitleKey };
  }
  if (type === ECodeType.MULTILINK) {
    const titleKey: TranslationKey = 'qr.stepType.multiLinks';
    const subTitleKey: TranslationKey = 'qr.stepType.multiLinksDescription';
    return { titleKey, subTitleKey };
  }
  if (type === ECodeType.PDF) {
    const titleKey: TranslationKey = 'qr.stepType.pdf';
    const subTitleKey: TranslationKey = 'qr.stepType.pdfDescription';
    return { titleKey, subTitleKey };
  }
  if (type === ECodeType.V_CARD) {
    const titleKey: TranslationKey = 'qr.stepType.vCard';
    const subTitleKey: TranslationKey = 'qr.stepType.vCardDescription';
    return { titleKey, subTitleKey };
  }
  return {};
};

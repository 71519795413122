import { ECodeType } from '@app/swagger-types';
import { useFormContext } from 'react-hook-form';
import { QRCodeFormSchema } from '../../api/qr-code.form.schema.api';
import { QRCodeMultilink } from './multilink/QRCodeMultilink';
import { QRCodePdf } from './pdf/QRCodePdf';
import { QRCodeVCard } from './vcard/QRCodeVCard';
import { QRCodeWebsiteUrl } from './website/QRCodeWebsiteUrl';

export const QRCodeInputContainer: React.FC = () => {
  const { watch } = useFormContext<QRCodeFormSchema>();
  const codeType = watch('codeType');

  if (codeType === ECodeType.WEBSITE) {
    return <QRCodeWebsiteUrl />;
  }

  if (codeType === ECodeType.MULTILINK) {
    return <QRCodeMultilink />;
  }

  if (codeType === ECodeType.PDF) {
    return <QRCodePdf />;
  }

  if (codeType === ECodeType.V_CARD) {
    return <QRCodeVCard />;
  }

  return (
    <div className="flex-1 bg-white px-5 py-6 xl:rounded-xl">
      {codeType} section
      <div>In progress</div>
    </div>
  );
};

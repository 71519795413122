import clsx from 'clsx';
import { useState, useRef } from 'react';

import { getFilesAsync } from '@app/utils/file.utils';
import { FileUploadInput } from '@app/components/file-upload-input/FileUploadInput';
import styles from './FileDropZoneWrapper.module.scss';

export type FileDropZoneWrapperProps = {
  className?: string;
  hoverClassName?: string;
  accept?: string;
  allowedTypes?: string[];
  multiple?: boolean;
  // TODO handle loading state, auto upload selected files
  loading?: boolean;
  onChange?: (data: File) => void;
  onMultipleChange?: (data: File[]) => void;
  onClick?: () => void;
  onNotAllowed?: (file?: File) => void;
};

export const FileDropZoneWrapper: React.FC<FileDropZoneWrapperProps> = ({
  className,
  hoverClassName,
  accept,
  allowedTypes,
  children,
  multiple,
  onNotAllowed,
  onChange,
  onMultipleChange,
  onClick,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dropHover, setDropHover] = useState(false);

  const pickFileHandler = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
    onClick && onClick();
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
    setDropHover(true);
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDropHover(false);
  };

  const onFileUpload = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (multiple && onMultipleChange) {
      setDropHover(false);

      const files = await getFilesAsync(e.dataTransfer);
      for (let i = 0; i < files.length; i++) {
        if (allowedTypes && allowedTypes.indexOf(files[i]?.type || '') === -1) {
          e.dataTransfer.clearData();
          if (onNotAllowed) {
            onNotAllowed(files[i]);
          }
          return;
        }
      }

      return onMultipleChange(files);
    }
    const file = e.dataTransfer.files[0];
    if (file && onChange) {
      setDropHover(false);
      if (allowedTypes && allowedTypes.indexOf(file.type) === -1) {
        e.dataTransfer.clearData();
        if (onNotAllowed) {
          onNotAllowed(file);
        }
        return;
      }
      onChange(file);
    }
  };

  return (
    <div
      className={clsx(
        {
          [styles.DropHover]: dropHover,
          ...(hoverClassName ? { [hoverClassName]: dropHover } : {}),
        },
        className
      )}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={onFileUpload}
      onClick={pickFileHandler}
      aria-hidden
    >
      {children}
      <FileUploadInput
        multiple={multiple}
        onMultipleChange={onMultipleChange}
        onChange={onChange}
        onNotAllowed={onNotAllowed}
        accept={accept}
        allowedTypes={allowedTypes}
        ref={fileInputRef}
      />
    </div>
  );
};

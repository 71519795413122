import { AppLogo } from '@app/assets';
import { typedEnv } from '@app/environment/typed-env';
import { useMemo } from 'react';
import { UIStyledSection } from '../components/UIStyledSection';

export const UITabBranding: React.FC = () => {
  const memoMeta = useMemo(() => {
    const faviconTag = document.querySelector<HTMLLinkElement>('link[rel="icon"');
    const metaTags = [...document.querySelectorAll('meta')];
    const errors: Error[] = [];
    if (!faviconTag) {
      errors.push(new Error('no favicon'));
    }
    metaTags.forEach((e) => {
      if (e.parentElement !== document.head) {
        const err = new Error('meta tag out of head');
        console.error(err, e);
        errors.push(err);
      }
    });
    return { faviconTag, metaTags, errors };
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="mt-6 border-l-2 border-gray-400 bg-gray-100 p-2 italic">
        Branding - general user facing appearences, links and messages about the product.
      </div>
      <UIStyledSection title="App logo">
        <AppLogo />
      </UIStyledSection>
      <UIStyledSection title="Browser tab and Google search appearence">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            {memoMeta.errors.map((e, i) => (
              <div key={i} className="bg-red-200 p-1 px-2 font-bold text-red-700">
                {String(e)}
              </div>
            ))}
          </div>
          <div className="flex gap-4">
            <div>
              <img
                src={memoMeta.faviconTag?.getAttribute('href') || '/invalid-favicon-url'}
                alt="browser tab icon"
                width={32}
                height={32}
              />
            </div>
            <div className="flex flex-col gap-4">
              <div className="font-bold">{typedEnv.REACT_APP_TITLE}</div>
              <div className="text-gray-500">{typedEnv.REACT_APP_DESCRIPTION}</div>
            </div>
          </div>
        </div>
      </UIStyledSection>
      <UIStyledSection title="Meta tags">
        <div className="flex flex-col gap-4">
          {memoMeta.metaTags.map((e, i) => {
            const name = e.name;
            const property = e.getAttribute('property');
            if (!name && !property) {
              return null;
            }
            return (
              <div key={i} className="flex gap-4 p-1 px-2">
                {name ? (
                  <>
                    META name <span className="rounded bg-sky-200 p-1 px-2 font-bold text-sky-700">{name}</span>
                  </>
                ) : (
                  <>
                    META property{' '}
                    <span className="rounded bg-pink-200 p-1 px-2 font-bold text-pink-700">{property}</span>
                  </>
                )}
                <div className="rounded bg-purple-200 p-1 px-2 font-bold text-purple-700">{e.content}</div>
              </div>
            );
          })}
        </div>
      </UIStyledSection>
    </div>
  );
};

import { ColorPickerInput, Input, Typography } from '@app/components';
import { clsxm } from '@app/styles/clsxm';
import { EFrameType } from '@app/swagger-types';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { DesignFormSchema } from '../../api/template.form.schema.api';
import { FrameContainer } from '../../../qr-code/components/frame/FrameContainer';
import { useTranslation } from 'react-i18next';

const ORDERED_FRAME_TYPES = [
  EFrameType.NONE,
  EFrameType.BORDER_LABEL_BOTTOM,
  EFrameType.BORDER_LABEL_TOP,
  EFrameType.ARROW_LABEL_TOP,
  EFrameType.ARROW_LABEL_BOTTOM,
  EFrameType.BORDER_SPACE_LABEL_BOTTOM,
  EFrameType.BORDER_SPACE_LABEL_TOP,
  EFrameType.BORDER_INTERRUPTION,
  EFrameType.NO_BORDER,
  EFrameType.BORDER,
];

export const TemplateFrameStep: React.FC = () => {
  const { t } = useTranslation();
  const { control, register, watch, setValue, getValues } = useFormContext<DesignFormSchema>();
  const { frameType } = watch();

  return (
    <div className="space-y-4">
      <Typography variant="m" className="font-bold">
        {t('qr.stepCustomize.frame.tabTitle')}
      </Typography>
      <div className="grid grid-cols-3 gap-y-4 gap-x-2 sm:grid-cols-4 lg:grid-cols-5 2xl:gap-x-4">
        {ORDERED_FRAME_TYPES.map((key) => {
          return (
            <FrameContainer
              key={key}
              isOption
              selected={frameType === EFrameType[key]}
              type={EFrameType[key]}
              onClick={(v) => {
                setValue('frameType', v);
                console.log('frameType', v);
                const currentColor = getValues('frameTextColor').toUpperCase();
                const BLACK = '#000000';
                const WHITE = '#FFFFFF';
                // TODO reusable utils for design default reset
                if (
                  [
                    EFrameType.BORDER_LABEL_BOTTOM,
                    EFrameType.BORDER_LABEL_TOP,
                    EFrameType.ARROW_LABEL_TOP,
                    EFrameType.ARROW_LABEL_BOTTOM,
                    EFrameType.BORDER_SPACE_LABEL_BOTTOM,
                  ].includes(v)
                ) {
                  if (currentColor === BLACK) {
                    setValue('frameTextColor', WHITE);
                  }
                } else {
                  if (currentColor === WHITE) {
                    setValue('frameTextColor', BLACK);
                  }
                }
              }}
            />
          );
        })}
      </div>
      <div
        className={clsxm(
          'grid grid-cols-1 gap-4 pt-2 sm:grid-cols-2',
          frameType === EFrameType.NONE && 'pointer-events-none opacity-70'
        )}
      >
        <Input {...register('frameText')} label={t('qr.design.frameCustomText')} withBG placeholder="SCAN ME" />
        <Controller
          name="frameTextSize"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <NumberFormat
              {...field}
              allowNegative={false}
              placeholder="0"
              label={t('qr.design.frameTextSize')}
              autoComplete="off"
              withBG
              value={value || ''}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (e.key === 'ArrowUp') {
                  onChange((value || 0) + 1);
                } else if (e.key === 'ArrowDown' && value) {
                  onChange(value - 1);
                }
              }}
              onValueChange={(values) => {
                onChange(Math.max(Number(values.value), 0));
              }}
              customInput={Input}
            />
          )}
        />

        <Controller
          name="frameTextColor"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <ColorPickerInput
              inputProps={{ label: t('qr.design.frameTextColor'), placeholder: '#000000', ...field }}
              color={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="frameBackgroundColor"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <ColorPickerInput
              // TODO use values from default instance
              inputProps={{ label: t('qr.design.frameBackgroundColor'), placeholder: '#000000', ...field }}
              color={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="patternColor"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <ColorPickerInput
              inputProps={{ label: t('qr.design.patternColor'), placeholder: '#000000', ...field }}
              color={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="backgroundColor"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <ColorPickerInput
              // TODO use values from default instance
              inputProps={{ label: t('qr.design.backgroundColor'), placeholder: '#000000', ...field }}
              color={value}
              onChange={onChange}
            />
          )}
        />
      </div>
    </div>
  );
};

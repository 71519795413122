import { useMount } from 'react-use';

import { AuthStatus } from '@app/auth/api/auth.type';

import { useStore } from '@app/store/useStore.hook';

import { RootRoutes } from './RootRoutes';
import { useFiltredRoutes } from '@app/router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_CODES } from '@app/i18n/i18.utils';
import { isCustomer } from '@app/auth/auth.utils';
import { gtag } from '@app/gtag/gtag';

export const RootLayoutElements = () => {
  const elements = useFiltredRoutes(RootRoutes);

  return <>{elements}</>;
};

export const RootLayout: React.FC = () => {
  const {
    i18n: { language, changeLanguage },
  } = useTranslation();
  const user = useStore.useCurrentUser();
  const getMe = useStore.useGetMe();
  const authStatus = useStore.useAuthStatus();

  useMount(() => {
    getMe();
  });

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!(user.language in LANGUAGE_CODES)) {
      return;
    }
    if (!Object.values(LANGUAGE_CODES).find((e) => e === language)) {
      return;
    }
    const code = LANGUAGE_CODES[user.language];
    if (language !== code) {
      changeLanguage(code);
    }
  }, [user, language, changeLanguage]);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!isCustomer(user)) {
      return;
    }
    gtag.initialSessionStart({ user_id: user.id });
  }, [user]);

  if (authStatus === AuthStatus.UNKNOWN) {
    return null;
  }

  return <RootLayoutElements />;
};

export const MOCK_CITIES = [
  { city: 'Kyiv', latitude: 50.4501, longitude: 30.5234 },
  { city: 'Warsaw', latitude: 52.2297, longitude: 21.0122 },
  { city: 'Berlin', latitude: 52.52, longitude: 13.405 },
  { city: 'London', latitude: 51.5074, longitude: -0.1278 },
  { city: 'New York', latitude: 40.7128, longitude: -74.006 },
  { city: 'Amsterdam', latitude: 52.3676, longitude: 4.9041 },
  { city: 'Paris', latitude: 48.8566, longitude: 2.3522 },
  { city: 'Tokyo', latitude: 35.6895, longitude: 139.6917 },
  { city: 'Delhi', latitude: 28.6139, longitude: 77.209 },
  { city: 'Shanghai', latitude: 31.2304, longitude: 121.4737 },
  { city: 'São Paulo', latitude: -23.5505, longitude: -46.6333 },
  { city: 'Mexico City', latitude: 19.4326, longitude: -99.1332 },
  { city: 'Cairo', latitude: 30.0444, longitude: 31.2357 },
  { city: 'Mumbai', latitude: 19.076, longitude: 72.8777 },
  { city: 'Beijing', latitude: 39.9042, longitude: 116.4074 },
  { city: 'Dhaka', latitude: 23.8103, longitude: 90.4125 },
  { city: 'Osaka', latitude: 34.6937, longitude: 135.5022 },
  { city: 'Karachi', latitude: 24.8607, longitude: 67.0011 },
  { city: 'Buenos Aires', latitude: -34.6118, longitude: -58.4173 },
  { city: 'Chongqing', latitude: 29.4316, longitude: 106.9123 },
  { city: 'Istanbul', latitude: 41.0082, longitude: 28.9784 },
  { city: 'Kolkata', latitude: 22.5726, longitude: 88.3639 },
  { city: 'Manila', latitude: 13.41, longitude: 122.56 },
  { city: 'Lagos', latitude: 6.5244, longitude: 3.3792 },
  { city: 'Rio de Janeiro', latitude: -22.9068, longitude: -43.1729 },
  { city: 'Tianjin', latitude: 39.3434, longitude: 117.3616 },
  { city: 'Kinshasa', latitude: -4.4419, longitude: 15.2663 },
  { city: 'Guangzhou', latitude: 23.1291, longitude: 113.2644 },
  { city: 'Los Angeles', latitude: 34.0522, longitude: -118.2437 },
  { city: 'Shenzhen', latitude: 22.5431, longitude: 114.0579 },
  { city: 'Lahore', latitude: 31.5497, longitude: 74.3436 },
  { city: 'Bangalore', latitude: 12.9716, longitude: 77.5946 },
  { city: 'Bogotá', latitude: 4.711, longitude: -74.0721 },
  { city: 'Jakarta', latitude: -6.2088, longitude: 106.8456 },
  { city: 'Chennai', latitude: 13.0827, longitude: 80.2707 },
  { city: 'Lima', latitude: -12.0464, longitude: -77.0428 },
  { city: 'Bangkok', latitude: 13.7563, longitude: 100.5018 },
  { city: 'Seoul', latitude: 37.5665, longitude: 126.978 },
  { city: 'Nagoya', latitude: 35.1815, longitude: 136.9066 },
  { city: 'Hyderabad', latitude: 17.385, longitude: 78.4867 },
  { city: 'Tehran', latitude: 35.6895, longitude: 51.389 },
  { city: 'Chicago', latitude: 41.8781, longitude: -87.6298 },
  { city: 'Chengdu', latitude: 30.5728, longitude: 104.0668 },
  { city: 'Nanjing', latitude: 32.0603, longitude: 118.7969 },
  { city: 'Wuhan', latitude: 30.5928, longitude: 114.3055 },
  { city: 'Ho Chi Minh City', latitude: 10.7769, longitude: 106.7009 },
  { city: 'Luanda', latitude: -8.8399, longitude: 13.2894 },
  { city: 'Ahmedabad', latitude: 23.0225, longitude: 72.5714 },
  { city: 'Kuala Lumpur', latitude: 3.139, longitude: 101.6869 },
  { city: "Xi'an", latitude: 34.3416, longitude: 108.9398 },
  { city: 'Hong Kong', latitude: 22.3193, longitude: 114.1694 },
  { city: 'Dongguan', latitude: 23.0205, longitude: 113.7518 },
  { city: 'Hangzhou', latitude: 30.2741, longitude: 120.1551 },
  { city: 'Foshan', latitude: 23.0219, longitude: 113.1214 },
  { city: 'Shenyang', latitude: 41.8057, longitude: 123.4315 },
  { city: 'Riyadh', latitude: 24.7136, longitude: 46.6753 },
  { city: 'Baghdad', latitude: 33.3152, longitude: 44.3661 },
  { city: 'Santiago', latitude: -33.4489, longitude: -70.6693 },
  { city: 'Surat', latitude: 21.1702, longitude: 72.8311 },
  { city: 'Madrid', latitude: 40.4168, longitude: -3.7038 },
  { city: 'Suzhou', latitude: 31.2989, longitude: 120.5853 },
  { city: 'Pune', latitude: 18.5204, longitude: 73.8567 },
  { city: 'Harbin', latitude: 45.8038, longitude: 126.5349 },
  { city: 'Houston', latitude: 29.7604, longitude: -95.3698 },
  { city: 'Dallas', latitude: 32.7767, longitude: -96.797 },
  { city: 'Toronto', latitude: 43.651, longitude: -79.347 },
  { city: 'Dar es Salaam', latitude: -6.7924, longitude: 39.2083 },
  { city: 'Miami', latitude: 25.7617, longitude: -80.1918 },
  { city: 'Belo Horizonte', latitude: -19.9167, longitude: -43.9345 },
  { city: 'Singapore', latitude: 1.3521, longitude: 103.8198 },
  { city: 'Philadelphia', latitude: 39.9526, longitude: -75.1652 },
  { city: 'Atlanta', latitude: 33.749, longitude: -84.388 },
  { city: 'Fukuoka', latitude: 33.5904, longitude: 130.4017 },
  { city: 'Khartoum', latitude: 15.5007, longitude: 32.5599 },
  { city: 'Barcelona', latitude: 41.3851, longitude: 2.1734 },
  { city: 'Johannesburg', latitude: -26.2041, longitude: 28.0473 },
  { city: 'Qingdao', latitude: 36.0671, longitude: 120.3826 },
  { city: 'Dalian', latitude: 38.914, longitude: 121.6147 },
  { city: 'Washington', latitude: 38.8951, longitude: -77.0364 },
  { city: 'Yangon', latitude: 16.8409, longitude: 96.1735 },
  { city: 'Alexandria', latitude: 31.2156, longitude: 29.9553 },
  { city: 'Jinan', latitude: 36.6512, longitude: 117.1201 },
  { city: 'Guadalajara', latitude: 20.6595, longitude: -103.3494 },
  { city: 'Sydney', latitude: -33.8688, longitude: 151.2093 },
  { city: 'Melbourne', latitude: -37.8136, longitude: 144.9631 },
  { city: 'Brisbane', latitude: -27.4698, longitude: 153.0251 },
  { city: 'Perth', latitude: -31.9505, longitude: 115.8605 },
  { city: 'Adelaide', latitude: -34.9285, longitude: 138.6007 },
  { city: 'Hobart', latitude: -42.8821, longitude: 147.3272 },
  { city: 'Darwin', latitude: -12.4634, longitude: 130.8456 },
];

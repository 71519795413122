import { Button, Typography } from '@app/components';
import { useTranslation } from 'react-i18next';
import { HELPCENTER_LINK_EXTERNAL } from './help.constants';

/**
 * @deprecated
 */
export const HelpPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="relative flex h-full flex-col gap-4 p-6">
      <div className="flex items-center justify-between gap-4">
        <Typography variant="xl" className="font-bold lg:text-xxl">
          {t('support.pageTitle')}
        </Typography>
      </div>
      <div className="grid h-1/2 place-items-center">
        <div className="flex flex-col gap-4 text-center">
          <div>{t('support.haveAQuestion')}</div>
          <div>
            <a className="font-bold" href={HELPCENTER_LINK_EXTERNAL} target="_blank" rel="noreferrer">
              <Button fullWidth variant="contained">
                {t('support.helpcenter')}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

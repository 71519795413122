import { EAuthMethod } from '@app/auth/constant';
import { envOverride } from '@app/environment/env-override';
import { typedEnv } from '@app/environment/typed-env';
import { Button, Tooltip } from '@app/components';
import { DevKitSection } from '../DevKitSection';

export const EnvOverrideDevKitSection: React.FC = () => {
  const toggleTarget = typedEnv.REACT_APP_AUTH_METHOD === EAuthMethod.COOKIE ? EAuthMethod.TOKEN : EAuthMethod.COOKIE;
  return (
    <DevKitSection title="Environment override">
      <div>
        Current auth method: <b>{typedEnv.REACT_APP_AUTH_METHOD}</b>
      </div>
      <div>
        <Tooltip title="this will reload the app">
          <Button
            onClick={() => {
              envOverride.persist({
                REACT_APP_AUTH_METHOD: toggleTarget,
              });
              window.location.reload();
            }}
          >
            <span>Swith To {toggleTarget}</span>
          </Button>
        </Tooltip>
        <div>
          <div>
            Override:
            {envOverride.state.REACT_APP_AUTH_METHOD ? (
              <>
                <pre>{JSON.stringify(envOverride.state, null, 2)}</pre>
                <Tooltip title="this will reload the app">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      envOverride.cleanup();
                      window.location.reload();
                    }}
                  >
                    <span>Reset Override</span>
                  </Button>
                </Tooltip>
              </>
            ) : (
              <strong className="ml-2">not applied</strong>
            )}
          </div>
        </div>
      </div>
    </DevKitSection>
  );
};

import { clsxm } from '@app/styles/clsxm';
import { EInvoiceStatus } from '@app/swagger-types';
import { useTranslation } from 'react-i18next';

export const InvoiceStatusBadge: React.FC<{
  status: EInvoiceStatus;
  className?: string;
}> = ({ status, className }) => {
  const { t } = useTranslation();
  const isPaid = status === EInvoiceStatus.PAID;
  const isFailed = status === EInvoiceStatus.FAILED;
  const isPending = status === EInvoiceStatus.OPEN;
  const isOther = !isPaid && !isFailed;
  const renderFriendlyStatus = () => {
    if (isPaid) {
      return t('billing.invoice.paid');
    }
    if (isFailed) {
      return t('billing.invoice.failed');
    }
    if (isPending) {
      return t('billing.invoice.pending');
    }
  };

  return (
    <div className="inline-block">
      <div
        className={clsxm(
          'inline-flex items-center rounded px-2 py-[2px] text-xs',
          isFailed && 'bg-red-200 text-red-800',
          isPaid && 'bg-accept-light text-accept-main',
          isOther && 'bg-gray-200 text-gray-800',
          className
        )}
      >
        {renderFriendlyStatus() || status}
      </div>
    </div>
  );
};

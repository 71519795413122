import { AuthStatus } from '@app/auth/api/auth.type';
import { AUTH_TOKEN_KEY } from '@app/auth/constant';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { Routes } from '@app/constants/routes';
import { gtag } from '@app/gtag/gtag';
import { EGTAGRegistrationOrigin, EGTAGRegistrationPopUp } from '@app/gtag/gtag.type';
import { useTypedNavigate } from '@app/router';
import { useStore } from '@app/store/useStore.hook';
import localforage from 'localforage';
import { useMount } from 'react-use';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import {
  AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_POPUP,
  AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_SOURCE,
  AUTH_QUERY_PARAM_SIGN_UP_REQUEST_TYPE,
  AUTH_QUERY_PARAM_TOKEN,
} from '../auth.constants';

export const OAuth2AuthorizationPage: React.FC = () => {
  const [token, setToken] = useQueryParam(AUTH_QUERY_PARAM_TOKEN, withDefault(StringParam, null));
  const [requestType, setRequestType] = useQueryParam(
    AUTH_QUERY_PARAM_SIGN_UP_REQUEST_TYPE,
    withDefault(StringParam, null)
  );
  const [forwardedSource] = useQueryParam(AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_SOURCE, withDefault(StringParam, null));
  const [forwardedPopup] = useQueryParam(AUTH_QUERY_PARAM_FORWARDED_SIGN_UP_POPUP, withDefault(StringParam, null));
  const navigate = useTypedNavigate();

  const getMe = useStore.useGetMe();
  const authStatus = useStore.useAuthStatus();

  // "as" is fine here because any string may be useful for analytics
  const origin = (forwardedSource as EGTAGRegistrationOrigin | undefined) || EGTAGRegistrationOrigin.DIRECT;
  // "as" is fine here because any string may be useful for analytics
  const popup = forwardedPopup as EGTAGRegistrationPopUp | undefined;

  useMount(async () => {
    try {
      if (!token) {
        navigate({ to: Routes.auth.sign_in });
      }
      await localforage.setItem(AUTH_TOKEN_KEY, token);
      setToken(undefined);
      const user = await getMe();
      if (requestType === 'signup' && user) {
        gtag.signUp({
          authentication_method: 'google',
          user_email: user.email,
          user_id: user.id,
          origin,
          popup,
        });
      }
      setRequestType(undefined);
    } catch {
      navigate({ to: Routes.auth.sign_in });
    }
  });

  if (authStatus === AuthStatus.UNKNOWN) {
    return null;
  }

  return <ContentLoader loading={true} entity="Google" isEmpty={false} />;
};

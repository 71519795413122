import { useStore } from '@app/store/useStore.hook';

export const usePlanLimits = () => {
  const currentUser = useStore.useCurrentUser();
  const planDetails = currentUser?.currentPlanDetails;

  return {
    allLimits: planDetails,
    currentUser,
    canCreateQRCode: checkIsFirstNumberLess(currentUser?.totalCodes, planDetails?.amountMaxQrCodes),
    canCreateTemplate: planDetails?.hasTemplates,
  };
};

const checkIsFirstNumberLess = (first?: number, second?: number) => {
  if (typeof first !== 'number' || typeof second !== 'number') {
    return;
  }
  return first < second;
};

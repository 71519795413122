export const QRstatus: React.FC<{
  isDeleted?: boolean;
  createdBy?: object;
}> = ({ isDeleted, createdBy }) => {
  if (isDeleted) {
    return <div className="rounded bg-red-400 p-1 text-red-900">DELETED</div>;
  }
  if (createdBy) {
    return <div className="rounded bg-sky-200 p-1 text-sky-900">ASSIGNED</div>;
  }
  return <div className="rounded bg-purple-200 p-1 text-purple-900">NOT ASSIGNED</div>;
};

import { DeleteIcon, EditIcon } from '@app/assets';
import { Checkbox, IconButton } from '@app/components';
import { QRCodeRenderer } from '@app/domain/qr-code/components/QRCodeRenderer';
import { extractStylePropsFromQRDesign } from '@app/domain/qr-code/qr-code.utils';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { clsxm } from '@app/styles/clsxm';
import { DesignOutDto } from '@app/swagger-types';

export const TemplateContainer: React.FC<{
  template: DesignOutDto;
  deleteTemplate: (design: DesignOutDto) => void;
  handleNavigate: (id: string) => void;
  selected?: boolean;
  handleSelectDesign?: (design: DesignOutDto, selected: boolean) => void;
  disalbed?: boolean;
  className?: string;
  qrClassName?: string;
}> = ({
  template,
  deleteTemplate,
  handleNavigate,
  handleSelectDesign,
  disalbed = false,
  selected = false,
  className,
  qrClassName,
}) => {
  return (
    <div className={clsxm('flex flex-col gap-1 overflow-hidden rounded-xl border border-gray-200 bg-white', className)}>
      <div className="flex flex-1 flex-col gap-1 bg-white px-2 py-3">
        <div className="flex items-center justify-between gap-3">
          {handleSelectDesign ? (
            <Checkbox
              checked={selected}
              onChange={() => {
                handleSelectDesign(template, !selected);
              }}
            />
          ) : (
            <div />
          )}
          <div className="flex gap-[2px]">
            <IconButton
              onClick={() => {
                if (template?.template?.id) {
                  handleNavigate(template?.template?.id);
                }
              }}
              variant="transparent"
              size="small"
            >
              <EditIcon />
            </IconButton>
            <ConfirmDialog
              trigger={
                <IconButton disabled={disalbed} variant="transparent" size="small">
                  <DeleteIcon />
                </IconButton>
              }
              onConfirm={() => {
                if (template?.template?.id) {
                  deleteTemplate(template);
                }
              }}
              title="Delete template?"
              confirmText="Yes, Delete"
            />
          </div>
        </div>
        <div className="py-2 px-2 sm:px-4 xl:px-10">
          <QRCodeRenderer
            asPreview
            {...extractStylePropsFromQRDesign(template)}
            qrTemplateId={template.id}
            frameClassName={clsxm('h-full w-full mx-auto', qrClassName)}
          />
        </div>
      </div>
      <div className="bg-gray-100 py-3 text-center text-gray-700">{template?.template?.title || ''}</div>
    </div>
  );
};

import { PaginatedResponse } from '@app/api/api.type';
import { GET_TEMPLATES_QUERY, GET_TEMPLATE_QUERY } from '@app/constants/query-api-configs';
import { APIQueryParams } from '@app/models/api';
import { DesignOutDto, TemplateDeleteInDto } from '@app/swagger-types';
import { buildSortParam } from '@app/utils/api.util';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { TEMPLATES_PAGE_SIZE } from '../constants';
import { TemplateApi } from './template.api';
import { DesignOutDtoSchema } from './template.schema.api';
import { OverriddenDesignInDto } from './template.types.api';

export const useGetTemplates = (
  params: APIQueryParams,
  options?: UseInfiniteQueryOptions<
    unknown,
    Error,
    PaginatedResponse<DesignOutDto>,
    unknown,
    (typeof GET_TEMPLATES_QUERY.name | APIQueryParams)[]
  >
) => {
  return useInfiniteQuery(
    [GET_TEMPLATES_QUERY.name, params],
    async ({ pageParam = 0 }) => {
      return await TemplateApi.getTemplates({
        ...params,
        sort: params.sort ?? buildSortParam<DesignOutDto>('createdDate', 'desc'),
        size: params.size ?? TEMPLATES_PAGE_SIZE,
        page: pageParam || 0,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length;
        return nextPage;
      },
      staleTime: GET_TEMPLATES_QUERY.config.staleTime,
      keepPreviousData: true,
      ...options,
    }
  );
};

export const useCreateTemplate = ({
  onSuccess,
  ...options
}: UseMutationOptions<DesignOutDtoSchema, Error, OverriddenDesignInDto> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (Dto) => {
      return await TemplateApi.createTemplate(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_TEMPLATES_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useDeleteTemplate = ({ onSuccess, ...options }: UseMutationOptions<unknown, Error, string> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (Dto) => {
      return await TemplateApi.deleteTemplate(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_TEMPLATES_QUERY.name);
        queryClient.invalidateQueries(GET_TEMPLATE_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useMultiDeleteTemplates = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, TemplateDeleteInDto> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (Dto) => {
      return await TemplateApi.multiDeleteTemplates(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_TEMPLATES_QUERY.name);
        queryClient.invalidateQueries(GET_TEMPLATE_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useUpdateTemplate = ({
  onSuccess,
  ...options
}: UseMutationOptions<DesignOutDtoSchema, Error, OverriddenDesignInDto & { templateId: string }> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (Dto) => {
      return await TemplateApi.updateTemplate(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_TEMPLATES_QUERY.name);
        queryClient.invalidateQueries(GET_TEMPLATE_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useGetTemplateById = (
  id?: string | null,
  options?: UseQueryOptions<
    unknown,
    Error,
    DesignOutDto | undefined,
    (typeof GET_TEMPLATE_QUERY.name | string | undefined | null)[]
  >
) => {
  return useQuery(
    [GET_TEMPLATE_QUERY.name, id],
    async () => {
      if (!id) {
        return;
      }
      return await TemplateApi.getById(id);
    },
    {
      staleTime: GET_TEMPLATE_QUERY.config.staleTime,
      ...options,
    }
  );
};

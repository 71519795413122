import { Button, Container } from '@app/components';
import { Routes } from '@app/constants/routes';
import { BillingApi } from '@app/domain/billing/api/billing.api';
import { PaymentMethodDetails } from '@app/domain/billing/PaymentMethodDetails';
import { useActivePlan } from '@app/domain/plan/hooks/useActivePlan';
import { SubscriptionStatusBadge } from '@app/domain/subscription/SubscriptionStatusBadge';
import { SubscriptionPriceContainer } from '@app/domain/user/components/subscription/SubscriptionPriceContainer';
import { useTypedNavigate } from '@app/router';
import { clsxm } from '@app/styles/clsxm';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { CustomerInvoicesTable } from './invoices/CustomerInvoicesTable';

export const AccountBillingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const { plan, isTrial, endOfSubscriptionDate, planType, planStatus } = useActivePlan();

  const { data: methodInfo } = useQuery({
    queryFn: async () => BillingApi.getMyPaymentMethod(),
  });

  const {
    data: customerPortal,
    isLoading,
    mutate: mutateUpdatePaymentMethod,
  } = useMutation({
    mutationFn: async () => BillingApi.goToUpdatePaymentMethod({ returnURL: window.location.href }),
  });

  if (customerPortal) {
    window.location.href = customerPortal.url;
  }

  const isPortalLoading = isLoading || Boolean(customerPortal);

  return (
    <div className="flex flex-col gap-6">
      <div className="grid gap-6 md:grid-cols-2">
        <Container className={clsxm('h-full w-full rounded-none md:rounded-xl')}>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <b>
                {plan?.name} {t('billing.plan')}
              </b>
              <div>
                <SubscriptionStatusBadge
                  endOfSubscriptionDate={endOfSubscriptionDate}
                  planType={planType}
                  planStatus={planStatus}
                />
              </div>
            </div>
            {plan && (
              <div>
                <SubscriptionPriceContainer planPrice={plan.price} className="items-start gap-2" />
              </div>
            )}
            <div>
              <a href={Routes.customer.account.subscription}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate({ to: Routes.customer.account.subscription });
                  }}
                >
                  {t('subscription.buttonManagePlan')}
                </Button>
              </a>
            </div>
          </div>
        </Container>
        {!isTrial && (
          <Container className={clsxm('h-full w-full rounded-none md:rounded-xl')}>
            <div className="flex flex-col gap-4">
              <div>
                <b>{t('billing.paymentMethod')}</b>
              </div>
              {methodInfo && <PaymentMethodDetails info={methodInfo} />}
              <div>
                <Button
                  size="small"
                  variant="outlined"
                  loading={isPortalLoading}
                  onClick={() => {
                    mutateUpdatePaymentMethod();
                  }}
                >
                  {t('billing.updatePaymentMethod')}
                </Button>
              </div>
            </div>
          </Container>
        )}
      </div>
      <CustomerInvoicesTable />
    </div>
  );
};

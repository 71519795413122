import { QrCodeIcon } from '@app/assets';
import { Button, Container, Typography } from '@app/components';
import { SmartProgressBar } from '@app/components/progress/SmartProgressBar';
import { Routes } from '@app/constants/routes';
import { useTypedNavigate } from '@app/router';
import { useStore } from '@app/store/useStore.hook';
import { useTranslation } from 'react-i18next';

export const AccountUsage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useTypedNavigate();
  const user = useStore.useCurrentUser();

  return (
    <Container className="flex flex-col gap-6 p-6 lg:rounded-xl">
      <Typography variant="m" className="font-bold">
        {t('account.sectionUsage.title')}
      </Typography>
      <div className="flex flex-col gap-6 lg:flex-row lg:gap-10">
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex items-center gap-4 text-gray-700">
            <QrCodeIcon />
            <div>
              {t('account.sectionUsage.amountOfQRsCreated', {
                totalQRCodes: user?.totalCodes || 0,
                maxQRCodes: user?.currentPlanDetails?.amountMaxQrCodes || 0,
              })}
            </div>
          </div>
          <SmartProgressBar value={user?.totalCodes} max={user?.currentPlanDetails?.amountMaxQrCodes} />
        </div>
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex items-center gap-4 text-gray-700">
            <QrCodeIcon />
            <div>
              {t('account.sectionUsage.amountOfScans', {
                totalScans: user?.monthlyScans || 0,
                maxScans: user?.currentPlanDetails?.amountMaxScans || `∞`,
              })}
            </div>
          </div>
          <SmartProgressBar
            value={!user?.currentPlanDetails?.amountMaxScans ? -1 : user?.monthlyScans}
            max={user?.currentPlanDetails?.amountMaxScans || 100}
          />
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            navigate({ to: Routes.customer.account.subscription });
          }}
          color="secondary"
          variant="outlined"
          className="w-[220px]"
        >
          {t('subscription.buttonUpgradePlan')}
        </Button>
      </div>
    </Container>
  );
};

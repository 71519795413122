import { InvoiceStatusBadge } from '@app/domain/billing/InvoiceStatusBadge';
import { EInvoiceStatus } from '@app/swagger-types';
import { UIStyledSection } from '../../components/UIStyledSection';

export const UISectionInvoiceStatus: React.FC = () => {
  return (
    <UIStyledSection title="InvoiceStatusBadge">
      <div className="flex flex-wrap gap-4">
        {CASES.map((e, i) => {
          return (
            <div key={i} className="flex gap-4 p-2 hover:ring-1">
              <InvoiceStatusBadge status={e.status} />
            </div>
          );
        })}
      </div>
    </UIStyledSection>
  );
};

const CASES: React.ComponentProps<typeof InvoiceStatusBadge>[] = Object.values(EInvoiceStatus).map((e) => ({
  status: e,
}));

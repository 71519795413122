import { Tooltip } from '@app/components';
import { IS_PROD_ENV } from '@app/environment/typed-env';
import { clsxm } from '@app/styles/clsxm';

export const StripeIdBadge: React.FC<{
  customerId?: string | null;
  chargebeeId?: string | null;
}> = ({ customerId, chargebeeId }) => {
  return (
    <div
      className={clsxm(
        'rounded px-1 text-center',
        chargebeeId ? 'bg-orange-200 text-orange-800' : customerId ? 'bg-sky-200 text-sky-800' : 'italic text-gray-400'
      )}
    >
      {chargebeeId ? (
        <Tooltip title="ChargeBee">
          <span>{chargebeeId}</span>
        </Tooltip>
      ) : customerId ? (
        <a href={getLinkForStripe(customerId)} target="_blank" rel="noreferrer">
          {customerId}
        </a>
      ) : (
        'no stripe'
      )}
    </div>
  );
};

const getLinkForStripe = (customerId: string) => {
  if (IS_PROD_ENV) {
    return `https://dashboard.stripe.com/customers/${customerId}`;
  }
  return `https://dashboard.stripe.com/test/customers/${customerId}`;
};

import { AuthApi } from '@app/auth/api/auth.api';
import { Button } from '@app/components';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

export const DeleteMyAccountButton: React.FC<{
  textVariant?: boolean;
}> = ({ textVariant }) => {
  const { t } = useTranslation();
  const { mutate: removeUser, isLoading } = useMutation(
    async () => {
      return await AuthApi.deleteAccount();
    },
    {
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  return (
    <ConfirmDialog
      trigger={
        <Button loading={isLoading} color="secondary" variant={textVariant ? 'text' : 'outlined'} className="w-[220px]">
          {t('account.sectionManage.deleteAccount')}
        </Button>
      }
      containerClassName="max-w-[350px]"
      confirmButtonProps={{ color: 'error' }}
      onConfirm={removeUser}
      textClassName="text-center"
      title={t('account.sectionManage.deleteAccountWarningTitle')}
      text={t('account.sectionManage.deleteAccountWarningDescription')}
      confirmText={t('account.sectionManage.deleteAccount')}
    />
  );
};

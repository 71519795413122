import { ECurrency, ELanguage } from '@app/swagger-types';
import { email, oneOfEnum, password } from '@app/utils/yup.utils';
import { InferType, object, string } from 'yup';

export const UserAccountInfoFormSchema = object({
  firstName: string().required('Required field'),
  lastName: string().required('Required field'),
  email: email(),
  language: oneOfEnum(ELanguage).required('Required field'),
  currency: oneOfEnum(ECurrency).required('Required field'),
});
export type UserAccountInfoFormSchema = InferType<typeof UserAccountInfoFormSchema>;

export const ChangePasswordFormSchema = object({
  newPassword: password(),
  confirmNewPassword: password().test(
    'password-and-confirm-password-the-same',
    'The confirmed password must be the same as the password',
    (confirmPassword, { parent: { newPassword } }: { parent: { newPassword: string } }) => {
      return confirmPassword === newPassword;
    }
  ),
});
export type ChangePasswordFormSchema = InferType<typeof ChangePasswordFormSchema>;

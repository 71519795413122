import { BillingInfoOutDto, BillingOutDto } from '@app/swagger-types';
import { checkTypeCompliance, WithNullableKeys } from '@app/utils/type.utils';
import { email } from '@app/utils/yup.utils';
import { InferType, object, string } from 'yup';

export const BillingOutDtoSchema = object({
  firstName: string().nullable(),
  lastName: string().nullable(),
  billingEmail: string().required(),
  companyName: string().nullable(),
  country: string().nullable(),
  state: string().nullable(),
  city: string().nullable(),
  zipCode: string().nullable(),
  streetAddress: string().nullable(),
});
export type BillingOutDtoSchema = InferType<typeof BillingOutDtoSchema>;
checkTypeCompliance<BillingOutDto, BillingOutDtoSchema>();
checkTypeCompliance<BillingOutDtoSchema, BillingOutDto>();

export const BillingOutDtoFormSchema = object({
  firstName: string().nullable(),
  lastName: string().nullable(),
  billingEmail: email().required(),
  companyName: string().nullable(),
  country: string().nullable(),
  state: string().nullable(),
  city: string().nullable(),
  zipCode: string().nullable(),
  streetAddress: string().nullable(),
});
export type BillingOutDtoFormSchema = InferType<typeof BillingOutDtoFormSchema>;

export const BillingInfoOutDtoSchema = object({
  billing: BillingOutDtoSchema.nullable().optional(),
});
export type BillingInfoOutDtoSchema = InferType<typeof BillingInfoOutDtoSchema>;
checkTypeCompliance<WithNullableKeys<BillingInfoOutDto, 'billing'>, BillingInfoOutDtoSchema>();
checkTypeCompliance<BillingInfoOutDtoSchema, WithNullableKeys<BillingInfoOutDto, 'billing'>>();

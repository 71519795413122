import { CompanyIcon, JobPositionIcon } from '@app/assets/icons/vcard';
import { VCardFormSchema } from '@app/domain/qr-code/api/qr-code.form.schema.api';
import { VCardOutDto } from '@app/swagger-types';
import { Divider } from '@mui/material';
import { VCardInfoItem } from './VCardInfoItem';

export const VCardCompany: React.FC<{ vcard?: VCardOutDto | VCardFormSchema | null }> = ({ vcard }) => {
  return (
    <div className="mb-6 flex flex-col gap-2">
      {vcard?.companyName && (
        <>
          <VCardInfoItem
            title="Company"
            icon={<CompanyIcon />}
            text={vcard.companyName}
            titleColor={vcard.designTitleColor}
            textColor={vcard.designTextColor}
            textHoverColor={vcard.designTextHoverColor}
            iconColor={vcard?.designIconColor}
          />
          <Divider flexItem />
        </>
      )}
      {vcard?.jobPosition && (
        <VCardInfoItem
          title="Job position"
          icon={<JobPositionIcon />}
          text={vcard.jobPosition}
          titleColor={vcard.designTitleColor}
          textColor={vcard.designTextColor}
          iconColor={vcard?.designIconColor}
          textHoverColor={vcard.designTextHoverColor}
        />
      )}
    </div>
  );
};

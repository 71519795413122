import styles from './NavSidebar.module.scss';
import React, { useMemo } from 'react';

import { SidebarRouteLink } from '@app/models/router/';

import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, List, Toolbar } from '@mui/material';

import { NavSidebarLink } from './nav-sidebar-link/NavSidebarLink';

type NavSidebarProps = {
  badgesData?: Record<string, number>;
  navItems: SidebarRouteLink[][];
} & MuiDrawerProps;

export const NavSidebar: React.FC<NavSidebarProps> = ({ badgesData, navItems, ...rest }) => {
  // const navigate = useLocation();

  // useEffect(() => {
  //   return history.listen(() => {
  //     if (navMobileOpen) {
  //       toggleNavMenu(false)();
  //     }
  //   });
  // }, [history, navMobileOpen, toggleNavMenu]);

  const navContent = useMemo(() => {
    return (
      <>
        <Toolbar />
        <List disablePadding>
          <div className={styles.ItemsWrapper}>
            {navItems.map((group, groupIndex) => (
              <div key={groupIndex} className={styles.GroupWrapper}>
                {group.map((menuItem) => {
                  const badge = menuItem.badgeProp && badgesData ? badgesData[menuItem.badgeProp] : undefined;
                  return (
                    <NavSidebarLink
                      name={menuItem.name}
                      key={menuItem.name}
                      to={menuItem.path}
                      icon={menuItem.icon}
                      nestedLinks={menuItem.nestedLinks}
                      badge={badge}
                      badgesData={badgesData}
                      tooltip={menuItem.tooltip}
                    />
                  );
                })}
              </div>
            ))}
          </div>
        </List>
      </>
    );
  }, [badgesData, navItems]);

  // if (!isDesktop) {
  //   return (
  //     <SwipeableDrawer
  //       {...rest}
  //       classes={{ paper: styles.Paper, root: styles.SwipeableRoot }}
  //       open={navMobileOpen}
  //       onOpen={toggleNavMenu(true)}
  //       onClose={toggleNavMenu(false)}
  //       ModalProps={{
  //         BackdropProps: { className: styles.MobileBackdrop },
  //       }}
  //       elevation={0}
  //     >
  //       {navContent}
  //     </SwipeableDrawer>
  //   );
  // }

  return (
    <MuiDrawer variant="permanent" {...rest} classes={{ paper: styles.Paper }}>
      {navContent}
    </MuiDrawer>
  );
};

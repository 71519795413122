import styles from './NavSidebarLink.module.scss';
import React from 'react';

import { SidebarRouteLink } from '@app/models/router/';

import { ListItem, ListItemText, Badge } from '@mui/material';

import { ConditionalWrapper } from '@app/hoc/conditional-wrapper/ConditionalWrapper';
import { Tooltip } from '@app/components';
import { NavLink } from '@app/layout/nav-link/NavLink';
import { clsxm } from '@app/styles/clsxm';

type NavSidebarLinkProps = {
  name: string;
  icon: JSX.Element;
  to: string;
  badge?: number;
  badgesData?: Record<string, number>;
  tooltip?: string;
  isChild?: boolean;
  nestedLinks?: SidebarRouteLink[];
  disabled?: boolean;
};

export const NavSidebarLink: React.FC<NavSidebarLinkProps> = ({
  name,
  icon,
  badge,
  nestedLinks,
  badgesData,
  isChild,

  tooltip,
  to,
  disabled,
  ...rest
}) => {
  return (
    <React.Fragment>
      <ListItem
        className={clsxm(
          styles.SidebarLink,
          isChild && styles.SidebarLinkInner,
          Boolean(nestedLinks) && styles.HasChild,
          disabled && 'pointer-events-none opacity-50'
        )}
        {...rest}
        component={NavLink}
        to={to}
        activeClassName={styles.Active}
      >
        <ConditionalWrapper
          condition={!!tooltip}
          wrapper={(children) => (
            <Tooltip
              classes={{
                tooltip: styles.Tooltip,
              }}
              title={tooltip || ''}
              variant="dark"
              disableInteractive
            >
              {children as React.ReactElement}
            </Tooltip>
          )}
        >
          <div className={styles.LinkContent}>
            {icon}
            <ListItemText className={styles.SidebarLinkText}>{name}</ListItemText>

            {badge ? (
              <Badge
                className={clsxm(styles.Badge, badge > 99 && styles.BadgeOverflow)}
                badgeContent={badge}
                color="primary"
              />
            ) : (
              ''
            )}
          </div>
        </ConditionalWrapper>
      </ListItem>

      {nestedLinks &&
        nestedLinks.map((nestedLink) => (
          <NavSidebarLink
            key={nestedLink.name}
            name={nestedLink.name}
            to={nestedLink.path}
            icon={nestedLink.icon}
            isChild
            badge={nestedLink.badgeProp && badgesData ? badgesData[nestedLink.badgeProp] : undefined}
            badgesData={badgesData}
            tooltip={nestedLink.tooltip}
          />
        ))}
    </React.Fragment>
  );
};

import styles from './Checkbox.module.scss';
import { get } from 'lodash';
import React from 'react';

import { clsxm } from '@app/styles/clsxm';

import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';

import { CheckboxChecked, CheckboxEmpty } from '@app/assets';

export type ColorTypes = 'primary' | 'red' | 'green' | 'blue';

export type CheckboxProps = {
  color?: ColorTypes;
  label?: string | number | React.ReactElement;
  errorMessage?: string | boolean | undefined;
  containerClassName?: string;
} & Omit<MuiCheckboxProps, 'color'>;

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ errorMessage, label, className, color, containerClassName, ...props }, ref) => {
    let checkboxComponent = (
      <MuiCheckbox
        icon={<CheckboxEmpty />}
        checkedIcon={<CheckboxChecked />}
        {...props}
        inputRef={ref}
        className={clsxm(styles.checkbox, get(styles, `${color}`), className)}
      />
    );
    if (label) {
      checkboxComponent = (
        <div className={clsxm('flex items-center text-gray-500 dark:text-white', containerClassName)}>
          <FormControlLabel
            classes={{ label: clsxm('text-gray-500', props.checked && 'text-gray-800') }}
            control={checkboxComponent}
            label={label}
          />
        </div>
      );
    }
    return (
      <div>
        {checkboxComponent}

        {errorMessage && <FormHelperText className={styles['error-text']}>{errorMessage}</FormHelperText>}
      </div>
    );
  }
);

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { WebsiteURLFormSchema } from './schema/WebsiteURLFormSchema';
import { Button, Form } from '@app/components';
import { useState } from 'react';
import { useHandler } from '@app/hooks/useHandler.hook';
import { gtag } from '@app/gtag/gtag';
import { DesignInDto, ECodeType, ECornerType, EFrameType, EPatternType } from '@app/swagger-types';
import { QRCodePatched } from '@app/domain/qr-code/components/QRCodePatched';
import { SelectQRCodeTypeContainer } from '@app/domain/qr-code/components/type/SelectQRCodeTypeContainer';
import { QRCodeRenderer } from '@app/domain/qr-code/components/QRCodeRenderer';
import { QRCodeWebsiteUrl } from '@app/domain/qr-code/components/input/website/QRCodeWebsiteUrl';
import { QRWidgetDesignAccordion } from './design/QRWidgetDesignAccordion';
import { clsxm } from '@app/styles/clsxm';
import {
  EWidgetSignUpTriggerSource,
  WIDGET_AVAILABLE_COLORS,
  WIDGET_DEFAULT_COLOR,
  WIDGET_LOGO_OPTION_APP_LOGO,
} from './widget.constants';

import { QRWidgetSignUpDialog } from './QRWidgetSignUpDialog';
import { useMutation } from 'react-query';
import { WidgetApi } from './widget.api';
import { getLinkForCode } from '@app/domain/qr-code/qr-code.utils';

import { QRDownloadHandlerFn } from '@app/domain/qr-code/components/qr-code-internal.types';
import { QRWidgetDownloadDialog } from './QRWidgetDownloadDialog';
import { FiRrArrowRight, FiRrCheck, ScanQrCodeIcon } from '@app/assets';
import { SvgScopeWrap } from '@app/utils/SvgScopeWrap';
import { useTranslation } from 'react-i18next';
import { IS_LOCALHOST } from '@app/environment/typed-env';
import { useMediaQuery, useTheme } from '@mui/material';
import { useGoogleClickId } from '@app/gtag/useGoogleClickId';

export const QRWidgetContainer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('lg'));

  const formMethods = useForm({
    resolver: yupResolver(WebsiteURLFormSchema),
    defaultValues: {
      websiteUrl: '',
    },
  });
  const [qrCode, setQrCode] = useState<QRCodePatched | undefined>();
  const qrCodeHandler = useHandler((qr: QRCodePatched) => {
    setQrCode(qr);
  });

  const [downloadHandler, setDownloadHandler] = useState<QRDownloadHandlerFn | undefined>(undefined);

  const onDownloadHandler = useHandler((downloadFn?: QRDownloadHandlerFn) => {
    // setter has to be a function to avoid downloadFn call
    setDownloadHandler(() => downloadFn);
  });

  const [frameType, setFrameType] = useState<EFrameType>(EFrameType.NONE);
  const [shape, setShape] = useState<EPatternType>(EPatternType.SQUARE);
  const [color, setColor] = useState<typeof WIDGET_AVAILABLE_COLORS[number]>(WIDGET_DEFAULT_COLOR);
  const [publicLogoType, setPublicLogoType] = useState<typeof WIDGET_LOGO_OPTION_APP_LOGO | undefined>();
  // const { logoUrl: pulicLogoURL } = usePublicLogoImg();

  const [isSignUpDialogOpen, setIsSignUpDialogOpen] = useState(false);
  const [triggerSource, setTriggerSource] = useState<EWidgetSignUpTriggerSource | null>(null);

  // TODO logo url
  const normalizePropsForDesign = (): DesignInDto => {
    let cornerType = ECornerType.SQUARE;
    if (shape === EPatternType.CLASSY_ROUNDED) {
      cornerType = ECornerType.ROUNDED;
    } else if (shape === EPatternType.DOTS) {
      cornerType = ECornerType.FULL_CIRCLE;
    }
    const textProps: Partial<DesignInDto> = {
      frameText: 'SCAN ME',
      frameTextSize: 15,
    };
    return {
      ...textProps,
      frameType: frameType,
      frameTextColor: '#FFFFFF',
      cornerType,
      cornerColor: color,
      patternType: shape,
      patternColor: color,
      backgroundColor: '#FFFFFF',
      frameBackgroundColor: color,
    };
  };

  const propsForDesign = normalizePropsForDesign();

  const [scheduledQRIdForDownload, setScheduledQRIdForDownload] = useState('');
  // set default value 'mock' for testing download dialog
  const { googleClickId } = useGoogleClickId();

  const createQRMutation = useMutation({
    mutationFn: async ({ websiteUrl }: WebsiteURLFormSchema) => {
      setScheduledQRIdForDownload('');
      // console.log({ websiteUrl, frameType, shape, color, logoUrl });
      return WidgetApi.generateQRCode({
        websiteUrl,
        design: propsForDesign,
        publicLogoType,
        googleClickId,
      });
    },

    onSuccess: (data) => {
      setScheduledQRIdForDownload(data.id);
      if (IS_LOCALHOST) {
        console.log(
          'schedule qr for download',
          data,
          getLinkForCode(data.id),
          `${window.location.origin}/qr/${data.id}`
        );
      }
    },
  });
  const serverSavedQR = createQRMutation.data;

  const signUpTriggerHandler = (source: EWidgetSignUpTriggerSource) => {
    setTriggerSource(source);
    setIsSignUpDialogOpen(true);
  };

  const urlValue = formMethods.watch('websiteUrl');

  const onSubmit: SubmitHandler<WebsiteURLFormSchema> = (data) => {
    if (!qrCode) {
      return;
    }
    setDownloadHandler(undefined);
    createQRMutation.mutate({ websiteUrl: data.websiteUrl });
    gtag.downloadQRCode({
      codeType: ECodeType.WEBSITE,
      websiteURL: data.websiteUrl,
    });
  };

  const isValidForm = formMethods.formState.isValid;
  const isButtonDisabled = !qrCode || !urlValue || Boolean(scheduledQRIdForDownload);

  const isFirstSectionFocused = !isValidForm;
  const isSecondSectionFocused = !isFirstSectionFocused;

  const shouldBlurQR = !urlValue || createQRMutation.isLoading;

  return (
    <FormProvider {...formMethods}>
      <QRWidgetDownloadDialog
        // remount when link changed
        key={`dialog-${scheduledQRIdForDownload}`}
        open={Boolean(scheduledQRIdForDownload)}
        onClose={() => {
          setScheduledQRIdForDownload('');
        }}
        scheduledIdForDownload={scheduledQRIdForDownload}
        serverSavedQRID={serverSavedQR?.id}
        googleClickId={googleClickId}
        downloadHandler={downloadHandler}
      />
      <QRWidgetSignUpDialog
        autoLinkQRID={serverSavedQR?.id || null}
        googleClickId={googleClickId}
        open={isSignUpDialogOpen}
        onClose={() => {
          setIsSignUpDialogOpen(false);
        }}
        triggerSource={triggerSource}
      />
      <Form control={formMethods.control} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="main-container flex flex-col gap-2 lg:flex-row">
          {/* QR type and input */}
          <div
            className={clsxm(
              'flex-[2] rounded-[10px] border-[1.5px] border-gray-200 bg-white',
              isFirstSectionFocused && 'border-slate-400'
            )}
          >
            <div className="flex h-full flex-col justify-between">
              <div>
                <SelectQRCodeTypeContainer
                  className="h-auto bg-transparent"
                  forWidgetMode
                  onSignUpTrigger={(triggerSource) => {
                    setIsSignUpDialogOpen(true);
                    setTriggerSource(triggerSource);
                  }}
                />
                <QRCodeWebsiteUrl className="h-auto" hint={t('qr.websiteInput.yourCodeGeneratedAutomatically')} />
              </div>
              <div className="flex h-full items-end px-5 py-6">
                <Button
                  variant="outlined"
                  className="rounded-lg py-2 xl:inline-flex"
                  size="medium"
                  onClick={() => {
                    setIsSignUpDialogOpen(true);
                    setTriggerSource(EWidgetSignUpTriggerSource.ON_SCAN_TRACKING);
                  }}
                  startIcon={<ScanQrCodeIcon />}
                >
                  {t('widget.scanTracking')}
                </Button>
              </div>
            </div>
          </div>
          {/* circle at the middle */}
          <div>
            <div className="relative flex h-full flex-col">
              <div className="absolute left-1/2 lg:top-1/2 lg:left-auto">
                <div
                  className="relative -top-6 -left-6 flex h-12 w-12 items-center justify-center rounded-full border-gray-200 bg-white p-[1.5px]"
                  style={{
                    backgroundImage: `linear-gradient(to ${
                      isFirstSectionFocused ? (isBelowLg ? 'top' : 'left') : isBelowLg ? 'bottom' : 'right'
                    }, ${GRAY_200} 66.6%, ${SLATE_400} 66.6%)`,
                  }}
                >
                  <div className="flex h-full w-full items-center justify-center rounded-full bg-white">
                    <SvgScopeWrap>
                      {isValidForm ? (
                        <FiRrCheck className="fill-accept-main" />
                      ) : (
                        <FiRrArrowRight className="rotate-90 fill-accept-dark-blue lg:rotate-0" />
                      )}
                    </SvgScopeWrap>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* QR styling */}
          <div
            className={clsxm(
              'flex-1 rounded-[10px] border-[1.5px] border-gray-200 bg-white p-6',
              isSecondSectionFocused && 'border-slate-400'
            )}
          >
            {/* max height = qr min height + padding bottom */}
            <div className="mx-auto max-h-[228px] max-w-[204px] px-6 pb-6">
              <QRCodeRenderer
                asPreview={!serverSavedQR}
                onQRCode={qrCodeHandler}
                onDownloadHandler={onDownloadHandler}
                frameClassName={clsxm('bg-transparent min-h-[204px] mx-auto', shouldBlurQR && 'blur-sm')}
                {...propsForDesign}
                publicLogoType={publicLogoType}
                qrId={scheduledQRIdForDownload || serverSavedQR?.id}
              />
            </div>
            <QRWidgetDesignAccordion
              frameType={frameType}
              shape={shape}
              color={color}
              publicLogoType={publicLogoType}
              onFrameSelect={(frameType) => {
                setFrameType(frameType);
              }}
              onShapeSelect={(shape) => {
                setShape(shape);
              }}
              onColorSelect={(color) => {
                setColor(color);
              }}
              onLogoSelect={(logoUrl) => {
                setPublicLogoType(logoUrl);
              }}
              onSignUpTrigger={(source) => {
                signUpTriggerHandler(source);
              }}
            />
            <Button
              type="submit"
              disabled={isButtonDisabled}
              loading={createQRMutation.isLoading || Boolean(scheduledQRIdForDownload)}
              className="w-full rounded-lg"
            >
              {t('widget.download')}
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

// hardcode constants for less dependencies
const GRAY_200 = '#E5E7EB';
const SLATE_400 = '#94A3B8';

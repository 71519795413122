import { Outlet } from 'react-router-dom';

import { clsxm } from '@app/styles/clsxm';
import { HomeButton } from '@app/components/buttons/home-button/HomeButton';

export const EmailModule = () => {
  return (
    <div className={clsxm('relative flex min-h-screen w-full flex-1 bg-accept-light-bg')}>
      <div className={'flex w-full flex-grow flex-col gap-4'}>
        <div className="flex w-full justify-center pt-24">
          <HomeButton />
        </div>
        <main className={clsxm('w-full')}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

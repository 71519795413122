import { isCustomer } from '@app/auth/auth.utils';
import { NotFound } from '@app/components/table/components/not-found/NotFound';
import { GET_SCANNED_QR_CODE_BY_ID_QUERY } from '@app/constants/query-api-configs';
import { Routes } from '@app/constants/routes';
import { QRCodeApi } from '@app/domain/qr-code/api/qr-code.api';
import { MultilinkPreview } from '@app/domain/qr-code/components/input/multilink/MultilinkPreview';
import { QRPdfViewer } from '@app/domain/qr-code/components/input/pdf/QRPdfViewer';
import { useScanQRCode } from '@app/domain/scan/api/scan.hooks.api';
import { VCardPreview } from '@app/domain/scan/components/vcard/VCardPreview';
import { IS_LOCALHOST, IS_NOT_PROD_ENV } from '@app/environment/typed-env';
import { useGoogleClickId } from '@app/gtag/useGoogleClickId';
import { AUTH_QUERY_PARAM_AUTO_LINK_QR_ID } from '@app/modules/auth/auth.constants';
import { useTypedNavigate, useTypedParams } from '@app/router';
import { useStore } from '@app/store/useStore.hook';
import { ECodeType } from '@app/swagger-types';
import { validateURL } from '@app/utils/url.util';
import { CircularProgress } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';

export const ScanQRCodePage: React.FC = () => {
  const { id } = useTypedParams(Routes.qr_code.scanned);
  const { data, isLoading } = useScanQRCode(id);
  const queryClient = useQueryClient();
  const qr = data?.qr;
  const scan = data?.scan;
  const user = useStore.useCurrentUser();
  const { googleClickId } = useGoogleClickId();

  useEffect(
    () => () => {
      queryClient.cancelQueries([[GET_SCANNED_QR_CODE_BY_ID_QUERY.name, id]]);
    },
    [id, queryClient]
  );

  const navigate = useTypedNavigate();

  const [memoShouldClaimOrExpired, reasonForDebug] = useMemo(() => {
    if (!qr) {
      return [false, 'no_qr'];
    }
    try {
      const now = Date.now();
      if (qr.expireDate) {
        const expireDate = new Date(qr.expireDate);
        const isExpired = now > expireDate.getTime();
        if (isExpired) {
          return [true, 'expired'];
        }
        return [false, 'not_expired'];
      }
      const startDate = new Date(qr.createdDate);
      // should claim QR code
      if (now - DELTA_24_HOURS > startDate.getTime()) {
        return [true, 'should_claim'];
      }
    } catch (err) {
      console.error('error', err);
    }
    return [false, 'finally'];
  }, [qr]);

  useEffect(() => {
    if (!qr) {
      return;
    }
    if (!memoShouldClaimOrExpired) {
      return;
    }
    let mounted = true;
    // only linked to customers QR codes have expiration date
    if (qr.expireDate) {
      navigate({ to: Routes.authSpecial.expired_qr, queryParams: { [AUTH_QUERY_PARAM_AUTO_LINK_QR_ID]: qr.id } });
      return;
    }
    if (!user || !isCustomer(user)) {
      navigate({ to: Routes.authSpecial.claim_qr, queryParams: { [AUTH_QUERY_PARAM_AUTO_LINK_QR_ID]: qr.id } });
      return;
    }
    QRCodeApi.claim({ qrId: qr.id, googleClickId }).then(() => {
      if (!mounted) {
        return;
      }
      navigate({ to: Routes.customer.my_qr_codes.index });
    });
    return () => {
      mounted = false;
    };
  }, [qr, navigate, memoShouldClaimOrExpired, user, googleClickId]);

  if (IS_LOCALHOST) {
    console.log('qr reason', reasonForDebug, user);
  }

  if (isLoading || !scan || !qr) {
    return LOADER;
  }

  if (memoShouldClaimOrExpired) {
    return LOADER;
  }

  if (qr.codeType === ECodeType.WEBSITE) {
    const rawURL = qr.website?.url;
    if (rawURL) {
      const normalURL = validateURL(rawURL).url ? rawURL : `https://${rawURL}`;
      window.location.href = normalURL;
    }
    return LOADER;
  }

  // TODO validate vCard, check DTO type
  if (scan.codeType === ECodeType.V_CARD && scan.vcard) {
    return <VCardPreview vcard={scan.vcard} />;
  }

  if (scan.codeType === ECodeType.MULTILINK && scan.multilink) {
    return <MultilinkPreview multilink={scan.multilink} />;
  }

  if (scan.codeType === ECodeType.PDF && scan.pdf) {
    return <QRPdfViewer pdf={scan.pdf} />;
  }

  return <NotFound />;
};

const LOADER = (
  <div className="flex h-full w-full items-center justify-center">
    <CircularProgress />
  </div>
);

const DELTA_24_HOURS = IS_NOT_PROD_ENV
  ? // 5 min for localhost
    1e3 * 60 * 5
  : 1e3 * 60 * 60 * 24;

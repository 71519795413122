import { EmptyPlaceholderIcon } from '@app/assets/icons/vcard';
import { CachedImg } from '@app/components';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { typedEnv } from '@app/environment/typed-env';
import { clsxm } from '@app/styles/clsxm';
import { useGetLibraryById } from '../api/library.hooks.api';

export const LibraryImage: React.FC<{ logoSize?: number; libraryId?: string; alt?: string }> = ({ libraryId, alt }) => {
  const { data: library, isLoading } = useGetLibraryById(libraryId && libraryId !== NO_LOGO_ID ? libraryId : undefined);

  if (!library) {
    return <EmptyPlaceholderIcon className="bg-gray-200" />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="h-full w-full">
      <CachedImg
        key={libraryId}
        // TODO use util to get file src
        src={`${typedEnv.REACT_APP_API_BASE_URL}/public/files/${library.file.id}`}
        alt={alt}
        className={clsxm('h-full w-full rounded object-cover')}
        draggable="false"
      />
    </div>
  );
};

import { Page } from '@app/hoc';
import { useTypedNavigate, useTypedParams } from '@app/router';
import { Routes } from '@app/constants/routes';
import { useMutation, useQuery } from 'react-query';
import { QRCodeApi } from '@app/domain/qr-code/api/qr-code.api';
import { ContentLoader } from '@app/components/loaders/content-loader/ContentLoader';
import { BackButton } from '@app/components/buttons/back-button/BackButton';
import { QRid } from './components/QRid';
import { QRstatus } from './components/QRstatus';
import { format } from 'date-fns';
import { FORMAT } from '@app/constants/formats';
import { EUserStatus, QrCodeAdminInDto } from '@app/swagger-types';
import { QRCodeRenderer } from '@app/domain/qr-code/components/QRCodeRenderer';
import { extractRenderPropsFromQR, getLinkForCode } from '@app/domain/qr-code/qr-code.utils';
import { VCardPreview } from '@app/domain/scan/components/vcard/VCardPreview';
import { Autocomplete, Button } from '@app/components';
import { UserApi } from '@app/domain/user/user.api';
import { CustomerAdminOutSchema } from '@app/domain/user';
import { useState } from 'react';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';

export const AdminQREditPage: React.FC = () => {
  const { id } = useTypedParams(Routes.admin.qr.edit);
  const navigate = useTypedNavigate();
  const [selectedUser, setSelectedUser] = useState<CustomerAdminOutSchema | null>(null);
  const [search, setSearch] = useState('');
  const [isChangingUser, setIsChangingUser] = useState(false);

  const qrQuery = useQuery({
    queryKey: [id],
    queryFn: async () => {
      if (!id) {
        return;
      }
      return QRCodeApi.adminGetById(id);
    },
  });

  const qrMutation = useMutation({
    mutationFn: async (payload: QrCodeAdminInDto) => {
      if (!id) {
        return;
      }
      return QRCodeApi.adminPatchById(id, payload);
    },
    onSuccess: () => {
      setIsChangingUser(false);
      setSelectedUser(null);
    },
  });

  const qr = qrMutation.data || qrQuery.data;

  const assignedUserEmail = qr?.createdBy?.email;

  return (
    <Page>
      <div className="p-5">
        <BackButton
          onClick={() => {
            window.history.length > 1 ? window.history.back() : navigate({ to: Routes.admin.qr.index });
            // navigate({ to: Routes.admin.qr.edit, params: { id: Math.random().toString() } });
          }}
        />
      </div>
      <div className="p-5">
        {qrQuery.isLoading && <ContentLoader loading />}
        {qr && (
          <div className="flex flex-col items-start gap-2">
            {qr.isDeleted && <div className="w-full rounded bg-red-200 text-center text-red-900">DELETED QR CODE</div>}
            <ConfirmDialog
              title={qr.isDeleted ? 'Restore deleted QR?' : 'Delete QR code?'}
              onConfirm={() => {
                qrMutation.mutate({
                  isDeleted: !qr.isDeleted,
                });
              }}
              trigger={
                qr.isDeleted ? (
                  <Button loading={qrMutation.isLoading}>Restore QR</Button>
                ) : (
                  <Button loading={qrMutation.isLoading} color="error">
                    Delete QR
                  </Button>
                )
              }
            />
          </div>
        )}
        {qr && (
          <div className="flex flex-wrap gap-4">
            <table>
              <tbody>
                <tr>
                  <td className="p-2 font-bold">Name:</td>
                  <td className="p-2">{qr.name}</td>
                </tr>
                <tr>
                  <td className="p-2 font-bold">Code type:</td>
                  <td className="p-2">{qr.codeType}</td>
                </tr>
                <tr>
                  <td className="p-2 font-bold">Scans:</td>
                  <td className="p-2">{qr.amountOfScans || 0}</td>
                </tr>
                {qr.website && (
                  <tr>
                    <td className="p-2 font-bold">Website URL:</td>
                    <td className="p-2">{qr.website.url}</td>
                  </tr>
                )}
                <tr>
                  <td className="p-2 font-bold">ID:</td>
                  <td className="p-2">
                    <QRid id={qr.id} />
                  </td>
                </tr>
                <tr>
                  <td className="p-2 font-bold">Status:</td>
                  <td className="p-2">
                    <div className="flex">
                      <QRstatus isDeleted={qr.isDeleted} createdBy={qr.createdBy} />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="p-2 font-bold">User:</td>
                  <td className="p-2">
                    {!assignedUserEmail || isChangingUser ? (
                      <div className="flex flex-col gap-2">
                        {assignedUserEmail && (
                          <div className="rounded bg-gray-100 p-1 ring-1 ring-gray-400">{assignedUserEmail}</div>
                        )}
                        <Autocomplete<CustomerAdminOutSchema>
                          value={selectedUser}
                          isOptionEqualToValue={(option, value) => {
                            return value?.id === option?.id;
                          }}
                          fetchOnOpen
                          browserAutocompleteOff
                          asyncSearch
                          inputValue={search}
                          onInputChange={(e) => setSearch(e)}
                          filterOptions={(e) => e}
                          getOptionLabel={(option) =>
                            option.email || (option.deletedEmail ? `${option.deletedEmail} (deleted)` : option.id)
                          }
                          getOptions={async (search) => {
                            console.log('search', search);
                            const res = await UserApi.getCustomers({
                              term: search,
                              size: 10,
                              page: 0,
                              userStatus: EUserStatus.ACTIVE,
                            });
                            return res.result;
                          }}
                          onChange={(_, data) => {
                            console.log('change', data);
                            setSelectedUser(data);
                          }}
                        />
                        <div className="flex gap-2">
                          {assignedUserEmail && (
                            <Button
                              color="secondary"
                              onClick={() => {
                                setIsChangingUser(false);
                                setSelectedUser(null);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                          <Button
                            disabled={!selectedUser || selectedUser.id === qr.createdBy?.id}
                            loading={qrMutation.isLoading}
                            onClick={() => {
                              if (!selectedUser) {
                                return;
                              }
                              qrMutation.mutate({
                                createdById: selectedUser.id,
                              });
                            }}
                          >
                            Assign
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2">
                        {assignedUserEmail && (
                          <div className="rounded bg-gray-100 p-1 ring-1 ring-gray-400">{assignedUserEmail}</div>
                        )}
                        <Button
                          onClick={() => {
                            setIsChangingUser(true);
                          }}
                        >
                          {assignedUserEmail ? 'Change' : 'Assign'}
                        </Button>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 font-bold">Created date:</td>
                  <td className="p-2">{format(new Date(qr.createdDate), FORMAT.FULL_TIME)}</td>
                </tr>
              </tbody>
            </table>
            <div className="flex flex-col">
              <b>Simulate scan link:</b>
              <div>{getLinkForCode(qr.id)}</div>
              <div className="w-full max-w-xs">
                <QRCodeRenderer qrId={qr.id} {...extractRenderPropsFromQR(qr)} />
              </div>
            </div>
          </div>
        )}
      </div>
      {qr?.vcard && (
        <div className="w-full max-w-xs">
          <VCardPreview vcard={qr.vcard} />
        </div>
      )}
    </Page>
  );
};

import { QRCodeForm } from '@app/domain/qr-code/components/QRCodeForm';
import { DEFAULT_MULTILINK_FORM_VALUES, DEFAULT_VCARD_FORM_VALUES } from '@app/domain/qr-code/constants';
import { DEFAULT_TEMPLATE_FORM_VALUES } from '@app/domain/template/constants';
import { ECodeType } from '@app/swagger-types';

export const CreateQRCodePage: React.FC = () => {
  return (
    <QRCodeForm
      defaultValues={{
        // TODO: set current default values
        codeType: ECodeType.WEBSITE,
        name: 'QR code',
        ...DEFAULT_TEMPLATE_FORM_VALUES,
        multilink: DEFAULT_MULTILINK_FORM_VALUES,
        vcard: DEFAULT_VCARD_FORM_VALUES,
      }}
    />
  );
};

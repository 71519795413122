import React from 'react';

import { Routes } from '@app/constants/routes';

import { SidebarRouteLink } from '@app/models/router';

import { ManageAccounts, ManageSearch, Settings, Email, DocumentScanner } from '@mui/icons-material/';
import { DrawerProps as MuiDrawerProps } from '@mui/material';

import { NavSidebar } from '@app/layout/nav-sidebar/NavSidebar';

const navItems: SidebarRouteLink[][] = [
  [
    {
      path: Routes.admin.manage_accounts,
      name: 'Dashboard',
      icon: <ManageAccounts />,
    },
  ],
  [
    {
      path: Routes.admin.user_management.index,
      name: 'User Management',
      icon: <ManageAccounts />,
      tooltip: 'You can manage users here',
    },
    {
      path: Routes.admin.qr.index,
      name: 'QR codes',
      icon: <ManageSearch />,
      // TODO
      // badgeProp: 'testBadge',
    },
    {
      path: Routes.admin.email.index,
      name: 'Email reports',
      icon: <Email />,
    },
    {
      path: Routes.admin.brief.index,
      name: 'Brief tool',
      icon: <DocumentScanner />,
    },
  ],
  [
    {
      path: Routes.admin.settings,
      name: 'Settings',
      icon: <Settings />,
      tooltip: 'Settings page here',
    },
  ],
];

export const AdminSidebarMenu: React.FC<MuiDrawerProps> = ({ ...rest }) => {
  // FETCH COUNTS FROM REALTIME / API
  const badgesData: Record<string, number> = {
    testBadge: 25,
  };

  return <NavSidebar navItems={navItems} {...rest} badgesData={badgesData} />;
};

import { useForm, SubmitHandler } from 'react-hook-form';

import { Typography } from '@mui/material';

import { Input, Button, Form } from '@app/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { DividerWithText } from '@app/components/divider/DividerWithText';
import { useMutation } from 'react-query';
import { AuthApi } from '@app/auth/api/auth.api';
import { useStore } from '@app/store/useStore.hook';
import { maybeRenderError } from '@app/utils/maybeRenderError';
import { useTranslation } from 'react-i18next';
import { GoogleButton } from '@app/components/buttons/google-button/GoogleButton';
import { SignInFormValidationSchema } from '@app/auth/api/auth.form';
import { useHandler } from '@app/hooks/useHandler.hook';
import { TypedLink, useTypedNavigate } from '@app/router';
import { Routes } from '@app/constants/routes';

export const ExpiredQRPage = () => {
  const { t } = useTranslation();
  const user = useStore.useCurrentUser();
  const setCurrentUser = useStore.useSetCurrentUser();
  const navigate = useTypedNavigate();

  const { control, formState, handleSubmit, register, watch, clearErrors } = useForm<SignInFormValidationSchema>({
    resolver: yupResolver(SignInFormValidationSchema),
  });

  const {
    mutate: login,
    isLoading,
    error,
  } = useMutation(
    async (formData: SignInFormValidationSchema) => {
      const { data } = await AuthApi.signIn({ ...formData });
      return data;
    },
    {
      onSuccess: (res) => {
        setCurrentUser(res);
      },
    }
  );

  const onSubmit: SubmitHandler<SignInFormValidationSchema> = useHandler((formData) => {
    clearErrors();
    login(formData);
  });

  const { email, password } = watch();

  const disabledSubmitBtn = !email || !password;

  const renderBody = () => {
    if (!user) {
      return (
        <div className="flex h-full w-full max-w-sm flex-col gap-y-4">
          <GoogleButton origin={null} />
          <DividerWithText text={t('auth.or')} />
          <Form
            control={control}
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-1 flex-col gap-y-4 sm:flex-initial"
          >
            <Input
              {...register('email')}
              label={t('auth.email')}
              withBG
              placeholder={t('auth.enterYourEmail')}
              id="email"
              data-cy="sign-in-email"
              errorMessage={formState.errors.email?.message || ''}
            />
            <Input
              {...register('password')}
              label={t('auth.password')}
              placeholder={t('auth.enterYourPassword')}
              withBG
              type="password"
              id="password"
              data-cy="sign-in-password"
              errorMessage={formState.errors.password?.message || ''}
            />

            <div className="flex items-center justify-center">
              <TypedLink
                to={Routes.auth.forgot_password}
                className="font-semibold text-accept-dark-blue hover:text-accept-dark-blue/50"
              >
                {t('auth.forgotPassword')}
              </TypedLink>
            </div>

            <div className="mt-auto space-y-4 sm:mt-0">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                className="!mt-6"
                loading={isLoading}
                disabled={disabledSubmitBtn}
                data-cy="sign-in-submit"
              >
                {t('auth.signIn')}
              </Button>
              {maybeRenderError(error as Error)}
            </div>
          </Form>

          <Typography align="center" className="text-gray-700 dark:text-white">
            {t('auth.dontHaveAnAccount')}{' '}
            <TypedLink to={Routes.auth.sign_up} className="font-semibold text-accept-main hover:text-accept-hover">
              {t('auth.signUp')}
            </TypedLink>
          </Typography>
        </div>
      );
    }
    return (
      <div className="flex h-full w-full max-w-sm flex-col items-center gap-y-4">
        <Button
          onClick={() => {
            navigate({ to: Routes.customer.account.subscription });
          }}
          className="w-[220px]"
        >
          {t('subscription.buttonUpgradePlan')}
        </Button>
      </div>
    );
  };

  return (
    <div className="flex h-full flex-col items-center gap-y-4">
      <div className="mb-4 flex flex-col gap-2">
        <Typography variant="4xl" className="text-center font-bold">
          {t('qr.expired.qrCodeNoLongerValid')}
        </Typography>
        <Typography variant="s" className="text-center text-gray-500">
          {t('qr.expired.reactivateThisQRCode')}
        </Typography>
      </div>
      {renderBody()}
    </div>
  );
};

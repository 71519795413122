import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { ECornerType, EFrameType, EPatternType } from '@app/swagger-types';
import { QRCodeFormSchema } from '../qr-code/api/qr-code.form.schema.api';

export const TEMPLATES_PAGE_SIZE = 20;

export const DEFAULT_TEMPLATE_FORM_VALUES: Omit<QRCodeFormSchema, 'codeType' | 'name'> = {
  libraryId: NO_LOGO_ID,
  logoSize: 20,
  frameText: 'SCAN ME',
  frameTextSize: 15,
  frameTextColor: '#000000',
  frameBackgroundColor: '#000000',
  backgroundColor: '#FFFFFF',
  patternColor: '#000000',
  cornerColor: '#000000',
  frameType: EFrameType.NONE,
  patternType: EPatternType.SQUARE,
  cornerType: ECornerType.SQUARE,
};

import { PaginatedResponse } from '@app/api/api.type';
import {
  GET_DASHBOARD_MY_STATS_QUERY,
  GET_MY_QR_CODES_QUERY,
  GET_MY_QR_CODE_BY_ID_QUERY,
} from '@app/constants/query-api-configs';
import { useStore } from '@app/store/useStore.hook';
import { QRCodeInDtoOverride } from '@app/swagger-override-types';
import { QrCodeMultiDeleteInDto } from '@app/swagger-types';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { APIQRCodeQueryParams, QRCodeOutDtoOverridden } from '../qr-code.types';
import { QRCodeApi } from './qr-code.api';

export const useCreateQRCode = ({
  onSuccess,
  ...options
}: UseMutationOptions<QRCodeOutDtoOverridden, Error, QRCodeInDtoOverride> = {}) => {
  const queryClient = useQueryClient();
  const getMe = useStore.useGetMe();

  return useMutation(
    async (Dto) => {
      return await QRCodeApi.create(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await getMe();
        await queryClient.invalidateQueries(GET_MY_QR_CODES_QUERY.name);
        await queryClient.invalidateQueries(GET_DASHBOARD_MY_STATS_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useGetQRCodes = (
  params: APIQRCodeQueryParams,
  options?: UseQueryOptions<
    unknown,
    Error,
    PaginatedResponse<QRCodeOutDtoOverridden>,
    (typeof GET_MY_QR_CODES_QUERY.name | APIQRCodeQueryParams)[]
  >
) => {
  return useQuery(
    [GET_MY_QR_CODES_QUERY.name, params],
    async () => {
      return await QRCodeApi.getAll(params);
    },
    {
      staleTime: GET_MY_QR_CODES_QUERY.config.staleTime,
      keepPreviousData: true,
      ...options,
    }
  );
};

export const useDeleteQRCode = ({ onSuccess, ...options }: UseMutationOptions<unknown, Error, string> = {}) => {
  const queryClient = useQueryClient();
  const getMe = useStore.useGetMe();

  return useMutation(
    async (Dto) => {
      return await QRCodeApi.delete(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await Promise.all([
          getMe(),
          queryClient.invalidateQueries(GET_MY_QR_CODES_QUERY.name),
          queryClient.invalidateQueries(GET_DASHBOARD_MY_STATS_QUERY.name),
        ]);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useGetQRCodeById = (
  id?: string | null,
  options?: UseQueryOptions<
    unknown,
    Error,
    QRCodeOutDtoOverridden | undefined,
    (typeof GET_MY_QR_CODE_BY_ID_QUERY.name | string | undefined | null)[]
  >
) => {
  return useQuery(
    [GET_MY_QR_CODE_BY_ID_QUERY.name, id],
    async () => {
      if (!id) {
        return;
      }
      return await QRCodeApi.getById(id);
    },
    {
      staleTime: GET_MY_QR_CODE_BY_ID_QUERY.config.staleTime,
      ...options,
    }
  );
};

export const useUpdateQRCode = ({
  onSuccess,
  ...options
}: UseMutationOptions<QRCodeOutDtoOverridden, Error, QRCodeInDtoOverride & { id: string }> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (Dto) => {
      return await QRCodeApi.update(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_MY_QR_CODES_QUERY.name);
        queryClient.invalidateQueries(GET_MY_QR_CODE_BY_ID_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useUpdateQRCodeName = ({
  onSuccess,
  ...options
}: UseMutationOptions<QRCodeOutDtoOverridden, Error, { name: string; id: string }> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (Dto) => {
      return await QRCodeApi.updateName(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_MY_QR_CODES_QUERY.name);
        queryClient.removeQueries(GET_MY_QR_CODE_BY_ID_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

export const useMultiDeleteQRCodes = ({
  onSuccess,
  ...options
}: UseMutationOptions<unknown, Error, QrCodeMultiDeleteInDto> = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (Dto) => {
      return await QRCodeApi.multiDelete(Dto);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(GET_MY_QR_CODES_QUERY.name);
        queryClient.invalidateQueries(GET_MY_QR_CODE_BY_ID_QUERY.name);
        onSuccess && (await onSuccess(data, variables, context));
      },
      ...options,
    }
  );
};

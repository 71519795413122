import { DeleteIcon, UploadIcon } from '@app/assets';
import { Button, IconButton, Input, Typography } from '@app/components';
import { NO_LOGO_ID } from '@app/components/gallery-editor/components/image-item/ImageItem';
import { DesignFormSchema } from '@app/domain/template/api/template.form.schema.api';
import { useCreateTemplate, useGetTemplates } from '@app/domain/template/api/template.hooks.api';
import { DEFAULT_TEMPLATE_FORM_VALUES, TEMPLATES_PAGE_SIZE } from '@app/domain/template/constants';
import { ConfirmDialog } from '@app/hoc/confirm-dialog/ConfirmDialog';
import { clsxm } from '@app/styles/clsxm';
import { DesignOutDto } from '@app/swagger-types';
import { Box } from '@mui/material';
import { pick } from 'lodash';
import { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { QRCodeFormSchema } from '../../api/qr-code.form.schema.api';
import { extractStylePropsFromQRDesign, extractStylePropsFromQRForm } from '../../qr-code.utils';
import { QRCodeRenderer } from '../QRCodeRenderer';

// TODO complete translations after full implementation
export const QRCodeCustomizeTemplatesStep: React.FC = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement | null>(null);
  const { getValues, setValue, watch } = useFormContext<QRCodeFormSchema>();
  const { mutate: createTemplate, isLoading } = useCreateTemplate();
  const { data: paginatedTemplates, isFetching, fetchNextPage } = useGetTemplates({});

  const formLatestValue = watch();
  const { templateId } = formLatestValue;

  const allTemplatesInOnePage = useMemo(() => {
    return (
      paginatedTemplates?.pages.reduce((prev: DesignOutDto[], cur) => {
        return [...prev, ...cur.result];
      }, []) || []
    );
  }, [paginatedTemplates?.pages]);

  const totalTemplates = (() => {
    return allTemplatesInOnePage?.length || 0;
  })();

  const hideLoadMoreButton = (() => {
    return (paginatedTemplates?.pages[0]?.total || 0) <= (paginatedTemplates?.pages.length || 0) * TEMPLATES_PAGE_SIZE;
  })();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-4">
        <Typography variant="m" className="font-bold sm:text-xl">
          {t('qr.stepCustomize.template.tabTitle')}
        </Typography>
        <ConfirmDialog
          trigger={
            <Button loading={isLoading} startIcon={<UploadIcon />} variant="outlined">
              {t('qr.stepCustomize.template.saveDesign')}
            </Button>
          }
          onConfirm={() => {
            if (ref?.current) {
              const values = pick(getValues(), Object.keys(DesignFormSchema.omit(['template']).fields));
              createTemplate(
                {
                  ...DEFAULT_TEMPLATE_FORM_VALUES,
                  ...values,
                  template: { title: ref.current.value || 'Template' },
                  frameText: values?.frameText || '',
                  libraryId: values?.libraryId === NO_LOGO_ID ? null : values.libraryId,
                },
                {
                  onSuccess: () => {
                    toast.success(t('toaster.templateSaved'));
                  },
                }
              );
            }
          }}
          containerClassName="min-w-[300px] sm:min-w-[350px]"
          title="Save design as template?"
          confirmText={t('dialog.save')}
          actionsContainerClassName="flex-row-reverse"
        >
          <div>
            <QRCodeRenderer
              asPreview
              {...extractStylePropsFromQRForm(formLatestValue)}
              frameClassName="min-w-[160px] max-w-[250px] flex-1 bg-transparent p-0 lg:min-w-[240px] 2xl:max-w-[60%]"
            />
          </div>
          <div className="my-6 w-full">
            <Input
              ref={ref}
              label="Enter a name for your template"
              withBG
              placeholder="Enter"
              defaultValue="QR code template"
            />
          </div>
        </ConfirmDialog>
      </div>
      <div
        className={clsxm(
          'grid grid-cols-2 gap-4',
          'sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-5'
        )}
      >
        {!!totalTemplates &&
          allTemplatesInOnePage?.map((template) => {
            const isSelected = templateId === template?.template?.id;
            return (
              <ConfirmDialog
                key={template.id}
                disabled={isSelected}
                trigger={
                  <Box
                    className={clsxm(
                      'flex flex-col overflow-hidden rounded-xl border border-gray-200',
                      !isSelected && 'hover:cursor-pointer hover:border-accept-hover'
                    )}
                  >
                    <div className="relative flex-1">
                      <QRCodeRenderer
                        asPreview
                        {...extractStylePropsFromQRDesign(template)}
                        frameClassName={clsxm(
                          'z-0 w-full rounded-none border-none bg-white p-2',
                          isSelected && 'opacity-50'
                        )}
                      />
                      {isSelected && (
                        <div className="absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center">
                          <ConfirmDialog
                            trigger={
                              <IconButton variant="transparent" className="bg-white">
                                <DeleteIcon />
                              </IconButton>
                            }
                            onConfirm={() => {
                              setValue('templateId', null);
                            }}
                            title="Delete this design?"
                            text="Previous design will be applied."
                            confirmText={t('dialog.yesDelete')}
                            textClassName="text-center mb-8 text-gray-500"
                            titleClassName="mb-2"
                          />
                        </div>
                      )}
                    </div>
                    <div className="bg-gray-100 px-1 py-3 text-center text-gray-700">
                      {template?.template?.title || ''}
                    </div>
                  </Box>
                }
                onConfirm={() => {
                  if (template?.template?.id) {
                    // refactor - templateId not needed when override applied
                    // setValue('templateId', template.template.id);
                    // TODO test this logic more
                    // TODO reuse override via util
                    setValue('libraryId', template.library?.id);
                    setValue('frameType', template.frameType);
                    setValue('frameTextSize', template.frameTextSize);
                    setValue('frameTextColor', template.frameTextColor);
                    setValue('frameBackgroundColor', template.frameBackgroundColor);
                    setValue('backgroundColor', template.backgroundColor);
                    setValue('patternType', template.patternType);
                    setValue('cornerType', template.cornerType);
                    setValue('patternColor', template.patternColor);
                    setValue('cornerColor', template.cornerColor);
                    toast.success('The design is overwritten by selected template');
                  }
                }}
                containerClassName="max-w-[350px]"
                textClassName="text-center mb-6 text-gray-500"
                titleClassName="mb-2"
                text={`Are you sure you want to overwrite this design as ${template.template?.title} template?"`}
                title="Overwrite this design?"
                confirmText="Confirm Overwriting"
              />
            );
          })}
      </div>

      {!hideLoadMoreButton && (
        <div className="flex justify-center">
          <Button
            onClick={() => {
              fetchNextPage();
            }}
            className="w-[200px]"
            loading={isFetching}
            variant="text"
          >
            {t('search.showMore')}
          </Button>
        </div>
      )}
    </div>
  );
};
